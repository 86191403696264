import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  FinishEvent,
  GetSingleStat,
  ReqTourStatGet,
  TourMake,
  TourUpdate,
} from '../../proto/leaderboard_pb';
import {
  LeaderboardTourGetRoute,
  LeaderboardTourMakeRoute,
  LeaderboardTourUpdateRoute,
  LeaderboardTourFinishRoute,
  LeaderboardTourGetCurrentRoute,
  LeaderboardSingleStatGetRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export class TournamentsStore {
  public error: RpcError | null = null;

  public current: LeaderboardTourGetCurrentRoute.ResponseType | null = null;

  public singleTourStat: LeaderboardSingleStatGetRoute.ResponseType = {
    entities: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  public tournaments: LeaderboardTourGetRoute.ResponseType = {
    entities: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getTournament(dto: Partial<ReqTourStatGet.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardTourGetRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardTourGetRoute.ResponseType, LeaderboardTourGetRoute.RequestType>({
          method: 'leaderboard_tournamentGet',
          data: {
            amountPerPage: dto.amountperpage || 10,
            pageNumber: dto.pagenumber || 1,
            seasonId: dto.seasonid || 0,
          },
        });

      this.tournaments = response;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *createTournament(dto: TourMake.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardTourMakeRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardTourMakeRoute.ResponseType, LeaderboardTourMakeRoute.RequestType>({
          method: 'leaderboard_tournamentMake',
          data: {
            endDate: dto.enddate,
            seasonId: dto.seasonid,
            startDate: dto.startdate,
            status: dto.status,
          },
        });

      if (response.tournament) {
        this.tournaments.entities = [
          response.tournament,
          ...this.tournaments.entities,
        ];
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateTournament(dto: TourUpdate.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardTourUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardTourUpdateRoute.ResponseType, LeaderboardTourUpdateRoute.RequestType>({
          method: 'leaderboard_tournamentUpdate',
          data: {
            id: dto.id,
            endDate: dto.enddate,
            startDate: dto.startdate,
          },
        });

      this.tournaments.entities = this.tournaments.entities.map(
        (item) => {
          if (response.tournament && item.id === response.tournament.id) {
            return {
              ...response.tournament,
              startDate: response.tournament.startDate || item.startDate,
              endDate: response.tournament.endDate || item.endDate,
            };
          }

          return item;
        },
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *finishTournament(dto: FinishEvent.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardTourFinishRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardTourFinishRoute.ResponseType, LeaderboardTourFinishRoute.RequestType>({
          method: 'leaderboard_tournamentFinish',
          data: {
            id: dto.id,
            endDate: dto.enddate,
          },
        });

      this.tournaments.entities = this.tournaments.entities.map(
        (item) => {
          if (response.tournament && item.id === response.tournament.id) {
            return response.tournament;
          }

          return item;
        },
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getCurrentTournament(dto: FinishEvent.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardTourGetCurrentRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardTourGetCurrentRoute.ResponseType, LeaderboardTourGetCurrentRoute.RequestType>({
          method: 'leaderboard_tournamentGetCurrent',
          data: {
            id: dto.id,
          },
        });

      this.current = response;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getSingleTourStat(dto: GetSingleStat.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardSingleStatGetRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardSingleStatGetRoute.ResponseType, LeaderboardSingleStatGetRoute.RequestType>({
          method: 'leaderboard_singleStatGet',
          data: {
            tourId: dto.tourid,
            order: dto.order || 'ASC',
            pageNumber: dto.pagenumber || 1,
            amountPerPage: dto.amountperpage || 10,
            orderBy: dto.orderby || 'place',
          },
        });

      this.singleTourStat = response;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
