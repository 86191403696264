import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  CreateVersion,
  FindVersion,
  UpdateVersion,
} from '../../proto/balance_pb';
import {
  BalanceBalanceVersionCreateRoute,
  BalanceBalanceVersionDeleteRoute,
  BalanceBalanceVersionGetRoute,
  BalanceBalanceVersionUpdateRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export class CashoutVersionStore {
  public error: RpcError | null = null;

  public versions: BalanceBalanceVersionGetRoute.ResponseType = {
    entities: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getCashoutVersions(dto: FindVersion.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: BalanceBalanceVersionGetRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceBalanceVersionGetRoute.ResponseType, BalanceBalanceVersionGetRoute.RequestType>({
          method: 'balance_versionGet',
          data: {
            amountPerPage: dto.amountperpage || 10,
            pageNumber: dto.pagenumber || 1,
          },
        });

      this.versions = response;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *createCashoutVersion(dto: CreateVersion.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: BalanceBalanceVersionCreateRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceBalanceVersionCreateRoute.ResponseType, BalanceBalanceVersionCreateRoute.RequestType>({
          method: 'balance_versionCreate',
          data: {
            os: dto.os,
            inAppStatus: dto.inappstatus,
            value: dto.value,
          },
        });

      this.versions.entities = [response, ...this.versions.entities];
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateCashoutVersion(dto: UpdateVersion.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: BalanceBalanceVersionUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceBalanceVersionUpdateRoute.ResponseType, BalanceBalanceVersionUpdateRoute.RequestType>({
          method: 'balance_versionUpdate',
          data: {
            id: dto.id,
            inAppStatus: dto.inappstatus,
            os: dto.os,
            value: dto.value,
          },
        });

      this.versions.entities = this.versions.entities.map(
        (item) => {
          if (response.id === item.id) {
            return {
              id: item.id,
              os: response.os || item.os,
              inAppStatus: response.inAppStatus || item.inAppStatus,
              value: response.value || item.value,
            };
          }

          return item;
        },
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *deleteCashOutVersion(id: number) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: BalanceBalanceVersionDeleteRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceBalanceVersionDeleteRoute.ResponseType, BalanceBalanceVersionDeleteRoute.RequestType>({
          method: 'balance_versionDelete',
          data: {
            id,
          },
        });

      this.versions.entities = this.versions.entities.filter(
        (item) => item.id !== response.id,
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
