import React, {useEffect} from 'react';
import {observer} from 'mobx-react';
import {useSnackbar} from 'notistack';
import {Notification} from '../../stores';
import {useStores} from '../../stores/app';

export const Notifier = observer(() => {
  const {notificationStore} = useStores();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  useEffect(() => {
    notificationStore.notifications.forEach(
      ({key, message, options, dismissed = false}: Notification) => {
        if (dismissed) {
          closeSnackbar(key);
          return;
        }

        if (notificationStore.displayed.includes(key)) {
          return;
        }

        enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, myKey) => {
            if (options?.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            notificationStore.removeSnackBar(myKey);
            notificationStore.removeDisplayed(myKey);
          },
        });

        notificationStore.storeDisplayed(key);
      }
    );
  }, [notificationStore.notifications.length]);

  return null;
});
