import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { Buffer } from 'buffer';
import { AppStore } from '../app';
import { WebSocketService } from '../../services/transport';
import { AuthAuthLoginByUsernameRoute } from '@boints/grpc';

type FormKeys = 'username' | 'password';
type FormFields = Record<FormKeys, string>;

const initialValues: FormFields = { username: '', password: '' };

export class AuthStore {
  public error: RpcError | null = null;

  public accessToken = localStorage.getItem('accessToken') || '';
  public userName = localStorage.getItem('userName') || '';
  public fields = initialValues;
  public visible = false;

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  get isLoggedIn(): boolean {
    if (this.accessToken) {
      const isExpired = JSON.parse(Buffer.from(this.accessToken.split('.')[1], 'base64').toString()).exp * 1000 < Date.now();
      if (isExpired) {
        this.logout();
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  get isSubmitDisabled(): boolean {
    const { username, password } = this.fields;
    return username === '' && password === '';
  }

  get isVisible(): boolean {
    return this.visible;
  }

  toggleVisible() {
    this.visible = !this.visible;
  }

  onChangeLoginInput(name: FormKeys, value: string) {
    this.fields[name] = value;
  }

  onLoginSubmit() {
    const { username, password } = this.fields;
    this.loginByUsername(username, password);
  }

  *loginByUsername(username: string, password: string) {
    try {
      const { accessToken }: AuthAuthLoginByUsernameRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthAuthLoginByUsernameRoute.ResponseType, AuthAuthLoginByUsernameRoute.RequestType>({
          method: 'auth_loginByUsername',
          data: {
            username,
            password,
          },
        });

      this.rootStore.isLoading = true;
      this.error = null;

      this.accessToken = accessToken;
      this.userName = username;
      this.resetForm();
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userName', username);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  logout() {
    this.accessToken = '';
    this.userName = '';
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userName');
  }

  resetForm() {
    this.fields = initialValues;
  }
}
