import React, {useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextField,
  Box,
  Typography,
  MenuItem,
  Select,
} from '@mui/material';
import {getExportTicketsStyle} from './add-black-white-list.styles';
import {Add} from '@mui/icons-material';
import {AddBlackWhiteList} from '../../../proto/auth_pb';

interface OwnProps {
  addBlackWhiteListRequest: (data: AddBlackWhiteList.AsObject) => void;
  handleUpdatePage?: () => void;
  isWhiteList?: boolean;
}

export const AddBlackWhiteListModal: React.FC<OwnProps & any> = ({
  addBlackWhiteListRequest,
  handleUpdatePage,
  isWhiteList,
}) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<any>({
    value: '',
  });

  const classes = getExportTicketsStyle();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setState({value: ''});
  };

  const onSubmit = (values: any) => {
    addBlackWhiteListRequest(values);

    handleClose();
    if (handleUpdatePage) {
      handleUpdatePage();
    }
  };

  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        startIcon={<Add />}
      >
        create new
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.root,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>
                Create new BlackList item
              </Typography>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  onChange={handleChange}
                  defaultValue={''}
                  name="value"
                  label={'User Id'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              {isWhiteList && (
                <Box
                  className={classes.textField}
                  sx={{justifyContent: 'space-evenly'}}
                >
                  <Select
                    className={classes.input}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="type"
                    defaultValue={'id'}
                    label={'Value type'}
                    onChange={(e) => {
                      handleChange(e as any);
                    }}
                  >
                    <MenuItem value={'id'}>Id</MenuItem>
                    <MenuItem value={'ip'}>Ip Address</MenuItem>
                    <MenuItem value={'email'}>E-mail</MenuItem>
                  </Select>
                </Box>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(state)}
            color="primary"
            autoFocus
            disabled={!state.value}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
