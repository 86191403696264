import { observer } from 'mobx-react';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { CheckBox, IndeterminateCheckBox } from '@mui/icons-material';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import { getWithdrawalTicketsStyle } from './withdrawal-tickets.styles';
import {
  RequestGetTicketDto,
  RequestUpdateTicketDto,
  TicketDto,
} from '../../proto/cashout_pb';
import { UpdateTicketStatusDialog } from '../../components/dialogs';
import { ExportTickets } from '../../components/modals';
import { UserQualityCashoutStatus } from '@boints/types';
import { getUQMarkerStatusDescription } from '../../stores';

const fraudStatusKeys: Record<string, string> = {
  1: 'Pass it',
  2: 'Need to check (no adjoe transaction since last cashout)',
  3: 'Suspicious (no adjoe transactions)',
  4: 'Processing',
  5: 'Suspicious (Email was used by another user)',
  6: 'Suspicious (Didn\'t pass user quality checks)',
};

const TicketStatus: Record<number, string> = {
  1: 'Active',
  2: 'Accepted',
  3: 'Declined',
  4: 'Pending',
  5: 'Error',
  6: 'Created',
};

const payment: Record<number, string> = {
  1: 'PayPal',
  2: 'Amazon',
  3: 'Instant',
};

const donateMethodKeys: Record<string, string> = {
  1: '-',
  2: 'Red cross',
  3: 'World Vision',
};

const getDonateMethod = (method: number): string => {
  return donateMethodKeys[method];
};

const convertWithdrawalMethod = (a: number) => {
  return payment[a];
};

const getTicketStatus = (status: number): string => {
  return TicketStatus[status];
};

const dateFormat = (value: any): string => {
  if (Number(value) !== 0) {
    return moment(+value).format('MM/DD/YYYY, HH:mm:ss');
  } else {
    return '-';
  }
};

export const WithdrawalTickets = observer(() => {
  const { ticketStore } = useStores();
  const classes = getWithdrawalTicketsStyle();

  const changeTicketStatus = (dto: RequestUpdateTicketDto.AsObject) =>
    ticketStore.changeTicketStatus(dto);

  const loadTickets = (dto: RequestGetTicketDto.AsObject) => {
    if (dto.order === 'navButtons') {
      return;
    }

    ticketStore.getTickets(dto);
  };

  const getFraudScore = (ipScore: number): string => {
    return ipScore || ipScore >= 0 ? ipScore.toString() : 'unknown';
  };

  const getTicketFraudStatus = (props: TicketDto.AsObject) => {
    const fraudToClass: Record<number, string> = {
      1: classes.fraudPass,
      2: classes.fraudCheck,
      3: classes.fraudSuspect,
      4: classes.fraudPending,
      5: classes.fraudSuspect,
    };
    return (
      <Tooltip
        title={fraudStatusKeys[props.fraudcheck]}
        className={
          fraudToClass[props.fraudcheck as number] || classes.fraudSuspect
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
        </svg>
      </Tooltip>
    );
  };

  const getUQMarkerStatus = (props: TicketDto.AsObject) => {
    const fraudToClass: Record<UserQualityCashoutStatus, string> = {
      [UserQualityCashoutStatus.PASSED]: classes.fraudPass,
      [UserQualityCashoutStatus.OFFLINE]: classes.fraudCheck,
      [UserQualityCashoutStatus.FAILED]: classes.fraudSuspect,
    };
    return (
      <Tooltip
        title={getUQMarkerStatusDescription(props.userqualitycashoutstatus)}
        className={
          fraudToClass[props.userqualitycashoutstatus as number] || classes.fraudSuspect
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
        </svg>
      </Tooltip>
    );
  };

  const getDonateCheck = (props: TicketDto.AsObject) => {
    return <>{props.donate ? <CheckBox /> : <IndeterminateCheckBox />}</>;
  };

  const columns: Column[] = [
    { id: 'id', label: 'Ticket ID', minWidth: 90 },
    { id: 'userid', label: 'User ID', minWidth: 90 },
    { id: 'currency', label: 'Currency', minWidth: 90 },
    { id: 'amount', label: 'Amount', minWidth: 110 },
    { id: 'email', label: 'Email', minWidth: 220 },
    {
      id: 'method',
      label: 'Withdrawal Method',
      minWidth: 130,
      format: convertWithdrawalMethod,
    },
    { id: 'userip', label: 'User IP', minWidth: 120 },
    //TODO: Sort key don't compare with back (why?)
    {
      id: 'version',
      label: 'Build Number',
      sortKey: 'version',
      disableSort: true,
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 80,
      format: getTicketStatus,
    },
    {
      id: 'createdate',
      label: 'Date Created',
      sortKey: 'createdate',
      minWidth: 100,
      format: dateFormat,
    },
    {
      id: 'fraudcheck',
      label: 'Adjoe Marker',
      sortKey: 'fraudCheck',
      minWidth: 90,
      component: getTicketFraudStatus,
    },
    {
      id: 'userqualitycashoutstatus',
      label: 'UQS Marker',
      sortKey: 'userqualitycashoutstatus',
      minWidth: 90,
      component: getUQMarkerStatus,
    },
    {
      id: 'donate',
      label: 'Donate',
      minWidth: 70,
      component: getDonateCheck,
    },
    {
      id: 'donatemethod',
      label: 'Donate Method',
      minWidth: 70,
      format: getDonateMethod,
    },
    {
      id: 'ipfraudscore',
      label: 'IP Fraud',
      minWidth: 110,
      format: getFraudScore,
    },
    {
      id: 'emailfraudscore',
      label: 'Email Fraud',
      minWidth: 110,
      format: getFraudScore,
    },
    {
      id: 'ipcountrycode',
      label: 'IP Country Code',
      minWidth: 110,
      format: (code) => (code ? code : 'unknown'),
    },
    {
      id: 'emailuseractivity',
      label: 'User Activity',
      minWidth: 110,
      format: (ua) => (ua ? ua : 'unknown'),
    },
    {
      id: 'playintegrityinfo',
      label: 'App Integrity',
      minWidth: 110,
      disableSort: true,
      format: (verdict) => (verdict ? verdict.appintegrity : 'unknown'),
    },
    {
      id: 'playintegrityinfo',
      label: 'Account Details',
      minWidth: 110,
      disableSort: true,
      format: (verdict) => (verdict ? verdict.accountdetails : 'unknown'),
    },
    {
      id: 'playintegrityinfo',
      label: 'Device Integrity',
      minWidth: 110,
      disableSort: true,
      format: (verdict) => (verdict ? verdict.deviceintegrity : 'unknown'),
    },
    {
      id: 'fraudstatusreason',
      label: 'Fraud status reason',
      minWidth: 110,
      disableSort: true,
      format: (reason) => (reason ? reason : '-'),
    },
    {
      id: 'navButtons',
      label: '',
      minWidth: 120,
      component: (props: any) => (
        <UpdateTicketStatusDialog
          row={props}
          changeStatusAction={changeTicketStatus}
        />
      ),
    },
  ];

  return (
    <CustomTable
      data={ticketStore.tickets.dataList}
      totalCount={ticketStore.tickets.totalcount}
      requestAction={loadTickets}
      columns={columns}
      defaultSortKey="id"
      isSearchShow
      isDataSliced
      searchInputText="Type Ticket ID, Email or IP here"
      pageAction={() => <ExportTickets />}
    />
  );
});
