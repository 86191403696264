import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { getTestsStyle } from './tests.styles';
import { GetListRequest, GetListResponse, GetListResponse_ParamsEntry, GetTestsWithPaginationRequest,
  GetTestsWithPaginationResponse,LogType, RunMultipleRequest,
  RunMultipleResponse, RunPerformanceRequest, RunPerformanceResponse, TestStatus, UpdateTestStatusBroadcast,
} from '../../core/interfaces/tests.interfaces';
import { Pagination, Select, FormControl, MenuItem, InputLabel, SelectChangeEvent, capitalize } from '@mui/material';
import { WebSocketService } from '../../services/transport';
import { PerformanceTestDialog } from './performance-test-dialog';
import { TestParamsDialog } from './test-params-dialog';

export const Tests = observer(() => {
  const { notificationStore } = useStores();
  const classes = getTestsStyle();

  const perPage = 10;

  const [testsList, setTestsList] = useState<{ testName: string, paramsSchema: GetListResponse_ParamsEntry[] }[]>([]);
  const [openedTest, setOpenedTest] = useState<number>();
  const [tests, setTests] = useState<UpdateTestStatusBroadcast[]>([]);
  const [testsCount, setTestsCount] = useState<number>(20);
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [page, setPage] = useState<number>(1);
  const [newTest, setNewTest] = useState<UpdateTestStatusBroadcast>();

  const loadPage = () => {
    const getTests = async () => {
      const tests = await WebSocketService
        .sendRequest<GetTestsWithPaginationResponse, GetTestsWithPaginationRequest>({
          method: 'tests_getTestsWithPagination', data: { page, perPage },
        });

      setTestsCount(tests.count);

      setTests(tests.tests.map((v) => ({
        id: v.id,
        method: v.method,
        status: v.status as unknown as TestStatus,
        logs: v.logs as LogType[],
        createdAt: v.createdAt,
      })));

    };

    getTests().catch((e) => {
      notificationStore.enqueueSnackBar({
        variant: 'error',
        message: (e as Error).message,
      });
    });
  };

  useEffect(() => {
    if (newTest) {
      const testIdx = tests.findIndex((v) => v.id === newTest.id);

      if (testIdx !== -1) {
        setTests(tests.map((v, i) => i === testIdx ? newTest : v));
  
        return;
      }
  
      setTestsCount(testsCount + 1);
  
      setTests([newTest, ...tests.slice(0, 9)]);
    }
  }, [newTest]);

  useEffect(() => {
    loadPage();
  }, [page]);

  useEffect(() => {
    loadPage();

    const getTestsList = async () => {
      const testsList = await WebSocketService.sendRequest<GetListResponse, GetListRequest>({ method: 'tests_getTestsList', data: {} });

      setTestsList(testsList.tests.map(({ name, params }) => ({ testName: name, paramsSchema: params })));
    };

    getTestsList().catch((e) => {
      notificationStore.enqueueSnackBar({
        variant: 'error',
        message: (e as Error).message,
      });
    });

    WebSocketService.subscribeToBroadcast<UpdateTestStatusBroadcast>({
      type: 'updateTestStatus',
      cb: (value) => {
        setNewTest(value);
      },
    });
  }, []);

  const handleOpenTest = (testIdx: number) => {
    setOpenedTest(testIdx);
  };

  const handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleSelectedTestsChange = (event: SelectChangeEvent) => {
    setSelectedTests(event.target.value as unknown as string[]);
  };

  const handleRunTests = async (tests: { testName: string, params: { [k: string]: string } }[] ) => {
    if (!tests?.length) {
      notificationStore.enqueueSnackBar({
        variant: 'error',
        message: 'Select the test',
      });
      return;
    }

    try {
      await WebSocketService
        .sendRequest<RunMultipleRequest, RunMultipleResponse>({
          method: 'tests_runTests',
          data: {
            tests: tests.map(({ params, testName }) => ({ testName, params: Object.entries(params).map(([key, type]) => ({ key, type })) })),
          },
        });
    } catch(e) {
      notificationStore.enqueueSnackBar({
        variant: 'error',
        message: (e as Error).message,
      });
      return;
    }
  };

  const handleRunPerformanceTest = async ({ runInterval, testsCount }: { testsCount: number, runInterval: number }) => {
    const testName = selectedTests[0];

    if (!testName) {
      notificationStore.enqueueSnackBar({ variant: 'error', message: 'Select the test' });
      return;
    }

    if (!testsCount || !runInterval) {
      notificationStore.enqueueSnackBar({
        variant: 'error', message: 'Enter not 0 params',
      });
      return;
    }

    try {
      await WebSocketService
        .sendRequest<RunPerformanceRequest, RunPerformanceResponse>({
          method: 'tests_runPerformanceTest',
          data: { testsCount: +testsCount, runInterval: +runInterval, testName, params: [] },
        });
    } catch (e) {
      notificationStore.enqueueSnackBar({
        variant: 'error', message: (e as Error).message,
      });
    }
  };

  const logsJsx = typeof openedTest !== 'number' ? '' : tests[openedTest].logs.map((log, index) => (
    <div className="row" key={index}>
      <div className="col-12">
        <div className={[classes.log, classes[`logContainer${capitalize(log.type)}` as 'log']].join(' ')}>
          [{log.type.toUpperCase()}]&nbsp;
          {log.msg}
        </div>
      </div>
    </div>
  ));

  const testsJsx = tests.map((test, i) => {
    let className;
    if (test.status === TestStatus.failed) className = classes.testItemFailed;
    else if (test.status === TestStatus.passed) className = classes.testItemSuccess;

    return (
      <div className="row" key={test.id}>
        <div className="col-12">
          <button className={[classes.testItem, className].join(' ') } onClick={() => handleOpenTest(i)}>
            <span className="row flex-nowrap justify-content-between">
              <span className="col-auto">
                { test.id }
              </span>
              <span className="col-auto">
                { test.method}
              </span>
              <span className="col-auto">
                { new Date(test.createdAt).toISOString().split('T')[0] }
              </span>
            </span>
          </button>
        </div>
      </div>
    );
  });

  return (
    <div className={['container', classes.mt24].join(' ')}>
      <h1>Tests</h1>

      <div className="row justify-content-between">
        <div className="col-8">
          <div className="col-12">
            <h3 className={classes.mt16}>Logs</h3>
          </div>

          <div className={['col-12', classes.logContainer].join(' ')} style={{ overflowY: 'auto', maxHeight: '600px' }}>
            {logsJsx}
          </div>
            
        </div>

        <div className="col-4">
          <div className="row">
            <div className="col-12">
              <h3>Run test</h3>
            </div>
            <div className="col-12">
              <FormControl fullWidth>
                <InputLabel id="select-test-label">Tests</InputLabel>
                <Select
                  labelId="select-test-label"
                  id="select-test"
                  value={selectedTests as unknown as string}
                  label="Tests"
                  onChange={handleSelectedTestsChange}
                  multiple
                >
                  {testsList.map(({ testName }) => (<MenuItem key={testName} value={testName}>{testName}</MenuItem>))}
                </Select>
              </FormControl>
            </div>

            <div className={['col-12', classes.mt24].join(' ')}>
              <div className="row justify-content-between">
                <TestParamsDialog
                  tests={testsList.filter(({ testName }) => selectedTests.includes(testName))}
                  runTestAction={handleRunTests}
                />
                <PerformanceTestDialog
                  runTestAction={handleRunPerformanceTest}
                  testName={selectedTests[0]}
                />
              </div>
            </div>
          </div>

          <div className={['col-12', classes.mt24].join(' ')}>
            {testsJsx}
          </div>

          <div className={['col-12', classes.mt16].join(' ')}>
            <Pagination
              color="primary"
              count={Math.ceil(testsCount / perPage)}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>

    </div>
  );
});
