import React, {useState} from 'react';
import {withStyles} from '@mui/styles';
import {
  Dialog,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import {getDialogStyles} from './custom-dialog.styles';
import {EditableCell} from './editable-cell';
import { Profile } from '../../../proto/auth_pb';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ResetUserButton } from '../../misc';
import { AuthUserResetRoute } from '@boints/grpc';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  updateStatusAction?: ({ userId, userStatus }: { userId: number, userStatus: number }) => void;
  updateBalance?: any;
  user: Profile.AsObject | null;
  userReset: (dto: Omit<AuthUserResetRoute.RequestType, 'accessToken'>) => Generator<Promise<unknown>, void, unknown>;
  refreshResetFn?: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = OwnProps;

const StyledDialog = withStyles({
  paper: {
    width: '100%',
  },
})(Dialog);

export type Fields = 'Boints';

const fields: Fields[] = ['Boints'];

export const CustomDialog: React.FC<Props> = ({
  open,
  onClose,
  user,
  updateStatusAction,
  updateBalance,
  userReset,
  refreshResetFn,
}) => {
  const classes = getDialogStyles();
  const [openBan, setOpenBan] = useState<boolean>(false);

  if (!user) {
    return null;
  }

  const {balanceboints} = user;

  const valuesField: Record<string, number> = {
    Boints: balanceboints,
  };

  const handleClickOpen = () => {
    setOpenBan(true);
  };

  const handleCloseBanModal = () => {
    setOpenBan(false);
  };

  const refrestReset = (val: boolean) => {
    if (refreshResetFn) {
      refreshResetFn(val);
    }
    onClose();
  }

  const handleAgree = () => {
    if (updateStatusAction) {
      updateStatusAction({
        userId: user.id,
        userStatus: 2,
      });
    }

    setOpenBan(false);
    onClose();
  };
  return (
    <StyledDialog onClose={onClose} open={open}>
      <Dialog
        open={openBan}
        onClose={handleCloseBanModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want ban user with id {user.id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseBanModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAgree} color="secondary" autoFocus>
            Ban
          </Button>
        </DialogActions>
      </Dialog>
      <Box className={classes.container}>
        <DialogTitle className={classes.titleContainer}>
          User Options
          <ManageAccountsIcon fontSize='large' />
        </DialogTitle>
        {"Id: "}{user.id}
        <br />
        {"Login: "}{user.name}
        {fields.map((item: Fields) => (
          <EditableCell
            updateBalance={updateBalance}
            key={item}
            label={item}
            id={user.id}
            defaultValue={valuesField[item]}
          />
        ))}
      </Box>
      <Box className={classes.btnContainer}>
        <Box className={classes.banResetContainer}>
          <Button onClick={() => handleClickOpen()} className={classes.banReserBtn} variant="contained" color="error" >
            Ban
          </Button>
          <ResetUserButton
            userResetAction={userReset}
            refreshAction={refrestReset}
            initialSelectedUser={{ id: user.id, name: user.name }}
          />
        </Box>
        <Box>
          <Button onClick={onClose} className={classes.cancelContainer}>
            Cancel
          </Button>
        </Box>
      </Box>
    </StyledDialog>
  );
};
