// source: src/proto/reward.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var custom_stats_pb = require('./custom/stats_pb.js');
goog.object.extend(proto, custom_stats_pb);
goog.exportSymbol('proto.reward.CalcProcessRequest', null, global);
goog.exportSymbol('proto.reward.CalcProcessResponse', null, global);
goog.exportSymbol('proto.reward.CloseTaskListRequest', null, global);
goog.exportSymbol('proto.reward.CreateDoubleBoints', null, global);
goog.exportSymbol('proto.reward.CreateStepBonusConfigRequest', null, global);
goog.exportSymbol('proto.reward.CreateStepBonusConfigResponse', null, global);
goog.exportSymbol('proto.reward.CurrUserGames', null, global);
goog.exportSymbol('proto.reward.DefaultResponse', null, global);
goog.exportSymbol('proto.reward.DeletStepBonusConfigRequest', null, global);
goog.exportSymbol('proto.reward.DeleteDoubleBoints', null, global);
goog.exportSymbol('proto.reward.DeleteStageOrderRequest', null, global);
goog.exportSymbol('proto.reward.DoubleBoints', null, global);
goog.exportSymbol('proto.reward.DoubleBointsMany', null, global);
goog.exportSymbol('proto.reward.EditStepBonusConfigRequest', null, global);
goog.exportSymbol('proto.reward.EditStepBonusConfigResponse', null, global);
goog.exportSymbol('proto.reward.Empty', null, global);
goog.exportSymbol('proto.reward.FindDoubleBoints', null, global);
goog.exportSymbol('proto.reward.FindFinishedTasklistCountRequest', null, global);
goog.exportSymbol('proto.reward.FindFinishedTasklistCountResponse', null, global);
goog.exportSymbol('proto.reward.GetByOrderAndVersionRequest', null, global);
goog.exportSymbol('proto.reward.GetMCEventResponse', null, global);
goog.exportSymbol('proto.reward.GetMcUsersStats', null, global);
goog.exportSymbol('proto.reward.GetMcUsersStatsRequest', null, global);
goog.exportSymbol('proto.reward.GetStepBonusConfigResponse', null, global);
goog.exportSymbol('proto.reward.GetStrikeBonusByUserId', null, global);
goog.exportSymbol('proto.reward.GetUserActiveTaskListsResponse', null, global);
goog.exportSymbol('proto.reward.GetUserCurrTaskListResponse', null, global);
goog.exportSymbol('proto.reward.GetUsersMcStatsRequest', null, global);
goog.exportSymbol('proto.reward.GetUsersMcStatsResponse', null, global);
goog.exportSymbol('proto.reward.HealthCheckGrpcResponse', null, global);
goog.exportSymbol('proto.reward.HealthCheckRequest', null, global);
goog.exportSymbol('proto.reward.HealthCheckResponse', null, global);
goog.exportSymbol('proto.reward.HealthCheckResponse.ServingStatus', null, global);
goog.exportSymbol('proto.reward.IsDoubleBointsActive', null, global);
goog.exportSymbol('proto.reward.IsWorkerEnabledResponse', null, global);
goog.exportSymbol('proto.reward.JwtAuth', null, global);
goog.exportSymbol('proto.reward.MCGroup', null, global);
goog.exportSymbol('proto.reward.MCStage', null, global);
goog.exportSymbol('proto.reward.MCStep', null, global);
goog.exportSymbol('proto.reward.McUserStat', null, global);
goog.exportSymbol('proto.reward.OfferWallRewardEntity', null, global);
goog.exportSymbol('proto.reward.OfferWallRewardFindAllRequest', null, global);
goog.exportSymbol('proto.reward.OfferWallRewardFindAllResponse', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaCreateRequest', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaCreateResponse', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaData', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaEntity', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaFindAllRequest', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaFindAllResponse', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaRewardTypeCondition', null, global);
goog.exportSymbol('proto.reward.OfferWallSchemaUpdate', null, global);
goog.exportSymbol('proto.reward.ProcessEventRequest', null, global);
goog.exportSymbol('proto.reward.ProcessEventResponse', null, global);
goog.exportSymbol('proto.reward.ReciveMCEventRequest', null, global);
goog.exportSymbol('proto.reward.RequestSetCashOutStage', null, global);
goog.exportSymbol('proto.reward.RequestSetCurrTutorialStage', null, global);
goog.exportSymbol('proto.reward.ResponseGetCashOutStage', null, global);
goog.exportSymbol('proto.reward.ResponseGetCurrTutorialStage', null, global);
goog.exportSymbol('proto.reward.RewardStagePattern', null, global);
goog.exportSymbol('proto.reward.RewardStagePatternCreateRequest', null, global);
goog.exportSymbol('proto.reward.RewardStagePatternDeleteRequest', null, global);
goog.exportSymbol('proto.reward.RewardStagePatternFindOneRequest', null, global);
goog.exportSymbol('proto.reward.RewardStagePatternUpdateRequest', null, global);
goog.exportSymbol('proto.reward.RewardStagePatterns', null, global);
goog.exportSymbol('proto.reward.RewardStageStep', null, global);
goog.exportSymbol('proto.reward.RewardType', null, global);
goog.exportSymbol('proto.reward.StageOrder', null, global);
goog.exportSymbol('proto.reward.StageOrderFindAllResponse', null, global);
goog.exportSymbol('proto.reward.StageOrderUpdate', null, global);
goog.exportSymbol('proto.reward.StepBonus', null, global);
goog.exportSymbol('proto.reward.StepBonusWithCheck', null, global);
goog.exportSymbol('proto.reward.TimeSpan', null, global);
goog.exportSymbol('proto.reward.UpdateDoubleBoints', null, global);
goog.exportSymbol('proto.reward.UserGame', null, global);
goog.exportSymbol('proto.reward.UserMcStats', null, global);
goog.exportSymbol('proto.reward.UserProgress', null, global);
goog.exportSymbol('proto.reward.UserStageOrder', null, global);
goog.exportSymbol('proto.reward.getStepBonusConfigByUserIdResponse', null, global);
goog.exportSymbol('proto.reward.isShowToManyTaskListPopUpResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetByOrderAndVersionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.GetByOrderAndVersionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetByOrderAndVersionRequest.displayName = 'proto.reward.GetByOrderAndVersionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetMcUsersStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.GetMcUsersStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetMcUsersStatsRequest.displayName = 'proto.reward.GetMcUsersStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetMcUsersStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.GetMcUsersStats.repeatedFields_, null);
};
goog.inherits(proto.reward.GetMcUsersStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetMcUsersStats.displayName = 'proto.reward.GetMcUsersStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.McUserStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.McUserStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.McUserStat.displayName = 'proto.reward.McUserStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CurrUserGames = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.CurrUserGames.repeatedFields_, null);
};
goog.inherits(proto.reward.CurrUserGames, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CurrUserGames.displayName = 'proto.reward.CurrUserGames';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.UserGame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.UserGame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.UserGame.displayName = 'proto.reward.UserGame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.UserStageOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.UserStageOrder.repeatedFields_, null);
};
goog.inherits(proto.reward.UserStageOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.UserStageOrder.displayName = 'proto.reward.UserStageOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.UserProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.UserProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.UserProgress.displayName = 'proto.reward.UserProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.StageOrderFindAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.StageOrderFindAllResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.StageOrderFindAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.StageOrderFindAllResponse.displayName = 'proto.reward.StageOrderFindAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.DeleteStageOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.DeleteStageOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.DeleteStageOrderRequest.displayName = 'proto.reward.DeleteStageOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.StageOrderUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.StageOrderUpdate.repeatedFields_, null);
};
goog.inherits(proto.reward.StageOrderUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.StageOrderUpdate.displayName = 'proto.reward.StageOrderUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.StageOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.StageOrder.repeatedFields_, null);
};
goog.inherits(proto.reward.StageOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.StageOrder.displayName = 'proto.reward.StageOrder';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.MCStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.MCStage.repeatedFields_, null);
};
goog.inherits(proto.reward.MCStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.MCStage.displayName = 'proto.reward.MCStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.MCGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.MCGroup.repeatedFields_, null);
};
goog.inherits(proto.reward.MCGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.MCGroup.displayName = 'proto.reward.MCGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.MCStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.MCStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.MCStep.displayName = 'proto.reward.MCStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.FindDoubleBoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.FindDoubleBoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.FindDoubleBoints.displayName = 'proto.reward.FindDoubleBoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.DoubleBointsMany = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.DoubleBointsMany.repeatedFields_, null);
};
goog.inherits(proto.reward.DoubleBointsMany, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.DoubleBointsMany.displayName = 'proto.reward.DoubleBointsMany';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.DoubleBoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.DoubleBoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.DoubleBoints.displayName = 'proto.reward.DoubleBoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.DeleteDoubleBoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.DeleteDoubleBoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.DeleteDoubleBoints.displayName = 'proto.reward.DeleteDoubleBoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CreateDoubleBoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.CreateDoubleBoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CreateDoubleBoints.displayName = 'proto.reward.CreateDoubleBoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.UpdateDoubleBoints = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.UpdateDoubleBoints, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.UpdateDoubleBoints.displayName = 'proto.reward.UpdateDoubleBoints';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.IsDoubleBointsActive = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.IsDoubleBointsActive, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.IsDoubleBointsActive.displayName = 'proto.reward.IsDoubleBointsActive';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CalcProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.CalcProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CalcProcessRequest.displayName = 'proto.reward.CalcProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CalcProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.CalcProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CalcProcessResponse.displayName = 'proto.reward.CalcProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CloseTaskListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.CloseTaskListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CloseTaskListRequest.displayName = 'proto.reward.CloseTaskListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetUsersMcStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.GetUsersMcStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetUsersMcStatsRequest.displayName = 'proto.reward.GetUsersMcStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetUsersMcStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.GetUsersMcStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.GetUsersMcStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetUsersMcStatsResponse.displayName = 'proto.reward.GetUsersMcStatsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.UserMcStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.UserMcStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.UserMcStats.displayName = 'proto.reward.UserMcStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetUserActiveTaskListsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.GetUserActiveTaskListsResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.GetUserActiveTaskListsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetUserActiveTaskListsResponse.displayName = 'proto.reward.GetUserActiveTaskListsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.HealthCheckGrpcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.HealthCheckGrpcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.HealthCheckGrpcResponse.displayName = 'proto.reward.HealthCheckGrpcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.Empty.displayName = 'proto.reward.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.JwtAuth = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.JwtAuth, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.JwtAuth.displayName = 'proto.reward.JwtAuth';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.HealthCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.HealthCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.HealthCheckRequest.displayName = 'proto.reward.HealthCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.HealthCheckResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.HealthCheckResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.HealthCheckResponse.displayName = 'proto.reward.HealthCheckResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.IsWorkerEnabledResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.IsWorkerEnabledResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.IsWorkerEnabledResponse.displayName = 'proto.reward.IsWorkerEnabledResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaFindAllRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallSchemaFindAllRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaFindAllRequest.displayName = 'proto.reward.OfferWallSchemaFindAllRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallSchemaCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaCreateRequest.displayName = 'proto.reward.OfferWallSchemaCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallRewardFindAllRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallRewardFindAllRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallRewardFindAllRequest.displayName = 'proto.reward.OfferWallRewardFindAllRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallRewardFindAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.OfferWallRewardFindAllResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.OfferWallRewardFindAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallRewardFindAllResponse.displayName = 'proto.reward.OfferWallRewardFindAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaCreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallSchemaCreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaCreateResponse.displayName = 'proto.reward.OfferWallSchemaCreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallSchemaUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaUpdate.displayName = 'proto.reward.OfferWallSchemaUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.OfferWallSchemaData.repeatedFields_, null);
};
goog.inherits(proto.reward.OfferWallSchemaData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaData.displayName = 'proto.reward.OfferWallSchemaData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaFindAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.OfferWallSchemaFindAllResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.OfferWallSchemaFindAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaFindAllResponse.displayName = 'proto.reward.OfferWallSchemaFindAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.RewardType.repeatedFields_, null);
};
goog.inherits(proto.reward.RewardType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardType.displayName = 'proto.reward.RewardType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaRewardTypeCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallSchemaRewardTypeCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaRewardTypeCondition.displayName = 'proto.reward.OfferWallSchemaRewardTypeCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallSchemaEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.OfferWallSchemaEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallSchemaEntity.displayName = 'proto.reward.OfferWallSchemaEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.OfferWallRewardEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.OfferWallRewardEntity.repeatedFields_, null);
};
goog.inherits(proto.reward.OfferWallRewardEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.OfferWallRewardEntity.displayName = 'proto.reward.OfferWallRewardEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.DefaultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.DefaultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.DefaultResponse.displayName = 'proto.reward.DefaultResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.isShowToManyTaskListPopUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.isShowToManyTaskListPopUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.isShowToManyTaskListPopUpResponse.displayName = 'proto.reward.isShowToManyTaskListPopUpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RequestSetCurrTutorialStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.RequestSetCurrTutorialStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RequestSetCurrTutorialStage.displayName = 'proto.reward.RequestSetCurrTutorialStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.ResponseGetCurrTutorialStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.ResponseGetCurrTutorialStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.ResponseGetCurrTutorialStage.displayName = 'proto.reward.ResponseGetCurrTutorialStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.ResponseGetCashOutStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.ResponseGetCashOutStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.ResponseGetCashOutStage.displayName = 'proto.reward.ResponseGetCashOutStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RequestSetCashOutStage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.RequestSetCashOutStage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RequestSetCashOutStage.displayName = 'proto.reward.RequestSetCashOutStage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.ReciveMCEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.ReciveMCEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.ReciveMCEventRequest.displayName = 'proto.reward.ReciveMCEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetMCEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.GetMCEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetMCEventResponse.displayName = 'proto.reward.GetMCEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStageStep = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.RewardStageStep, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStageStep.displayName = 'proto.reward.RewardStageStep';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStagePattern = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.RewardStagePattern.repeatedFields_, null);
};
goog.inherits(proto.reward.RewardStagePattern, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStagePattern.displayName = 'proto.reward.RewardStagePattern';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStagePatterns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.RewardStagePatterns.repeatedFields_, null);
};
goog.inherits(proto.reward.RewardStagePatterns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStagePatterns.displayName = 'proto.reward.RewardStagePatterns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStagePatternFindOneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.RewardStagePatternFindOneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStagePatternFindOneRequest.displayName = 'proto.reward.RewardStagePatternFindOneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStagePatternCreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.RewardStagePatternCreateRequest.repeatedFields_, null);
};
goog.inherits(proto.reward.RewardStagePatternCreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStagePatternCreateRequest.displayName = 'proto.reward.RewardStagePatternCreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStagePatternUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.RewardStagePatternUpdateRequest.repeatedFields_, null);
};
goog.inherits(proto.reward.RewardStagePatternUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStagePatternUpdateRequest.displayName = 'proto.reward.RewardStagePatternUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.RewardStagePatternDeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.RewardStagePatternDeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.RewardStagePatternDeleteRequest.displayName = 'proto.reward.RewardStagePatternDeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetUserCurrTaskListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.GetUserCurrTaskListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetUserCurrTaskListResponse.displayName = 'proto.reward.GetUserCurrTaskListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.FindFinishedTasklistCountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.FindFinishedTasklistCountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.FindFinishedTasklistCountRequest.displayName = 'proto.reward.FindFinishedTasklistCountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.FindFinishedTasklistCountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.FindFinishedTasklistCountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.FindFinishedTasklistCountResponse.displayName = 'proto.reward.FindFinishedTasklistCountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.ProcessEventRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.ProcessEventRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.ProcessEventRequest.displayName = 'proto.reward.ProcessEventRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.ProcessEventResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.ProcessEventResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.ProcessEventResponse.displayName = 'proto.reward.ProcessEventResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.EditStepBonusConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.EditStepBonusConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.EditStepBonusConfigResponse.displayName = 'proto.reward.EditStepBonusConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.EditStepBonusConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.EditStepBonusConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.EditStepBonusConfigRequest.displayName = 'proto.reward.EditStepBonusConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CreateStepBonusConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.CreateStepBonusConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CreateStepBonusConfigResponse.displayName = 'proto.reward.CreateStepBonusConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.CreateStepBonusConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.CreateStepBonusConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.CreateStepBonusConfigRequest.displayName = 'proto.reward.CreateStepBonusConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.DeletStepBonusConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.DeletStepBonusConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.DeletStepBonusConfigRequest.displayName = 'proto.reward.DeletStepBonusConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetStepBonusConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.GetStepBonusConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.GetStepBonusConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetStepBonusConfigResponse.displayName = 'proto.reward.GetStepBonusConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.GetStrikeBonusByUserId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.GetStrikeBonusByUserId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.GetStrikeBonusByUserId.displayName = 'proto.reward.GetStrikeBonusByUserId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.getStepBonusConfigByUserIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reward.getStepBonusConfigByUserIdResponse.repeatedFields_, null);
};
goog.inherits(proto.reward.getStepBonusConfigByUserIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.getStepBonusConfigByUserIdResponse.displayName = 'proto.reward.getStepBonusConfigByUserIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.TimeSpan = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.TimeSpan, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.TimeSpan.displayName = 'proto.reward.TimeSpan';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.StepBonus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.StepBonus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.StepBonus.displayName = 'proto.reward.StepBonus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reward.StepBonusWithCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reward.StepBonusWithCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reward.StepBonusWithCheck.displayName = 'proto.reward.StepBonusWithCheck';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetByOrderAndVersionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetByOrderAndVersionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetByOrderAndVersionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetByOrderAndVersionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stageorder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stageversion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetByOrderAndVersionRequest}
 */
proto.reward.GetByOrderAndVersionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetByOrderAndVersionRequest;
  return proto.reward.GetByOrderAndVersionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetByOrderAndVersionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetByOrderAndVersionRequest}
 */
proto.reward.GetByOrderAndVersionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageorder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetByOrderAndVersionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetByOrderAndVersionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetByOrderAndVersionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetByOrderAndVersionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStageorder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStageversion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.GetByOrderAndVersionRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetByOrderAndVersionRequest} returns this
 */
proto.reward.GetByOrderAndVersionRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stageOrder = 2;
 * @return {number}
 */
proto.reward.GetByOrderAndVersionRequest.prototype.getStageorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetByOrderAndVersionRequest} returns this
 */
proto.reward.GetByOrderAndVersionRequest.prototype.setStageorder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stageVersion = 3;
 * @return {number}
 */
proto.reward.GetByOrderAndVersionRequest.prototype.getStageversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetByOrderAndVersionRequest} returns this
 */
proto.reward.GetByOrderAndVersionRequest.prototype.setStageversion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetMcUsersStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetMcUsersStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetMcUsersStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetMcUsersStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountperpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    search: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderby: jspb.Message.getFieldWithDefault(msg, 5, ""),
    order: jspb.Message.getFieldWithDefault(msg, 6, ""),
    timefrom: jspb.Message.getFieldWithDefault(msg, 7, 0),
    timeto: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetMcUsersStatsRequest}
 */
proto.reward.GetMcUsersStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetMcUsersStatsRequest;
  return proto.reward.GetMcUsersStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetMcUsersStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetMcUsersStatsRequest}
 */
proto.reward.GetMcUsersStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountperpage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderby(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimefrom(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimeto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetMcUsersStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetMcUsersStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetMcUsersStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetMcUsersStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmountperpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderby();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTimefrom();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTimeto();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 pageNumber = 2;
 * @return {number}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 amountPerPage = 3;
 * @return {number}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getAmountperpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setAmountperpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string search = 4;
 * @return {string}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string orderBy = 5;
 * @return {string}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getOrderby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setOrderby = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string order = 6;
 * @return {string}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 timeFrom = 7;
 * @return {number}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getTimefrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setTimefrom = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 timeTo = 8;
 * @return {number}
 */
proto.reward.GetMcUsersStatsRequest.prototype.getTimeto = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStatsRequest} returns this
 */
proto.reward.GetMcUsersStatsRequest.prototype.setTimeto = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.GetMcUsersStats.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetMcUsersStats.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetMcUsersStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetMcUsersStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetMcUsersStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountperpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mcusersList: jspb.Message.toObjectList(msg.getMcusersList(),
    proto.reward.McUserStat.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetMcUsersStats}
 */
proto.reward.GetMcUsersStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetMcUsersStats;
  return proto.reward.GetMcUsersStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetMcUsersStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetMcUsersStats}
 */
proto.reward.GetMcUsersStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountperpage(value);
      break;
    case 4:
      var value = new proto.reward.McUserStat;
      reader.readMessage(value,proto.reward.McUserStat.deserializeBinaryFromReader);
      msg.addMcusers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetMcUsersStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetMcUsersStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetMcUsersStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetMcUsersStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmountperpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMcusersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reward.McUserStat.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 totalCount = 1;
 * @return {number}
 */
proto.reward.GetMcUsersStats.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStats} returns this
 */
proto.reward.GetMcUsersStats.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageNumber = 2;
 * @return {number}
 */
proto.reward.GetMcUsersStats.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStats} returns this
 */
proto.reward.GetMcUsersStats.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 amountPerPage = 3;
 * @return {number}
 */
proto.reward.GetMcUsersStats.prototype.getAmountperpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetMcUsersStats} returns this
 */
proto.reward.GetMcUsersStats.prototype.setAmountperpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated McUserStat mcUsers = 4;
 * @return {!Array<!proto.reward.McUserStat>}
 */
proto.reward.GetMcUsersStats.prototype.getMcusersList = function() {
  return /** @type{!Array<!proto.reward.McUserStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.McUserStat, 4));
};


/**
 * @param {!Array<!proto.reward.McUserStat>} value
 * @return {!proto.reward.GetMcUsersStats} returns this
*/
proto.reward.GetMcUsersStats.prototype.setMcusersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reward.McUserStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.McUserStat}
 */
proto.reward.GetMcUsersStats.prototype.addMcusers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reward.McUserStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.GetMcUsersStats} returns this
 */
proto.reward.GetMcUsersStats.prototype.clearMcusersList = function() {
  return this.setMcusersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.McUserStat.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.McUserStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.McUserStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.McUserStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stageorder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stageversion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    currstage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    currgroup: jspb.Message.getFieldWithDefault(msg, 5, 0),
    currstep: jspb.Message.getFieldWithDefault(msg, 6, 0),
    currreward: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalearned: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.McUserStat}
 */
proto.reward.McUserStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.McUserStat;
  return proto.reward.McUserStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.McUserStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.McUserStat}
 */
proto.reward.McUserStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageorder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageversion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrstage(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrgroup(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrstep(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrreward(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalearned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.McUserStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.McUserStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.McUserStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.McUserStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStageorder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStageversion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCurrstage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCurrgroup();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCurrstep();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCurrreward();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalearned();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 stageOrder = 2;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getStageorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setStageorder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stageVersion = 3;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getStageversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setStageversion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 currStage = 4;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getCurrstage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setCurrstage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 currGroup = 5;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getCurrgroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setCurrgroup = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 currStep = 6;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getCurrstep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setCurrstep = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 currReward = 7;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getCurrreward = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setCurrreward = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 totalEarned = 8;
 * @return {number}
 */
proto.reward.McUserStat.prototype.getTotalearned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.McUserStat} returns this
 */
proto.reward.McUserStat.prototype.setTotalearned = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.CurrUserGames.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CurrUserGames.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CurrUserGames.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CurrUserGames} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CurrUserGames.toObject = function(includeInstance, msg) {
  var f, obj = {
    gamesList: jspb.Message.toObjectList(msg.getGamesList(),
    proto.reward.UserGame.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CurrUserGames}
 */
proto.reward.CurrUserGames.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CurrUserGames;
  return proto.reward.CurrUserGames.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CurrUserGames} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CurrUserGames}
 */
proto.reward.CurrUserGames.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.UserGame;
      reader.readMessage(value,proto.reward.UserGame.deserializeBinaryFromReader);
      msg.addGames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CurrUserGames.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CurrUserGames.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CurrUserGames} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CurrUserGames.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGamesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.UserGame.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserGame games = 1;
 * @return {!Array<!proto.reward.UserGame>}
 */
proto.reward.CurrUserGames.prototype.getGamesList = function() {
  return /** @type{!Array<!proto.reward.UserGame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.UserGame, 1));
};


/**
 * @param {!Array<!proto.reward.UserGame>} value
 * @return {!proto.reward.CurrUserGames} returns this
*/
proto.reward.CurrUserGames.prototype.setGamesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.UserGame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.UserGame}
 */
proto.reward.CurrUserGames.prototype.addGames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.UserGame, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.CurrUserGames} returns this
 */
proto.reward.CurrUserGames.prototype.clearGamesList = function() {
  return this.setGamesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.UserGame.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.UserGame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.UserGame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserGame.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iconurl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.UserGame}
 */
proto.reward.UserGame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.UserGame;
  return proto.reward.UserGame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.UserGame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.UserGame}
 */
proto.reward.UserGame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconurl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.UserGame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.UserGame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.UserGame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserGame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIconurl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.reward.UserGame.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.UserGame} returns this
 */
proto.reward.UserGame.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iconUrl = 2;
 * @return {string}
 */
proto.reward.UserGame.prototype.getIconurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.UserGame} returns this
 */
proto.reward.UserGame.prototype.setIconurl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.UserStageOrder.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.UserStageOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.UserStageOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.UserStageOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserStageOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    countstages: jspb.Message.getFieldWithDefault(msg, 1, 0),
    countgroups: jspb.Message.getFieldWithDefault(msg, 2, 0),
    groupdescriptionsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    offerwall: jspb.Message.getFieldWithDefault(msg, 4, ""),
    startingbonus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    adjoestagescount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    value: jspb.Message.getFieldWithDefault(msg, 7, 0),
    type: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.UserStageOrder}
 */
proto.reward.UserStageOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.UserStageOrder;
  return proto.reward.UserStageOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.UserStageOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.UserStageOrder}
 */
proto.reward.UserStageOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountstages(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountgroups(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupdescriptions(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwall(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartingbonus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdjoestagescount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.UserStageOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.UserStageOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.UserStageOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserStageOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountstages();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCountgroups();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGroupdescriptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getOfferwall();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStartingbonus();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAdjoestagescount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int32 countStages = 1;
 * @return {number}
 */
proto.reward.UserStageOrder.prototype.getCountstages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setCountstages = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 countGroups = 2;
 * @return {number}
 */
proto.reward.UserStageOrder.prototype.getCountgroups = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setCountgroups = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string groupDescriptions = 3;
 * @return {!Array<string>}
 */
proto.reward.UserStageOrder.prototype.getGroupdescriptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setGroupdescriptionsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.addGroupdescriptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.clearGroupdescriptionsList = function() {
  return this.setGroupdescriptionsList([]);
};


/**
 * optional string offerwall = 4;
 * @return {string}
 */
proto.reward.UserStageOrder.prototype.getOfferwall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setOfferwall = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 startingBonus = 5;
 * @return {number}
 */
proto.reward.UserStageOrder.prototype.getStartingbonus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setStartingbonus = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 adjoeStagesCount = 6;
 * @return {number}
 */
proto.reward.UserStageOrder.prototype.getAdjoestagescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setAdjoestagescount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 value = 7;
 * @return {number}
 */
proto.reward.UserStageOrder.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.reward.UserStageOrder.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.UserStageOrder} returns this
 */
proto.reward.UserStageOrder.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.UserProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.UserProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.UserProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    stage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    group: jspb.Message.getFieldWithDefault(msg, 2, 0),
    step: jspb.Message.getFieldWithDefault(msg, 3, 0),
    reward: jspb.Message.getFieldWithDefault(msg, 4, 0),
    minimumcahout: jspb.Message.getFieldWithDefault(msg, 5, 0),
    firstreward: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isnewuser: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.UserProgress}
 */
proto.reward.UserProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.UserProgress;
  return proto.reward.UserProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.UserProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.UserProgress}
 */
proto.reward.UserProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGroup(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReward(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinimumcahout(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFirstreward(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnewuser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.UserProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.UserProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.UserProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getReward();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getMinimumcahout();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getFirstreward();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getIsnewuser();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 stage = 1;
 * @return {number}
 */
proto.reward.UserProgress.prototype.getStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setStage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 group = 2;
 * @return {number}
 */
proto.reward.UserProgress.prototype.getGroup = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setGroup = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 step = 3;
 * @return {number}
 */
proto.reward.UserProgress.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 reward = 4;
 * @return {number}
 */
proto.reward.UserProgress.prototype.getReward = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setReward = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 minimumCahout = 5;
 * @return {number}
 */
proto.reward.UserProgress.prototype.getMinimumcahout = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setMinimumcahout = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 firstReward = 6;
 * @return {number}
 */
proto.reward.UserProgress.prototype.getFirstreward = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setFirstreward = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool isNewUser = 7;
 * @return {boolean}
 */
proto.reward.UserProgress.prototype.getIsnewuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.UserProgress} returns this
 */
proto.reward.UserProgress.prototype.setIsnewuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.StageOrderFindAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.StageOrderFindAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.StageOrderFindAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.StageOrderFindAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StageOrderFindAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageordersList: jspb.Message.toObjectList(msg.getStageordersList(),
    proto.reward.StageOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.StageOrderFindAllResponse}
 */
proto.reward.StageOrderFindAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.StageOrderFindAllResponse;
  return proto.reward.StageOrderFindAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.StageOrderFindAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.StageOrderFindAllResponse}
 */
proto.reward.StageOrderFindAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.StageOrder;
      reader.readMessage(value,proto.reward.StageOrder.deserializeBinaryFromReader);
      msg.addStageorders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.StageOrderFindAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.StageOrderFindAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.StageOrderFindAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StageOrderFindAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageordersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.StageOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StageOrder stageOrders = 1;
 * @return {!Array<!proto.reward.StageOrder>}
 */
proto.reward.StageOrderFindAllResponse.prototype.getStageordersList = function() {
  return /** @type{!Array<!proto.reward.StageOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.StageOrder, 1));
};


/**
 * @param {!Array<!proto.reward.StageOrder>} value
 * @return {!proto.reward.StageOrderFindAllResponse} returns this
*/
proto.reward.StageOrderFindAllResponse.prototype.setStageordersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.StageOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.StageOrder}
 */
proto.reward.StageOrderFindAllResponse.prototype.addStageorders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.StageOrder, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.StageOrderFindAllResponse} returns this
 */
proto.reward.StageOrderFindAllResponse.prototype.clearStageordersList = function() {
  return this.setStageordersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.DeleteStageOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.DeleteStageOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.DeleteStageOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DeleteStageOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.DeleteStageOrderRequest}
 */
proto.reward.DeleteStageOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.DeleteStageOrderRequest;
  return proto.reward.DeleteStageOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.DeleteStageOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.DeleteStageOrderRequest}
 */
proto.reward.DeleteStageOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.DeleteStageOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.DeleteStageOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.DeleteStageOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DeleteStageOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.DeleteStageOrderRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.DeleteStageOrderRequest} returns this
 */
proto.reward.DeleteStageOrderRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.DeleteStageOrderRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DeleteStageOrderRequest} returns this
 */
proto.reward.DeleteStageOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.StageOrderUpdate.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.StageOrderUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.StageOrderUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.StageOrderUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StageOrderUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stageorder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startingbonus: jspb.Message.getFieldWithDefault(msg, 3, 0),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reward.MCStage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.StageOrderUpdate}
 */
proto.reward.StageOrderUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.StageOrderUpdate;
  return proto.reward.StageOrderUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.StageOrderUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.StageOrderUpdate}
 */
proto.reward.StageOrderUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageorder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartingbonus(value);
      break;
    case 4:
      var value = new proto.reward.MCStage;
      reader.readMessage(value,proto.reward.MCStage.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.StageOrderUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.StageOrderUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.StageOrderUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StageOrderUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStageorder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStartingbonus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reward.MCStage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.StageOrderUpdate.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.StageOrderUpdate} returns this
 */
proto.reward.StageOrderUpdate.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stageOrder = 2;
 * @return {number}
 */
proto.reward.StageOrderUpdate.prototype.getStageorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StageOrderUpdate} returns this
 */
proto.reward.StageOrderUpdate.prototype.setStageorder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 startingBonus = 3;
 * @return {number}
 */
proto.reward.StageOrderUpdate.prototype.getStartingbonus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StageOrderUpdate} returns this
 */
proto.reward.StageOrderUpdate.prototype.setStartingbonus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated MCStage items = 4;
 * @return {!Array<!proto.reward.MCStage>}
 */
proto.reward.StageOrderUpdate.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reward.MCStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.MCStage, 4));
};


/**
 * @param {!Array<!proto.reward.MCStage>} value
 * @return {!proto.reward.StageOrderUpdate} returns this
*/
proto.reward.StageOrderUpdate.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reward.MCStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.MCStage}
 */
proto.reward.StageOrderUpdate.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reward.MCStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.StageOrderUpdate} returns this
 */
proto.reward.StageOrderUpdate.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.StageOrder.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.StageOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.StageOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.StageOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StageOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stageorder: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stageversion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startingbonus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    stagesList: jspb.Message.toObjectList(msg.getStagesList(),
    proto.reward.MCStage.toObject, includeInstance),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.StageOrder}
 */
proto.reward.StageOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.StageOrder;
  return proto.reward.StageOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.StageOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.StageOrder}
 */
proto.reward.StageOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageorder(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStageversion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartingbonus(value);
      break;
    case 5:
      var value = new proto.reward.MCStage;
      reader.readMessage(value,proto.reward.MCStage.deserializeBinaryFromReader);
      msg.addStages(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.StageOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.StageOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.StageOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StageOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getStageorder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStageversion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStartingbonus();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getStagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.reward.MCStage.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.StageOrder.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StageOrder} returns this
 */
proto.reward.StageOrder.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 stageOrder = 2;
 * @return {number}
 */
proto.reward.StageOrder.prototype.getStageorder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StageOrder} returns this
 */
proto.reward.StageOrder.prototype.setStageorder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stageVersion = 3;
 * @return {number}
 */
proto.reward.StageOrder.prototype.getStageversion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StageOrder} returns this
 */
proto.reward.StageOrder.prototype.setStageversion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 startingBonus = 4;
 * @return {number}
 */
proto.reward.StageOrder.prototype.getStartingbonus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StageOrder} returns this
 */
proto.reward.StageOrder.prototype.setStartingbonus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated MCStage stages = 5;
 * @return {!Array<!proto.reward.MCStage>}
 */
proto.reward.StageOrder.prototype.getStagesList = function() {
  return /** @type{!Array<!proto.reward.MCStage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.MCStage, 5));
};


/**
 * @param {!Array<!proto.reward.MCStage>} value
 * @return {!proto.reward.StageOrder} returns this
*/
proto.reward.StageOrder.prototype.setStagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.reward.MCStage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.MCStage}
 */
proto.reward.StageOrder.prototype.addStages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.reward.MCStage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.StageOrder} returns this
 */
proto.reward.StageOrder.prototype.clearStagesList = function() {
  return this.setStagesList([]);
};


/**
 * optional bool active = 6;
 * @return {boolean}
 */
proto.reward.StageOrder.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.StageOrder} returns this
 */
proto.reward.StageOrder.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.MCStage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.MCStage.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.MCStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.MCStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.MCStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    offerwall: jspb.Message.getFieldWithDefault(msg, 1, ""),
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.reward.MCGroup.toObject, includeInstance),
    valuetype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.MCStage}
 */
proto.reward.MCStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.MCStage;
  return proto.reward.MCStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.MCStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.MCStage}
 */
proto.reward.MCStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwall(value);
      break;
    case 2:
      var value = new proto.reward.MCGroup;
      reader.readMessage(value,proto.reward.MCGroup.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValuetype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.MCStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.MCStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.MCStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.MCStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfferwall();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reward.MCGroup.serializeBinaryToWriter
    );
  }
  f = message.getValuetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string offerwall = 1;
 * @return {string}
 */
proto.reward.MCStage.prototype.getOfferwall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.MCStage} returns this
 */
proto.reward.MCStage.prototype.setOfferwall = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MCGroup groups = 2;
 * @return {!Array<!proto.reward.MCGroup>}
 */
proto.reward.MCStage.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.reward.MCGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.MCGroup, 2));
};


/**
 * @param {!Array<!proto.reward.MCGroup>} value
 * @return {!proto.reward.MCStage} returns this
*/
proto.reward.MCStage.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reward.MCGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.MCGroup}
 */
proto.reward.MCStage.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reward.MCGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.MCStage} returns this
 */
proto.reward.MCStage.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};


/**
 * optional string valueType = 3;
 * @return {string}
 */
proto.reward.MCStage.prototype.getValuetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.MCStage} returns this
 */
proto.reward.MCStage.prototype.setValuetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.MCGroup.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.MCGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.MCGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.MCGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.MCGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stepsList: jspb.Message.toObjectList(msg.getStepsList(),
    proto.reward.MCStep.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.MCGroup}
 */
proto.reward.MCGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.MCGroup;
  return proto.reward.MCGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.MCGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.MCGroup}
 */
proto.reward.MCGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.reward.MCStep;
      reader.readMessage(value,proto.reward.MCStep.deserializeBinaryFromReader);
      msg.addSteps(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.MCGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.MCGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.MCGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.MCGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStepsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reward.MCStep.serializeBinaryToWriter
    );
  }
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.reward.MCGroup.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.MCGroup} returns this
 */
proto.reward.MCGroup.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MCStep steps = 3;
 * @return {!Array<!proto.reward.MCStep>}
 */
proto.reward.MCGroup.prototype.getStepsList = function() {
  return /** @type{!Array<!proto.reward.MCStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.MCStep, 3));
};


/**
 * @param {!Array<!proto.reward.MCStep>} value
 * @return {!proto.reward.MCGroup} returns this
*/
proto.reward.MCGroup.prototype.setStepsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reward.MCStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.MCStep}
 */
proto.reward.MCGroup.prototype.addSteps = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reward.MCStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.MCGroup} returns this
 */
proto.reward.MCGroup.prototype.clearStepsList = function() {
  return this.setStepsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.MCStep.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.MCStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.MCStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.MCStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    reward: jspb.Message.getFieldWithDefault(msg, 1, 0),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    valuetype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rewardtype: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.MCStep}
 */
proto.reward.MCStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.MCStep;
  return proto.reward.MCStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.MCStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.MCStep}
 */
proto.reward.MCStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReward(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValuetype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRewardtype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.MCStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.MCStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.MCStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.MCStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReward();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValuetype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRewardtype();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 reward = 1;
 * @return {number}
 */
proto.reward.MCStep.prototype.getReward = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.MCStep} returns this
 */
proto.reward.MCStep.prototype.setReward = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.reward.MCStep.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.MCStep} returns this
 */
proto.reward.MCStep.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string valueType = 3;
 * @return {string}
 */
proto.reward.MCStep.prototype.getValuetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.MCStep} returns this
 */
proto.reward.MCStep.prototype.setValuetype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string rewardType = 4;
 * @return {string}
 */
proto.reward.MCStep.prototype.getRewardtype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.MCStep} returns this
 */
proto.reward.MCStep.prototype.setRewardtype = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.FindDoubleBoints.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.FindDoubleBoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.FindDoubleBoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.FindDoubleBoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    amountperpage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.FindDoubleBoints}
 */
proto.reward.FindDoubleBoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.FindDoubleBoints;
  return proto.reward.FindDoubleBoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.FindDoubleBoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.FindDoubleBoints}
 */
proto.reward.FindDoubleBoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountperpage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.FindDoubleBoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.FindDoubleBoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.FindDoubleBoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.FindDoubleBoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAmountperpage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.FindDoubleBoints.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.FindDoubleBoints} returns this
 */
proto.reward.FindDoubleBoints.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 amountPerPage = 2;
 * @return {number}
 */
proto.reward.FindDoubleBoints.prototype.getAmountperpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.FindDoubleBoints} returns this
 */
proto.reward.FindDoubleBoints.prototype.setAmountperpage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pageNumber = 3;
 * @return {number}
 */
proto.reward.FindDoubleBoints.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.FindDoubleBoints} returns this
 */
proto.reward.FindDoubleBoints.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.DoubleBointsMany.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.DoubleBointsMany.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.DoubleBointsMany.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.DoubleBointsMany} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DoubleBointsMany.toObject = function(includeInstance, msg) {
  var f, obj = {
    entitiesList: jspb.Message.toObjectList(msg.getEntitiesList(),
    proto.reward.DoubleBoints.toObject, includeInstance),
    totalcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amountperpage: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.DoubleBointsMany}
 */
proto.reward.DoubleBointsMany.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.DoubleBointsMany;
  return proto.reward.DoubleBointsMany.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.DoubleBointsMany} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.DoubleBointsMany}
 */
proto.reward.DoubleBointsMany.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.DoubleBoints;
      reader.readMessage(value,proto.reward.DoubleBoints.deserializeBinaryFromReader);
      msg.addEntities(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountperpage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.DoubleBointsMany.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.DoubleBointsMany.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.DoubleBointsMany} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DoubleBointsMany.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.DoubleBoints.serializeBinaryToWriter
    );
  }
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAmountperpage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * repeated DoubleBoints entities = 1;
 * @return {!Array<!proto.reward.DoubleBoints>}
 */
proto.reward.DoubleBointsMany.prototype.getEntitiesList = function() {
  return /** @type{!Array<!proto.reward.DoubleBoints>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.DoubleBoints, 1));
};


/**
 * @param {!Array<!proto.reward.DoubleBoints>} value
 * @return {!proto.reward.DoubleBointsMany} returns this
*/
proto.reward.DoubleBointsMany.prototype.setEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.DoubleBoints=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.DoubleBoints}
 */
proto.reward.DoubleBointsMany.prototype.addEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.DoubleBoints, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.DoubleBointsMany} returns this
 */
proto.reward.DoubleBointsMany.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};


/**
 * optional int32 totalCount = 2;
 * @return {number}
 */
proto.reward.DoubleBointsMany.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBointsMany} returns this
 */
proto.reward.DoubleBointsMany.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 pageNumber = 3;
 * @return {number}
 */
proto.reward.DoubleBointsMany.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBointsMany} returns this
 */
proto.reward.DoubleBointsMany.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 amountPerPage = 4;
 * @return {number}
 */
proto.reward.DoubleBointsMany.prototype.getAmountperpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBointsMany} returns this
 */
proto.reward.DoubleBointsMany.prototype.setAmountperpage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.DoubleBoints.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.DoubleBoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.DoubleBoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DoubleBoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    day: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hourbegin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hourend: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    minutesbegin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minutesend: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.DoubleBoints}
 */
proto.reward.DoubleBoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.DoubleBoints;
  return proto.reward.DoubleBoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.DoubleBoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.DoubleBoints}
 */
proto.reward.DoubleBoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourbegin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourend(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesbegin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.DoubleBoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.DoubleBoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.DoubleBoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DoubleBoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHourbegin();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHourend();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMinutesbegin();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMinutesend();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.DoubleBoints.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 day = 2;
 * @return {number}
 */
proto.reward.DoubleBoints.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 hourBegin = 3;
 * @return {number}
 */
proto.reward.DoubleBoints.prototype.getHourbegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setHourbegin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 hourEnd = 4;
 * @return {number}
 */
proto.reward.DoubleBoints.prototype.getHourend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setHourend = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.reward.DoubleBoints.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 minutesBegin = 6;
 * @return {number}
 */
proto.reward.DoubleBoints.prototype.getMinutesbegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setMinutesbegin = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 minutesEnd = 7;
 * @return {number}
 */
proto.reward.DoubleBoints.prototype.getMinutesend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DoubleBoints} returns this
 */
proto.reward.DoubleBoints.prototype.setMinutesend = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.DeleteDoubleBoints.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.DeleteDoubleBoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.DeleteDoubleBoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DeleteDoubleBoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.DeleteDoubleBoints}
 */
proto.reward.DeleteDoubleBoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.DeleteDoubleBoints;
  return proto.reward.DeleteDoubleBoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.DeleteDoubleBoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.DeleteDoubleBoints}
 */
proto.reward.DeleteDoubleBoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.DeleteDoubleBoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.DeleteDoubleBoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.DeleteDoubleBoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DeleteDoubleBoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.DeleteDoubleBoints.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.DeleteDoubleBoints} returns this
 */
proto.reward.DeleteDoubleBoints.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.DeleteDoubleBoints.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DeleteDoubleBoints} returns this
 */
proto.reward.DeleteDoubleBoints.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CreateDoubleBoints.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CreateDoubleBoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CreateDoubleBoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CreateDoubleBoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    day: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hourbegin: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hourend: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    minutesbegin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    minutesend: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CreateDoubleBoints}
 */
proto.reward.CreateDoubleBoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CreateDoubleBoints;
  return proto.reward.CreateDoubleBoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CreateDoubleBoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CreateDoubleBoints}
 */
proto.reward.CreateDoubleBoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourbegin(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourend(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesbegin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CreateDoubleBoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CreateDoubleBoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CreateDoubleBoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CreateDoubleBoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getHourbegin();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHourend();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMinutesbegin();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMinutesend();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.CreateDoubleBoints.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 day = 2;
 * @return {number}
 */
proto.reward.CreateDoubleBoints.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 hourBegin = 3;
 * @return {number}
 */
proto.reward.CreateDoubleBoints.prototype.getHourbegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setHourbegin = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 hourEnd = 4;
 * @return {number}
 */
proto.reward.CreateDoubleBoints.prototype.getHourend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setHourend = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool enabled = 5;
 * @return {boolean}
 */
proto.reward.CreateDoubleBoints.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int32 minutesBegin = 6;
 * @return {number}
 */
proto.reward.CreateDoubleBoints.prototype.getMinutesbegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setMinutesbegin = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 minutesEnd = 7;
 * @return {number}
 */
proto.reward.CreateDoubleBoints.prototype.getMinutesend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.CreateDoubleBoints} returns this
 */
proto.reward.CreateDoubleBoints.prototype.setMinutesend = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.UpdateDoubleBoints.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.UpdateDoubleBoints.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.UpdateDoubleBoints} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UpdateDoubleBoints.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hourbegin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hourend: jspb.Message.getFieldWithDefault(msg, 5, 0),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    minutesbegin: jspb.Message.getFieldWithDefault(msg, 7, 0),
    minutesend: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.UpdateDoubleBoints}
 */
proto.reward.UpdateDoubleBoints.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.UpdateDoubleBoints;
  return proto.reward.UpdateDoubleBoints.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.UpdateDoubleBoints} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.UpdateDoubleBoints}
 */
proto.reward.UpdateDoubleBoints.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourbegin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHourend(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesbegin(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutesend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.UpdateDoubleBoints.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.UpdateDoubleBoints.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.UpdateDoubleBoints} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UpdateDoubleBoints.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHourbegin();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getHourend();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMinutesbegin();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getMinutesend();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.UpdateDoubleBoints.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.UpdateDoubleBoints.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 day = 3;
 * @return {number}
 */
proto.reward.UpdateDoubleBoints.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 hourBegin = 4;
 * @return {number}
 */
proto.reward.UpdateDoubleBoints.prototype.getHourbegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setHourbegin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 hourEnd = 5;
 * @return {number}
 */
proto.reward.UpdateDoubleBoints.prototype.getHourend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setHourend = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool enabled = 6;
 * @return {boolean}
 */
proto.reward.UpdateDoubleBoints.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 minutesBegin = 7;
 * @return {number}
 */
proto.reward.UpdateDoubleBoints.prototype.getMinutesbegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setMinutesbegin = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 minutesEnd = 8;
 * @return {number}
 */
proto.reward.UpdateDoubleBoints.prototype.getMinutesend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UpdateDoubleBoints} returns this
 */
proto.reward.UpdateDoubleBoints.prototype.setMinutesend = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.IsDoubleBointsActive.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.IsDoubleBointsActive.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.IsDoubleBointsActive} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.IsDoubleBointsActive.toObject = function(includeInstance, msg) {
  var f, obj = {
    isactive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.IsDoubleBointsActive}
 */
proto.reward.IsDoubleBointsActive.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.IsDoubleBointsActive;
  return proto.reward.IsDoubleBointsActive.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.IsDoubleBointsActive} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.IsDoubleBointsActive}
 */
proto.reward.IsDoubleBointsActive.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.IsDoubleBointsActive.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.IsDoubleBointsActive.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.IsDoubleBointsActive} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.IsDoubleBointsActive.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsactive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool isActive = 1;
 * @return {boolean}
 */
proto.reward.IsDoubleBointsActive.prototype.getIsactive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.IsDoubleBointsActive} returns this
 */
proto.reward.IsDoubleBointsActive.prototype.setIsactive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CalcProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CalcProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CalcProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CalcProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    balancechanged: (f = msg.getBalancechanged()) && custom_stats_pb.BalanceChanged.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CalcProcessRequest}
 */
proto.reward.CalcProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CalcProcessRequest;
  return proto.reward.CalcProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CalcProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CalcProcessRequest}
 */
proto.reward.CalcProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new custom_stats_pb.BalanceChanged;
      reader.readMessage(value,custom_stats_pb.BalanceChanged.deserializeBinaryFromReader);
      msg.setBalancechanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CalcProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CalcProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CalcProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CalcProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalancechanged();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      custom_stats_pb.BalanceChanged.serializeBinaryToWriter
    );
  }
};


/**
 * optional stats.BalanceChanged balanceChanged = 1;
 * @return {?proto.stats.BalanceChanged}
 */
proto.reward.CalcProcessRequest.prototype.getBalancechanged = function() {
  return /** @type{?proto.stats.BalanceChanged} */ (
    jspb.Message.getWrapperField(this, custom_stats_pb.BalanceChanged, 1));
};


/**
 * @param {?proto.stats.BalanceChanged|undefined} value
 * @return {!proto.reward.CalcProcessRequest} returns this
*/
proto.reward.CalcProcessRequest.prototype.setBalancechanged = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.CalcProcessRequest} returns this
 */
proto.reward.CalcProcessRequest.prototype.clearBalancechanged = function() {
  return this.setBalancechanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.CalcProcessRequest.prototype.hasBalancechanged = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CalcProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CalcProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CalcProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CalcProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasdoubleboints: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CalcProcessResponse}
 */
proto.reward.CalcProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CalcProcessResponse;
  return proto.reward.CalcProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CalcProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CalcProcessResponse}
 */
proto.reward.CalcProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasdoubleboints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CalcProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CalcProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CalcProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CalcProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasdoubleboints();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool hasDoubleBoints = 1;
 * @return {boolean}
 */
proto.reward.CalcProcessResponse.prototype.getHasdoubleboints = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.CalcProcessResponse} returns this
 */
proto.reward.CalcProcessResponse.prototype.setHasdoubleboints = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CloseTaskListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CloseTaskListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CloseTaskListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CloseTaskListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tasklistid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CloseTaskListRequest}
 */
proto.reward.CloseTaskListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CloseTaskListRequest;
  return proto.reward.CloseTaskListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CloseTaskListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CloseTaskListRequest}
 */
proto.reward.CloseTaskListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTasklistid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CloseTaskListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CloseTaskListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CloseTaskListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CloseTaskListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTasklistid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.CloseTaskListRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.CloseTaskListRequest} returns this
 */
proto.reward.CloseTaskListRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 taskListId = 2;
 * @return {number}
 */
proto.reward.CloseTaskListRequest.prototype.getTasklistid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.CloseTaskListRequest} returns this
 */
proto.reward.CloseTaskListRequest.prototype.setTasklistid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetUsersMcStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetUsersMcStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetUsersMcStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUsersMcStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    search: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amountperpage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    order: jspb.Message.getFieldWithDefault(msg, 5, ""),
    orderby: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetUsersMcStatsRequest}
 */
proto.reward.GetUsersMcStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetUsersMcStatsRequest;
  return proto.reward.GetUsersMcStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetUsersMcStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetUsersMcStatsRequest}
 */
proto.reward.GetUsersMcStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearch(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountperpage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderby(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetUsersMcStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetUsersMcStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetUsersMcStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUsersMcStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSearch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAmountperpage();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrderby();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.GetUsersMcStatsRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUsersMcStatsRequest} returns this
 */
proto.reward.GetUsersMcStatsRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string search = 2;
 * @return {string}
 */
proto.reward.GetUsersMcStatsRequest.prototype.getSearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUsersMcStatsRequest} returns this
 */
proto.reward.GetUsersMcStatsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 pageNumber = 3;
 * @return {number}
 */
proto.reward.GetUsersMcStatsRequest.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUsersMcStatsRequest} returns this
 */
proto.reward.GetUsersMcStatsRequest.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 amountPerPage = 4;
 * @return {number}
 */
proto.reward.GetUsersMcStatsRequest.prototype.getAmountperpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUsersMcStatsRequest} returns this
 */
proto.reward.GetUsersMcStatsRequest.prototype.setAmountperpage = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string order = 5;
 * @return {string}
 */
proto.reward.GetUsersMcStatsRequest.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUsersMcStatsRequest} returns this
 */
proto.reward.GetUsersMcStatsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string orderBy = 6;
 * @return {string}
 */
proto.reward.GetUsersMcStatsRequest.prototype.getOrderby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUsersMcStatsRequest} returns this
 */
proto.reward.GetUsersMcStatsRequest.prototype.setOrderby = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.GetUsersMcStatsResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetUsersMcStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetUsersMcStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetUsersMcStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUsersMcStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalcount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pagenumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amountperpage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.reward.UserMcStats.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetUsersMcStatsResponse}
 */
proto.reward.GetUsersMcStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetUsersMcStatsResponse;
  return proto.reward.GetUsersMcStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetUsersMcStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetUsersMcStatsResponse}
 */
proto.reward.GetUsersMcStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalcount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPagenumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmountperpage(value);
      break;
    case 4:
      var value = new proto.reward.UserMcStats;
      reader.readMessage(value,proto.reward.UserMcStats.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetUsersMcStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetUsersMcStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetUsersMcStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUsersMcStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalcount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPagenumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmountperpage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reward.UserMcStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 totalCount = 1;
 * @return {number}
 */
proto.reward.GetUsersMcStatsResponse.prototype.getTotalcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUsersMcStatsResponse} returns this
 */
proto.reward.GetUsersMcStatsResponse.prototype.setTotalcount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 pageNumber = 2;
 * @return {number}
 */
proto.reward.GetUsersMcStatsResponse.prototype.getPagenumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUsersMcStatsResponse} returns this
 */
proto.reward.GetUsersMcStatsResponse.prototype.setPagenumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 amountPerPage = 3;
 * @return {number}
 */
proto.reward.GetUsersMcStatsResponse.prototype.getAmountperpage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUsersMcStatsResponse} returns this
 */
proto.reward.GetUsersMcStatsResponse.prototype.setAmountperpage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated UserMcStats data = 4;
 * @return {!Array<!proto.reward.UserMcStats>}
 */
proto.reward.GetUsersMcStatsResponse.prototype.getDataList = function() {
  return /** @type{!Array<!proto.reward.UserMcStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.UserMcStats, 4));
};


/**
 * @param {!Array<!proto.reward.UserMcStats>} value
 * @return {!proto.reward.GetUsersMcStatsResponse} returns this
*/
proto.reward.GetUsersMcStatsResponse.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reward.UserMcStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.UserMcStats}
 */
proto.reward.GetUsersMcStatsResponse.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reward.UserMcStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.GetUsersMcStatsResponse} returns this
 */
proto.reward.GetUsersMcStatsResponse.prototype.clearDataList = function() {
  return this.setDataList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.UserMcStats.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.UserMcStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.UserMcStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserMcStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tasklistattempt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tasklistcompleted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tasklistactive: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tasklistclosed: jspb.Message.getFieldWithDefault(msg, 5, 0),
    adjoeappsstarted: jspb.Message.getFieldWithDefault(msg, 6, 0),
    totalpostbackcount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    totalearned: jspb.Message.getFieldWithDefault(msg, 8, 0),
    finishedtasklistcount: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.UserMcStats}
 */
proto.reward.UserMcStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.UserMcStats;
  return proto.reward.UserMcStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.UserMcStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.UserMcStats}
 */
proto.reward.UserMcStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTasklistattempt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTasklistcompleted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTasklistactive(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTasklistclosed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAdjoeappsstarted(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalpostbackcount(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalearned(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinishedtasklistcount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.UserMcStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.UserMcStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.UserMcStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.UserMcStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTasklistattempt();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTasklistcompleted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTasklistactive();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTasklistclosed();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAdjoeappsstarted();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTotalpostbackcount();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTotalearned();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getFinishedtasklistcount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 taskListAttempt = 2;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getTasklistattempt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setTasklistattempt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 taskListCompleted = 3;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getTasklistcompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setTasklistcompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 taskListActive = 4;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getTasklistactive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setTasklistactive = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 taskListClosed = 5;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getTasklistclosed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setTasklistclosed = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 adjoeAppsStarted = 6;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getAdjoeappsstarted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setAdjoeappsstarted = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 totalPostBackCount = 7;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getTotalpostbackcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setTotalpostbackcount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 totalEarned = 8;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getTotalearned = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setTotalearned = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 finishedTaskListCount = 9;
 * @return {number}
 */
proto.reward.UserMcStats.prototype.getFinishedtasklistcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.UserMcStats} returns this
 */
proto.reward.UserMcStats.prototype.setFinishedtasklistcount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.GetUserActiveTaskListsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetUserActiveTaskListsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetUserActiveTaskListsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetUserActiveTaskListsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUserActiveTaskListsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tasklistsList: jspb.Message.toObjectList(msg.getTasklistsList(),
    proto.reward.GetUserCurrTaskListResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetUserActiveTaskListsResponse}
 */
proto.reward.GetUserActiveTaskListsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetUserActiveTaskListsResponse;
  return proto.reward.GetUserActiveTaskListsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetUserActiveTaskListsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetUserActiveTaskListsResponse}
 */
proto.reward.GetUserActiveTaskListsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.GetUserCurrTaskListResponse;
      reader.readMessage(value,proto.reward.GetUserCurrTaskListResponse.deserializeBinaryFromReader);
      msg.addTasklists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetUserActiveTaskListsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetUserActiveTaskListsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetUserActiveTaskListsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUserActiveTaskListsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTasklistsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.GetUserCurrTaskListResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GetUserCurrTaskListResponse taskLists = 1;
 * @return {!Array<!proto.reward.GetUserCurrTaskListResponse>}
 */
proto.reward.GetUserActiveTaskListsResponse.prototype.getTasklistsList = function() {
  return /** @type{!Array<!proto.reward.GetUserCurrTaskListResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.GetUserCurrTaskListResponse, 1));
};


/**
 * @param {!Array<!proto.reward.GetUserCurrTaskListResponse>} value
 * @return {!proto.reward.GetUserActiveTaskListsResponse} returns this
*/
proto.reward.GetUserActiveTaskListsResponse.prototype.setTasklistsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.GetUserCurrTaskListResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.GetUserCurrTaskListResponse}
 */
proto.reward.GetUserActiveTaskListsResponse.prototype.addTasklists = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.GetUserCurrTaskListResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.GetUserActiveTaskListsResponse} returns this
 */
proto.reward.GetUserActiveTaskListsResponse.prototype.clearTasklistsList = function() {
  return this.setTasklistsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.HealthCheckGrpcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.HealthCheckGrpcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.HealthCheckGrpcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.HealthCheckGrpcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.HealthCheckGrpcResponse}
 */
proto.reward.HealthCheckGrpcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.HealthCheckGrpcResponse;
  return proto.reward.HealthCheckGrpcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.HealthCheckGrpcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.HealthCheckGrpcResponse}
 */
proto.reward.HealthCheckGrpcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.HealthCheckGrpcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.HealthCheckGrpcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.HealthCheckGrpcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.HealthCheckGrpcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.reward.HealthCheckGrpcResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.HealthCheckGrpcResponse} returns this
 */
proto.reward.HealthCheckGrpcResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.Empty}
 */
proto.reward.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.Empty;
  return proto.reward.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.Empty}
 */
proto.reward.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.JwtAuth.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.JwtAuth.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.JwtAuth} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.JwtAuth.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.JwtAuth}
 */
proto.reward.JwtAuth.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.JwtAuth;
  return proto.reward.JwtAuth.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.JwtAuth} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.JwtAuth}
 */
proto.reward.JwtAuth.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.JwtAuth.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.JwtAuth.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.JwtAuth} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.JwtAuth.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.JwtAuth.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.JwtAuth} returns this
 */
proto.reward.JwtAuth.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.HealthCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.HealthCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.HealthCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.HealthCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.HealthCheckRequest}
 */
proto.reward.HealthCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.HealthCheckRequest;
  return proto.reward.HealthCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.HealthCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.HealthCheckRequest}
 */
proto.reward.HealthCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.HealthCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.HealthCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.HealthCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.HealthCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string service = 1;
 * @return {string}
 */
proto.reward.HealthCheckRequest.prototype.getService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.HealthCheckRequest} returns this
 */
proto.reward.HealthCheckRequest.prototype.setService = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.HealthCheckResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.HealthCheckResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.HealthCheckResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.HealthCheckResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.HealthCheckResponse}
 */
proto.reward.HealthCheckResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.HealthCheckResponse;
  return proto.reward.HealthCheckResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.HealthCheckResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.HealthCheckResponse}
 */
proto.reward.HealthCheckResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.reward.HealthCheckResponse.ServingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.HealthCheckResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.HealthCheckResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.HealthCheckResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.HealthCheckResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.reward.HealthCheckResponse.ServingStatus = {
  UNKNOWN: 0,
  SERVING: 1,
  NOT_SERVING: 2,
  SERVICE_UNKNOWN: 3
};

/**
 * optional ServingStatus status = 1;
 * @return {!proto.reward.HealthCheckResponse.ServingStatus}
 */
proto.reward.HealthCheckResponse.prototype.getStatus = function() {
  return /** @type {!proto.reward.HealthCheckResponse.ServingStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.reward.HealthCheckResponse.ServingStatus} value
 * @return {!proto.reward.HealthCheckResponse} returns this
 */
proto.reward.HealthCheckResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.IsWorkerEnabledResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.IsWorkerEnabledResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.IsWorkerEnabledResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.IsWorkerEnabledResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isenabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.IsWorkerEnabledResponse}
 */
proto.reward.IsWorkerEnabledResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.IsWorkerEnabledResponse;
  return proto.reward.IsWorkerEnabledResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.IsWorkerEnabledResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.IsWorkerEnabledResponse}
 */
proto.reward.IsWorkerEnabledResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsenabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.IsWorkerEnabledResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.IsWorkerEnabledResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.IsWorkerEnabledResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.IsWorkerEnabledResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsenabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool isEnabled = 1;
 * @return {boolean}
 */
proto.reward.IsWorkerEnabledResponse.prototype.getIsenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.IsWorkerEnabledResponse} returns this
 */
proto.reward.IsWorkerEnabledResponse.prototype.setIsenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaFindAllRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaFindAllRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaFindAllRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaFindAllRequest}
 */
proto.reward.OfferWallSchemaFindAllRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaFindAllRequest;
  return proto.reward.OfferWallSchemaFindAllRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaFindAllRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaFindAllRequest}
 */
proto.reward.OfferWallSchemaFindAllRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaFindAllRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaFindAllRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaFindAllRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaFindAllRequest} returns this
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool active = 2;
 * @return {boolean}
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallSchemaFindAllRequest} returns this
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.setActive = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.OfferWallSchemaFindAllRequest} returns this
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.clearActive = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaFindAllRequest.prototype.hasActive = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    data: (f = msg.getData()) && proto.reward.OfferWallSchemaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaCreateRequest}
 */
proto.reward.OfferWallSchemaCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaCreateRequest;
  return proto.reward.OfferWallSchemaCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaCreateRequest}
 */
proto.reward.OfferWallSchemaCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 4:
      var value = new proto.reward.OfferWallSchemaData;
      reader.readMessage(value,proto.reward.OfferWallSchemaData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.reward.OfferWallSchemaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.setActive = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.clearActive = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.hasActive = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OfferWallSchemaData data = 4;
 * @return {?proto.reward.OfferWallSchemaData}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.getData = function() {
  return /** @type{?proto.reward.OfferWallSchemaData} */ (
    jspb.Message.getWrapperField(this, proto.reward.OfferWallSchemaData, 4));
};


/**
 * @param {?proto.reward.OfferWallSchemaData|undefined} value
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
*/
proto.reward.OfferWallSchemaCreateRequest.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.OfferWallSchemaCreateRequest} returns this
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaCreateRequest.prototype.hasData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallRewardFindAllRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallRewardFindAllRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallRewardFindAllRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issent: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallRewardFindAllRequest}
 */
proto.reward.OfferWallRewardFindAllRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallRewardFindAllRequest;
  return proto.reward.OfferWallRewardFindAllRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallRewardFindAllRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallRewardFindAllRequest}
 */
proto.reward.OfferWallRewardFindAllRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallRewardFindAllRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallRewardFindAllRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallRewardFindAllRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallRewardFindAllRequest} returns this
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isSent = 2;
 * @return {boolean}
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.getIssent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallRewardFindAllRequest} returns this
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.setIssent = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.OfferWallRewardFindAllRequest} returns this
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.clearIssent = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallRewardFindAllRequest.prototype.hasIssent = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.OfferWallRewardFindAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallRewardFindAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallRewardFindAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallRewardFindAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallRewardFindAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reward.OfferWallRewardEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallRewardFindAllResponse}
 */
proto.reward.OfferWallRewardFindAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallRewardFindAllResponse;
  return proto.reward.OfferWallRewardFindAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallRewardFindAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallRewardFindAllResponse}
 */
proto.reward.OfferWallRewardFindAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.OfferWallRewardEntity;
      reader.readMessage(value,proto.reward.OfferWallRewardEntity.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallRewardFindAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallRewardFindAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallRewardFindAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallRewardFindAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.OfferWallRewardEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OfferWallRewardEntity items = 1;
 * @return {!Array<!proto.reward.OfferWallRewardEntity>}
 */
proto.reward.OfferWallRewardFindAllResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reward.OfferWallRewardEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.OfferWallRewardEntity, 1));
};


/**
 * @param {!Array<!proto.reward.OfferWallRewardEntity>} value
 * @return {!proto.reward.OfferWallRewardFindAllResponse} returns this
*/
proto.reward.OfferWallRewardFindAllResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.OfferWallRewardEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.OfferWallRewardEntity}
 */
proto.reward.OfferWallRewardFindAllResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.OfferWallRewardEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.OfferWallRewardFindAllResponse} returns this
 */
proto.reward.OfferWallRewardFindAllResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaCreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaCreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaCreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaCreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    item: (f = msg.getItem()) && proto.reward.OfferWallSchemaEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaCreateResponse}
 */
proto.reward.OfferWallSchemaCreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaCreateResponse;
  return proto.reward.OfferWallSchemaCreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaCreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaCreateResponse}
 */
proto.reward.OfferWallSchemaCreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.OfferWallSchemaEntity;
      reader.readMessage(value,proto.reward.OfferWallSchemaEntity.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaCreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaCreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaCreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaCreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reward.OfferWallSchemaEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional OfferWallSchemaEntity item = 1;
 * @return {?proto.reward.OfferWallSchemaEntity}
 */
proto.reward.OfferWallSchemaCreateResponse.prototype.getItem = function() {
  return /** @type{?proto.reward.OfferWallSchemaEntity} */ (
    jspb.Message.getWrapperField(this, proto.reward.OfferWallSchemaEntity, 1));
};


/**
 * @param {?proto.reward.OfferWallSchemaEntity|undefined} value
 * @return {!proto.reward.OfferWallSchemaCreateResponse} returns this
*/
proto.reward.OfferWallSchemaCreateResponse.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.OfferWallSchemaCreateResponse} returns this
 */
proto.reward.OfferWallSchemaCreateResponse.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaCreateResponse.prototype.hasItem = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    data: (f = msg.getData()) && proto.reward.OfferWallSchemaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaUpdate}
 */
proto.reward.OfferWallSchemaUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaUpdate;
  return proto.reward.OfferWallSchemaUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaUpdate}
 */
proto.reward.OfferWallSchemaUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 5:
      var value = new proto.reward.OfferWallSchemaData;
      reader.readMessage(value,proto.reward.OfferWallSchemaData.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.reward.OfferWallSchemaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.OfferWallSchemaUpdate.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.OfferWallSchemaUpdate.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reward.OfferWallSchemaUpdate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaUpdate.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.reward.OfferWallSchemaUpdate.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.setActive = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.clearActive = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaUpdate.prototype.hasActive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OfferWallSchemaData data = 5;
 * @return {?proto.reward.OfferWallSchemaData}
 */
proto.reward.OfferWallSchemaUpdate.prototype.getData = function() {
  return /** @type{?proto.reward.OfferWallSchemaData} */ (
    jspb.Message.getWrapperField(this, proto.reward.OfferWallSchemaData, 5));
};


/**
 * @param {?proto.reward.OfferWallSchemaData|undefined} value
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
*/
proto.reward.OfferWallSchemaUpdate.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.OfferWallSchemaUpdate} returns this
 */
proto.reward.OfferWallSchemaUpdate.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaUpdate.prototype.hasData = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.OfferWallSchemaData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaData.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaData.toObject = function(includeInstance, msg) {
  var f, obj = {
    containsmorethancondition: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    rewardtypesList: jspb.Message.toObjectList(msg.getRewardtypesList(),
    proto.reward.RewardType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaData}
 */
proto.reward.OfferWallSchemaData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaData;
  return proto.reward.OfferWallSchemaData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaData}
 */
proto.reward.OfferWallSchemaData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setContainsmorethancondition(value);
      break;
    case 2:
      var value = new proto.reward.RewardType;
      reader.readMessage(value,proto.reward.RewardType.deserializeBinaryFromReader);
      msg.addRewardtypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContainsmorethancondition();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRewardtypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.reward.RewardType.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool containsMoreThanCondition = 1;
 * @return {boolean}
 */
proto.reward.OfferWallSchemaData.prototype.getContainsmorethancondition = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallSchemaData} returns this
 */
proto.reward.OfferWallSchemaData.prototype.setContainsmorethancondition = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated RewardType rewardTypes = 2;
 * @return {!Array<!proto.reward.RewardType>}
 */
proto.reward.OfferWallSchemaData.prototype.getRewardtypesList = function() {
  return /** @type{!Array<!proto.reward.RewardType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.RewardType, 2));
};


/**
 * @param {!Array<!proto.reward.RewardType>} value
 * @return {!proto.reward.OfferWallSchemaData} returns this
*/
proto.reward.OfferWallSchemaData.prototype.setRewardtypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.reward.RewardType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.RewardType}
 */
proto.reward.OfferWallSchemaData.prototype.addRewardtypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.reward.RewardType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.OfferWallSchemaData} returns this
 */
proto.reward.OfferWallSchemaData.prototype.clearRewardtypesList = function() {
  return this.setRewardtypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.OfferWallSchemaFindAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaFindAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaFindAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaFindAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaFindAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reward.OfferWallSchemaEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaFindAllResponse}
 */
proto.reward.OfferWallSchemaFindAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaFindAllResponse;
  return proto.reward.OfferWallSchemaFindAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaFindAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaFindAllResponse}
 */
proto.reward.OfferWallSchemaFindAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.OfferWallSchemaEntity;
      reader.readMessage(value,proto.reward.OfferWallSchemaEntity.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaFindAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaFindAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaFindAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaFindAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.OfferWallSchemaEntity.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OfferWallSchemaEntity items = 1;
 * @return {!Array<!proto.reward.OfferWallSchemaEntity>}
 */
proto.reward.OfferWallSchemaFindAllResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reward.OfferWallSchemaEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.OfferWallSchemaEntity, 1));
};


/**
 * @param {!Array<!proto.reward.OfferWallSchemaEntity>} value
 * @return {!proto.reward.OfferWallSchemaFindAllResponse} returns this
*/
proto.reward.OfferWallSchemaFindAllResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.OfferWallSchemaEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.OfferWallSchemaEntity}
 */
proto.reward.OfferWallSchemaFindAllResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.OfferWallSchemaEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.OfferWallSchemaFindAllResponse} returns this
 */
proto.reward.OfferWallSchemaFindAllResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.RewardType.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardType.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardType.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rewardpayouttype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rewardpayoutvalue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    conditionsList: jspb.Message.toObjectList(msg.getConditionsList(),
    proto.reward.OfferWallSchemaRewardTypeCondition.toObject, includeInstance),
    rewardpayoutfieldpath: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardType}
 */
proto.reward.RewardType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardType;
  return proto.reward.RewardType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardType}
 */
proto.reward.RewardType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRewardpayouttype(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardpayoutvalue(value);
      break;
    case 4:
      var value = new proto.reward.OfferWallSchemaRewardTypeCondition;
      reader.readMessage(value,proto.reward.OfferWallSchemaRewardTypeCondition.deserializeBinaryFromReader);
      msg.addConditions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRewardpayoutfieldpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRewardpayouttype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRewardpayoutvalue();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getConditionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.reward.OfferWallSchemaRewardTypeCondition.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.reward.RewardType.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardType} returns this
 */
proto.reward.RewardType.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string rewardPayoutType = 2;
 * @return {string}
 */
proto.reward.RewardType.prototype.getRewardpayouttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardType} returns this
 */
proto.reward.RewardType.prototype.setRewardpayouttype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 rewardPayoutValue = 3;
 * @return {number}
 */
proto.reward.RewardType.prototype.getRewardpayoutvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardType} returns this
 */
proto.reward.RewardType.prototype.setRewardpayoutvalue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated OfferWallSchemaRewardTypeCondition conditions = 4;
 * @return {!Array<!proto.reward.OfferWallSchemaRewardTypeCondition>}
 */
proto.reward.RewardType.prototype.getConditionsList = function() {
  return /** @type{!Array<!proto.reward.OfferWallSchemaRewardTypeCondition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.OfferWallSchemaRewardTypeCondition, 4));
};


/**
 * @param {!Array<!proto.reward.OfferWallSchemaRewardTypeCondition>} value
 * @return {!proto.reward.RewardType} returns this
*/
proto.reward.RewardType.prototype.setConditionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reward.OfferWallSchemaRewardTypeCondition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.OfferWallSchemaRewardTypeCondition}
 */
proto.reward.RewardType.prototype.addConditions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reward.OfferWallSchemaRewardTypeCondition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.RewardType} returns this
 */
proto.reward.RewardType.prototype.clearConditionsList = function() {
  return this.setConditionsList([]);
};


/**
 * optional string rewardPayoutFieldPath = 5;
 * @return {string}
 */
proto.reward.RewardType.prototype.getRewardpayoutfieldpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardType} returns this
 */
proto.reward.RewardType.prototype.setRewardpayoutfieldpath = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.RewardType} returns this
 */
proto.reward.RewardType.prototype.clearRewardpayoutfieldpath = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.RewardType.prototype.hasRewardpayoutfieldpath = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaRewardTypeCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaRewardTypeCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaRewardTypeCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    conditiontype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conditionvalue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    conditionfieldpath: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaRewardTypeCondition}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaRewardTypeCondition;
  return proto.reward.OfferWallSchemaRewardTypeCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaRewardTypeCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaRewardTypeCondition}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditiontype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConditionvalue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionfieldpath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaRewardTypeCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaRewardTypeCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaRewardTypeCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConditiontype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConditionvalue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getConditionfieldpath();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string conditionType = 1;
 * @return {string}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.getConditiontype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaRewardTypeCondition} returns this
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.setConditiontype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 conditionValue = 2;
 * @return {number}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.getConditionvalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.OfferWallSchemaRewardTypeCondition} returns this
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.setConditionvalue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string conditionFieldPath = 3;
 * @return {string}
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.getConditionfieldpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaRewardTypeCondition} returns this
 */
proto.reward.OfferWallSchemaRewardTypeCondition.prototype.setConditionfieldpath = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallSchemaEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallSchemaEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallSchemaEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    identifier: jspb.Message.getFieldWithDefault(msg, 4, ""),
    schema: (f = msg.getSchema()) && proto.reward.OfferWallSchemaData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallSchemaEntity}
 */
proto.reward.OfferWallSchemaEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallSchemaEntity;
  return proto.reward.OfferWallSchemaEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallSchemaEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallSchemaEntity}
 */
proto.reward.OfferWallSchemaEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 5:
      var value = new proto.reward.OfferWallSchemaData;
      reader.readMessage(value,proto.reward.OfferWallSchemaData.deserializeBinaryFromReader);
      msg.setSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallSchemaEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallSchemaEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallSchemaEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallSchemaEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSchema();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.reward.OfferWallSchemaData.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.OfferWallSchemaEntity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.OfferWallSchemaEntity} returns this
 */
proto.reward.OfferWallSchemaEntity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reward.OfferWallSchemaEntity.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaEntity} returns this
 */
proto.reward.OfferWallSchemaEntity.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool active = 3;
 * @return {boolean}
 */
proto.reward.OfferWallSchemaEntity.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallSchemaEntity} returns this
 */
proto.reward.OfferWallSchemaEntity.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string identifier = 4;
 * @return {string}
 */
proto.reward.OfferWallSchemaEntity.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallSchemaEntity} returns this
 */
proto.reward.OfferWallSchemaEntity.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional OfferWallSchemaData schema = 5;
 * @return {?proto.reward.OfferWallSchemaData}
 */
proto.reward.OfferWallSchemaEntity.prototype.getSchema = function() {
  return /** @type{?proto.reward.OfferWallSchemaData} */ (
    jspb.Message.getWrapperField(this, proto.reward.OfferWallSchemaData, 5));
};


/**
 * @param {?proto.reward.OfferWallSchemaData|undefined} value
 * @return {!proto.reward.OfferWallSchemaEntity} returns this
*/
proto.reward.OfferWallSchemaEntity.prototype.setSchema = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.OfferWallSchemaEntity} returns this
 */
proto.reward.OfferWallSchemaEntity.prototype.clearSchema = function() {
  return this.setSchema(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.OfferWallSchemaEntity.prototype.hasSchema = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.OfferWallRewardEntity.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.OfferWallRewardEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.OfferWallRewardEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.OfferWallRewardEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallRewardEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    offerwallschemaidentifier: jspb.Message.getFieldWithDefault(msg, 3, ""),
    issenttobalances: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    payoutsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    requestid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.OfferWallRewardEntity}
 */
proto.reward.OfferWallRewardEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.OfferWallRewardEntity;
  return proto.reward.OfferWallRewardEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.OfferWallRewardEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.OfferWallRewardEntity}
 */
proto.reward.OfferWallRewardEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwallschemaidentifier(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIssenttobalances(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPayouts(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.OfferWallRewardEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.OfferWallRewardEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.OfferWallRewardEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.OfferWallRewardEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOfferwallschemaidentifier();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIssenttobalances();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPayoutsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.OfferWallRewardEntity.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 userId = 2;
 * @return {number}
 */
proto.reward.OfferWallRewardEntity.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string offerWallSchemaIdentifier = 3;
 * @return {string}
 */
proto.reward.OfferWallRewardEntity.prototype.getOfferwallschemaidentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setOfferwallschemaidentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool isSentToBalances = 4;
 * @return {boolean}
 */
proto.reward.OfferWallRewardEntity.prototype.getIssenttobalances = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setIssenttobalances = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated int64 payouts = 5;
 * @return {!Array<number>}
 */
proto.reward.OfferWallRewardEntity.prototype.getPayoutsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setPayoutsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.addPayouts = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.clearPayoutsList = function() {
  return this.setPayoutsList([]);
};


/**
 * optional string requestId = 6;
 * @return {string}
 */
proto.reward.OfferWallRewardEntity.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 createdAt = 7;
 * @return {number}
 */
proto.reward.OfferWallRewardEntity.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.OfferWallRewardEntity} returns this
 */
proto.reward.OfferWallRewardEntity.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.DefaultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.DefaultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.DefaultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DefaultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.DefaultResponse}
 */
proto.reward.DefaultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.DefaultResponse;
  return proto.reward.DefaultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.DefaultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.DefaultResponse}
 */
proto.reward.DefaultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatuscode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.DefaultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.DefaultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.DefaultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DefaultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 statusCode = 1;
 * @return {number}
 */
proto.reward.DefaultResponse.prototype.getStatuscode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DefaultResponse} returns this
 */
proto.reward.DefaultResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.reward.DefaultResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.DefaultResponse} returns this
 */
proto.reward.DefaultResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.isShowToManyTaskListPopUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.isShowToManyTaskListPopUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.isShowToManyTaskListPopUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.isShowToManyTaskListPopUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isshow: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.isShowToManyTaskListPopUpResponse}
 */
proto.reward.isShowToManyTaskListPopUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.isShowToManyTaskListPopUpResponse;
  return proto.reward.isShowToManyTaskListPopUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.isShowToManyTaskListPopUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.isShowToManyTaskListPopUpResponse}
 */
proto.reward.isShowToManyTaskListPopUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsshow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.isShowToManyTaskListPopUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.isShowToManyTaskListPopUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.isShowToManyTaskListPopUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.isShowToManyTaskListPopUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsshow();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool isShow = 1;
 * @return {boolean}
 */
proto.reward.isShowToManyTaskListPopUpResponse.prototype.getIsshow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.isShowToManyTaskListPopUpResponse} returns this
 */
proto.reward.isShowToManyTaskListPopUpResponse.prototype.setIsshow = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RequestSetCurrTutorialStage.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RequestSetCurrTutorialStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RequestSetCurrTutorialStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RequestSetCurrTutorialStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tutorialstage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RequestSetCurrTutorialStage}
 */
proto.reward.RequestSetCurrTutorialStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RequestSetCurrTutorialStage;
  return proto.reward.RequestSetCurrTutorialStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RequestSetCurrTutorialStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RequestSetCurrTutorialStage}
 */
proto.reward.RequestSetCurrTutorialStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTutorialstage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RequestSetCurrTutorialStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RequestSetCurrTutorialStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RequestSetCurrTutorialStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RequestSetCurrTutorialStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTutorialstage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.RequestSetCurrTutorialStage.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RequestSetCurrTutorialStage} returns this
 */
proto.reward.RequestSetCurrTutorialStage.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tutorialStage = 2;
 * @return {string}
 */
proto.reward.RequestSetCurrTutorialStage.prototype.getTutorialstage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RequestSetCurrTutorialStage} returns this
 */
proto.reward.RequestSetCurrTutorialStage.prototype.setTutorialstage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.ResponseGetCurrTutorialStage.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.ResponseGetCurrTutorialStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.ResponseGetCurrTutorialStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ResponseGetCurrTutorialStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    currstage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.ResponseGetCurrTutorialStage}
 */
proto.reward.ResponseGetCurrTutorialStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.ResponseGetCurrTutorialStage;
  return proto.reward.ResponseGetCurrTutorialStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.ResponseGetCurrTutorialStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.ResponseGetCurrTutorialStage}
 */
proto.reward.ResponseGetCurrTutorialStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrstage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.ResponseGetCurrTutorialStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.ResponseGetCurrTutorialStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.ResponseGetCurrTutorialStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ResponseGetCurrTutorialStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrstage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string currStage = 1;
 * @return {string}
 */
proto.reward.ResponseGetCurrTutorialStage.prototype.getCurrstage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ResponseGetCurrTutorialStage} returns this
 */
proto.reward.ResponseGetCurrTutorialStage.prototype.setCurrstage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.ResponseGetCashOutStage.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.ResponseGetCashOutStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.ResponseGetCashOutStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ResponseGetCashOutStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    currstage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.ResponseGetCashOutStage}
 */
proto.reward.ResponseGetCashOutStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.ResponseGetCashOutStage;
  return proto.reward.ResponseGetCashOutStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.ResponseGetCashOutStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.ResponseGetCashOutStage}
 */
proto.reward.ResponseGetCashOutStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrstage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.ResponseGetCashOutStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.ResponseGetCashOutStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.ResponseGetCashOutStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ResponseGetCashOutStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrstage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string currStage = 1;
 * @return {string}
 */
proto.reward.ResponseGetCashOutStage.prototype.getCurrstage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ResponseGetCashOutStage} returns this
 */
proto.reward.ResponseGetCashOutStage.prototype.setCurrstage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RequestSetCashOutStage.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RequestSetCashOutStage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RequestSetCashOutStage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RequestSetCashOutStage.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cashoutstage: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RequestSetCashOutStage}
 */
proto.reward.RequestSetCashOutStage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RequestSetCashOutStage;
  return proto.reward.RequestSetCashOutStage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RequestSetCashOutStage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RequestSetCashOutStage}
 */
proto.reward.RequestSetCashOutStage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashoutstage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RequestSetCashOutStage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RequestSetCashOutStage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RequestSetCashOutStage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RequestSetCashOutStage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCashoutstage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.RequestSetCashOutStage.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RequestSetCashOutStage} returns this
 */
proto.reward.RequestSetCashOutStage.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cashOutStage = 3;
 * @return {string}
 */
proto.reward.RequestSetCashOutStage.prototype.getCashoutstage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RequestSetCashOutStage} returns this
 */
proto.reward.RequestSetCashOutStage.prototype.setCashoutstage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.ReciveMCEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.ReciveMCEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.ReciveMCEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ReciveMCEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    eventkey: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.ReciveMCEventRequest}
 */
proto.reward.ReciveMCEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.ReciveMCEventRequest;
  return proto.reward.ReciveMCEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.ReciveMCEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.ReciveMCEventRequest}
 */
proto.reward.ReciveMCEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.ReciveMCEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.ReciveMCEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.ReciveMCEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ReciveMCEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEventkey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.ReciveMCEventRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ReciveMCEventRequest} returns this
 */
proto.reward.ReciveMCEventRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string eventKey = 2;
 * @return {string}
 */
proto.reward.ReciveMCEventRequest.prototype.getEventkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ReciveMCEventRequest} returns this
 */
proto.reward.ReciveMCEventRequest.prototype.setEventkey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetMCEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetMCEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetMCEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetMCEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lasteventkey: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetMCEventResponse}
 */
proto.reward.GetMCEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetMCEventResponse;
  return proto.reward.GetMCEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetMCEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetMCEventResponse}
 */
proto.reward.GetMCEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLasteventkey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetMCEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetMCEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetMCEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetMCEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLasteventkey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string lastEventKey = 1;
 * @return {string}
 */
proto.reward.GetMCEventResponse.prototype.getLasteventkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetMCEventResponse} returns this
 */
proto.reward.GetMCEventResponse.prototype.setLasteventkey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStageStep.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStageStep.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStageStep} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStageStep.toObject = function(includeInstance, msg) {
  var f, obj = {
    trigger: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0),
    reward: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStageStep}
 */
proto.reward.RewardStageStep.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStageStep;
  return proto.reward.RewardStageStep.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStageStep} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStageStep}
 */
proto.reward.RewardStageStep.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrigger(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReward(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStageStep.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStageStep.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStageStep} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStageStep.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrigger();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getReward();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string trigger = 1;
 * @return {string}
 */
proto.reward.RewardStageStep.prototype.getTrigger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStageStep} returns this
 */
proto.reward.RewardStageStep.prototype.setTrigger = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.reward.RewardStageStep.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStageStep} returns this
 */
proto.reward.RewardStageStep.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 reward = 3;
 * @return {number}
 */
proto.reward.RewardStageStep.prototype.getReward = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStageStep} returns this
 */
proto.reward.RewardStageStep.prototype.setReward = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.RewardStagePattern.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStagePattern.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStagePattern.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStagePattern} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePattern.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offerwall: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nextstageconditions: jspb.Message.getFieldWithDefault(msg, 4, ""),
    randomizereward: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    patternList: jspb.Message.toObjectList(msg.getPatternList(),
    proto.reward.RewardStageStep.toObject, includeInstance),
    index: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStagePattern}
 */
proto.reward.RewardStagePattern.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStagePattern;
  return proto.reward.RewardStagePattern.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStagePattern} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStagePattern}
 */
proto.reward.RewardStagePattern.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwall(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextstageconditions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRandomizereward(value);
      break;
    case 6:
      var value = new proto.reward.RewardStageStep;
      reader.readMessage(value,proto.reward.RewardStageStep.deserializeBinaryFromReader);
      msg.addPattern(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStagePattern.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStagePattern.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStagePattern} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePattern.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOfferwall();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNextstageconditions();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRandomizereward();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPatternList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.reward.RewardStageStep.serializeBinaryToWriter
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.RewardStagePattern.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reward.RewardStagePattern.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string offerwall = 3;
 * @return {string}
 */
proto.reward.RewardStagePattern.prototype.getOfferwall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.setOfferwall = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string nextStageConditions = 4;
 * @return {string}
 */
proto.reward.RewardStagePattern.prototype.getNextstageconditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.setNextstageconditions = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool randomizeReward = 5;
 * @return {boolean}
 */
proto.reward.RewardStagePattern.prototype.getRandomizereward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.setRandomizereward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated RewardStageStep pattern = 6;
 * @return {!Array<!proto.reward.RewardStageStep>}
 */
proto.reward.RewardStagePattern.prototype.getPatternList = function() {
  return /** @type{!Array<!proto.reward.RewardStageStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.RewardStageStep, 6));
};


/**
 * @param {!Array<!proto.reward.RewardStageStep>} value
 * @return {!proto.reward.RewardStagePattern} returns this
*/
proto.reward.RewardStagePattern.prototype.setPatternList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.reward.RewardStageStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.RewardStageStep}
 */
proto.reward.RewardStagePattern.prototype.addPattern = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.reward.RewardStageStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.clearPatternList = function() {
  return this.setPatternList([]);
};


/**
 * optional int32 index = 7;
 * @return {number}
 */
proto.reward.RewardStagePattern.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePattern} returns this
 */
proto.reward.RewardStagePattern.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.RewardStagePatterns.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStagePatterns.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStagePatterns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStagePatterns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatterns.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.reward.RewardStagePattern.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStagePatterns}
 */
proto.reward.RewardStagePatterns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStagePatterns;
  return proto.reward.RewardStagePatterns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStagePatterns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStagePatterns}
 */
proto.reward.RewardStagePatterns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.RewardStagePattern;
      reader.readMessage(value,proto.reward.RewardStagePattern.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStagePatterns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStagePatterns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStagePatterns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatterns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.RewardStagePattern.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RewardStagePattern items = 1;
 * @return {!Array<!proto.reward.RewardStagePattern>}
 */
proto.reward.RewardStagePatterns.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.reward.RewardStagePattern>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.RewardStagePattern, 1));
};


/**
 * @param {!Array<!proto.reward.RewardStagePattern>} value
 * @return {!proto.reward.RewardStagePatterns} returns this
*/
proto.reward.RewardStagePatterns.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.RewardStagePattern=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.RewardStagePattern}
 */
proto.reward.RewardStagePatterns.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.RewardStagePattern, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.RewardStagePatterns} returns this
 */
proto.reward.RewardStagePatterns.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStagePatternFindOneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStagePatternFindOneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStagePatternFindOneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternFindOneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStagePatternFindOneRequest}
 */
proto.reward.RewardStagePatternFindOneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStagePatternFindOneRequest;
  return proto.reward.RewardStagePatternFindOneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStagePatternFindOneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStagePatternFindOneRequest}
 */
proto.reward.RewardStagePatternFindOneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStagePatternFindOneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStagePatternFindOneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStagePatternFindOneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternFindOneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.RewardStagePatternFindOneRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternFindOneRequest} returns this
 */
proto.reward.RewardStagePatternFindOneRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.RewardStagePatternFindOneRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePatternFindOneRequest} returns this
 */
proto.reward.RewardStagePatternFindOneRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.RewardStagePatternCreateRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStagePatternCreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStagePatternCreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternCreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    offerwall: jspb.Message.getFieldWithDefault(msg, 3, ""),
    nextstageconditions: jspb.Message.getFieldWithDefault(msg, 4, ""),
    randomizereward: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    patternList: jspb.Message.toObjectList(msg.getPatternList(),
    proto.reward.RewardStageStep.toObject, includeInstance),
    index: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStagePatternCreateRequest}
 */
proto.reward.RewardStagePatternCreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStagePatternCreateRequest;
  return proto.reward.RewardStagePatternCreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStagePatternCreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStagePatternCreateRequest}
 */
proto.reward.RewardStagePatternCreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwall(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextstageconditions(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRandomizereward(value);
      break;
    case 6:
      var value = new proto.reward.RewardStageStep;
      reader.readMessage(value,proto.reward.RewardStageStep.deserializeBinaryFromReader);
      msg.addPattern(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStagePatternCreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStagePatternCreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternCreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOfferwall();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNextstageconditions();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRandomizereward();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPatternList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.reward.RewardStageStep.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string offerwall = 3;
 * @return {string}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getOfferwall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.setOfferwall = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string nextStageConditions = 4;
 * @return {string}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getNextstageconditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.setNextstageconditions = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool randomizeReward = 5;
 * @return {boolean}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getRandomizereward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.setRandomizereward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated RewardStageStep pattern = 6;
 * @return {!Array<!proto.reward.RewardStageStep>}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getPatternList = function() {
  return /** @type{!Array<!proto.reward.RewardStageStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.RewardStageStep, 6));
};


/**
 * @param {!Array<!proto.reward.RewardStageStep>} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
*/
proto.reward.RewardStagePatternCreateRequest.prototype.setPatternList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.reward.RewardStageStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.RewardStageStep}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.addPattern = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.reward.RewardStageStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.clearPatternList = function() {
  return this.setPatternList([]);
};


/**
 * optional int32 index = 7;
 * @return {number}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.setIndex = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.RewardStagePatternCreateRequest} returns this
 */
proto.reward.RewardStagePatternCreateRequest.prototype.clearIndex = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.RewardStagePatternCreateRequest.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.RewardStagePatternUpdateRequest.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStagePatternUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStagePatternUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    offerwall: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nextstageconditions: jspb.Message.getFieldWithDefault(msg, 5, ""),
    randomizereward: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    patternList: jspb.Message.toObjectList(msg.getPatternList(),
    proto.reward.RewardStageStep.toObject, includeInstance),
    index: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStagePatternUpdateRequest}
 */
proto.reward.RewardStagePatternUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStagePatternUpdateRequest;
  return proto.reward.RewardStagePatternUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStagePatternUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStagePatternUpdateRequest}
 */
proto.reward.RewardStagePatternUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwall(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextstageconditions(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRandomizereward(value);
      break;
    case 7:
      var value = new proto.reward.RewardStageStep;
      reader.readMessage(value,proto.reward.RewardStageStep.deserializeBinaryFromReader);
      msg.addPattern(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStagePatternUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStagePatternUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOfferwall();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNextstageconditions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRandomizereward();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getPatternList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.reward.RewardStageStep.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string offerwall = 4;
 * @return {string}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getOfferwall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setOfferwall = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string nextStageConditions = 5;
 * @return {string}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getNextstageconditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setNextstageconditions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool randomizeReward = 6;
 * @return {boolean}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getRandomizereward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setRandomizereward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated RewardStageStep pattern = 7;
 * @return {!Array<!proto.reward.RewardStageStep>}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getPatternList = function() {
  return /** @type{!Array<!proto.reward.RewardStageStep>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.RewardStageStep, 7));
};


/**
 * @param {!Array<!proto.reward.RewardStageStep>} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
*/
proto.reward.RewardStagePatternUpdateRequest.prototype.setPatternList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.reward.RewardStageStep=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.RewardStageStep}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.addPattern = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.reward.RewardStageStep, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.clearPatternList = function() {
  return this.setPatternList([]);
};


/**
 * optional int32 index = 8;
 * @return {number}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.setIndex = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.RewardStagePatternUpdateRequest} returns this
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.clearIndex = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.RewardStagePatternUpdateRequest.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.RewardStagePatternDeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.RewardStagePatternDeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.RewardStagePatternDeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternDeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.RewardStagePatternDeleteRequest}
 */
proto.reward.RewardStagePatternDeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.RewardStagePatternDeleteRequest;
  return proto.reward.RewardStagePatternDeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.RewardStagePatternDeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.RewardStagePatternDeleteRequest}
 */
proto.reward.RewardStagePatternDeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.RewardStagePatternDeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.RewardStagePatternDeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.RewardStagePatternDeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.RewardStagePatternDeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.RewardStagePatternDeleteRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.RewardStagePatternDeleteRequest} returns this
 */
proto.reward.RewardStagePatternDeleteRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.RewardStagePatternDeleteRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.RewardStagePatternDeleteRequest} returns this
 */
proto.reward.RewardStagePatternDeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetUserCurrTaskListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetUserCurrTaskListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUserCurrTaskListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    appname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    finished: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    offerwall: jspb.Message.getFieldWithDefault(msg, 6, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 7, 0),
    step: jspb.Message.getFieldWithDefault(msg, 8, 0),
    trigger: jspb.Message.getFieldWithDefault(msg, 9, 0),
    value: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imgurl: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fullappname: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetUserCurrTaskListResponse}
 */
proto.reward.GetUserCurrTaskListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetUserCurrTaskListResponse;
  return proto.reward.GetUserCurrTaskListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetUserCurrTaskListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetUserCurrTaskListResponse}
 */
proto.reward.GetUserCurrTaskListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppname(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFinished(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfferwall(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStage(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrigger(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgurl(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullappname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetUserCurrTaskListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetUserCurrTaskListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetUserCurrTaskListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAppname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getFinished();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getOfferwall();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStage();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getTrigger();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getImgurl();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFullappname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string appName = 3;
 * @return {string}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getAppname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setAppname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool finished = 5;
 * @return {boolean}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string offerwall = 6;
 * @return {string}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getOfferwall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setOfferwall = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 stage = 7;
 * @return {number}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setStage = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 step = 8;
 * @return {number}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 trigger = 9;
 * @return {number}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getTrigger = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setTrigger = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 value = 10;
 * @return {number}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string imgUrl = 11;
 * @return {string}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getImgurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setImgurl = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fullAppName = 12;
 * @return {string}
 */
proto.reward.GetUserCurrTaskListResponse.prototype.getFullappname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.GetUserCurrTaskListResponse} returns this
 */
proto.reward.GetUserCurrTaskListResponse.prototype.setFullappname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.FindFinishedTasklistCountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.FindFinishedTasklistCountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.FindFinishedTasklistCountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.FindFinishedTasklistCountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.FindFinishedTasklistCountRequest}
 */
proto.reward.FindFinishedTasklistCountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.FindFinishedTasklistCountRequest;
  return proto.reward.FindFinishedTasklistCountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.FindFinishedTasklistCountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.FindFinishedTasklistCountRequest}
 */
proto.reward.FindFinishedTasklistCountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.FindFinishedTasklistCountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.FindFinishedTasklistCountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.FindFinishedTasklistCountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.FindFinishedTasklistCountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.FindFinishedTasklistCountRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.FindFinishedTasklistCountRequest} returns this
 */
proto.reward.FindFinishedTasklistCountRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.reward.FindFinishedTasklistCountRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.FindFinishedTasklistCountRequest} returns this
 */
proto.reward.FindFinishedTasklistCountRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.FindFinishedTasklistCountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.FindFinishedTasklistCountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.FindFinishedTasklistCountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.FindFinishedTasklistCountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.FindFinishedTasklistCountResponse}
 */
proto.reward.FindFinishedTasklistCountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.FindFinishedTasklistCountResponse;
  return proto.reward.FindFinishedTasklistCountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.FindFinishedTasklistCountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.FindFinishedTasklistCountResponse}
 */
proto.reward.FindFinishedTasklistCountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.FindFinishedTasklistCountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.FindFinishedTasklistCountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.FindFinishedTasklistCountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.FindFinishedTasklistCountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count = 1;
 * @return {number}
 */
proto.reward.FindFinishedTasklistCountResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.FindFinishedTasklistCountResponse} returns this
 */
proto.reward.FindFinishedTasklistCountResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.ProcessEventRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.ProcessEventRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.ProcessEventRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ProcessEventRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsadded: (f = msg.getStatsadded()) && custom_stats_pb.StatsAdded.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.ProcessEventRequest}
 */
proto.reward.ProcessEventRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.ProcessEventRequest;
  return proto.reward.ProcessEventRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.ProcessEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.ProcessEventRequest}
 */
proto.reward.ProcessEventRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new custom_stats_pb.StatsAdded;
      reader.readMessage(value,custom_stats_pb.StatsAdded.deserializeBinaryFromReader);
      msg.setStatsadded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.ProcessEventRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.ProcessEventRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.ProcessEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ProcessEventRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsadded();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      custom_stats_pb.StatsAdded.serializeBinaryToWriter
    );
  }
};


/**
 * optional stats.StatsAdded statsAdded = 1;
 * @return {?proto.stats.StatsAdded}
 */
proto.reward.ProcessEventRequest.prototype.getStatsadded = function() {
  return /** @type{?proto.stats.StatsAdded} */ (
    jspb.Message.getWrapperField(this, custom_stats_pb.StatsAdded, 1));
};


/**
 * @param {?proto.stats.StatsAdded|undefined} value
 * @return {!proto.reward.ProcessEventRequest} returns this
*/
proto.reward.ProcessEventRequest.prototype.setStatsadded = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.ProcessEventRequest} returns this
 */
proto.reward.ProcessEventRequest.prototype.clearStatsadded = function() {
  return this.setStatsadded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventRequest.prototype.hasStatsadded = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.ProcessEventResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.ProcessEventResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.ProcessEventResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ProcessEventResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasreward: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    rewardamount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupdescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    nextgroupdescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 6, 0),
    txid: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.ProcessEventResponse}
 */
proto.reward.ProcessEventResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.ProcessEventResponse;
  return proto.reward.ProcessEventResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.ProcessEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.ProcessEventResponse}
 */
proto.reward.ProcessEventResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasreward(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardamount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrency(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupdescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextgroupdescription(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.ProcessEventResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.ProcessEventResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.ProcessEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.ProcessEventResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasreward();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional bool hasReward = 1;
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.getHasreward = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setHasreward = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 rewardAmount = 2;
 * @return {number}
 */
proto.reward.ProcessEventResponse.prototype.getRewardamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setRewardamount = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.clearRewardamount = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.hasRewardamount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 currency = 3;
 * @return {number}
 */
proto.reward.ProcessEventResponse.prototype.getCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.clearCurrency = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string groupDescription = 4;
 * @return {string}
 */
proto.reward.ProcessEventResponse.prototype.getGroupdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setGroupdescription = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.clearGroupdescription = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.hasGroupdescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string nextGroupDescription = 5;
 * @return {string}
 */
proto.reward.ProcessEventResponse.prototype.getNextgroupdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setNextgroupdescription = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.clearNextgroupdescription = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.hasNextgroupdescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 stage = 6;
 * @return {number}
 */
proto.reward.ProcessEventResponse.prototype.getStage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setStage = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.clearStage = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.hasStage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string txId = 7;
 * @return {string}
 */
proto.reward.ProcessEventResponse.prototype.getTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.setTxid = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.reward.ProcessEventResponse} returns this
 */
proto.reward.ProcessEventResponse.prototype.clearTxid = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.ProcessEventResponse.prototype.hasTxid = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.EditStepBonusConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.EditStepBonusConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.EditStepBonusConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.EditStepBonusConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: (f = msg.getStep()) && proto.reward.StepBonus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.EditStepBonusConfigResponse}
 */
proto.reward.EditStepBonusConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.EditStepBonusConfigResponse;
  return proto.reward.EditStepBonusConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.EditStepBonusConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.EditStepBonusConfigResponse}
 */
proto.reward.EditStepBonusConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.StepBonus;
      reader.readMessage(value,proto.reward.StepBonus.deserializeBinaryFromReader);
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.EditStepBonusConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.EditStepBonusConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.EditStepBonusConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.EditStepBonusConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reward.StepBonus.serializeBinaryToWriter
    );
  }
};


/**
 * optional StepBonus step = 1;
 * @return {?proto.reward.StepBonus}
 */
proto.reward.EditStepBonusConfigResponse.prototype.getStep = function() {
  return /** @type{?proto.reward.StepBonus} */ (
    jspb.Message.getWrapperField(this, proto.reward.StepBonus, 1));
};


/**
 * @param {?proto.reward.StepBonus|undefined} value
 * @return {!proto.reward.EditStepBonusConfigResponse} returns this
*/
proto.reward.EditStepBonusConfigResponse.prototype.setStep = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.EditStepBonusConfigResponse} returns this
 */
proto.reward.EditStepBonusConfigResponse.prototype.clearStep = function() {
  return this.setStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.EditStepBonusConfigResponse.prototype.hasStep = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.EditStepBonusConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.EditStepBonusConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.EditStepBonusConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.EditStepBonusConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    step: (f = msg.getStep()) && proto.reward.StepBonus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.EditStepBonusConfigRequest}
 */
proto.reward.EditStepBonusConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.EditStepBonusConfigRequest;
  return proto.reward.EditStepBonusConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.EditStepBonusConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.EditStepBonusConfigRequest}
 */
proto.reward.EditStepBonusConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = new proto.reward.StepBonus;
      reader.readMessage(value,proto.reward.StepBonus.deserializeBinaryFromReader);
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.EditStepBonusConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.EditStepBonusConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.EditStepBonusConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.EditStepBonusConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStep();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reward.StepBonus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.EditStepBonusConfigRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.EditStepBonusConfigRequest} returns this
 */
proto.reward.EditStepBonusConfigRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StepBonus step = 2;
 * @return {?proto.reward.StepBonus}
 */
proto.reward.EditStepBonusConfigRequest.prototype.getStep = function() {
  return /** @type{?proto.reward.StepBonus} */ (
    jspb.Message.getWrapperField(this, proto.reward.StepBonus, 2));
};


/**
 * @param {?proto.reward.StepBonus|undefined} value
 * @return {!proto.reward.EditStepBonusConfigRequest} returns this
*/
proto.reward.EditStepBonusConfigRequest.prototype.setStep = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.EditStepBonusConfigRequest} returns this
 */
proto.reward.EditStepBonusConfigRequest.prototype.clearStep = function() {
  return this.setStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.EditStepBonusConfigRequest.prototype.hasStep = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CreateStepBonusConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CreateStepBonusConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CreateStepBonusConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CreateStepBonusConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: (f = msg.getStep()) && proto.reward.StepBonus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CreateStepBonusConfigResponse}
 */
proto.reward.CreateStepBonusConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CreateStepBonusConfigResponse;
  return proto.reward.CreateStepBonusConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CreateStepBonusConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CreateStepBonusConfigResponse}
 */
proto.reward.CreateStepBonusConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.StepBonus;
      reader.readMessage(value,proto.reward.StepBonus.deserializeBinaryFromReader);
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CreateStepBonusConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CreateStepBonusConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CreateStepBonusConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CreateStepBonusConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reward.StepBonus.serializeBinaryToWriter
    );
  }
};


/**
 * optional StepBonus step = 1;
 * @return {?proto.reward.StepBonus}
 */
proto.reward.CreateStepBonusConfigResponse.prototype.getStep = function() {
  return /** @type{?proto.reward.StepBonus} */ (
    jspb.Message.getWrapperField(this, proto.reward.StepBonus, 1));
};


/**
 * @param {?proto.reward.StepBonus|undefined} value
 * @return {!proto.reward.CreateStepBonusConfigResponse} returns this
*/
proto.reward.CreateStepBonusConfigResponse.prototype.setStep = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.CreateStepBonusConfigResponse} returns this
 */
proto.reward.CreateStepBonusConfigResponse.prototype.clearStep = function() {
  return this.setStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.CreateStepBonusConfigResponse.prototype.hasStep = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.CreateStepBonusConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.CreateStepBonusConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.CreateStepBonusConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CreateStepBonusConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    step: (f = msg.getStep()) && proto.reward.StepBonus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.CreateStepBonusConfigRequest}
 */
proto.reward.CreateStepBonusConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.CreateStepBonusConfigRequest;
  return proto.reward.CreateStepBonusConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.CreateStepBonusConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.CreateStepBonusConfigRequest}
 */
proto.reward.CreateStepBonusConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = new proto.reward.StepBonus;
      reader.readMessage(value,proto.reward.StepBonus.deserializeBinaryFromReader);
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.CreateStepBonusConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.CreateStepBonusConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.CreateStepBonusConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.CreateStepBonusConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStep();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reward.StepBonus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.CreateStepBonusConfigRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.CreateStepBonusConfigRequest} returns this
 */
proto.reward.CreateStepBonusConfigRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StepBonus step = 2;
 * @return {?proto.reward.StepBonus}
 */
proto.reward.CreateStepBonusConfigRequest.prototype.getStep = function() {
  return /** @type{?proto.reward.StepBonus} */ (
    jspb.Message.getWrapperField(this, proto.reward.StepBonus, 2));
};


/**
 * @param {?proto.reward.StepBonus|undefined} value
 * @return {!proto.reward.CreateStepBonusConfigRequest} returns this
*/
proto.reward.CreateStepBonusConfigRequest.prototype.setStep = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.CreateStepBonusConfigRequest} returns this
 */
proto.reward.CreateStepBonusConfigRequest.prototype.clearStep = function() {
  return this.setStep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.CreateStepBonusConfigRequest.prototype.hasStep = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.DeletStepBonusConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.DeletStepBonusConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.DeletStepBonusConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DeletStepBonusConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.DeletStepBonusConfigRequest}
 */
proto.reward.DeletStepBonusConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.DeletStepBonusConfigRequest;
  return proto.reward.DeletStepBonusConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.DeletStepBonusConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.DeletStepBonusConfigRequest}
 */
proto.reward.DeletStepBonusConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.DeletStepBonusConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.DeletStepBonusConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.DeletStepBonusConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.DeletStepBonusConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.reward.DeletStepBonusConfigRequest.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reward.DeletStepBonusConfigRequest} returns this
 */
proto.reward.DeletStepBonusConfigRequest.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.reward.DeletStepBonusConfigRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.DeletStepBonusConfigRequest} returns this
 */
proto.reward.DeletStepBonusConfigRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.GetStepBonusConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetStepBonusConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetStepBonusConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetStepBonusConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetStepBonusConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusstepList: jspb.Message.toObjectList(msg.getBonusstepList(),
    proto.reward.StepBonus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetStepBonusConfigResponse}
 */
proto.reward.GetStepBonusConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetStepBonusConfigResponse;
  return proto.reward.GetStepBonusConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetStepBonusConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetStepBonusConfigResponse}
 */
proto.reward.GetStepBonusConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.StepBonus;
      reader.readMessage(value,proto.reward.StepBonus.deserializeBinaryFromReader);
      msg.addBonusstep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetStepBonusConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetStepBonusConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetStepBonusConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetStepBonusConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusstepList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.StepBonus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StepBonus bonusStep = 1;
 * @return {!Array<!proto.reward.StepBonus>}
 */
proto.reward.GetStepBonusConfigResponse.prototype.getBonusstepList = function() {
  return /** @type{!Array<!proto.reward.StepBonus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.StepBonus, 1));
};


/**
 * @param {!Array<!proto.reward.StepBonus>} value
 * @return {!proto.reward.GetStepBonusConfigResponse} returns this
*/
proto.reward.GetStepBonusConfigResponse.prototype.setBonusstepList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.StepBonus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.StepBonus}
 */
proto.reward.GetStepBonusConfigResponse.prototype.addBonusstep = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.StepBonus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.GetStepBonusConfigResponse} returns this
 */
proto.reward.GetStepBonusConfigResponse.prototype.clearBonusstepList = function() {
  return this.setBonusstepList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.GetStrikeBonusByUserId.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.GetStrikeBonusByUserId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.GetStrikeBonusByUserId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetStrikeBonusByUserId.toObject = function(includeInstance, msg) {
  var f, obj = {
    totaldays: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentday: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rewardamount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.GetStrikeBonusByUserId}
 */
proto.reward.GetStrikeBonusByUserId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.GetStrikeBonusByUserId;
  return proto.reward.GetStrikeBonusByUserId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.GetStrikeBonusByUserId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.GetStrikeBonusByUserId}
 */
proto.reward.GetStrikeBonusByUserId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotaldays(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrentday(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardamount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.GetStrikeBonusByUserId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.GetStrikeBonusByUserId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.GetStrikeBonusByUserId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.GetStrikeBonusByUserId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotaldays();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentday();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRewardamount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 totalDays = 1;
 * @return {number}
 */
proto.reward.GetStrikeBonusByUserId.prototype.getTotaldays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetStrikeBonusByUserId} returns this
 */
proto.reward.GetStrikeBonusByUserId.prototype.setTotaldays = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 currentDay = 2;
 * @return {number}
 */
proto.reward.GetStrikeBonusByUserId.prototype.getCurrentday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetStrikeBonusByUserId} returns this
 */
proto.reward.GetStrikeBonusByUserId.prototype.setCurrentday = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rewardAmount = 3;
 * @return {number}
 */
proto.reward.GetStrikeBonusByUserId.prototype.getRewardamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.GetStrikeBonusByUserId} returns this
 */
proto.reward.GetStrikeBonusByUserId.prototype.setRewardamount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool enable = 4;
 * @return {boolean}
 */
proto.reward.GetStrikeBonusByUserId.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.GetStrikeBonusByUserId} returns this
 */
proto.reward.GetStrikeBonusByUserId.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reward.getStepBonusConfigByUserIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.getStepBonusConfigByUserIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.getStepBonusConfigByUserIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.getStepBonusConfigByUserIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusstepList: jspb.Message.toObjectList(msg.getBonusstepList(),
    proto.reward.StepBonusWithCheck.toObject, includeInstance),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    newdayafter: (f = msg.getNewdayafter()) && proto.reward.TimeSpan.toObject(includeInstance, f),
    bonusprogress: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse}
 */
proto.reward.getStepBonusConfigByUserIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.getStepBonusConfigByUserIdResponse;
  return proto.reward.getStepBonusConfigByUserIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.getStepBonusConfigByUserIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse}
 */
proto.reward.getStepBonusConfigByUserIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reward.StepBonusWithCheck;
      reader.readMessage(value,proto.reward.StepBonusWithCheck.deserializeBinaryFromReader);
      msg.addBonusstep(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 3:
      var value = new proto.reward.TimeSpan;
      reader.readMessage(value,proto.reward.TimeSpan.deserializeBinaryFromReader);
      msg.setNewdayafter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBonusprogress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.getStepBonusConfigByUserIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.getStepBonusConfigByUserIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.getStepBonusConfigByUserIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusstepList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reward.StepBonusWithCheck.serializeBinaryToWriter
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNewdayafter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.reward.TimeSpan.serializeBinaryToWriter
    );
  }
  f = message.getBonusprogress();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * repeated StepBonusWithCheck bonusStep = 1;
 * @return {!Array<!proto.reward.StepBonusWithCheck>}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.getBonusstepList = function() {
  return /** @type{!Array<!proto.reward.StepBonusWithCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reward.StepBonusWithCheck, 1));
};


/**
 * @param {!Array<!proto.reward.StepBonusWithCheck>} value
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse} returns this
*/
proto.reward.getStepBonusConfigByUserIdResponse.prototype.setBonusstepList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reward.StepBonusWithCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reward.StepBonusWithCheck}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.addBonusstep = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reward.StepBonusWithCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse} returns this
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.clearBonusstepList = function() {
  return this.setBonusstepList([]);
};


/**
 * optional bool enable = 2;
 * @return {boolean}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse} returns this
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional TimeSpan newDayAfter = 3;
 * @return {?proto.reward.TimeSpan}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.getNewdayafter = function() {
  return /** @type{?proto.reward.TimeSpan} */ (
    jspb.Message.getWrapperField(this, proto.reward.TimeSpan, 3));
};


/**
 * @param {?proto.reward.TimeSpan|undefined} value
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse} returns this
*/
proto.reward.getStepBonusConfigByUserIdResponse.prototype.setNewdayafter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse} returns this
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.clearNewdayafter = function() {
  return this.setNewdayafter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.hasNewdayafter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 bonusProgress = 4;
 * @return {number}
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.getBonusprogress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.getStepBonusConfigByUserIdResponse} returns this
 */
proto.reward.getStepBonusConfigByUserIdResponse.prototype.setBonusprogress = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.TimeSpan.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.TimeSpan.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.TimeSpan} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.TimeSpan.toObject = function(includeInstance, msg) {
  var f, obj = {
    hours: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minutes: jspb.Message.getFieldWithDefault(msg, 2, 0),
    seconds: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.TimeSpan}
 */
proto.reward.TimeSpan.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.TimeSpan;
  return proto.reward.TimeSpan.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.TimeSpan} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.TimeSpan}
 */
proto.reward.TimeSpan.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHours(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinutes(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.TimeSpan.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.TimeSpan.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.TimeSpan} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.TimeSpan.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHours();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinutes();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 hours = 1;
 * @return {number}
 */
proto.reward.TimeSpan.prototype.getHours = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.TimeSpan} returns this
 */
proto.reward.TimeSpan.prototype.setHours = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 minutes = 2;
 * @return {number}
 */
proto.reward.TimeSpan.prototype.getMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.TimeSpan} returns this
 */
proto.reward.TimeSpan.prototype.setMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 seconds = 3;
 * @return {number}
 */
proto.reward.TimeSpan.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.TimeSpan} returns this
 */
proto.reward.TimeSpan.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.StepBonus.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.StepBonus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.StepBonus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StepBonus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rewardafter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rewardamount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.StepBonus}
 */
proto.reward.StepBonus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.StepBonus;
  return proto.reward.StepBonus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.StepBonus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.StepBonus}
 */
proto.reward.StepBonus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardafter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardamount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.StepBonus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.StepBonus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.StepBonus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StepBonus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRewardafter();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRewardamount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.StepBonus.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StepBonus} returns this
 */
proto.reward.StepBonus.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 rewardAfter = 2;
 * @return {number}
 */
proto.reward.StepBonus.prototype.getRewardafter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StepBonus} returns this
 */
proto.reward.StepBonus.prototype.setRewardafter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rewardAmount = 3;
 * @return {number}
 */
proto.reward.StepBonus.prototype.getRewardamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StepBonus} returns this
 */
proto.reward.StepBonus.prototype.setRewardamount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reward.StepBonusWithCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.reward.StepBonusWithCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reward.StepBonusWithCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StepBonusWithCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    rewardafter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rewardamount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isreached: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reward.StepBonusWithCheck}
 */
proto.reward.StepBonusWithCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reward.StepBonusWithCheck;
  return proto.reward.StepBonusWithCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reward.StepBonusWithCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reward.StepBonusWithCheck}
 */
proto.reward.StepBonusWithCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardafter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRewardamount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsreached(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reward.StepBonusWithCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reward.StepBonusWithCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reward.StepBonusWithCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reward.StepBonusWithCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRewardafter();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRewardamount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsreached();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.reward.StepBonusWithCheck.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StepBonusWithCheck} returns this
 */
proto.reward.StepBonusWithCheck.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 rewardAfter = 2;
 * @return {number}
 */
proto.reward.StepBonusWithCheck.prototype.getRewardafter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StepBonusWithCheck} returns this
 */
proto.reward.StepBonusWithCheck.prototype.setRewardafter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 rewardAmount = 3;
 * @return {number}
 */
proto.reward.StepBonusWithCheck.prototype.getRewardamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reward.StepBonusWithCheck} returns this
 */
proto.reward.StepBonusWithCheck.prototype.setRewardamount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool isReached = 4;
 * @return {boolean}
 */
proto.reward.StepBonusWithCheck.prototype.getIsreached = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reward.StepBonusWithCheck} returns this
 */
proto.reward.StepBonusWithCheck.prototype.setIsreached = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


goog.object.extend(exports, proto.reward);
