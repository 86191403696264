import {makeAutoObservable} from 'mobx';
import {RpcError} from 'grpc-web';
import {AppStore} from '../app';
import {
  GetNotificationsTempaltesRequest, GetNotificationsTempaltesResponse,
  UpdateNotificationTemplateRequest, UpdateNotificationTemplateResponse,
} from '../../proto/notifications_pb';
import { NotificationsService, WebSocketService } from '../../services';
import { NotificationsAdminGetNotificationsTempaltesRoute, NotificationsAdminToggleNotificationRoute, NotificationsAdminUpdateNotificationTemplateRoute } from '@boints/grpc';

export class NotificationsTemplatesStore {
  public error: RpcError | null = null;
  public templates: NotificationsAdminGetNotificationsTempaltesRoute.ResponseType['templates'] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getNotificationsTempaltes(dto: NotificationsAdminGetNotificationsTempaltesRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { templates } = yield WebSocketService
        .sendRequest<NotificationsAdminGetNotificationsTempaltesRoute.ResponseType, NotificationsAdminGetNotificationsTempaltesRoute.RequestType>({
          method: 'notifications_getTemplates',
          data: {},
        })

      this.templates = templates;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateNotificationTemplate(dto: NotificationsAdminUpdateNotificationTemplateRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<NotificationsAdminUpdateNotificationTemplateRoute.ResponseType, NotificationsAdminUpdateNotificationTemplateRoute.RequestType>({
          method: 'notifications_updateTemplate',
          data: dto
        })

      this.templates = this.templates.map((item) => {
        if (item.type === dto.type) {
          return {
            ...item,
            headertemplate: dto.headerTemplate,
            messagetemplate: dto.headerTemplate,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *toggleNotificationTemplate(dto: NotificationsAdminToggleNotificationRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response = yield WebSocketService
        .sendRequest<NotificationsAdminToggleNotificationRoute.ResponseType, NotificationsAdminToggleNotificationRoute.RequestType>({
          method: 'notifications_toggle',
          data: dto
        })

      this.templates = this.templates.map((item) => {
        if (item.type === dto.type) {
          return {
            ...item,
            enabled: response.enabled,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
