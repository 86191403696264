import React, {useEffect, useState} from 'react';
import {Select, MenuItem} from '@mui/material';
import {EditableHOC} from '../editable-hoc';

interface Props {
  value: string;
  options: {[value: string]: string};
  onChange: (value: string) => void;
}

export const SelectCell: React.FC<Props> = ({value, options, onChange}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <EditableHOC
      onSave={() => {
        onChange(currentValue);
      }}
      onCancel={() => {
        setCurrentValue(value);
      }}
    >
      {(editable) => (
        <Select
          size='small'
          value={currentValue}
          onChange={(e) => setCurrentValue(e.target.value)}
          disabled={!editable}
          style={{flex: 1}}
        >
          {Object.entries(options).map(([value, title], idx) => (
            <MenuItem key={idx} value={value}>
              {title}
            </MenuItem>
          ))}
        </Select>
      )}
    </EditableHOC>
  );
};
