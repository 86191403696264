import { RpcError } from "grpc-web";
import { makeAutoObservable } from "mobx";
import {
  RewardStagePatternCreateRequest,
  RewardStagePatterns,
  RewardStagePatternUpdateRequest,
  RewardStagePatternDeleteRequest,
} from "../../proto/reward_pb";
import { AppStore } from "../app";
import {
  RewardRewardStageCreateRoute,
  RewardRewardStageDeleteRoute,
  RewardRewardStageFindAllRoute,
  RewardRewardStageUpdateRoute,
} from "@boints/grpc";
import { WebSocketService } from "../../services/transport";

export class MotivatianlCurrencyStore {
  public error: RpcError | null = null;

  public stagePatterns: RewardStagePatterns.AsObject = {
    itemsList: [],
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getAll() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: RewardRewardStageFindAllRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardRewardStageFindAllRoute.ResponseType, RewardRewardStageFindAllRoute.RequestType>({
          method: 'reward_findAll',
          data: {},
        });

      this.stagePatterns.itemsList = response.items.map((item) => {
        const { id, index, name, nextStageConditions, offerwall, pattern, randomizeReward } = item;
        return {
          id,
          name,
          index,
          offerwall,
          patternList: pattern,
          randomizereward: randomizeReward,
          nextstageconditions: nextStageConditions,
        };
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *create(data: RewardStagePatternCreateRequest.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { name, nextstageconditions, offerwall, patternList, randomizereward, index } = data;
      const response: RewardRewardStageCreateRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardRewardStageCreateRoute.ResponseType, RewardRewardStageCreateRoute.RequestType>({
          method: 'reward_create',
          data: {
            name,
            offerwall,
            index:  index || 0,
            pattern: patternList,
            randomizeReward: randomizereward,
            nextStageConditions: nextstageconditions,
          },
        });
      
      const newItem = {
        id: response.id,
        name: response.name,
        index: response.index,
        offerwall: response.offerwall,
        patternList: response.pattern,
        randomizereward: response.randomizeReward,
        nextstageconditions: response.nextStageConditions,
      };

      this.stagePatterns.itemsList = [...this.stagePatterns.itemsList, newItem].sort((a, b) => a.index - b.index);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *update(data: RewardStagePatternUpdateRequest.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { id, name, nextstageconditions, offerwall, patternList, randomizereward, index } = data;
      const response: RewardRewardStageUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardRewardStageUpdateRoute.ResponseType, RewardRewardStageUpdateRoute.RequestType>({
          method: 'reward_update',
          data: {
            id,
            name,
            offerwall,
            index:  index || 0,
            pattern: patternList,
            randomizeReward: randomizereward,
            nextStageConditions: nextstageconditions,
          },
        });

      const newItem = {
        id: response.id,
        name: response.name,
        index: response.index,
        offerwall: response.offerwall,
        patternList: response.pattern,
        randomizereward: response.randomizeReward,
        nextstageconditions: response.nextStageConditions,
      };

      this.stagePatterns.itemsList = this.stagePatterns.itemsList.map(item => item.id === newItem.id ? newItem : item);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *delete(data: RewardStagePatternDeleteRequest.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardRewardStageDeleteRoute.ResponseType, RewardRewardStageDeleteRoute.RequestType>({
          method: 'reward_delete',
          data: {
            id: data.id,
          },
        });

      this.stagePatterns.itemsList = this.stagePatterns.itemsList.filter(item => item.id !== data.id);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
