import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getCustomTableStyleV2 = makeStyles((theme: Theme) =>
  createStyles({
    patternItem: {
      margin: 0,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
    },
  }),
);
