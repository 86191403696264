import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getSettingsStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: '40px',
    }
  }),
);
