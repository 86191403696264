export const convertKeysToLowerCase = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = key.toLowerCase();
      result[newKey] = convertKeysToLowerCase(obj[key]);
    }
  }

  return result;
}