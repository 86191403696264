export const balance = {
  dailyReportGet: {
    microserviceName: 'balance',
    serviceName: 'DailyReportService',
    method: 'get',
  },
  dailyReportGetOne: {
    microserviceName: 'balance',
    serviceName: 'DailyReportService',
    method: 'getOne',
  },
  versionGet: {
    microserviceName: 'balance',
    serviceName: 'BalanceVersionService',
    method: 'get',
  },
  versionCreate: {
    microserviceName: 'balance',
    serviceName: 'BalanceVersionService',
    method: 'create',
  },
  versionUpdate: {
    microserviceName: 'balance',
    serviceName: 'BalanceVersionService',
    method: 'update',
  },
  versionDelete: {
    microserviceName: 'balance',
    serviceName: 'BalanceVersionService',
    method: 'delete',
  },
  transactionFindAll: {
    microserviceName: 'balance',
    serviceName: 'TransactionService',
    method: 'findAll',
  },
  balanceSet: {
    microserviceName: 'balance',
    serviceName: 'BalanceService',
    method: 'set',
  },
};