import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { FindDailyReports } from '../../proto/balance_pb';
import { useStores } from '../../stores/app';
import { CustomTable, Column, LinkCell } from '../../components/table';

export const DailyReport = observer(() => {
  const { dailyReportStore } = useStores();

  const columns: Column[] = [
    {
      id: 'timestamp',
      label: 'Date',
      component: ({ id, timestamp }) => (
        <LinkCell
          value={moment(new Date(+timestamp))
            .format('dddd DD/MM/YYYYY')
            .toString()}
          url={`/dashboard/daily-report-details/${id}`}
        />
      ),
    },
    {
      id: 'reportMap',
      label: 'Earned',
      component: ({ reportMap }) =>
        reportMap.filter(([key]: [key: any]) => key === 'earned')[0][1],
    },
    {
      id: 'reportMap',
      label: 'Promo',
      component: ({ reportMap }) =>
        reportMap.filter(([key]: [key: any]) => key === 'promo')[0][1],
    },
    {
      id: 'reportMap',
      label: 'Total',
      component: ({ reportMap }) =>
        reportMap.filter(([key]: [key: any]) => key === 'total')[0][1],
    },
  ];

  const loadDailyReports = (dto: FindDailyReports.AsObject) =>
    dailyReportStore.getDailyReports(dto);

  return (
    <CustomTable
      requestAction={loadDailyReports}
      columns={columns}
      data={dailyReportStore.reports.entitiesList}
      totalCount={dailyReportStore.reports.totalcount}
      isSortEnabled={false}
      defaultSortKey="id"
      isDataSliced
    />
  );
});
