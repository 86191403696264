import React, {useEffect, useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Box,
  useTheme,
  useMediaQuery,
  ListSubheader,
  Autocomplete,
} from '@mui/material';
import {observer} from 'mobx-react';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import {Info} from '@mui/icons-material';
import clsx from 'clsx';
import {getResetUserModalStyle} from './reset-user.styles';
import {useStores} from '../../../stores/app';
import { IShortUser } from '../../../core';

const LISTBOX_PADDING = 8; // px

const USER_IDS_ERROR = "Please, select the user login's.";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleAgree: (data: any) => void;
  initialSelectedUser?: IShortUser;
}

export const ResetUserModal: React.FC<Props> = observer(
  ({ open, handleClose, handleAgree, initialSelectedUser }) => {
    const {userStore} = useStores();
    const [selectedUser, setSelectedUser] = useState<IShortUser[] | IShortUser>(
      initialSelectedUser ? [initialSelectedUser] : []
    );
    const [withFullStory, setWithFullStory] = useState<boolean>(false);
    const [searchUser, setSearchUser] = useState<string>();
    const [userIdsError, setUserIdsError] = useState<string>(initialSelectedUser ? '' : USER_IDS_ERROR);
    const classes = getResetUserModalStyle();

    const handleChange = (e: any, value: IShortUser[]) => {
      setSelectedUser(value);
      value.length ? setUserIdsError('') : setUserIdsError(USER_IDS_ERROR);
    };

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchUser(e.target.value);
    };

    const handleCloseInner = () => {
      setSelectedUser([]);

      handleClose();
    };

    const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
      setWithFullStory(e.target.checked);
    };

    const handleClick = () => {
      if (Array.isArray(selectedUser) && selectedUser.length) {
        const userIds = selectedUser.map((el) => el.id);
        handleAgree({userIds, withFullStory });
        setSelectedUser([]);
        return;
      } else {
        setUserIdsError(USER_IDS_ERROR);
      }
    };

    useEffect(() => {
      if (open && userStore.searchResult.players.length === 0) {
        userStore.searchPlayers({
          pageNumber: 1,
          amountPerPage: 100,
        });
      } else if (open && searchUser) {
        userStore.searchPlayers({
          pageNumber: 1,
          amountPerPage: 20,
          name: searchUser,
        });
      }
    }, [open, searchUser]);

    useEffect(() => {
    }, [])

    return (
      <Dialog
        open={open}
        onClose={handleCloseInner}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogContent>
          <Box className={classes.wrapper}>
            <Autocomplete
              multiple
              id="tags-standard"
              className={classes.autocomplete}
              options={userStore.searchResult.players}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              ListboxComponent={
                ListboxComponent as React.ComponentType<
                  React.HTMLAttributes<HTMLElement>
                >
              }
              getOptionLabel={(option) => option.name}
              defaultValue={initialSelectedUser ? [initialSelectedUser] : []}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(userIdsError)}
                  onChange={handleChangeInput}
                  helperText={userIdsError}
                  variant="standard"
                  label="Select users"
                />
              )}
            />
            <Tooltip
              classes={{
                tooltip: classes.test,
              }}
              title="Reset only selected users."
            >
              <Info />
            </Tooltip>
          </Box>
          <Box className={clsx(classes.wrapper, classes.checkbox)}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={withFullStory}
                  onChange={handleSelect}
                  name="checkedB"
                  color="primary"
                />
              }
              label="With full story"
            />
            <Tooltip
              classes={{
                tooltip: classes.test,
              }}
              title="If selected - delete all information about user. Else reset balances, progress on viewing ads and crossword levels."
            >
              <Info />
            </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInner} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClick} color="primary" autoFocus>
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

const OuterElementContext = React.createContext({});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
  const {data, index, style} = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

export const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    // eslint-disable-next-line react/prop-types
    const {children, ...other} = props as any;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {noSsr: true});
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: number) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);
