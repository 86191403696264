export const RANGE_FOR_EXPORT = 86400000;

export const BASE_URL_AUTH =
  (window['runConfig' as any] as any)?.BASE_URL_AUTH ||
  'https://boints-auth.playtowinapps.com';

export const BASE_URL_BALANCE =
  (window['runConfig' as any] as any)?.BASE_URL_BALANCE ||
  'https://boints-balance.playtowinapps.com';

export const BASE_URL_LEADERBOARD =
  (window['runConfig' as any] as any)?.BASE_URL_LEADERBOARD ||
  'https://boints-leaderboard.playtowinapps.com';

export const BFF_URL =
  (window['runConfig' as any] as any)?.BFF_URL ||
  'https://boints-bff.playtowinapps.com';

export const BASE_URL_REWARD =
  (window['runConfig' as any] as any)?.BASE_URL_REWARD ||
  'https://boints-reward.playtowinapps.com';

export const BASE_URL_DOUBLE_BOINTS =
  (window['runConfig' as any] as any)?.BASE_URL_DOUBLE_BOINTS ||
  'https://boints-double-boints.playtowinapps.com';

export const BASE_URL_CASHOUT =
  (window['runConfig' as any] as any)?.BASE_URL_CASHOUT ||
  'https://boints-cashout.playtowinapps.com';

export const BASE_URL_OFFERWALL =
  (window['runConfig' as any] as any)?.BASE_URL_OFFERWALL ||
  'https://boints-offerwall.playtowinapps.com';

export const BASE_URL_OFFERWALL_FE =
  (window['runConfig' as any] as any)?.BASE_URL_OFFERWALL_FE ||
  'https://boints-offerwall-fe.playtowinapps.com';

export const BASE_URL_SETTINGS =
  (window['runConfig' as any] as any)?.BASE_URL_SETTINGS ||
  'https://boints-settings.playtowinapps.com';

export const BASE_URL_USER_QUALITY =
  (window['runConfig' as any] as any)?.BASE_URL_USER_QUALITY ||
  'https://boints-user-quality.playtowinapps.com';

export const BASE_URL_NOTIFICATIONS =
  (window['runConfig' as any] as any)?.BASE_URL_NOTIFICATIONS ||
  'https://boints-notifications.playtowinapps.com';

export const IS_STAGE = (window['runConfig' as any] as any)?.IS_STAGE || false;
export const IS_LOCALHOST = (window['runConfig' as any] as any)?.IS_LOCALHOST || false;