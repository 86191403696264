import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getEditDailyCoinsGoalStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      marginBottom: '0px !important',
      paddingBottom: '0px !important',
    },
    topContainer: {
      display: 'flex',
    },
    inputEditing: {
      margin: '5px !important',
      width: '100% !important'
    },
    patternContainer: {
      border: '1px solid',
      borderRadius: '5px',
      borderColor: '#b8b8b8',
      color: 'black',
      padding: '5px',
      margin: '5px',
      display: 'flex',
      justifyContent: 'center',
    },
    patternItem: {
      margin: 0,
      padding: 0,
      cursor: 'pointer',
    },
    crossBtn: {
      marginRight: '5px !important',
    },
    errorLabel: {
      color: theme.palette.error.main,
    }
  })
);
