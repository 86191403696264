import { observer } from 'mobx-react';
import { getRoleByPermission, getUserStatus } from '../../stores';
import { Column, CustomTable } from '../../components/table';
import { dateFormat } from '../../core/utils';
import { UnbanDialog } from '../../components/dialogs';
import { useStores } from '../../stores/app';
import { RequestGetUserDto } from '../../proto/auth_pb';
import { AuthUserBanRoute } from '@boints/grpc';

export const BannedUsers = observer(() => {
  const { userStore } = useStores();

  const loadBannedPlayers = (dto: RequestGetUserDto.AsObject) => {
    if (dto.order === 'navButtons') {
      return;
    }

    userStore.getBannedPlayers(dto);
  };

  const userUpdateStatus = (dto: Omit<AuthUserBanRoute.RequestType, 'accessToken'>) => {
    console.log({ dto });
    userStore.updatePlayerStatus(dto);

  };
  const columns: Column[] = [
    { id: 'id', label: 'ID', sortKey: 'user.id', minWidth: 70 },
    {
      id: 'permissionLevel',
      label: 'Permission Level',
      sortKey: 'user.permissionLevel',
      minWidth: 100,
      format: getRoleByPermission,
    },
    { id: 'name', label: 'Login', sortKey: 'user.name', minWidth: 150 },
    {
      id: 'reason',
      label: 'Reason',
      disableSort: true,
      minWidth: 180,
      format: (item: string) => (item?.length > 0 ? item : '-'),
    },
    {
      id: 'email',
      label: 'Email',
      sortKey: 'user.email',
      minWidth: 180,
      format: (item: string) => (item?.length > 0 ? item : '-'),
    },
    {
      id: 'facebookId',
      label: 'Facebook ID',
      sortKey: 'user.facebookId',
      minWidth: 170,
      format: (item: string) => (item?.length > 0 ? item : '-'),
    },
    {
      id: 'deviceId',
      label: 'Device Id',
      sortKey: 'user.deviceId',
      minWidth: 170,
      format: (item: string) => (item?.length > 0 ? item : '-'),
    },
    {
      id: 'balanceBoints',
      label: 'Boints',
      sortKey: '"balanceBoints"',
      minWidth: 90,
    },
    {
      id: 'balanceTokens',
      label: 'Tokens',
      sortKey: '"balanceTokens"',
      minWidth: 90,
    },
    {
      id: 'status',
      label: 'Status',
      sortKey: 'user.status',
      minWidth: 85,
      format: getUserStatus,
    },
    {
      id: 'lastActiveDate',
      label: 'Last Active Date Time',
      sortKey: 'user.lastActiveDate',
      minWidth: 140,
      format: dateFormat,
    },
    {
      id: 'navButtons',
      label: '',
      minWidth: 120,
      component: (props: any) => (
        <UnbanDialog row={props} unbanUserAction={userUpdateStatus} />
      ),
    },
  ];

  return (
    <CustomTable
      data={userStore.bannedUsers.data}
      totalCount={userStore.bannedUsers.totalCount}
      requestAction={loadBannedPlayers}
      columns={columns}
      defaultSortKey="user.createDate"
      isSearchShow
      isDataSliced
      searchInputText="Type ID, Login, FacebookId or DeviceId here"
    />
  );
});
