import { OfferwallAdminImportCsvRoute } from "@boints/grpc";

export const validateRewards = (data: OfferwallAdminImportCsvRoute.RequestType['rewards']) => {
    const allIds: number[] = [];
    const rewardsIds: number[] = [];
    const idsWithBlockersReferredToThemselves: number[] = [];
    const idsWithBlockersReferredToNextReward: number[] = [];
    const idsWithRepeatedBlockers: number[] = [];

    data.forEach(({ id, blockersId, key, type }) => {
        id = Number(id);
        if (!key || !type || !id) {
            throw `Invalid reward. Some of the fields are missing. id: ${id}`;
        }

        const blockersIds = blockersId || [];

        allIds.push(id, ...blockersIds);
        rewardsIds.push(id);

        if (blockersIds.includes(id)) {
            idsWithBlockersReferredToThemselves.push(id);
        }

        if (blockersIds.some((v) => v > id)) {
            idsWithBlockersReferredToNextReward.push(id);
        }

        const uniqueBlockers = new Set();

        blockersIds.forEach((blockerId) => {
            if (uniqueBlockers.has(blockerId)) {
                idsWithRepeatedBlockers.push(id);
            }

            uniqueBlockers.add(blockerId);
        });
    });

    if (idsWithBlockersReferredToThemselves.length > 0) {
        throw `Some of the blocker ids refer to themselves. Invalid reward ids:${idsWithBlockersReferredToThemselves.join(', ')}`
    }

    const uniqueIds = [...new Set(allIds)];
    console.log(uniqueIds)
    
    if (idsWithRepeatedBlockers.length > 0) {
      throw `Some of the blocker ids are not unique. Invalid reward ids:${idsWithRepeatedBlockers.join(', ')}`
    }
    
    if (rewardsIds.length !== [...new Set(rewardsIds)].length) {
      throw 'Some of the reward ids are not unique.'
    }
    
    if (uniqueIds.length !== data.length) {
      const validIds = data.map(({ id }) => +id);
      const invalidIds = uniqueIds.filter((id) => !validIds.includes(+id));
      console.log('invalidIds', invalidIds, 'data length', data.length)

      throw `Some of the blocker ids refer to non-existing rewards. Non-existing reward ids:${invalidIds.join(', ')}`
    }
    
}