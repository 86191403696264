import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Typography } from '@mui/material';
import moment from 'moment';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import {
  GetSingleStat,
} from '../../proto/leaderboard_pb';

enum EventStatus {
  NOT_STARTED = 'not_started',
  CURRENT = 'current',
  FINISHED = 'finished',
}

export const TourInfo = observer(() => {
  const { seasonsStore, tournamentsStore } = useStores();
  const { tourId, seasonId } = useParams();

  const seasonItem = seasonsStore.seasons.entities.find(
    ({ id }) => id === Number(seasonId),
  );
  const tourItem = tournamentsStore.tournaments.entities.find(
    ({ id }) => id === Number(tourId),
  );

  const loadSingleTourStat = (dto: GetSingleStat.AsObject) =>
    tournamentsStore.getSingleTourStat(dto);

  useEffect(() => {
    if (tournamentsStore.tournaments.entities.length === 0) {
      tournamentsStore.getTournament({ seasonid: Number(seasonId), amountperpage: 10000000 });
    }

    if (!seasonsStore.seasons.entities.length) {
      seasonsStore.getSeasons({ pageNumber: 1, amountPerPage: 10000 });
    }
  }, [seasonsStore.seasons.entities]);

  const columns: Column[] = [
    { id: 'place', label: 'Place', minWidth: 50 },
    { id: 'userid', label: 'User ID', minWidth: 70 },
    { id: 'score', label: 'Score', minWidth: 70 },
    { id: 'tokens', label: 'Tokens', minWidth: 70 },
    {
      id: 'boints',
      label: 'Boints',
      minWidth: 70,
      format: (props: any) => {
        if (tourItem && tourItem.status === EventStatus.CURRENT) {
          return '-';
        }

        return String(props);
      },
    },
  ];

  const tourInfoBlock = (
    seasonItem: typeof seasonsStore.seasons.entities[0],
    tourItem: typeof tournamentsStore.tournaments.entities[0],
  ) => {
    return (
      <>
        <Typography>Status: {tourItem.status}</Typography>
        <Typography>{seasonItem.name}</Typography>
        <Typography>
          {moment(+tourItem.startDate).format('MM/DD/YYYY') +
            ' - ' +
            (tourItem.status === EventStatus.CURRENT
              ? 'until now'
              : moment(+tourItem.endDate).format('MM/DD/YYYY'))}
        </Typography>
        <Typography>Participants: {tourItem.participants}</Typography>
        <Typography>Cash reward: {String(tourItem.totalScore)}</Typography>
      </>
    );
  };

  return (
    <CustomTable
      columns={columns}
      data={tournamentsStore.singleTourStat.entities}
      isDataSliced={true}
      totalCount={tournamentsStore.singleTourStat.totalCount}
      isSortEnabled={false}
      isGoBackShow={true}
      requestAction={loadSingleTourStat}
      paramsForAction={{ tourid: Number(tourId) }}
      infoBlock={seasonItem && tourItem && tourInfoBlock(seasonItem, tourItem)}
    />
  );
});
