import { AdminServiceClient } from '../../proto/NotificationsServiceClientPb';
import { BASE_URL_NOTIFICATIONS } from '../../core';
import {
  GetNotificationsTempaltesRequest, GetNotificationsTempaltesResponse,
  UpdateNotificationTemplateRequest, UpdateNotificationTemplateResponse,
} from '../../proto/notifications_pb';

const adminServiceClient = new AdminServiceClient(
  BASE_URL_NOTIFICATIONS,
  {},
  {}
);

export const NotificationsService = new (class {
  async getNotificationsTemplates(
    dto: GetNotificationsTempaltesRequest.AsObject,
  ): Promise<GetNotificationsTempaltesResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new GetNotificationsTempaltesRequest();

      req.setAccesstoken(dto.accesstoken);

      adminServiceClient.getNotificationsTempaltes(req, {}, (err, res) => {
        if (res) {
          resolve(res.toObject());
        }
        reject(err);
      });
    });
  }

  async updateNotificationTempalte(
    dto: UpdateNotificationTemplateRequest.AsObject
  ): Promise<UpdateNotificationTemplateResponse.AsObject> {
    return new Promise((resolve, reject) => {
      const req = new UpdateNotificationTemplateRequest();

      req.setAccesstoken(dto.accesstoken);
      req.setType(dto.type);
      req.setHeadertemplate(dto.headertemplate);
      req.setMessagetemplate(dto.messagetemplate);

      adminServiceClient.updateNotificationTemplate(req, {}, (err, res) => {
        if (res) {
          resolve(res.toObject());
        }
        reject(err);
      });
    });
  }
})();
