import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getWithdrawalTicketsStyle = makeStyles((theme: Theme) =>
  createStyles({
    fraudPass: {
      fill: 'green',
    },
    fraudCheck: {
      fill: 'orange',
    },
    fraudSuspect: {
      fill: 'red',
    },
    fraudPending: {
      fill: 'blue',
    },
  }),
);
