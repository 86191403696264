import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useStores } from '../../stores/app';
import { dailyReportDetailsStyle } from './daily-report.styles';
import { transactionTypeTitles } from './daily-report.titles';

export const DailyReportDetails = observer(() => {
  const { dailyReportStore } = useStores();
  const { id } = useParams();
  const classes = dailyReportDetailsStyle();

  useEffect(() => {
    dailyReportStore.getOneDailyReport(Number(id));
  }, [id]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h2 className={classes.title}>
          {dailyReportStore.report.timestamp
            ? moment(new Date(+dailyReportStore.report.timestamp || ''))
              .format('dddd DD/MM/YYYYY')
              .toString()
            : ''}
        </h2>
        <div className={classes.offerwalls}>
          {dailyReportStore.report.reportMap
            .filter(([key]) => !['promo', 'earned', 'total'].includes(key))
            .map(([key, value]) => (
              <div key={key}>
                {(transactionTypeTitles as any)[key] || 'unknown transaction type'}: {value}
              </div>
            ))}
        </div>
        <div>
          {dailyReportStore.report.reportMap
            .filter(([key]) => ['promo', 'earned', 'total'].includes(key))
            .map(([key, value]) => (
              <div key={key}>
                {(transactionTypeTitles as any)[key]}: {value}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
});
