import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  StatsStatisticsGetHrsPostbackStatsRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';
import { GetHrsPostbackStatsResponse } from '../../proto/stats_pb';
import { generateCSV } from '../../core';
import moment from 'moment';
export class HrsStatsStore {
  public error: RpcError | null = null;

  public hrsPostbackStats: GetHrsPostbackStatsResponse.AsObject = {
    dataList: [],
    totalcount: 0,
    pagenumber: 0,
    amountperpage: 0,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getPostbackStats(data) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: StatsStatisticsGetHrsPostbackStatsRoute.ResponseType = yield WebSocketService
        .sendRequest<StatsStatisticsGetHrsPostbackStatsRoute.ResponseType, StatsStatisticsGetHrsPostbackStatsRoute.RequestType>({
          method: 'stats_getHrsPostbackStats',
          data: {
            timeFrom: data.timefrom,
            timeTo: data.timeto,
            order: data.order,
            orderBy: data.orderby,
            search: data.search,
            amountPerPage: data.amountperpage,
            pageNumber: data.pagenumber,
          },
        });

      this.hrsPostbackStats = {
        dataList: response.data?.map(item => {
          return {
            offerwallid: item.offerwallId,
            bunldeid: item.bunldeId,
            type: item.type,
            succeesspostbackcount: item.succeessPostbackCount,
            blockerspostbackcount: item.blockersPostbackCount,
            successpercent: item.successPercent,
            key: item.key,
            sum: item.sum,
          };
        }),
        totalcount: response.totalCount,
        pagenumber: response.pageNumber,
        amountperpage: response.amountPerPage,
      };

    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getPostbackStatsCsv(data) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: StatsStatisticsGetHrsPostbackStatsRoute.ResponseType = yield WebSocketService
        .sendRequest<StatsStatisticsGetHrsPostbackStatsRoute.ResponseType, StatsStatisticsGetHrsPostbackStatsRoute.RequestType>({
          method: 'stats_getHrsPostbackStats',
          data: {
            timeFrom: data.timefrom,
            timeTo: data.timeto,
            order: data.order,
            orderBy: data.orderby,
            search: data.search,
          } as any,
        });

      const csvData = response.data?.map(item => {
        return {
          offerwallid: item.offerwallId,
          bunldeid: item.bunldeId,
          type: item.type,
          succeesspostbackcount: item.succeessPostbackCount,
          blockerspostbackcount: item.blockersPostbackCount,
          successpercent: item.successPercent,
          key: item.key,
          sum: item.sum,
        };
      });

      const headers: any[] = [];
      Object.keys(csvData[0]).forEach((key: any) => {
        headers.push({
          key,
        });
      });

      generateCSV(
        headers,
        csvData,
        'hrsStats-' + moment().format('YYYY-MM-DD')
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
