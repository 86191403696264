import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getWithdrawalTicketsStyle = makeStyles((theme: Theme) =>
  createStyles({
    topContainer: {
      border:'solid 1px rgba(0,0,0,0.2)',
      borderBottom: 'none',
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      padding: '5px'
    },
    middleContainer: {
      borderLeft:'solid 1px rgba(0,0,0,0.2)',
      borderRight: 'solid 1px rgba(0,0,0,0.2)',
      padding: '5px'
    },
    bottomContainer: {
      border:'solid 1px rgba(0,0,0,0.2)',
      borderTop: 'none',
      borderBottomLeftRadius: '3px',
      borderBottomRightRadius: '3px',
      padding: '5px'
    },
    disablePadding: {
      padding:'0 !important',
    },
    widthHeight100: {
      width: '100%',
      height: '100%',
    }
  }),
);
