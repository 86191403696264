import { createTheme } from '@mui/material/styles';
import { makeAutoObservable } from 'mobx';
import { AppStore } from '../app';

export class ThemeStore {
  public darkMode = localStorage.getItem('theme') === 'dark';

  public theme = createTheme({
    palette: { mode: this.darkMode ? 'dark' : 'light' },
  });

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  switchMode() {
    this.darkMode = !this.darkMode;

    const theme = this.darkMode ? 'dark' : 'light';

    localStorage.setItem('theme', theme);

    this.theme = createTheme({ palette: { mode: theme } });
  }
}
