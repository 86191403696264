import React, {useState} from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  DialogTitle,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {Edit} from '@mui/icons-material';
import { RewardStagePattern, RewardStagePatternUpdateRequest } from '../../../proto/reward_pb';
import { getStageEditingStyles } from './mc-stage-edit.styles';
import { McEditPatternStage } from './mx-stage-pattern-edit';
import AddIcon from '@mui/icons-material/Add';
import { FormsValidator } from '../../../core/validator';

interface OwnProps {
  updatableObject: RewardStagePattern.AsObject;
  updateItemAction: (item: RewardStagePatternUpdateRequest.AsObject) => void;
  isCreate?: boolean;
  disabled?: boolean;
}

type Props = OwnProps;

const offerwalls = [
  {
    value: 'ADJOE',
    label: 'Adjoe',
  },
  {
    value: 'IRON_SOURCE',
    label: 'Iron Source',
  },
];

const validator = new FormsValidator({
  index: (value) => {
    if (value === "") {
      return 'Index can\'t be empty.';
    }
    return '';
  },
  name: (value) => {
    if (value === "") {
      return 'Name can\'t be empty.'
    }
    return '';
  },
  nextstageconditions: (value: string) => {
    if (value === "") {
      return "Next stage conditions can\'t be empty.";
    }
    const regexp = /^(?<multiple_task_lists>(multiple_task_lists|(targetAmount:\d+)))|(?<one_task_list>one_task_list)$/;
    if (!new RegExp(regexp).test(value)) {
      return 'Expect like: \'multiple_task_lists|targetAmount:{number}\' or just \'targetAmount:{number}\'';
    }
    return '';
  }
});

export const McEditStage: React.FC<Props> = ({
  updatableObject,
  updateItemAction,
  isCreate,
  disabled,
}) => {
  const classes = getStageEditingStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [currItem, setCurrItem] = useState<RewardStagePattern.AsObject>(updatableObject);
  const [isError, setIsError] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    updateItemAction(currItem  as RewardStagePatternUpdateRequest.AsObject);
    setOpen(false);
  };

  const handleChange = (data) => {
    if (data.target.type === 'checkbox') {
      setCurrItem({ ...currItem, [data.target.name]: !currItem[data.target.name] })
    } else {
      setCurrItem({ ...currItem, [data.target.name]: data.target.value })
      validator.validateField(data.target.name, data.target.value);
      setIsError(validator.isFormHaveError());
    }
  }

  const setNewPatterns = (data) => {
    setCurrItem({ ...currItem, patternList: data });
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.mainContainer}>
          <DialogTitle textAlign={'center'}>
            {isCreate ? "Creating new: " : "Editing "} {currItem.name}
          </DialogTitle>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.topContainer}>
              <TextField
                className={classes.inputEditing}
                defaultValue={currItem.name}
                label={'Name'}
                name="name"
                value={currItem.name}
                variant='outlined'
                onChange={handleChange}
                error={!!validator.getError("name")}
                helperText={validator.getError("name")}
                disabled
              />
              <TextField
                select
                className={classes.inputEditing}
                defaultValue={currItem.offerwall}
                label={'Offerwall'}
                name="offerwall"
                value={currItem.offerwall}
                variant='outlined'
                onChange={handleChange}
                disabled
              >
              {offerwalls.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            </Box>
            <Box className={classes.topContainer}>
              <TextField
                className={classes.inputEditing}
                defaultValue={currItem.nextstageconditions}
                label={'Next stage condition'}
                name="nextstageconditions"
                value={currItem.nextstageconditions}
                variant='outlined'
                onChange={handleChange}
                error={!!validator.getError("nextstageconditions")}
                helperText={validator.getError("nextstageconditions")}
                disabled
              />
              <TextField
                className={classes.inputEditing}
                defaultValue={currItem.index}
                label={'Index (execution order)'}
                name="index"
                type={'number'}
                value={currItem.index}
                variant='outlined'
                onChange={handleChange}
                error={!!validator.getError("index")}
                helperText={validator.getError("index")}
                disabled
              />
            </Box>
            <McEditPatternStage pattern={currItem.patternList} setNewPatterns={setNewPatterns} />
            <Box className={classes.topContainer}>
              <FormControlLabel
                control={<Checkbox defaultChecked={currItem.randomizereward} />}
                label="is reward randomized?"
                name='randomizereward'
                value={currItem.randomizereward}
                onChange={handleChange}
                disabled
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus disabled={isError}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={() => handleClickOpen()} color={isCreate ? "success" : "primary"} disabled={disabled}>
        {isCreate ? <AddIcon /> : <Edit />}
      </IconButton>
    </Box>
  );
};
