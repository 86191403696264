import React from 'react';
import {Box, IconButton} from '@mui/material';
import {AssignmentTurnedIn} from '@mui/icons-material';
import {FinishEvent} from '../../../proto/leaderboard_pb';

interface OwnProps {
  id: number;
  value: string;
  finishTour: (data: FinishEvent.AsObject) => void;
}

type Props = OwnProps;

export const FinishTournament: React.FC<Props> = ({id, value, finishTour}) => {
  const handleClick = () =>
    finishTour({
      accesstoken: '',
      id,
      enddate: new Date().getTime(),
    });

  return (
    <Box>
      {value === 'current' && (
        <IconButton size="small" onClick={handleClick}>
          <AssignmentTurnedIn style={{fontSize: '1.1rem'}} />
        </IconButton>
      )}
      {value}
    </Box>
  );
};
