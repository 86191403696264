import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

// $success-color: #4caf50;
// $warning-color: #ff9800;
// $error-color: #f44336;
// $trace-color: #2196f3;
// $success-background-color: #66bb6a;
// $error-background-color: #e57373;
// $warning-background-color: #ffb74d;
// $background-color: white;

export const getTestsStyle = makeStyles((theme: Theme) =>
  createStyles({
    log: {
      fontFamily: 'monospace',
      lineHeight: '22px',
      whiteSpace: 'pre-wrap',
      lineBreak: 'anywhere',
    },
    logContainer: {
      background: 'rgba(240, 240, 240)',
      padding: '12px 24px',
      borderRadius: '5px',
    },
    logContainerError: {
      color: '#f44336',
    },
    logContainerWarning: {
      color: '#ff9800',
    },
    logContainerTrace: {
      color: '#2196f3',
    },
    logContainerSuccess: {
      color: '#4caf50',
    },
    testItem: {
      width: '100%',
      padding: '4px 16px',
      marginTop: '8px',
      border: 'none',
      borderRadius: '3px',
      cursor: 'pointer',
      background: '#ffb74d',
    },
    testItemFailed: {
      background: '#e57373',
      color: 'white',
    },
    testItemSuccess: {
      background: '#66bb6a',
      color: 'white',
    },
    mt16: {
      marginTop: '16px',
    },
    mt24: {
      marginTop: '24px',
    },
  }),
);
