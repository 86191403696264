import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  GetBlackWhiteList,
  AddBlackWhiteList,
  UpdateBlackWhiteList,
} from '../../proto/auth_pb';
import { WebSocketService } from '../../services/transport';
import { 
  AuthBlackWhiteListAddRoute,
  AuthBlackWhiteListDeleteRoute,
  AuthBlackWhiteListGetRoute,
  AuthBlackWhiteListUpdateRoute,
} from '@boints/grpc';

export class BlacklistStore {
  public error: RpcError | null = null;

  public blacklist: AuthBlackWhiteListGetRoute.ResponseType  = {
    items: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getBlackList(dto: GetBlackWhiteList.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: AuthBlackWhiteListGetRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthBlackWhiteListGetRoute.ResponseType, AuthBlackWhiteListGetRoute.RequestType>({
          method: 'auth_getBlackWhiteList',
          data: {
            amountPerPage: dto.amountperpage || 10,
            listType: dto.listtype || 'black',
            type: dto.typeList || [],
            pageNumber: dto.pagenumber || 1,
          },
        });

      this.blacklist = response;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *addBlackList(dto: AddBlackWhiteList.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const result: AuthBlackWhiteListAddRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthBlackWhiteListAddRoute.ResponseType, AuthBlackWhiteListAddRoute.RequestType>({
          method: 'auth_addBlackWhiteList',
          data: {
            listType: dto.listtype || 'black',
            type: dto.type || 'id',
            value: dto.value || '',
          },
        });

      this.blacklist.items = [
        ...this.blacklist.items,
        ...result.items,
      ].slice(0, this.blacklist.amountPerPage);

      this.blacklist.totalCount = this.blacklist.totalCount + 1;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateBlackList(dto: UpdateBlackWhiteList.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const result: AuthBlackWhiteListUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthBlackWhiteListUpdateRoute.ResponseType, AuthBlackWhiteListUpdateRoute.RequestType>({
          method: 'auth_updateBlackWhiteList',
          data: {
            id: dto.id || 0,
            value: dto.value || '',
            listType: dto.listtype || 'black',
            type: dto.type,
          },
        });

      this.blacklist.items = this.blacklist.items.map((item) => {
        if (item.id === result.id) {
          return result;
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *deleteBlackList(id: number) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<AuthBlackWhiteListDeleteRoute.ResponseType, AuthBlackWhiteListDeleteRoute.RequestType>({
          method: 'auth_deleteBlackWhiteList',
          data: {
            id,
          },
        });

      this.blacklist.items = this.blacklist.items.filter(
        (item) => item.id !== id,
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
