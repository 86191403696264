import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  IconButton,
  Input,
  InputAdornment,
} from '@mui/material';
import {getCustomTableStyle} from './custom-table.styles';
import {ArrowBack, ArrowUpward, Search} from '@mui/icons-material';
import clsx from 'clsx';

interface Props {
  data: any;
  requestAction: any;
  totalCount?: number;
  paramsForAction?: any;
  paramsForActionName?: string;
  isGoBackShow?: boolean;
  isSearchShow?: boolean;
  customSearchParam?: string;
  searchInputText?: string;
  columns: Column[];
  isDataSliced?: boolean;
  defaultSortKey?: string;
  isSortEnabled?: boolean;
  pageAction?: (resetPagination: () => void) => React.ReactNode;
  infoBlock?: any;
  isPaginationShow?: boolean;
  isRefreshPage?: boolean;
  refreshResetFn?: any;
  onRowClickFn?: (props: any) => void;
  onRowClickComponent?: (props: any) => React.ReactNode;
  onRowClickProps?: any;
  tableInnerProps?: { [key: string]: string };
  defaultSearchValue?: string;
  isClientSideSort?: boolean;
}

export interface Column {
  id: string;
  label: string;
  alignContent?: 'start' | 'center';
  sortKey?: string;
  minWidth?: number;
  maxWidth?: number;
  component?: (props: any) => React.ReactNode;
  format?: (value: any) => string;
  disableSort?: boolean;
  containerClassName?: string;
  className?: string;
  labelComponent?: (props: any) => React.ReactNode;
}

let timeout: any;

export const CustomTable: React.FC<Props> = ({
  data,
  isDataSliced = false,
  columns,
  defaultSortKey,
  isSortEnabled = true,
  requestAction,
  paramsForAction,
  paramsForActionName,
  totalCount,
  isGoBackShow = false,
  isSearchShow = false,
  customSearchParam = '',
  searchInputText,
  isPaginationShow = true,
  isRefreshPage = false,
  refreshResetFn,
  pageAction,
  infoBlock,
  onRowClickFn,
  onRowClickComponent,
  onRowClickProps,
  tableInnerProps,
  defaultSearchValue,
  isClientSideSort,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState(defaultSearchValue);
  const [additionalParams, setAdditionalParams] = useState(
    paramsForAction || {}
  );

  // //TODO: Need to refactor (Maybe go to request generator with all parameters?)
  if (
    paramsForActionName &&
    additionalParams[paramsForActionName] !==
      paramsForAction[paramsForActionName]
  ) {
    setAdditionalParams(paramsForAction);
  }

  const [sortKey, setSortKey] = React.useState(
    isSortEnabled
      ? {orderBy: 'DESC', key: defaultSortKey ? defaultSortKey : 'id'}
      : {orderBy: '', key: ''}
  );
  const classes = getCustomTableStyle();
  const navigate = useNavigate();

  useEffect(() => {
    requestAction({
      ...additionalParams,
      pagenumber: page + 1,
      amountperpage: rowsPerPage,
      search: searchValue,
      orderby: sortKey.orderBy,
      order: sortKey.key,
    });

    //TODO: Try to modificat (look to references)
    if (isRefreshPage && refreshResetFn) {
      refreshResetFn(false);
    }
  }, [
    page,
    rowsPerPage,
    searchValue,
    sortKey,
    paramsForActionName && additionalParams[paramsForActionName],
    isRefreshPage,
  ]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if (!!additionalParams && !!customSearchParam) {
        additionalParams[customSearchParam] = event.target.value.toLowerCase();
      }

      setSearchValue(event.target.value.toLowerCase());
      setPage(0);
    }, 300);
  };

  const handleSetSortKey = (key: string) => {
    setPage(0);
    if (key !== sortKey.key) {
      setSortKey({orderBy: 'DESC', key});
      return;
    }
    setSortKey({orderBy: sortKey.orderBy === 'DESC' ? 'ASC' : 'DESC', key});
  };

  const viewData = isDataSliced
    ? data
    : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const clientSideSort = () => {
    if (isClientSideSort) {
      viewData.sort((a, b) => {
        if (a[sortKey.key] < b[sortKey.key]) {
          return sortKey.orderBy === 'ASC' ? -1 : 1;
        }
        if (a[sortKey.key] > b[sortKey.key]) {
          return sortKey.orderBy === 'ASC' ? 1 : -1;
        }
        return 0;
      });
    }
  }

  return (
    <Paper className={classes.root}>
      <Box className={classes.pageHeader}>
        {isGoBackShow && (
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
        )}
        {isSearchShow && (
          <Box className={classes.search}>
            <Input
              id="input-with-icon-adornment"
              placeholder={
                searchInputText
                  ? searchInputText
                  : 'Type data for search here...'
              }
              defaultValue={defaultSearchValue}
              onChange={handleSearch}
              className={classes.searchInput}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
          </Box>
        )}
        {infoBlock && <Box className={classes.infoBlock}>{infoBlock}</Box>}
        {pageAction && (
          <Box className={classes.pageActionBox}>
            {pageAction(() => setPage(0))}
          </Box>
        )}
        {onRowClickFn &&
          onRowClickComponent &&
          onRowClickComponent({...onRowClickProps})}
      </Box>
      <TableContainer className={classes.container}>
        <Table {...tableInnerProps} stickyHeader aria-label="sticky table"> 
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={`${column.id}${idx}`}
                  align="center"
                  style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}
                >
                  <Box className={classes.tableCell}>
                    {column.labelComponent ? column.labelComponent({}) : ''}
                    <Box>{column.label}</Box>
                    <Box>
                    {column.id !== 'navButtons' &&
                      isSortEnabled && !column.disableSort &&
                      (column.id === sortKey.key ||
                      column?.sortKey === sortKey.key ? (
                        <ArrowUpward
                          className={clsx(
                            classes.tableCellPointer,
                            classes.arrow,
                            sortKey.orderBy === 'ASC'
                              ? classes.icon
                              : classes.arrowDown
                          )}
                          onClick={() => {
                            if (isSortEnabled) {
                              clientSideSort();
                              handleSetSortKey(
                                column.sortKey ? column.sortKey : column.id
                              );
                            }
                          }}
                        />
                      ) : (
                        <ArrowUpward
                            className={clsx(classes.arrow, classes.icon, classes.tableCellPointer)}
                          onClick={() => {
                            if (isClientSideSort) {
                              viewData.sort((a, b) => {
                                if (a[sortKey.key] < b[sortKey.key]) {
                                  return sortKey.orderBy === 'ASC' ? -1 : 1;
                                }
                                if (a[sortKey.key] > b[sortKey.key]) {
                                  return sortKey.orderBy === 'ASC' ? 1 : -1;
                                }
                                return 0;
                              });
                            }
                            if (isSortEnabled) {
                              clientSideSort();
                              handleSetSortKey(
                                column.sortKey ? column.sortKey : column.id
                              );
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {viewData?.map((row: any, idx: number) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={idx}
                  onClick={() => onRowClickFn && onRowClickFn(row)}
                  className={classes.tableItem}
                >
                  {columns.map((column, idx) => {
                    const value = row[column.id];

                    return (
                      <TableCell key={`${column.id}${idx}`} align="center" className={column.containerClassName}>
                        <Box
                          className={clsx(
                            classes.box,
                            column.alignContent &&
                              column.alignContent === 'start'
                              ? classes.contentAlignStart
                              : classes.contentAlignCenter,
                            column.className,
                          )}
                          style={{maxWidth: column.maxWidth, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                        >
                          {column.component
                            ? column.component(row)
                            : column.format
                            ? column.format(value)
                            : value}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isPaginationShow && (
          <TablePagination
          style={{ overflow: 'visible'}}
          className={classes.pagination}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount || viewData?.length || -1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, page) => setPage(page)}
            onRowsPerPageChange={(e) => {
              setPage(0)
              setRowsPerPage(Number(e.target.value))
            }}
          />
      )}
    </Paper>
  );
};
