import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getExportTicketsStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 350,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      width: '100%',
    },
    title: {
      textAlign: 'center',
    },
    textField: {
      display: 'flex',
      width: '100%',
      alignItems: 'baseline',
      marginTop: theme.spacing(1.5),
    },
    errorTitle: {
      textAlign: 'center',
      color: 'red',
    },
  })
);
