import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import { LeaderboardSeasonGetRoute } from '@boints/grpc';
import { WebSocketService } from '../../services/transport';
export class SeasonsStore {
  public error: RpcError | null = null;

  public seasons: LeaderboardSeasonGetRoute.ResponseType = {
    entities: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getSeasons(dto: Partial<LeaderboardSeasonGetRoute.RequestType>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: LeaderboardSeasonGetRoute.ResponseType = yield WebSocketService
        .sendRequest<LeaderboardSeasonGetRoute.ResponseType, LeaderboardSeasonGetRoute.RequestType>({
          method: 'leaderboard_seasonGet',
          data: {
            amountPerPage: dto.amountPerPage || 10,
            pageNumber: dto.pageNumber || 1,
          },
        });

      this.seasons = response;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
