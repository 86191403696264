import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {useStores} from '../../stores/app';
import {Column, CustomTable, TextCell, ArrayCell} from '../../components/table';
import {
  GetNotificationsTempaltesRequest, GetNotificationsTempaltesResponse,
  UpdateNotificationTemplateRequest, UpdateNotificationTemplateResponse,
} from '../../proto/notifications_pb';
import { EditableField } from '../../core/interfaces/modal.interfaces';
import { EditableFieldType } from '../../core/enum/modals.enum';
import { CreateEditModal } from '../../components/modals/create-edit';
import { Box, Button, Checkbox, TextField } from '@mui/material';
import {
  NotificationsAdminGetNotificationsTempaltesRoute,
  NotificationsAdminUpdateNotificationTemplateRoute
} from '@boints/grpc';

export const Notifications = observer(() => {
  const { notificationsTemplatesStore } = useStores();

  const loadNotificationTemplates = () => {
    notificationsTemplatesStore.getNotificationsTempaltes({ accessToken: '' });
  };

  const updateNotificationTemplate = (dto: NotificationsAdminUpdateNotificationTemplateRoute.RequestType) => {
     notificationsTemplatesStore.updateNotificationTemplate(dto);
  };

  const editableNotificationTemplateItems: EditableField[] = [
    {
        label: "Description",
        type: EditableFieldType.string,
        paramName: "description",
        disabled: true,
    },
    {
        label: "Header template",
        type: EditableFieldType.string,
        paramName: "headerTemplate",
        customComponent: (currValue, setValue, currItem: NotificationsAdminGetNotificationsTempaltesRoute.ResponseType['templates']['0']) => {
          const inputRef = React.createRef();

          const insertTextAtCaret = (textToInsert) => {
            const input = inputRef.current as any;
            const { selectionStart, selectionEnd } = input;
        
            const textBeforeCaret = currValue.slice(0, selectionStart);
            const textAfterCaret = currValue.slice(selectionEnd);
        
            const newValue = textBeforeCaret + textToInsert + textAfterCaret;

            setValue(newValue);
          }

          return (
            <Box key="Header Template" marginTop='8px'>
                <Box marginBottom='8px'>
                  Available params:&nbsp; 
                  {currItem.availableParams.map((param) => {
                    return (
                      <Box key={param} marginRight='8px' marginTop='8px' display='inline-block'>
                        <Button
                          variant='contained'
                          onClick={() => insertTextAtCaret(`{${param}}`)}
                        >
                          {param}
                        </Button>
                      </Box>
                    )
                  })}
                </Box>
                <TextField
                    size='small'
                    label='Header Template'
                    fullWidth
                    type='string'
                    value={currValue}
                    onChange={(e: any) => setValue(e.target.value)}
                    inputRef={inputRef}
                />
            </Box>
          )
        }
    },
    {
      label: "Message template",
      type: EditableFieldType.string,
      paramName: "messageTemplate",
      customComponent: (currValue, setValue, currItem: NotificationsAdminGetNotificationsTempaltesRoute.ResponseType['templates']['0']) => {
        const inputRef = React.createRef();

        const insertTextAtCaret = (textToInsert) => {
          const input = inputRef.current as any;
          const { selectionStart, selectionEnd } = input;
      
          const textBeforeCaret = currValue.slice(0, selectionStart);
          const textAfterCaret = currValue.slice(selectionEnd);
      
          const newValue = textBeforeCaret + textToInsert + textAfterCaret;

          setValue(newValue);
        }

        return (
          <Box key="Message Template" marginTop='8px'>
              <Box marginBottom='8px'>
                Available params:&nbsp; 
                {currItem.availableParams.map((param) => {
                  return (
                    <Box key={param} marginRight='8px' marginTop='8px' display='inline-block'>
                      <Button
                        variant='contained'
                        onClick={() => insertTextAtCaret(`{${param}}`)}
                      >
                        {param}
                      </Button>
                    </Box>
                  )
                })}
              </Box>
              <TextField
                  size='small'
                  label='Message Template'
                  fullWidth
                  type='string'
                  value={currValue}
                  onChange={(e: any) => setValue(e.target.value)}
                  inputRef={inputRef}
              />
          </Box>
        )
      }
  },
];

  const columns: Column[] = [
    {
      id: 'description',
      label: 'Description',
      alignContent: 'start',
      minWidth: 100,
    },
    {
      id: 'enabled',
      label: 'Enabled',
      minWidth: 100,
      component: (item: NotificationsAdminGetNotificationsTempaltesRoute.ResponseType['templates']['0']) => (<div>
        <Checkbox
          checked={item.enabled}
          onChange={(e) => {
            notificationsTemplatesStore.toggleNotificationTemplate({ type: item.type, accessToken: '' });
          }}
        />
      </div>),
    },
    {
      id: 'headerTemplate',
      label: 'Header template',
      minWidth: 300,
    },
    {
      id: 'messageTemplate',
      label: 'Message template',
      minWidth: 300,
    },
    {
      id: '',
      label: '',
      minWidth: 20,
      component: (item: GetNotificationsTempaltesResponse.AsObject) => (<div>
          <CreateEditModal
              btnLabel='Notification Template'
              dialogName="Notification Template"
              currItem={item}
              editableFields={editableNotificationTemplateItems}
              acceptAction={updateNotificationTemplate}
          />
      </div>)
  },
  ];

  return (
    <CustomTable
      columns={columns}
      data={notificationsTemplatesStore.templates}
      isSortEnabled={false}
      requestAction={loadNotificationTemplates}
      isPaginationShow={false}
    />
  );
});
