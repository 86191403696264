import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getHrsStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: theme.spacing(2),
    },
    topBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    searchInput: {
      width: '300px',
    },
    body: {
      bottom: theme.spacing(2),
      top: theme.spacing(8),
      left: theme.spacing(32),
      right: theme.spacing(2),
      position: 'absolute',
      widht: '100%',
      overflow: 'auto',
      display: 'flex',
      borderRadius: '10px',
      border: `1px solid ${theme.palette.divider}`,
      'box-shadow': `inset 0px 0px 10px ${theme.palette.divider}`,
    },
    offerwallItem: {
      minWidth: '500px',
      maxWidth: '500px',
      margin: '10px 0px 10px 10px',
      borderRadius: '10px',
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      flexDirection: 'column',
      'box-shadow': ` 0px 0px 10px ${theme.palette.divider}`,
    },
    offerwallItemTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: '1.5em',
      backgroundColor: theme.palette.action.selected,
      borderRadius: '10px 10px 0 0',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    offerwallItemCO: {
      margin: theme.spacing(1),
    },
    gameBody: {
      padding: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    gameImg: {
      float: 'left',
      margin: theme.spacing(0,1,1,0),
      "& img": {
        borderRadius: '10px',
        minWidth: '150px',
        minHeight: '150px',
        width: '150px',
        height: '150px',
      }
    },
    statusBar: { width: '40px', height: '40px', borderRadius: '50%', border: `5px solid ${theme.palette.divider}`},
    editHrsActions: {
      position: 'sticky',
      width: "100%",
      bottom: '0px',
      'box-shadow': `0 -10px 10px -5px ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
      zIndex: 50,
    }
  }),
);
