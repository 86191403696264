import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Tab, Tabs, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { SelectCell, TextCell } from '../../components/table';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';
import { Setting } from '../../proto/settings_pb';
import { useStores } from '../../stores/app';
import { getFeatureToggleStyle } from './feature-toggle.styles';
import { FeatureItemInput, FeatureItemInputType } from './feature-toggle.types';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const FeatureToggle = observer(() => {
  const { settingsStore } = useStores();
  const classes = getFeatureToggleStyle();
  const [isOpen, setIsOpen] = useState(false);
  const [lastUpdatedItem, setLastUpdatedItem] = useState({});
  const [tabNubmer, tabNubmerChange] = useState(0);

  useEffect(() => {
    settingsStore.getSettingsByMicroservices({
      accesstoken: '',
      microservicesList: ['orchestrator', 'balance', 'notifications'],
    });
  }, []);

  const whiteListedSettings: FeatureItemInput[] = [
    {
      key: 'ADJOE_BLOCKER_ENABLE',
      type: FeatureItemInputType.INPUT_CHECKBOX,
      groupName: 'offerwall blocker',
    },
    {
      key: 'OFFERWALL_BLOCKER_NAME',
      type: FeatureItemInputType.INPUT_DROPDOWN,
      dropDownListItems: {
        "tapjoy": 'Tapjoy',
        "fyber": 'Fyber',
        'ironSource': 'IronSource',
        'pollfish': 'Pollfish',
        'max': 'Max',
        'aye': 'Aye',
        'adjoe': 'Adjoe',
        'adgem': 'AdGem',
        'inBrain': 'InBrain',
        'maf': 'Maf',
      },
      groupName: 'offerwall blocker',
    },
    {
      key: 'INSTANT_CASHOUT_ENABLED',
      type: FeatureItemInputType.INPUT_CHECKBOX,
    },

    {
      key: 'DEFAULT_REFERRAL_PROFIT_BOUNDARY_REWARD',
      type: FeatureItemInputType.INPUT_TEXT,
      groupName: 'refferal',
    },
    {
      key: 'DEFAULT_REFERRAL_PROFIT_BOUNDARY',
      type: FeatureItemInputType.INPUT_TEXT,
      groupName: 'refferal',
    },
    {
      key: 'DEFAULT_REFERRAL_PERCENT',
      type: FeatureItemInputType.INPUT_TEXT,
      groupName: 'refferal',
    },

    {
      key: 'BOINTS_CASHOUT_ENABLED',
      type: FeatureItemInputType.INPUT_CHECKBOX,
      groupName: 'cashout',
    },
    {
      key: 'MC_CASHOUT_ENABLED',
      type: FeatureItemInputType.INPUT_CHECKBOX,
      groupName: 'cashout',
    },
    {
      key: 'BOINTS_CASHOUT_MIN_LIMIT',
      type: FeatureItemInputType.INPUT_TEXT,
      groupName: 'cashout',
    },

    {
      key: 'FIREBASE_PUSH_ENABLED',
      type: FeatureItemInputType.INPUT_CHECKBOX,
      groupName: 'notifications',
    },
    {
      key: 'PUSHWOOSH_PUSH_ENABLED',
      type: FeatureItemInputType.INPUT_CHECKBOX,
      groupName: 'notifications',
    },
  ];

  const toggleTabs: string[] = [
    "offerwall blocker",
    "refferal",
    "cashout",
    "notifications",
  ];

  const updateData = (id, newValue, key) => {
    setLastUpdatedItem({ id, value: newValue, key });
    setIsOpen(true);
  };

  const updateNow = (id, newValue, key) => {
    settingsStore.updateSettingsByMicroservice({ id, value: newValue, key } as RequestUpdateSettingsDto.AsObject);
  };

  const sendUpdate = () => {
    settingsStore.updateSettingsByMicroservice(lastUpdatedItem as RequestUpdateSettingsDto.AsObject);
    setLastUpdatedItem({});
  };

  const mutateData = (data: Setting.AsObject[]) => {
    return data.filter(item => whiteListedSettings.findIndex(wl => wl.key === item.key) !== -1);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabNubmer} onChange={(_, i) => tabNubmerChange(i)} aria-label="basic tabs example">
            {toggleTabs.map(item => (<Tab label={item} />))}
          </Tabs>
        </Box>
        {toggleTabs.map((tabName, i) => (
          <CustomTabPanel value={tabNubmer} index={i}>
            {
              mutateData(settingsStore.featureToggleSettings.settingsList).map(item => {
                const wlItem = whiteListedSettings.find(wl => wl.key === item.key);

                return wlItem?.groupName === tabName || !wlItem?.groupName && tabName === 'other' ? (
                  <Card elevation={1} className={classes.listItem}>
                    <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box style={{ width: '600px' }}>
                        <Typography>{item.description}</Typography>
                        <Typography className={classes.description}>{item.key}</Typography>
                      </Box>
                      <Box style={{ width: '300px' }}>
                        {((wlItem?.type === FeatureItemInputType.INPUT_CHECKBOX) && <Checkbox
                          checked={item.value === 'true'}
                          color="primary"
                          onChange={(val) => { updateData(item.id, String(!(item.value === 'true')), item.key); }}
                        />)}
                        {((wlItem?.type === FeatureItemInputType.INPUT_DROPDOWN) && <SelectCell
                          options={wlItem.dropDownListItems || {}}
                          value={item.value}
                          onChange={(val) => { updateNow(item.id, val, item.key); }}
                        />)}
                        {((wlItem?.type === FeatureItemInputType.INPUT_TEXT) && <TextCell
                          value={item.value}
                          onChange={(value) => updateNow(item.id, value, item.key)}
                        />)}
                      </Box>
                    </ListItem>
                  </Card>) : '';
              })
            }
          </CustomTabPanel>
        ))}
      </Box>
      <List>
      </List>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent style={{ minWidth: '500px' }}>
          <p>
                        Set <b>{(lastUpdatedItem as RequestUpdateSettingsDto.AsObject).key}</b> to <b>'{(lastUpdatedItem as RequestUpdateSettingsDto.AsObject).value}'</b> ?
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button onClick={() => {
            sendUpdate();
            setIsOpen(false);
          }}>Yes</Button>
        </DialogActions>
      </Dialog >
    </>
  );
});
