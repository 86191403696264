import { observer } from 'mobx-react';
import { Box, InputLabel, Switch } from '@mui/material';
import { Check, AvTimer } from '@mui/icons-material';
import moment from 'moment';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';

export const RewardsTab = observer(() => {
  const { rewardStore } = useStores();

  const loadRewards = () => {
    rewardStore.getRewards({});
    rewardStore.isSendRewardWorkerEnabled();
  };

  const enableDisableWorker = () => {
    rewardStore.sendRewardWorkerEnbled
      ? rewardStore.disableSendRewardWorker()
      : rewardStore.enableSendRewardWorker();
  };

  const Actions = () => (
    <>
      <InputLabel>
        On/Off Rewards Worker
        <Switch
          color="primary"
          checked={rewardStore.sendRewardWorkerEnbled}
          onChange={enableDisableWorker}
        />
      </InputLabel>
    </>
  );

  const columns: Column[] = [
    { id: 'id', label: 'Id', minWidth: 80 },
    { id: 'offerwallschemaidentifier', label: 'Identifier' },
    {
      id: 'payoutsList',
      label: 'Payout',
      component: ({ payoutsList }) => payoutsList[0],
    },
    {
      id: 'issenttobalances',
      label: 'Is sent to balance',
      component: ({ issenttobalances }) =>
        issenttobalances ? (
          <Check sx={{ color: 'green' }} />
        ) : (
          <AvTimer sx={{ color: 'orange' }} />
        ),
    },
    { id: 'requestid', label: 'Request Id' },
    {
      id: 'createdat',
      label: 'Date',
      component: ({ createdat }) => (
        <Box>{moment(createdat).format('hh:mm DD/MM/YYYY')}</Box>
      ),
    },
  ];

  // Whery strange bug. is I comment string below - select will not work
  const a = rewardStore.sendRewardWorkerEnbled;

  return (
    <CustomTable
      columns={columns}
      pageAction={Actions}
      data={rewardStore.rewards}
      totalCount={rewardStore.rewards.length}
      isSortEnabled={false}
      requestAction={loadRewards}
      isPaginationShow={false}
    />
  );
});
