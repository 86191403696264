import React, {useState} from 'react';
import {
  IconButton,
  Box,
  TextField,
  MenuItem,
} from '@mui/material';
import { RewardStageStep } from '../../../proto/reward_pb';
import { getStageEditingStyles } from './mc-stage-edit.styles';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import EditOffIcon from '@mui/icons-material/EditOff';

interface OwnProps {
  pattern: RewardStageStep.AsObject[];
  setNewPatterns: (data: RewardStageStep.AsObject[]) => void;
}

type Props = OwnProps;

const trigger = [
    {
      value: 'DOWNLOAD_GAME',
      label: 'Download game',
    },
    {
      value: 'POSTBACK',
      label: 'PostBack',
    },
    {
        value: 'BOINTS_REACHED',
        label: 'Boints reached',
      },
  ];


export const McEditPatternStage: React.FC<Props> = ({
    pattern,
  setNewPatterns
}) => {
  const classes = getStageEditingStyles();
  const [currItems, setCurrItems] = useState<RewardStageStep.AsObject[]>(pattern);

  const [newItem, setNewItem] = useState<RewardStageStep.AsObject>({
    value: 0,
    trigger: 'POSTBACK',
    reward: 0.50,
  });

  const [editedItemId, setEditedItemId] = useState<number>(-1);

  const [editedItem, setEditedItem] = useState<RewardStageStep.AsObject>({
    value: 0,
    trigger: 'POSTBACK',
    reward: 0,
  });


  const removeItem = (index: number) => {
    const newItemsList = currItems.filter((a, itemIndex) => index !== itemIndex);
    setCurrItems(newItemsList)
    setNewPatterns(newItemsList)
  }

  const editItem = (index: number) => {
    if (index !== -1) {
      const item = {...currItems[index]};
      item.reward = item.reward / 100;
      setEditedItem(item);
    }
    setEditedItemId(index);
  }

  const confirmEdit = () => {
    if (editedItemId === -1) {
      newItem.reward = newItem.reward * 100;
      const newItemsList = [...currItems, newItem];

      setCurrItems(newItemsList);
      setNewPatterns(newItemsList);

      setNewItem({
        value: 0,
        trigger: 'POSTBACK',
        reward: 0.50,
      })
    } else {
      editedItem.reward = editedItem.reward * 100;
      const newItemsList = currItems.map((item, index) => index === editedItemId ? editedItem : item);
      setCurrItems(newItemsList);
      setNewPatterns(newItemsList);
      editItem(-1);
    }
  }

  const handleChange = (data) => {
    if (editedItemId === -1) {
      setNewItem({ ...newItem, [data.target.name]: data.target.value })
    } else {
      setEditedItem({ ...editedItem, [data.target.name]: data.target.value });
    }
  }

  return (
    <>
        <Box className={classes.patternContainer}>
            {currItems && currItems.length > 0 ? 
            <div>
                {currItems.map((item, index) => 
                <div className={classes.patternItem}>
                    {editedItemId === index ? 
                    <IconButton onClick={() => editItem(-1)} color="primary">
                        <EditOffIcon fontSize='small'/>
                    </IconButton>
                    :
                    <IconButton onClick={() => editItem(index)} color="primary">
                        <EditIcon fontSize='small'/>
                    </IconButton>
                    }
                    <IconButton onClick={() => removeItem(index)} color="warning" className={classes.crossBtn} disabled>
                        <CloseIcon fontSize='small'/>
                    </IconButton>
                    {`${item.value ? `${item.value} ` : ""}${item.trigger} = ${item.reward / 100}$`}
                </div>
                )}
            </div>
            : 'Empty'}
        </Box>
        <Box style={{ display: 'flex' }}>
            <TextField
              className={classes.inputEditing}
              defaultValue={newItem.value}
              value={editedItemId === -1 ? newItem.value : editedItem.value}
              label={'Value'}
              type='number'
              size='small'
              name="value"
              variant='outlined'
              onChange={handleChange}
              disabled
            />
            <TextField
              select
              className={classes.inputEditing}
              defaultValue={newItem.trigger}
              value={editedItemId === -1 ? newItem.trigger : editedItem.trigger}
              label={'Trigger'}
              size='small'
              name="trigger"
              variant='outlined'
              onChange={handleChange}
              disabled
            >
              {trigger.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              inputProps={{ step: ".01" }}
              className={classes.inputEditing}
              defaultValue={newItem.reward}
              value={editedItemId === -1 ? newItem.reward : editedItem.reward}
              label={'Reward'}
              type='number'
              name="reward"
              variant='outlined'
              size='small'
              onChange={handleChange}
              disabled={editedItemId === -1}
            />
            <IconButton onClick={confirmEdit} style={{ width: '50px' }} color='success' disabled={editedItemId === -1}>
                {editedItemId === -1 ? <AddIcon fontSize='small'/> : <CheckIcon/>}                
            </IconButton>
        </Box>
    </>
  );
};
