export const transactionTypeTitles = {
  1: 'AD',
  2: 'TAPJOY',
  3: 'FYBER',
  4: 'IRON SOURCE',
  5: 'POLIFISH VIA SURVEY',
  6: 'POLIFISH VIA OFFERWALL',
  7: 'REVU VIA OFFER',
  8: 'REVU VIA CUSTOMER',
  9: 'MAX',
  10: 'ADJOE',
  11: 'WITHDRAWAL',
  12: 'REDUCE PER REVU VIA OFFER REVERTED',
  13: 'REDUCE PER REVU VIA CUSTOMER REVERTED',
  14: 'WITHDRAWAL BONUS',
  15: 'SPIN THE WHEEL',
  16: 'AYE',
  17: 'ADGEM',
  18: 'REDUCE PER AYE',
  19: 'COMPLETED PER INBRAIN',
  20: 'ADD DISQUALIFIED PER INBRAIN',
  21: 'WITHDRAWAL DECLINED',
  22: 'REFERRAL BONUS',
  23: 'COMPLETE PROFILE',
  24: 'DAILY TOURNAMENT',
  25: 'TEAM TOURNAMENT',
  26: 'ADJOE BONUS',
  27: 'TEST OFFERWALL',
  31: 'ADD BY ADMIN',
  32: 'REDUCE BY ADMIN',
  33: 'DOUBLE BOINTS',
  34: 'PENDING BALANCE',
  35: 'INBRAIN RANK REWARD',
  36: 'OFFERWALL REWARD',
  37: 'BALANCE_TYPE_MOTIVATIONAL_CURRENCY_EARNED',
  38: 'BALANCE_TYPE_HRS_LEVEL_BASED',
  39: 'BALANCE_TYPE_HRS_TIME_BASED',
  40: 'BALANCE_TYPE_HRS_IAP_BASED',
  41: 'BALANCE_TYPE_HRS_VIDEO_BASED',
  42: 'BALANCE_TYPE_ADD_PER_MAF',
  43: 'BALANCE_TYPE_ADD_PER_OFFERTORO',
  44: 'BALANCE_TYPE_HRS_DAILY_REWARD',
  45: 'BALANCE_TYPE_ADD_PER_ADGATE',
  46: 'BALANCE_TYPE_ADD_PER_FARLY',
  47: 'BALANCE_TYPE_ADD_PER_ADBREAK',
  48: 'BALANCE_TYPE_ADD_PER_BITLABS',
  earned: 'EARNED',
  promo: 'PROMO',
  total: 'TOTAL',
};
