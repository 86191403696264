import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import { Box, Checkbox, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDeleteDialog } from '../../components/dialogs';
import { getCustomTableStyleV2 } from './motivation-currency-v2.styles';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';
import { McV2Edit } from '../../components/dialogs/mcv2-stage-edit';
import { MCStage, StageOrder } from '@boints/grpc/lib/proto-interfaces/reward';

export const MotivationalCurrencyV2 = observer(() => {
  const { motivatianlCurrencyV2Store, settingsStore } = useStores();
  const classes = getCustomTableStyleV2();

  const enableSettingsName = "MOTIVATIONAL_CURRENCY_ENABLE";

  const patternRender = (item: StageOrder) => {
    return (
      <div >
        {item?.stages?.map(stage => (
          <div className={classes.patternItem}>
            <b style={{ }}>
              {`Offerwall: ${stage.offerwall} - Total reward: ${stage.groups.reduce((totalSum, group) => totalSum + (group.steps.reduce((sum, item) => sum + item.reward, 0)), 0) / 100}$`}
            </b>
            {stage.groups.map(group => (
            <>{`${group.description} - ${group.steps.reduce((sum, item) => sum + item.reward, 0) / 100}$`}<br/></>
            ))}
          </div>
        ))}
      </div>
    );
  };
  
  const fetchData = () => {
    settingsStore.getSettingsByMicroservices({ accesstoken: '', microservicesList: ["orchestrator", "balance"] });
    motivatianlCurrencyV2Store.getAll();
  };

  const deleteItem = (idToDelete: number) => {
    motivatianlCurrencyV2Store.delete(idToDelete);
  };

  const updateItem = (item: StageOrder) => {
    if (item.stageOrder === -1) {
      item.stageOrder = motivatianlCurrencyV2Store.stagePatterns.stageOrders.reduce((max, item) => item.stageOrder > max ? item.stageOrder : max, 0) + 1
    }
    motivatianlCurrencyV2Store.update(item);
  };


  const mcActionButtons = (props) => {
    console.log(props);
    return (
      <Box style={{ display: 'flex' }}>
        <McV2Edit
          updatableObject={props}
          updateItemAction={updateItem}/>
          <CustomDeleteDialog
            labelForDelete={`Are you sure you want delete Motivational Currency Stage `}
            objectId={props.id}
            deleteItemAction={deleteItem}
          />
      </Box>
    );
  };

  const columns: Column[] = [
    {
      id: 'stageOrder',
      label: 'Iteration ID',
      format: (item) => "#" + (item + 1),
    },
    {
      id: 'stageVersion',
      label: 'Iteration Version',
      format: (item) => "v" + item,
    },
    {
      id: 'startingBonus',
      label: 'Starting Bonus',
      component: (item) => `${item.startingBonus / 100}$`
    },
    {
      id: 'stages',
      label: 'Iteration Preview',
      component: patternRender,
    },
    {
      id: 'actionButton',
      label: '',
      labelComponent: () => (<McV2Edit
        isCreate
        updatableObject={{
          id: 0,
          active: true,
          stageOrder: -1,
          stageVersion: 0,
          startingBonus: 0,
          bointsCashoutAllowed: true,
          stages: [],
        }}
        updateItemAction={updateItem}/>),
      component: mcActionButtons,
    }
  ];

  // console.log(motivatianlCurrencyV2Store.stagePatterns.stageOrders);

  return (
    <>
      <Box style={{ margin: '5px' }}>
        MC Enable:
        <Checkbox style={{ margin: '0px' }}
          checked={settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName)?.value === 'true'}
          color="primary"
          onChange={(val) => {
            const sett = settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName);
            console.log(settingsStore.featureToggleSettings.settingsList);
            if (sett) settingsStore.updateSettingsByMicroservice({ id: sett.id, value: String(!(sett.value === 'true')), key: sett.key } as RequestUpdateSettingsDto.AsObject);
          }}
        />
      </Box>
      <CustomTable
        data={motivatianlCurrencyV2Store.stagePatterns.stageOrders}
        requestAction={fetchData}
        columns={columns}
        isPaginationShow={false}
        isSortEnabled={false}
      />
    </>

  );
});
