import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: 'flex',
      maxWidth: 400,
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(2, 0),
      textAlign: 'center',
    },
    form: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(2),
      '& input': {
        margin: theme.spacing(1),
      },
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: ' center',
      marginTop: theme.spacing(2),
    },
    passwordWrapper: {
      display: 'flex',
    },
  }),
);
