import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import {green} from '@mui/material/colors';

export const getDeleteDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    unbanIcon: {
      color: green[600],
    },
  })
);
