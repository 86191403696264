import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import { GetSettingsResponse, UpdateSettingsRequest } from '../../proto/userQuality_pb';
import {
  UserQualityQualityGetSettingsRoute,
  UserQualityQualityUpdateSettingsRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';
export class UserQualityStore {
  public error: RpcError | null = null;

  public settings: GetSettingsResponse.AsObject = {
    settingsList: [],
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getSettings() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { settings }: UserQualityQualityGetSettingsRoute.ResponseType = yield WebSocketService
        .sendRequest<UserQualityQualityGetSettingsRoute.ResponseType, UserQualityQualityGetSettingsRoute.RequestType>({
          method: 'userQuality_getSettings',
          data: {},
        });

      this.settings.settingsList = settings.map((v) => {
        const { disabled, type, fingerprintInfo, accountDetails, appIntegrity, deviceIntegrity, isFingerprintEnabled, isGooglePlayIntegrityEnabled } = v;
        return {
          type,
          disabled,
          fingerprintinfo: {
            vpn: fingerprintInfo?.vpn || false,
            fraudscore: fingerprintInfo?.fraudScore || 101,
            emulator: fingerprintInfo?.emulator || false,
            countriesList: fingerprintInfo?.countries || [],
          },
          appintegrityList: appIntegrity,
          accountdetailsList: accountDetails,
          deviceintegrityList: deviceIntegrity,
          isfingerprintenabled: isFingerprintEnabled,
          isgoogleplayintegrityenabled: isGooglePlayIntegrityEnabled,
        };
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateSettings(dto: UpdateSettingsRequest.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<UserQualityQualityUpdateSettingsRoute.ResponseType, UserQualityQualityUpdateSettingsRoute.RequestType>({
          method: 'userQuality_updateSettings',
          data: {
            settings: dto.settingsList.map((v) => {
              return {
                type: v.type,
                disabled: v.disabled,
                appIntegrity: v.appintegrityList,
                accountDetails: v.accountdetailsList,
                deviceIntegrity: v.deviceintegrityList,
                fingerprintInfo: {
                  vpn: v.fingerprintinfo?.vpn || false,
                  emulator: v.fingerprintinfo?.emulator || false,
                  fraudScore: v.fingerprintinfo?.fraudscore || 101,
                  countries: v.fingerprintinfo?.countriesList || [],
                },
                isFingerprintEnabled: v.isfingerprintenabled,
                isGooglePlayIntegrityEnabled: v.isgoogleplayintegrityenabled,
                forcedActivation: false,
              };
            }),
          },
        });

      const settingsListMap = new Map(dto.settingsList.map((v) => [v.type, v]));

      this.settings.settingsList = this.settings.settingsList.map((v) => (
        settingsListMap.get(v.type) || v
      ));
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
