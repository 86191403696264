import React, {useEffect, useState} from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  DialogTitle,
  TextField,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import { Delete, Edit, ExpandMore, Launch } from '@mui/icons-material';
import { getEditDailyCoinsGoalStyles } from './edit-daily-coins-goal.styles';
import AddIcon from '@mui/icons-material/Add';
import { RewardDailyCashGoalFindAllResponse, RewardDailyCashGoalFindAllRoute } from '@boints/grpc';

const offerwalls = [
  {
    value: 'ADJOE',
    label: 'Adjoe',
  },
  {
    value: 'TAPJOY',
    label: 'Tapjoy',
  },
  {
    value: 'HRS',
    label: 'HRS',
  },
  {
    value: 'IRON_SOURCE',
    label: 'Iron Source',
  },
  {
    value: 'AD',
    label: 'AD',
  },
  {
    value: 'FYBER',
    label: 'Fyber',
  },
  {
    value: 'POLLFISH',
    label: 'Pollfish',
  },
  {
    value: 'REVU',
    label: 'RevU',
  },
  {
    value: 'MAX',
    label: 'MAX',
  },
  {
    value: 'AYE',
    label: 'AYE',
  },
  {
    value: 'ADGEM',
    label: 'AdGem',
  },
  {
    value: 'MAF',
    label: 'MAF',
  },
  {
    value: 'OFFERTORO',
    label: 'OfferToro',
  },
  {
    value: 'ADGATE',
    label: 'AdGate',
  },
  {
    value: 'FARLY',
    label: 'Farly',
  },
  {
    value: 'ADBREAK',
    label: 'AdBreak',
  },
  {
    value: 'BITLABS',
    label: 'BitLabs',
  },
];

const taskType = [
  {
    value: 'coins_earned',
    label: 'Coins Based: ',
  },
  {
    value: 'postbacks_count',
    label: 'Postbacks Based:',
  },
  {
    value: 'unique_installs_count',
    label: 'Unique Installs Based:',
  },
];

const taskTypeDescriptions = {
  coins_earned: 'Coins need to earn',
  postbacks_count: 'Postbacks to receive',
  unique_installs_count: 'Unique Installs to receive',
}

const valueType = [
  {
    value: 'summary',
    label: 'No Random',
  },
  {
    value: 'random',
    label: 'Random 0.8-1.2',
  },
];

interface OwnProps {
  updatableObject: RewardDailyCashGoalFindAllResponse['templates'][number];
  updateItemAction?: (item: RewardDailyCashGoalFindAllResponse['templates'][number]) => void;
  isCreate?: boolean;
  disabled?: boolean;
  btnText?: any;
  fetchEach?: () => void;
}

type Props = OwnProps;

export const EditDailyCoinsGoal: React.FC<Props> = ({
  updatableObject,
  updateItemAction,
  isCreate,
  disabled,
  btnText,
  fetchEach
}) => {
  const classes = getEditDailyCoinsGoalStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<string[]>([]);
  const [currItem, setCurrItem] = useState<RewardDailyCashGoalFindAllResponse['templates'][number]>(updatableObject);

  const handleClickOpen = () => {
    if (fetchEach) {
      fetchEach();
    }
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    const errors = validation(currItem);
    if (errors.length > 0) {
      setShowErrors(errors);
      return;
    }
    if (updateItemAction) {
      updateItemAction(currItem);
    }
    setOpen(false);
  };

  const getRewardsSum = (): number => {
    let sum = 0;
    if (!currItem) return 0;
    for (let i = 0; i < currItem.levels.length; i++) {
      for (let j = 0; j < currItem.levels[i].groups.length; j++) {
        for (let h = 0; h < currItem.levels[i].groups[j].tasks.length; h++) {
          sum += currItem.levels[i].groups[j].tasks[h].rewardAmount;
        }
      }
    }
    return sum;
  };

  const validation = (item: RewardDailyCashGoalFindAllResponse['templates'][number]): string[] => {
    const errors: string[] = [];
    if (item.levels.length === 0) {
      errors.push(`[LEVEL]: You need at least one Level.`);
    }
    for (let i = 0; i < item.levels.length; i++) {
      if (item.levels[i].groups.length === 0) {
        errors.push(`[LEVEL]: You need at least one Group in Level #${i + 1}.`);
      }
      for (let j = 0; j < item.levels[i].groups.length; j++) {
        if (item.levels[i].groups[j].tasks.length === 0) {
          errors.push(`[TASK]: You need at least one Task in Group #${j + 1}.`);
        }
        for (let h = 0; h < item.levels[i].groups[j].tasks.length; h++) {
          if (item.levels[i].groups[j].tasks[h].value <= 0) {
            errors.push(`[Level ${i + 1} - Group ${j + 1} - Task ${h}]: value can't be less than 0.`);
          }
          if (item.levels[i].groups[j].tasks[h].rewardAmount <= 0) {
            errors.push(`[Level ${i + 1} - Group ${j + 1} - Task ${h}]: Reward can't be less than 0.`);
          }
          if (item.levels[i].groups[j].tasks[h].rewardAmount % 1 !== 0) {
            errors.push(`[Level ${i + 1} - Group ${j + 1} - Task ${h}]: The reward is an irrational number. Please change the task reward setup so the postback reward can be calculated properly.`);
          }
        }
      }
    }
    return errors;
  };

  const LevelsRender = (levelsList: RewardDailyCashGoalFindAllRoute.ResponseType['templates'][number]['levels']) => {
    return levelsList.map((level, levelIndex) => (
    <Accordion key={(level as any).id} style={{ background: 'unset'}}>
      <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Typography whiteSpace={'nowrap'}><b>{`Level: ${levelIndex + 1}`}</b></Typography>
          <TextField
                select
                disabled={!updateItemAction}
                className={classes.inputEditing}
                defaultValue={level.offerwall}
                style={{ minWidth: '150px'}}
                name="offerwall"
                variant="standard"
                size='small'
                onChange={(e) => {
                  currItem.levels[levelIndex].offerwall = e.target.value;
                  setCurrItem({...currItem});
                }}
                onClick={(e) => e.stopPropagation()}
              >
              {offerwalls.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={!updateItemAction}
              select
              defaultValue={level.tasksType}
              className={classes.inputEditing}
              style={{ marginLeft: '5px', marginRight: '5px' }}
              name="valueType"
              value={level.tasksType}
              variant="standard"
              size='small'
              onChange={(e) => {
                currItem.levels[levelIndex].tasksType = e.target.value;
                setCurrItem({...currItem});
              }}
              onClick={(e) => e.stopPropagation()}
            >
            {taskType.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
          <IconButton style={{display: updateItemAction ? "block" : "none"}} onClick={(e) => {
            e.stopPropagation();
            currItem.levels = currItem.levels.filter((_, index) => index !== levelIndex);
            setCurrItem({...currItem});
            }}>
              <Delete color='error' />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box >
          {GroupRender(level.groups, levelIndex)}
          <Button style={{display: updateItemAction ? "block" : "none"}} onClick={() => {
            currItem.levels[levelIndex].groups.push({
              id: Math.random(),
              description: '',
              tasks: [],
            } as any);
            setCurrItem({...currItem});
          }}>Add Group</Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  ))}

  const GroupRender = (
    groupList: RewardDailyCashGoalFindAllRoute.ResponseType['templates'][number]['levels'][number]['groups'],
    levelIndex: number
  ) => {
    return groupList.map((group, groupIndex) => (
        <Accordion key={(group as any).id}>
          <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Typography whiteSpace={'nowrap'}><b>Group: {groupIndex + 1}</b></Typography>
              <TextField
                disabled={!updateItemAction}
                className={classes.inputEditing}
                defaultValue={group.description}
                name="name"
                size='small'
                variant='standard'
                style={{ minWidth: '450px'}}
                onBlur={(e) => {
                    currItem.levels[levelIndex].groups[groupIndex].description = e.target.value;
                    setCurrItem({...currItem});
                  }}
                onClick={(e) => e.stopPropagation()}
              />
              <IconButton style={{display: updateItemAction ? "block" : "none"}} onClick={(e) => {
                e.stopPropagation();
                currItem.levels[levelIndex].groups = currItem.levels[levelIndex].groups.filter((_, index) => index !== groupIndex);
                setCurrItem({...currItem});
                }}>
                    <Delete color='error' />
              </IconButton>
              </Box>
            </AccordionSummary>
          <AccordionDetails>
            {/* <Box>
              {"Random: "}
              <Checkbox
              style={{ padding: '0px' }}
                checked={group.israndom}
                // onChange={handleChange}
                name="israndom"
                color="primary"/>
            </Box> */}
            {TaskRender(group.tasks, levelIndex, groupIndex)}
            <Button style={{display: updateItemAction ? "block" : "none"}} onClick={() => {
            currItem.levels[levelIndex].groups[groupIndex].tasks.push({
              id: Math.random(),
              value: 0,
              rewardAmount: 0,
            } as any);
            setCurrItem({...currItem});
          }}>Add Task</Button>
          </AccordionDetails>
        </Accordion>
    ))
  }

  const TaskRender = (
    taskList: RewardDailyCashGoalFindAllRoute.ResponseType['templates'][number]['levels'][number]['groups'][number]['tasks'],
    levelIndex: number,
    groupIndex: number,
  ) => {
    return taskList.map((task, taskIndex) => (
      <Box key={(task as any).id} style={{ marginLeft: '15px', paddingLeft: '15px', marginBottom: '5px', display: 'flex' }}>
        <Typography style={{ marginRight: '10px'}}><b>Task {taskIndex + 1}: </b></Typography>
        <Typography>{`${taskTypeDescriptions[currItem.levels[levelIndex].tasksType]}: `}</Typography>
        <TextField
          disabled={!updateItemAction}
          defaultValue={task.value}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          name="value"
          size='small'
          variant='standard'
          onBlur={(e) => {
            currItem.levels[levelIndex].groups[groupIndex].tasks[taskIndex].value = +e.target.value;
            setCurrItem({...currItem});
          }}
          onClick={(e) => e.stopPropagation()}
        />
        <Typography>{". Reward: "}</Typography>
        <TextField
          disabled={!updateItemAction}
          defaultValue={task.rewardAmount}
          style={{ marginLeft: '5px', marginRight: '5px' }}
          name="reward"
          size='small'
          variant='standard'
          onBlur={(e) => {
            currItem.levels[levelIndex].groups[groupIndex].tasks[taskIndex].rewardAmount = +e.target.value;
            setCurrItem({...currItem});
          }}
          onClick={(e) => e.stopPropagation()}
        />
        Coins
        <IconButton style={{ display: updateItemAction ?  "block" : "none"}} onClick={(e) => {
          e.stopPropagation();
          currItem.levels[levelIndex].groups[groupIndex].tasks = currItem.levels[levelIndex].groups[groupIndex].tasks.filter((_, index) => index !== taskIndex);
          setCurrItem({...currItem});
        }}>
        <Delete color='error' />
        </IconButton>
      </Box>
    ))
  }

  useEffect(() => {
    setCurrItem(updatableObject);
  }, [updatableObject]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullScreen
      >
        {currItem ? 
          <DialogContent className={classes.mainContainer}>
            <DialogTitle textAlign={'center'}>
              {isCreate ? "Creating new: " : updateItemAction ? "Editing: " : `Preview: Iteration: ${currItem.iterationIndex + 1}, Version: ${currItem.version}`}
            </DialogTitle>
            <DialogContentText id="alert-dialog-description">
              <Box style={{ marginBottom: '5px'}}>
                {LevelsRender(currItem.levels)}
                <Button style={{display: updateItemAction ? "block" : "none"}} onClick={() => {
                  currItem.levels.push({
                    id: Math.random(),
                    offerwall: 'ADJOE',
                    groups: [],
                  } as any);
                  setCurrItem({...currItem});
                }}>Add Level</Button>
              </Box>
            </DialogContentText>
          </DialogContent>
        : <DialogContent style= {{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress/>
          </DialogContent>}
        <DialogActions style={{ boxShadow: '-4px 0 6px black', zIndex: 50 }}>
          <Typography style={{ width: '100%' }}>Rewards total: ${getRewardsSum()}</Typography>
          <Button onClick={handleCloseModal} color="secondary" style={{ display: updateItemAction ?  "block" : "none"}}>
            Discard
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            {updateItemAction ? "Save" : "Close"}
          </Button>
        </DialogActions>
      </Dialog>
      {btnText ? <Button
        onClick={() => handleClickOpen()}
        style={{textTransform: 'none'}}
        variant='text'
        color='inherit'>
        {btnText}
        <Launch style={{ fontSize: '0.9rem' }} />
      </Button> : 
      <IconButton onClick={() => handleClickOpen()} color={isCreate ? "success" : "primary"} disabled={disabled}>
        {isCreate ? <AddIcon /> : <Edit />}
      </IconButton>
      }
          <Box>
      <Dialog
        open={showErrors.length > 0}
        onClose={() => setShowErrors([])}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Errors:</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showErrors.map((error) => (<Box className={classes.errorLabel}>{error}</Box>))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowErrors([])} color="primary" autoFocus>
            Keep Editing
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </Box>
  );
};
