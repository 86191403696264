import { Box, Button, Input, InputAdornment, Stack, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import { getHrsStyle } from './hrs.styles';
import { Search, AppBlocking, AppShortcut, AppSettingsAlt, GppMaybe, GppBad, GppGood, Bookmark } from '@mui/icons-material';
import { useStores } from '../../stores/app';
import { useEffect, useState } from 'react';
import { Game, Offerwall } from '@boints/grpc/lib/proto-interfaces/offerwall';
import { NavLink } from 'react-router-dom';
import { Hrs } from '../hrs/hrs.page';
import { EditHrs } from './modal-edit-hrs';
import { EditableField } from '../../core/interfaces/modal.interfaces';
import { EditableFieldType } from '../../core/enum/modals.enum';
import { OfferwallAdminAddGameRoute } from '@boints/grpc';
import { CreateEditModal } from '../../components/modals/create-edit';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BundleChangeType } from '@boints/types';

const statusToIcon = {
  "true": {
    "true": <AppShortcut fontSize='large' style={{ color: 'green' }} />,
    "false": <AppSettingsAlt fontSize='large' style={{ color: 'orange' }} />
  },
  "false": {
    "true": <AppSettingsAlt fontSize='large' style={{ color: 'orange' }} />,
    "false": <AppBlocking fontSize='large' style={{ color: 'red' }} />
  }
}

const changeStatusToIcon = {
  [BundleChangeType.ADMITTED]: <GppGood fontSize='large' style={{ color: 'green' }} />,
  [BundleChangeType.MAJOR_CHANGED]: <GppBad fontSize='large' style={{ color: 'red' }} />,
  [BundleChangeType.MINOR_CHANGED]: <GppMaybe fontSize='large' style={{ color: 'orange' }} />,
}

const changeStatusToText = {
  [BundleChangeType.ADMITTED]: 'OK',
  [BundleChangeType.MAJOR_CHANGED]: 'Major changed',
  [BundleChangeType.MINOR_CHANGED]: 'Minor changed',
}

export const Hrsv2 = observer(() => {
  const { hrsStore, notificationStore } = useStores();
  const [filteredData, setFilteredData ] = useState<Offerwall[]>([]);
  const classes = getHrsStyle();

  let timer: NodeJS.Timeout;

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;
  if (value === ' ') { setFilteredData([]) }
  setFilteredData(hrsStore.allBundles.map(bundle => {
    return {
      ...bundle,
      games: bundle.games.filter(game => game.name.toLowerCase().includes(value.toLowerCase()))
    }
  }));
  // Clear the previous timer
  clearTimeout(timer);

  // Start a new timer
  timer = setTimeout(() => {
    setFilteredData(hrsStore.allBundles.map(bundle => {
      return {
        ...bundle,
        games: bundle.games.filter(game => game.name.toLowerCase().includes(value.toLowerCase()))
      }
    }));
  }, 500); // 500ms delay
};

  const editabledOfferwallItems: EditableField[] = [
    {
      label: "Offerwall Id",
      type: EditableFieldType.string,
      paramName: "offerwallId",
    },
  ];
  
  const editabledBundleidItems: EditableField[] = [
    {
      label: "Id",
      type: EditableFieldType.number,
      paramName: "id",
      disabled: true,
    },
    {
      label: "Bundle Name: ",
      type: EditableFieldType.string,
      paramName: "name",
    },
    {
      label: "Bundle Id: ",
      type: EditableFieldType.string,
      paramName: "bundleId",
    },
    {
      label: "Deep link template (http://example?clickId={clickId}): ",
      type: EditableFieldType.string,
      paramName: "deepLinkTemplate",
    },
    {
      label: "Description",
      type: EditableFieldType.string,
      paramName: "description",
    },
    {
      label: "Image",
      type: EditableFieldType.file,
      paramName: "picture",
    },
    ];

  useEffect(() => {
    hrsStore.getBundles();
    hrsStore.getOfferwallsStatus();
  }, []);



const addNewBandeId = (item: OfferwallAdminAddGameRoute.RequestType) => {
  const offerId = hrsStore.allBundles.find(offer => offer.offerwallId === item.offerwallId.toString())?.id;
  if (!offerId) {
    notificationStore.enqueueSnackBar({
      message: `This offerwallid not found: ${item.offerwallId}`,
      variant: 'error',
    });
    return;
  }
  hrsStore.addBundle({ ...item, offerwallId: offerId });
  hrsStore.getBundles();
};

const addNewOfferwall = (item: any) => {
  hrsStore.addOfferwall({ accessToken: '', offerwallId: item.offerwallId });
  hrsStore.getBundles();
};

  return (
    <Box className={classes.container}>
      <Box className={classes.topBar}>
        <Box>
          <Input
              placeholder={'Type game name for search here...'}
              onChange={handleInputChange}
              className={classes.searchInput}
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
            />
        </Box>
        <Box>
          <CreateEditModal
            icon=' '
            btnColor='primary'
            btnLabel='Create Offerwall'
            dialogName="HRS Offerwall"
            editableFields={editabledOfferwallItems}
            acceptAction={addNewOfferwall}
          />
        </Box>
      </Box>
      <Box className={classes.body} >
        {(filteredData.length > 0 ? filteredData : hrsStore.allBundles).map((offerwall: Offerwall) => (
        <Box className={classes.offerwallItem} key={offerwall.offerwallId}>
          <Box className={classes.offerwallItemTitle}>{offerwall.offerwallId}</Box>
          {/* <Button className={classes.offerwallItemCO} fullWidth>Create Game</Button> */}
          <CreateEditModal
            fullWidth
            icon=' '
            style='text'
            btnColor='info'
            btnLabel='Create Game'
            dialogName="HRS Bundle"
            btnStyle={{ backgroundColor: '#b3e5fc' }}
            editableFields={editabledBundleidItems}
            acceptAction={(data) => {
              data.offerwallId = offerwall.offerwallId;
              addNewBandeId(data)
            }}
          />
          <Stack style={{marginBottom: "5px", overflow: 'auto'}}>
            {offerwall.games.map((game: Game) => (
              <Box className={classes.gameBody} key={game.id}>
                <Box style={{ minHeight: '158px' }}>
                  <Box className={classes.gameImg}>
                    <LazyLoadImage src={game.picture} alt='game' onError={(e) => {
                      e.currentTarget.src = game.picture;
                    }}/>
                  </Box>
                  <Box style={{ float: 'right', display: 'flex'}}>
                    <Tooltip title={<Box><p style={{margin: 0}}>{`${game.enabledForServer ? "Enable" : "Disable"} for server.`}</p><p style={{margin: 0}}>{`${game.enabledForApp ? "Enable" : "Disable"} for app.`}</p></Box>} placement='top'>
                      { statusToIcon[String(game.enabledForApp)][String(game.enabledForServer)] }
                    </Tooltip>
                    <Tooltip title={changeStatusToText[game.changeStatus]} placement='top'>
                      { changeStatusToIcon[game.changeStatus] }
                    </Tooltip>
                    {
                      game.favorite
                      ?
                      <Tooltip title='Bookmark' placement='top'>
                        <Bookmark fontSize='large' color='primary'/>
                      </Tooltip>
                      : null
                    }
                  </Box>
                  <b>{game.name}</b>
                  <p style={{ textAlign: "left"}}>{game.description}</p>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <NavLink style={{ width: '100%'}} to={`/dashboard/hrs-stats?search=${game.bundleId}`}>
                    <Button variant='outlined' fullWidth style={{ marginRight: '5px' }}>Stats</Button>
                  </NavLink>
                  <EditHrs onClose={() => {hrsStore.getBundles()}}>
                    <Hrs defaultBundleId={game.bundleId} defaultCampaignId={game.campaignId} defaultOfferwallId={offerwall.offerwallId}/>
                  </EditHrs>
                </Box>
            </Box>
            ))}
          </Stack>
        </Box>
        ))}
      </Box>
    </Box>
  );
});
