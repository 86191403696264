import { Semaphore } from './Semaphore';

/** IT MAKE FUNCTION ASYNC !!!!!!!!!!!!!!!!!!! */
export function semaphore() {
  const semaphore = new Semaphore();

  return (target: unknown, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) => {
    const method = descriptor.value;

    descriptor.value = async function (...args: unknown[]) {
      await semaphore.enter();
      try {
        // @ts-ignore
        const response = await method.call(this, ...args);
        semaphore.leave();
        return response;
      } catch (e) {
        semaphore.leave();
        throw e;
      }
    };
  };
}
/** IT MAKE FUNCTION ASYNC !!!!!!!!!!!!!!!!!!! */
export function semaphoreByKey({ keyPath }: { keyPath: string }) {
  const queues = new Map();

  return (target: unknown, propertyName: string, descriptor: TypedPropertyDescriptor<Function>) => {
    const method = descriptor.value;

    descriptor.value = async function (...args: unknown[]) {
      const userIdPaths = keyPath.split('.');
      // @ts-ignore
      const userId = userIdPaths.reduce((prev, path) => prev[path], args);

      let semaphore = queues.get(userId);
      if (!semaphore) {
        semaphore = new Semaphore();
        queues.set(userId, semaphore);
      }

      await semaphore.enter();
      try {
        // @ts-ignore
        const response = await method.call(this, ...args);
        semaphore.leave();
        return response;
      } catch (e) {
        semaphore.leave();
        throw e;
      }
    };
  };
}