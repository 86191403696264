import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getCreateSettingStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 350,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      width: '100%',
    },
    title: {
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    textField: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    tooltip: {
      fontSize: 10,
    },
    button: {
      margin: theme.spacing(0, 1),
    },
  }),
);
