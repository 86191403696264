import React, {useState} from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
} from '@mui/material';
import {getDeleteDialogStyles} from './delete.styles';
import {Delete} from '@mui/icons-material';

interface OwnProps {
  labelForDelete: string;
  objectId: number | string | undefined;
  deleteItemAction?: any;
  disabled?: boolean;
  textButton?: string;
}

type Props = OwnProps;
export const CustomDeleteDialog: React.FC<Props> = ({
  labelForDelete,
  objectId,
  deleteItemAction,
  disabled,
  textButton,
}) => {
  const classes = getDeleteDialogStyles();
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpenDelete(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDelete(false);
  };

  const handleAgree = () => {
    deleteItemAction(objectId);
    setOpenDelete(false);
  };

  return (
    <Box>
      <Dialog
        open={openDelete}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {labelForDelete + objectId + '?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="error">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      { textButton && textButton?.length > 0 ? 
          <Button onClick={() => handleClickOpen()} disabled={disabled} color='error' variant='outlined'>
            {textButton}
          </Button>
        :
          <IconButton onClick={() => handleClickOpen()} disabled={disabled} color='error'>
            <Delete />
          </IconButton>
      }
    </Box>
  );
};
