import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import { Box, Button, Checkbox, Dialog, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDeleteDialog, CustomDialog } from '../../components/dialogs';
import { getCustomTableStyleV2 } from './mc-users-stats.styles';
import { RewardStagePatternUpdateRequest, StageOrder } from '../../proto/reward_pb';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';
import { McV2Edit } from '../../components/dialogs/mcv2-stage-edit';
import { McUserStat } from '@boints/grpc/lib/proto-interfaces/reward';
import { Launch } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { ExportMcV2UsersProgress } from '../../components/modals';

interface Params {
  userId: number;
}

export const McSingleUsersStats = observer<React.FC<Params>>(({userId}: Params) => {
  const { motivatianlCurrencyV2Store } = useStores();
  const [ isOpen, setIsOpen ] = useState<boolean>(false);
  const classes = getCustomTableStyleV2();

  const onCLose = () => {
    setIsOpen(false);
  }

  const fetchEach = (order: number, version: number) => {
    motivatianlCurrencyV2Store.getOneByOrderAndVersion(order, version);
  }

  const columns: Column[] = [
    {
      id: 'userId',
      label: 'User id',
    },
    {
      id: 'stageOrder',
      label: 'Iteration ID',
      format: (item) => "#" + (item + 1),
    },
    {
      id: 'stageVersion',
      label: 'Iteration Version',
      component: (item) => (
        <Box>
          <McV2Edit
            updatableObject={motivatianlCurrencyV2Store.stageOrderToView}
            btnText={`v${item.stageVersion}`}
            fetchEach={() => fetchEach(item.stageOrder, item.stageVersion)}
            />
      </Box>
      ),
    },
    {
      id: 'currStage',
      label: 'Stage',
      format: (item) => (item + 1),
    },
    {
      id: 'currGroup',
      label: 'Group',
      format: (item) => (item + 1),
    },
    {
      id: 'currStep',
      label: 'Current Step Progress',
      format: (item) => (item + 1),
    },
    {
      id: 'currReward',
      label: 'Current Step Progress',
    },
    {
      id: 'totalEarned',
      label: 'Iteration Earned',
      format: (item) => (item / 100) + " $",
    }
  ];

  const fetchData = () => {
    motivatianlCurrencyV2Store.getUserStats(userId);
  }

  return (
    <>
      <Dialog open={isOpen} onClose={() => onCLose()} scroll='paper' fullWidth maxWidth={'xl'}>
        <CustomTable
          data={motivatianlCurrencyV2Store.userStats || []}
          requestAction={fetchData}
          columns={columns}
        />
      </Dialog>
      <Button onClick={() => setIsOpen(true)}>Full history</Button>
    </>
  );
});
