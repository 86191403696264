import React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { getStyles } from './login.styles';
import { useStores } from '../../stores/app';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export const Login = observer(() => {
  const { authStore } = useStores();
  const classes = getStyles();

  return (
    <Paper elevation={3} className={classes.formWrapper}>
      <Typography variant="h6">Enter your username and password</Typography>
      <Typography><b>Username:</b> admin, <b>password:</b> same as device ID</Typography>
      <Box
        component="form"
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          authStore.onLoginSubmit();
        }}
      >
        <TextField
          type="text"
          name="username"
          variant="standard"
          placeholder="username"
          value={authStore.fields.username}
          onChange={(e) =>
            authStore.onChangeLoginInput('username', e.target.value)
          }
        />
        <Box
          className={classes.passwordWrapper}
        >
          <TextField
            type={authStore.isVisible ? 'text' : 'password'}
            name="password"
            variant="standard"
            placeholder="password"
            fullWidth
            value={authStore.fields.password}
            onChange={(e) =>
              authStore.onChangeLoginInput('password', e.target.value)
            }
          />
          <Button onClick={() => authStore.toggleVisible()}>
            { authStore.isVisible ? <VisibilityOff /> : <Visibility />}
          </Button>
        </Box>
        <Box className={classes.buttonWrapper}>
          <Button
            type="submit"
            variant="contained"
            disabled={authStore.isSubmitDisabled}
          >
            LOGIN
          </Button>
        </Box>
      </Box>
    </Paper>
  );
});
