import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import {
  Column,
  CustomTable,
  SelectCell,
  TextCell,
} from '../../components/table';
import {
  GetBlackWhiteList,
  AddBlackWhiteList,
  UpdateBlackWhiteList,
} from '../../proto/auth_pb';
import { AddBlackWhiteListModal } from '../../components/modals';
import { CustomDeleteDialog } from '../../components/dialogs';

const BlackListTypeOptions: {[value: string]: string} = {
  id: 'ID',
  ip: 'IP Address',
  email: 'Email',
};

export const Blacklist = observer(() => {
  const { blacklistStore } = useStores();

  const loadBlacklist = (dto: GetBlackWhiteList.AsObject) =>
    blacklistStore.getBlackList(dto);

  const addBlackList = (dto: AddBlackWhiteList.AsObject) =>
    blacklistStore.addBlackList(dto);

  const updateBlackList = (dto: UpdateBlackWhiteList.AsObject) =>
    blacklistStore.updateBlackList(dto);

  const deleteBlackList = (id: number) => blacklistStore.deleteBlackList(id);

  const columns: Column[] = [
    { id: 'id', label: 'Id', minWidth: 10 },
    {
      id: 'value',
      label: 'Value',
      component: (props: any) => {
        return (
          <TextCell
            value={props.value}
            onChange={(value) => updateBlackList({ ...props, value })}
          />
        );
      },
    },
    {
      id: 'type',
      label: 'Type',
      component: (props: any) => (
        <SelectCell
          options={BlackListTypeOptions}
          value={props.type}
          onChange={(type) => updateBlackList({ ...props, type })}
        />
      ),
    },
    {
      id: 'navButtons',
      label: '',
      minWidth: 100,
      component: (props: any) => (
        <CustomDeleteDialog
          labelForDelete={`Are you sure you want delete black list item `}
          objectId={props.id}
          deleteItemAction={deleteBlackList}
        />
      ),
    },
  ];

  return (
    <CustomTable
      data={blacklistStore.blacklist.items}
      totalCount={blacklistStore.blacklist.totalCount}
      requestAction={loadBlacklist}
      isSortEnabled={false}
      columns={columns}
      defaultSortKey="id"
      isDataSliced
      pageAction={() => (
        <AddBlackWhiteListModal addBlackWhiteListRequest={addBlackList}>
          Create new
        </AddBlackWhiteListModal>
      )}
    />
  );
});
