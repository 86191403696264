import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getCustomTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    optionBtn: {
      position: 'relative',
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    userIdContainer: {
      position: 'relative',
      display: 'flex',
    },
  }),
);
