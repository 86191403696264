import React, {useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import {getCreateTournamentStyle} from './create-tournament.styles';
import {Add} from '@mui/icons-material';
import moment from 'moment';
import {TourMake} from '../../../proto/leaderboard_pb';

interface OwnProps {
  createTournament: (data: TourMake.AsObject) => void;
  seasonid: number;
  isTournamentFinished: boolean;
}

type Props = OwnProps;

export const CreateTournamentModal: React.FC<Props> = ({
  createTournament,
  seasonid,
  isTournamentFinished,
}) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<any>({
    startdate: new Date().getTime(),
    enddate: new Date(Date.now() + 259200000).getTime(),
  });

  const classes = getCreateTournamentStyle();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({...state, [e.target.name]: new Date(e.target.value).getTime()});
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values: TourMake.AsObject) => {
    createTournament({
      ...values,
      seasonid,
      status: 'current',
    });

    handleClose();
  };

  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        disabled={!isTournamentFinished}
      >
        <Add /> Create
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.root,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>
                Create new tournament
              </Typography>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  type="datetime-local"
                  onChange={handleChange}
                  defaultValue={moment().format('YYYY-MM-DD[T]HH:mm')}
                  name="startdate"
                  label={'Start date'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  type="datetime-local"
                  onChange={handleChange}
                  defaultValue={moment(new Date(Date.now() + 259200000)).format(
                    'YYYY-MM-DD[T]HH:mm'
                  )}
                  name="enddate"
                  label={'End date'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit(state)} color="primary" autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateTournamentModal;
