import React from 'react';
import {Navigate, Outlet} from 'react-router';
import {observer} from 'mobx-react';
import {Box} from '@mui/material';
import {useStores} from '../../stores/app';
import {Loading} from './loading';

export const AuthLayout = observer(() => {
  const {authStore, isLoading} = useStores();

  if (authStore.isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%',
          flexWrap: 'wrap',
        }}
      >
        <Outlet />
      </Box>
      <Loading isLoading={isLoading} />
    </>
  );
});
