import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getUpdateTicketStatusDialogStyles = makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      width: 70,
      margin: theme.spacing(0.5),
    },
  });
});
