import { Autocomplete, Avatar, Box, Button, CardMedia, Checkbox, TextField, Tooltip } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ListboxComponent } from '../../components/modals';
import { Column, CustomTable } from '../../components/table';
import { currencyMapper, HrsPostbackType } from '../../core';
import { useStores } from '../../stores/app';
import { CreateEditModal } from '../../components/modals/create-edit';
import { EditableField } from '../../core/interfaces/modal.interfaces';
import { EditableFieldType } from '../../core/enum/modals.enum';
import { DailyRewardsDialog } from './daily-rewards-dialog';
import { LevelsOrderDialog } from './levels-order-dialog/levels-order-dialog';
import AddIcon from '@mui/icons-material/Add';
import { validateRewards } from '../../core/validator';
import { CustomDeleteDialog } from '../../components/dialogs';
import {Warning, Error, BookmarkBorder, Bookmark} from '@mui/icons-material';
import {
  OfferwallAdminAddGameRoute,
  OfferwallAdminAddRewardRoute,
  OfferwallAdminImportCsvRoute,
  OfferwallAdminUpdateDailyRewardsRoute,
  OfferwallAdminUpdateGameRoute,
  OfferwallAdminUpdateRewardRoute,
} from '@boints/grpc';
import { BundleOrderDialog, BundleOrderInfo } from './bundle-order-dialog';
import { AffectedBundleAttribute } from '@boints/types';
import { HrsStats } from '../hrs-stats';
import { getHrsV1Style } from './hrs.styles';

const minorErorrToString = {
  [AffectedBundleAttribute.BUNDLE_DESCRIPTION]: "Description need to set.",
  [AffectedBundleAttribute.BUNDLE_ICON]: "Icon need to set.",
  [AffectedBundleAttribute.BUNDLE_NAME]: "Name need to set.",
}


interface Props {
  defaultBundleId: string;
  defaultOfferwallId: string;
  defaultCampaignId: string;
}

export const Hrs: React.FC<Props> = observer(({
  defaultCampaignId,
  defaultBundleId,
  defaultOfferwallId,
}) => {
  const { hrsStore, notificationStore } = useStores();
  
  const [currBundleId, setCurrentBundleId] = useState<string>();
  const [currBundleIdPrimary, setCurrBundleIdPrimary] = useState<number>();
  const [currOfferwall, setCurrOfferwall] = useState<string>(defaultOfferwallId);
  const [currBundleInfo, setCurrBundleInfo] = useState<typeof hrsStore.allBundles[0]['games'][0]>();
  const [currentType, setCurrentType] = useState<string[]>([]);


  const classes = getHrsV1Style();

  useEffect(() => {
    setCurrOfferwall(defaultOfferwallId);
    setCurrBundleId(defaultBundleId);
    hrsStore.getChangeStatusListByCampaignId(defaultCampaignId);
  }, [defaultBundleId, defaultOfferwallId, defaultCampaignId]);

  const setCurrBundleId = (bundleId?: string) => {
    if (bundleId) {
      hrsStore.getDailyRewards({
        campaignId: defaultCampaignId, offerwallId: currOfferwall as string, accessToken: '',
      });
    }

    setCurrentBundleId(bundleId);
  };

  useEffect(() => {
    const bundle = hrsStore.allBundles
      .find((v) => v.offerwallId === currOfferwall)?.games
      .find((v) => v.campaignId === defaultCampaignId);

    setCurrBundleIdPrimary(bundle?.id);
    setCurrBundleInfo(bundle);
  }, [currBundleId, hrsStore.allBundles]);

  useEffect(() => {
    hrsStore.getBundles();
  }, [hrsStore]);

  useEffect(() => {
    if (currBundleId && currOfferwall) {
      hrsStore.getRewards({
        campaignId: defaultCampaignId,
        offerwallProviderId: currOfferwall,
        accessToken: '',
      });
    }
  }, [hrsStore, currBundleId, currOfferwall]);

  const addNewReward = (item: OfferwallAdminAddRewardRoute.RequestType) => {
    hrsStore.addReward({
      accessToken: '',
      key: item.key,
      type: item.type,
      alias: item.alias,
      rewardCurrency: item.rewardCurrency,
      rewardAmount: item.rewardAmount,
      offerwallProviderId: currOfferwall ?? "",
      // bundleId: currBundleId ?? "",
      campaignId: defaultCampaignId,
      blockersId: item.blockersId ?? [],
    });
  };

  const updateReward = (item: OfferwallAdminUpdateRewardRoute.RequestType) => {
    hrsStore.updateReward({
      accessToken: '',
      id: item.id,
      key: item.key,
      type: item.type,
      alias: item.alias,
      rewardCurrency: item.rewardCurrency,
      rewardAmount: item.rewardAmount,
      offerwallProviderId: currOfferwall ?? "",
      campaignId: defaultCampaignId,
      blockersId: item.blockersId ?? [],
    });
    hrsStore.getChangeStatusListByCampaignId(defaultCampaignId);
  };

  const updateDailyRewards = (dailyRewards: Omit<OfferwallAdminUpdateDailyRewardsRoute.RequestType, 'accessToken'>) => {
    hrsStore.updateDailyRewards({
      accessToken: '',
      // bundleId: dailyRewards.bundleId,
      campaignId: defaultCampaignId,
      offerwallId: dailyRewards.offerwallId,
      repeatCycle: dailyRewards.repeatCycle,
      rewards: dailyRewards.rewards,
    });
  };

  const addNewBandeId = (item: OfferwallAdminAddGameRoute.RequestType) => {
    const offerId = hrsStore.allBundles.find(offer => offer.offerwallId === item.offerwallId.toString())?.id;
    if (!offerId) {
      notificationStore.enqueueSnackBar({
        message: `This offerwallid not found: ${item.offerwallId}`,
        variant: 'error',
      });
      return;
    }
    hrsStore.addBundle({ ...item, offerwallId: offerId });
  };

  const updateBundle = (item: Partial<OfferwallAdminUpdateGameRoute.RequestType> & { offerwallId: string }) => {
    const bundle = {...currBundleInfo, ...item } as OfferwallAdminUpdateGameRoute.RequestType;
    hrsStore.updateBundle(bundle, item.offerwallId);
  };

  const addNewOfferwall = (item: any) => {
    hrsStore.addOfferwall({ accessToken: '', offerwallId: item.offerwallId });
  };

  const columns: Column[] = [
    {
      id: 'id',
      label: 'ID',
      minWidth: 20,
      sortKey: 'id',
    },
    {
      id: 'alias',
      label: 'Alias',
      minWidth: 100,
      disableSort: true,
      component: (item: typeof hrsStore.rewardList[0]) => <div>
        {item.alias}
        {hrsStore.rewardErrors[item.key] && hrsStore.rewardErrors[item.key][AffectedBundleAttribute.EVENT_ALIAS] && <Error style={{ color: 'red', marginLeft: '10px' }}/>}
        </div>,
    },
    {
      id: 'key',
      label: 'Key',
      minWidth: 100,
      sortKey: 'key',
    },
    {
      id: 'type',
      label: 'Type',
      minWidth: 150,
      disableSort: true,
      component: (item: typeof hrsStore.rewardList[0]) => <div>
      {item.type}
      {hrsStore.rewardErrors[item.key] && hrsStore.rewardErrors[item.key][AffectedBundleAttribute.EVENT_TYPE] && <Error style={{ color: 'red', marginLeft: '10px' }}/>}
      </div>,
    },
    {
      id: 'rewardCurrency',
      label: 'Reward Currency',
      disableSort: true,
      minWidth: 180,
      format: (value) => currencyMapper[value],
    },
    {
      id: 'rewardAmount',
      label: 'Reward Amount',
      sortKey: 'rewardAmount',
      minWidth: 170,
      component: (item: typeof hrsStore.rewardList[0]) => <div>
      {item.rewardAmount}
      {hrsStore.rewardErrors[item.key] && hrsStore.rewardErrors[item.key][AffectedBundleAttribute.EVENT_REWARD_AMOUNT] && <Error style={{ color: 'red', marginLeft: '10px' }}/>}
      </div>,
    },
    {
      id: 'blockersId',
      label: 'Blockers',
      minWidth: 170,
      disableSort: true,
      component: (item: typeof hrsStore.rewardList[0]) => <div>
        {(item.blockersId?.length > 0 ? item.blockersId?.map(blockerId => {
          const currBlockerItem = hrsStore.rewardList.find(reward => reward.id === blockerId);
          return (<div>{`${currBlockerItem?.alias} (${currBlockerItem?.id})`}</div>);
        }) : "empty")}
      </div>,
    },
    {
      id: '',
      label: '',
      minWidth: 20,
      disableSort: true,
      component: (item: typeof hrsStore.rewardList[0]) => (<div>
        <CreateEditModal
          btnLabel='Reward'
          dialogName="HRS Reward"
          currItem={item}
          editableFields={editabledRewardItems}
          acceptAction={updateReward}
        />
      </div>),
    },
  ];

  const editabledRewardItems: EditableField[] = [
    {
      label: "Id",
      type: EditableFieldType.number,
      paramName: "id",
      disabled: true,
    },
    {
      label: "Alias",
      type: EditableFieldType.string,
      paramName: "alias",
      validate: (value, row) => {
        if (!value) return '';
        // if (row.type === 'level' && value.length > 3) return "Value is to long";
        return '';
      },
    },
    {
      label: "Key",
      type: EditableFieldType.string,
      paramName: "key",
    },
    {
      label: "Type",
      type: EditableFieldType.string,
      paramName: "type",
      enumVariants: {
        "Time": 'time',
        "Level": 'level',
        "In App": 'inApp',
        "Video": "video",
        "Install": "install",
        "Achievement": 'achievement', 
        "Goal": 'goal',
      },
    },
    {
      label: "Reward Currency",
      type: EditableFieldType.number,
      paramName: "rewardCurrency",
      enumVariants: {
        "Boints": 1,
        "USD": 2,
        "Pending": 3,
      },
    },
    {
      label: "Reward Amount",
      type: EditableFieldType.number,
      paramName: "rewardAmount",
    },
    {
      label: "Blockers",
      type: EditableFieldType.string,
      paramName: "blockersId",
      customComponent: (currValue, setValue, currItem) => {
        const parsedVal: any = [];
        currValue?.forEach(val => {
          if (typeof val === 'number') {
            parsedVal.push(hrsStore.rewardList.find(rl => rl.id === val));
            return;
          }
          parsedVal.push(val);
        });

        return (
          <Autocomplete
            key={parsedVal}
            size='small'
            multiple
            options={currItem ? hrsStore.rewardList.filter(item => item.id !== currItem.id) : hrsStore.rewardList}
            value={parsedVal}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            ListboxComponent={
                            ListboxComponent as React.ComponentType<
                                React.HTMLAttributes<HTMLElement>
                            >
            }
            getOptionLabel={(option) => `${option.alias} (${option.id})`}
            onChange={(e, v) => { setValue((v as any).map(item => item.id)); }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ marginTop: '10px' }}
                label="Blockers"
              />
            )}
          />
        );
      },
    },
  ];

  const editabledOfferwallItems: EditableField[] = [
    {
      label: "Offerwall Id",
      type: EditableFieldType.string,
      paramName: "offerwallId",
    },
  ];

  const editabledBundleidItems: EditableField[] = [
    {
      label: "Id",
      type: EditableFieldType.number,
      paramName: "id",
      disabled: true,
    },
    {
      label: "OfferwallId: ",
      type: EditableFieldType.number,
      paramName: "offerwallId",
      enumVariants: hrsStore.allBundles ? Object.fromEntries(hrsStore.allBundles.map(item => [item.offerwallId, item.offerwallId])) : {},
      isEditThenDisable: true,
    }, {
      label: "Bundle Name: ",
      type: EditableFieldType.string,
      paramName: "name",
    },
    {
      label: "Bundle Id: ",
      type: EditableFieldType.string,
      paramName: "bundleId",
    },
    {
      label: "Deep link template (http://example?clickId={clickId}): ",
      type: EditableFieldType.string,
      paramName: "deepLinkTemplate",
    },
    {
      label: "Description",
      type: EditableFieldType.string,
      paramName: "description",
    },
    {
      label: "Image",
      type: EditableFieldType.file,
      paramName: "picture",
    },
    ];

  const mutateData = (data: typeof hrsStore.rewardList) => {
    if (currentType?.length > 0) {
      data = data.filter(item => currentType.includes(item.type));
    }

    if (!currBundleId || !currOfferwall) {
      return [];
    }
    return data;
  };

  const getBundlesOrderInfo = (): BundleOrderInfo[] => {
    const bundlesOrderInfo = [...hrsStore.allBundles.map(({ games }) => {
      return games?.map(({ bundleId, zIndex, id }) => {
        return {
          bundleIdPrimary: id,
          bundleId,
          zIndex,
        };
      });
    }).flat()];

    const filteredBundlesOrderInfo = bundlesOrderInfo.filter(item => typeof item !== 'undefined');
    filteredBundlesOrderInfo.sort((a, b) => b.zIndex - a.zIndex);

    return filteredBundlesOrderInfo;
  };

  const currBundleObj = hrsStore.allBundles.find(offer => offer.offerwallId === currOfferwall)?.games?.find(gl => gl.bundleId === currBundleId);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      try {
        const fileUrl = URL.createObjectURL(e.target.files[0]);
        const response = await fetch(fileUrl);
        const data = await response.text();

        if (currBundleId && currOfferwall) {
          const newRewards = convertCSVToRewardObj(data) as OfferwallAdminImportCsvRoute.RequestType['rewards'];
          if (newRewards.length > 0) validateRewards(newRewards);
          hrsStore.importCSV({
            accessToken: '',
            rewards: newRewards,
            campaignId: defaultCampaignId,
            offerwallProviderId: currOfferwall,
          }, currBundleId, currOfferwall);
        }
      } catch (ex) {
        notificationStore.enqueueSnackBar({
          message: ex as string,
          variant: 'error',
        });
      }
    }
  };

  const convertCSVToRewardObj = (csvData) => {
    const lines = csvData.split("\n");

    console.log(lines[0]);
    if (lines[0].trim() !== "id,key,type,reward_amount,blockers_id,alias") {
      throw 'CSV headers don\'t match the right signature';
    }

    if (!currBundleId || !currOfferwall) {
      throw 'Bundle Id or offerwall not selected.';
    }

    const headers = lines[0].split(",");
    const result: {}[] = [];

    const regex = new RegExp(`\\s*(")?(.*?)\\1\\s*(?:,|$)`, 'gs');

    const match = line => [...line.matchAll(regex)]
      .map(m => m[2])
      .slice(0, -1);

    for (let i = 1; i < lines.length; i++) {
      if (lines[i].length <= 0) break;

      const obj = {};
      const currentLine = match(lines[i]);

      for (let j = 0; j < headers.length; j++) {
        if (headers[j] === 'blockers_id') {
          if (!currentLine[j]) obj["blockersId"] = [];
          else obj["blockersId"] = currentLine[j].split(/\s*,\s*/).map(Number);
        } else {
          if (!currentLine[j]) obj[snakeToCamel(headers[j].trim())] = "";
          else obj[snakeToCamel(headers[j].trim())] = currentLine[j].trim();
        }
      }

      obj["offerwallProviderId"] = currOfferwall;
      obj["bundleId"] = currBundleId;
      obj["rewardCurrency"] = 1;
      result.push(obj);
    }

    return result;
  };

  const snakeToCamel = (str) => {
    return str.replace(/_([a-z])/g, (_, match) => match.toUpperCase());
  };

  const handleClick = event => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <Box>
      <Box style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Autocomplete
            key={currOfferwall}
            size='small'
            style={{ minWidth: '300px', marginRight: '10px' }}
            value={currOfferwall}
            options={hrsStore.allBundles.map(item => item.offerwallId)}
            isOptionEqualToValue={(option, value) => option === value}
            ListboxComponent={
                            ListboxComponent as React.ComponentType<
                                React.HTMLAttributes<HTMLElement>
                            >
            }
            disabled
            getOptionLabel={(option) => option}
            onChange={(e, v) => { setCurrOfferwall(v as string); setCurrBundleId(undefined); }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Offerwall Id"
              />
            )}
          />

          <Autocomplete
            key={currBundleId}
            // disabled={!currOfferwall}
            disabled
            size='small'
            style={{ minWidth: '300px', marginRight: '10px' }}
            value={currBundleId}
            options={hrsStore.allBundles.find(offer => offer.offerwallId === currOfferwall)?.games?.map(bndl => bndl.bundleId) ?? []}
            isOptionEqualToValue={(option, value) => option === value}
            ListboxComponent={
                            ListboxComponent as React.ComponentType<
                                React.HTMLAttributes<HTMLElement>
                            >
            }
            getOptionLabel={(option) => option as string}
            onChange={(e, v) => { setCurrBundleId(v as string); }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={currOfferwall}
                label="Select Bundle Id"
              />
            )}
          />
          
          { getBundlesOrderInfo().length > 0 &&
            <Box style={{ marginRight: '10px' }}>
              <BundleOrderDialog
                bundleOrderInfo={getBundlesOrderInfo()}
                updateBundleOrderInfo={(bundleOrderInfo) => {
                  hrsStore.updateGamesOrder({ games: bundleOrderInfo, accessToken: '' });
                }
                }
              />
            </Box>
          }

          <CreateEditModal
            tooltipText="Create new Reward"
            btnLabel='Reward'
            dialogName="HRS Reward"
            disabled={!currBundleId || !currOfferwall}
            editableFields={editabledRewardItems}
            acceptAction={addNewReward}
          />

          <Tooltip title={"Import new offerwall as CSV"}>
            <span>
              <Button component='label' variant='outlined' color='success' style={{ marginRight: "10px" }} disabled={!currBundleId || !currOfferwall}>
                <AddIcon /> {"import"}
                <input type='file' accept=".csv" hidden onChange={handleFileChange} onClick={handleClick} />
              </Button>
            </span>
          </Tooltip>
                    
        </Box>
        
        <Box style={{ display: 'flex' }}>
        {hrsStore.rewardWarnings.length > 0 &&
        <Box style={{ display: 'flex', alignItems: 'center'}}>
          <Tooltip title={<div>{hrsStore.rewardWarnings.map(item => <Box>{minorErorrToString[item]}</Box>)}</div>}>
            <Warning style={{ color: 'orange' }}/>
          </Tooltip>
        </Box>}
          <CreateEditModal
            tooltipText="Update current Bundle"
            btnLabel=' '
            dialogName="HRS Bundle"
            editableFields={editabledBundleidItems}
            acceptAction={(item) => {
              updateBundle(item);
              if (item.bundleId !== currBundleInfo?.bundleId) {
                setCurrBundleId();
                hrsStore.getBundles();
              }
              setCurrBundleInfo(item);
              hrsStore.getChangeStatusListByCampaignId(defaultCampaignId);
            }}
            disabled={!currBundleObj}
            currItem={{ ...currBundleInfo, offerwallid: currOfferwall } || {}}
          />

          {currBundleObj ?
            <Tooltip title={<CardMedia
              component="img"
              height="250"
              image={currBundleInfo?.picture}
            />}>
              <Avatar style={{ marginRight: '10px' }} src={currBundleInfo?.picture} />
            </Tooltip>
            : <Avatar style={{ marginRight: '10px' }} />}
          <Autocomplete
            multiple
            disabled={hrsStore.rewardList.length === 0}
            size='small'
            style={{ minWidth: '300px' }}
            options={Object.keys(HrsPostbackType)}
            isOptionEqualToValue={(option, value) => option === value}
            ListboxComponent={
                            ListboxComponent as React.ComponentType<
                                React.HTMLAttributes<HTMLElement>
                            >
            }
            getOptionLabel={(option) => option as string}
            onChange={(e, v) => { setCurrentType(v as string[]); }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={currentType}
                label="Select Reward Type"
              />
            )}
          />
        </Box>
      </Box>

      {currOfferwall ? (
        <Box style={{ marginLeft: '16px', marginBottom: '16px' }}>
          <h6>Offerwall secret: {hrsStore.allBundles.find((v) => v.offerwallId === currOfferwall)?.secret}</h6>

          {/* Bundles enabled for app and server checkbox start */}
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
            { typeof currBundleInfo?.enabledForServer === 'boolean' ?
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginLeft: '16px' }}>
                <h6 style={{ margin: 0 }}>Enabled for server:</h6>
                <Checkbox
                  title='Enabled for server'
                  checked={currBundleInfo.enabledForServer}
                  onChange={(e) => {
                    if (e.target.checked == false && currBundleInfo.enabledForApp) {
                      notificationStore.enqueueSnackBar({
                        message: 'Please disable for app first',
                        variant: 'error',
                      });
                      return;
                    }
                    updateBundle({
                      enabledForServer: e.target.checked,
                      offerwallId: currOfferwall,
                    });

                    setCurrBundleInfo({ ...currBundleInfo, enabledForServer: e.target.checked });
                  }}
                />
              </Box> : null}

              { typeof currBundleInfo?.enabledForApp === 'boolean' ?
              <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <h6 style={{ margin: 0 }}>Enabled for app:</h6>
                <Checkbox
                  title='Enabled for app'
                  checked={currBundleInfo.enabledForApp}
                  onChange={(e) => {
                    if (!currBundleInfo.enabledForServer) {
                      notificationStore.enqueueSnackBar({
                        message: 'Please enable for server first',
                        variant: 'error',
                      });
                      return;
                    }
                    updateBundle({
                      enabledForApp: e.target.checked,
                      offerwallId: currOfferwall,
                    });

                    setCurrBundleInfo({ ...currBundleInfo, enabledForApp: e.target.checked });
                  }}
                />
              </Box>
              : null}

              { typeof currBundleInfo?.favorite === 'boolean' ?
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                  <h6 style={{ margin: 0 }}>Favorite:</h6>
                  <Checkbox
                    title='Favorite'
                    icon={<BookmarkBorder />}
                    checkedIcon={<Bookmark />}
                    checked={currBundleInfo.favorite}
                    onChange={(e) => {
                      hrsStore.toggleFavoriteGame({
                        accessToken: '',
                        favorite: e.target.checked,
                        id: currBundleInfo.id,
                      })
                      setCurrBundleInfo({ ...currBundleInfo, favorite: e.target.checked });
                    }}
                  />
                </Box>
              : null}
          </Box>
          {/* Bundles enabled for app and server checkbox end */}
        </Box>
      ) : undefined}

      {hrsStore.dailyRewards && currBundleId && currOfferwall && currBundleIdPrimary
        ? <Box style={{ display: 'flex', marginLeft: '10px', marginBottom: '8px' }}>
          <Box style={{ marginRight: '8px' }}>
            <DailyRewardsDialog
              campaignId={defaultCampaignId}
              offerwallId={currOfferwall}
              dailyRewards={{ rewards: hrsStore.dailyRewards.rewards, repeatCycle: hrsStore.dailyRewards.repeatCycle }}
              updateDailyRewardsAction={updateDailyRewards}
            />
          </Box>

          { hrsStore.levelsOrder.length > 0 ?
            <Box>
              <LevelsOrderDialog
                bundleId={currBundleId}
                offerwallId={currOfferwall}
                levelsOrder={hrsStore.levelsOrder}
                bundleIdPrimary={currBundleIdPrimary}
                updateLevelsOrderAction={({ bundleIdPrimary, levelsOrder, offerwallId, bundleId }) => updateBundle({
                  id: bundleIdPrimary, levelsOrder, bundleId, offerwallId,
                })}
              />
            </Box> : null}

          <Box style={{ margin: '0 10px 10px 10px' }}>
            <CustomDeleteDialog
              labelForDelete={`Are you sure you want to delete the bundle `}
              objectId={currBundleId}
              deleteItemAction={() => {
                hrsStore.deleteGame({ id: currBundleIdPrimary!, accessToken: '' });
                setCurrBundleId();
                const event = new KeyboardEvent('keydown', { key: 'Escape' })
                document.dispatchEvent(event);
              }}
              textButton='Delete bundle'
            ></CustomDeleteDialog>
          </Box>
        </Box>
        : null}

      <CustomTable
        data={mutateData(hrsStore.rewardList)}
        requestAction={() => { }}
        columns={columns}
        isPaginationShow={false}
        defaultSortKey='id'
        tableInnerProps={{ "size": "small" }}
        isSortEnabled={true}
        isClientSideSort={true}
        isDataSliced={true}
      />
    </Box>
  );
});
