import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

const drawerWidth = 240;

export const getDrawerStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      padding: theme.spacing(2),
    },
    drawerPaper: {
      width: drawerWidth,
    },
    userInfo: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1.5),
    },

    listItemActive: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
      textDecoration: 'none',
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    list: {
      paddingLeft: `${theme.spacing(2)} !important`,
      paddingRight: `${theme.spacing(2)} !important`,
    },
    listItem: {
      borderRadius: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      color: `${theme.palette.text.primary} !important`,
      textDecoration: 'none',
      width: '100%',
    },
    listItemIcon: {
      color: 'inherit !important',
      minWidth: `30px !important`,
    },
    navSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      marginTop: theme.spacing(2),
    },
    themeSwitcher: {
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    errorIcon: { color: theme.palette.error.main },
    warningIcon: { color: theme.palette.warning.main },
    pulseError: {
      animation: `$pulseError 2s infinite`,
      "box-shadow": `0 0 0 0 ${theme.palette.error.main}`,
      // border: `5px solid ${theme.palette.error.main}`,
    },
    pulseWarning: {
      animation: `$pulseWarning 2s infinite`,
      "box-shadow": `0 0 0 0 ${theme.palette.warning.main}`,
      // border: `5px solid ${theme.palette.warning.main}`,
    },
    "@keyframes pulseError": {
      "0%": {
        // transform: "scale(0.95)",
        "box-shadow": `0 0 0 0 ${theme.palette.error.main}`,
      },
      "70%": {
        // transform: "scale(1)",
        "box-shadow": "0 0 0 10px rgba(0, 100, 0, 0)",
      },
      "100%": {
        // transform: "scale(0.95)",
        "box-shadow": "0 0 0 0 rgba(0, 100, 0, 0)",
      },
    },
    "@keyframes pulseWarning": {
      "0%": {
        // transform: "scale(0.95)",
        "box-shadow": `0 0 0 0 ${theme.palette.warning.main}`,
      },
      "70%": {
        // transform: "scale(1)",
        "box-shadow": "0 0 0 10px rgba(0, 100, 0, 0)",
      },
      "100%": {
        // transform: "scale(0.95)",
        "box-shadow": "0 0 0 0 rgba(0, 100, 0, 0)",
      },
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4em',
        height: '1em',
        borderRadius: '10px',
        outline: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.action.hover,
      },
      '*::-webkit-scrollbar-track': {
      },
      '*::-webkit-scrollbar-thumb': {
        'box-shadow': `inset 0px 0px 10px ${theme.palette.action.disabled}`,
        // backgroundColor: theme.palette.action.focus,
        borderRadius: '10px',
      }
    }
  })
);
