import React, {useState} from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
} from '@mui/material';
import {getDeleteDialogStyles} from './unban.styles';
import {HowToReg} from '@mui/icons-material';

interface Props {
  row: any;
  unbanUserAction?: (data: { userId: number, userStatus: number }) => void;
}

export const UnbanDialog: React.FC<Props> = ({row, unbanUserAction}) => {
  const classes = getDeleteDialogStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [info, setInfo] = useState<number>(0);

  const handleClickOpen = (row: any) => {
    setOpen(true);
    setInfo(row.id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    if (unbanUserAction) {
      unbanUserAction({
        userId: info,
        userStatus: 1,
      });
    }

    setOpen(false);
  };

  return (
    <Box>
      <IconButton onClick={() => handleClickOpen(row)}>
        <HowToReg className={classes.unbanIcon} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want unban user with id {info}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Unban
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
