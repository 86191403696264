import React, {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import {EditableHOC} from '../editable-hoc';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const leftPad = (value: number) =>
  value < 10 ? `0${value}` : `${value}`;

export const TimeCell: React.FC<Props> = ({value, onChange}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <EditableHOC
      onSave={() => {
        onChange(currentValue);
      }}
      onCancel={() => {
        setCurrentValue(value);
      }}
    >
      {(editable) => (
        <TextField
          type="time"
          value={currentValue}
          disabled={!editable}
          onChange={(e) => setCurrentValue(e.target.value)}
        />
      )}
    </EditableHOC>
  );
};
