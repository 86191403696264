import React, { useEffect, useRef, useState } from 'react';
import Editor, { OnMount } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';

interface JsonEditorProps {
  value: string;
  onChange: (value: string) => void;
}

export const JsonEditor: React.FC<JsonEditorProps> = ({ value, onChange }) => {
  const handleEditorChange = (v: string | undefined) => {
    onChange(v || '');
  };

  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);

  const changeEditorValue = (newValue: string) => {
    if (editorRef.current) {
      editorRef.current.setValue(newValue);
    }
  };

  useEffect(() => {
    changeEditorValue(value);
  }, [value]);

  const handleEditorDidMount: OnMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <Editor
      height="70vh"
      defaultLanguage="json"
      defaultValue={value}
      // value={value}
      theme="vs-dark"
      onChange={handleEditorChange}
      onMount={handleEditorDidMount}
    />
  );
};

