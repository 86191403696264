import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import {
  DoubleBointsMany,
  UpdateDoubleBoints,
} from '../../proto/doubleBoints_pb';
import { AppStore } from '../app';
import { DoubleBointsDoubleBointsGetRoute, DoubleBointsDoubleBointsUpdateRoute } from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export class DoubleBointsStore {
  public error: RpcError | null = null;

  public doubleBoints: DoubleBointsMany.AsObject = {
    entitiesList: [],
    totalcount: 0,
    pagenumber: 1,
    amountperpage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getDoubleBoints() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountPerPage, entities, pageNumber, totalCount }: DoubleBointsDoubleBointsGetRoute.ResponseType = yield WebSocketService
        .sendRequest<DoubleBointsDoubleBointsGetRoute.ResponseType, DoubleBointsDoubleBointsGetRoute.RequestType>({
          method: 'reward_getDoubleBoints',
          data: {
            amountPerPage: 10,
            pageNumber: 1,
          },
        });

      this.doubleBoints = {
        entitiesList: [],
        totalcount: 0,
        pagenumber: 1,
        amountperpage: 10,
      };

      if (entities) {
        this.doubleBoints.amountperpage = amountPerPage;
        this.doubleBoints.pagenumber = pageNumber;
        this.doubleBoints.totalcount = totalCount;
        this.doubleBoints.entitiesList = entities.map((entity) => {
          return {
            day: entity.day,
            enabled: entity.enabled,
            id: entity.id,
            hourbegin: entity.hourBegin,
            hourend: entity.hourEnd,
            minutesbegin: entity.minutesBegin,
            minutesend: entity.minutesEnd,
          };
        }).reverse();
      }

    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateDoubleBoints(dto: UpdateDoubleBoints.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { day, enabled, hourbegin, hourend, id, minutesbegin, minutesend } = dto;
      const response: DoubleBointsDoubleBointsUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<DoubleBointsDoubleBointsUpdateRoute.ResponseType, DoubleBointsDoubleBointsUpdateRoute.RequestType>({
          method: 'reward_updateDoubleBoints',
          data: {
            id,
            day,
            enabled,
            hourEnd: hourend,
            hourBegin: hourbegin,
            minutesEnd: minutesend,
            minutesBegin: minutesbegin,
          },
        });

      this.doubleBoints.entitiesList = this.doubleBoints.entitiesList.map(
        (item) => {
          if (item.id === id) {
            const { day, enabled, hourBegin, hourEnd, minutesBegin, minutesEnd, id } = response;
            return {
              id,
              day,
              enabled,
              hourend: hourEnd,
              hourbegin: hourBegin,
              minutesend: minutesEnd,
              minutesbegin: minutesBegin,
            };
          }

          return item;
        },
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
