import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, IconButton } from '@mui/material';
import { Add, Create } from '@mui/icons-material';
import { useStores } from '../../stores/app';
import { Column, CustomTable, SwitchCell } from '../../components/table';
import { RewardSchemaDialog, DialogState } from '../../components/dialogs';
import { OfferWallSchemaEntity } from '../../proto/reward_pb';

export const SchamasTab = observer(() => {
  const { rewardStore } = useStores();

  const [dialogState, setDialogState] = useState<DialogState>('closed');
  const [dialogData, setDialogData] =
    useState<OfferWallSchemaEntity.AsObject | null>(null);

  const loadRewardSchemas = useCallback(() => {
    rewardStore.getRewardSchemes({});
  }, []);

  const createRewardSchema = useCallback(
    ({ name, active, schema }: OfferWallSchemaEntity.AsObject) => {
      rewardStore.createRewardScheme({ name, active, data: schema });
    },
    [],
  );

  const updateRewardSchema = useCallback(
    ({ id, name, active, schema }: OfferWallSchemaEntity.AsObject) => {
      rewardStore.updateRewardScheme({ id, name, active, data: schema });
    },
    [],
  );

  const onCloseHandler = useCallback(() => {
    setDialogState('closed');
    setDialogData(null);
  }, []);

  const onSubmitHandler = useCallback(
    (data: OfferWallSchemaEntity.AsObject) => {
      if (dialogState === 'create') {
        createRewardSchema(data);
      }

      if (dialogState === 'edit') {
        updateRewardSchema(data);
      }

      onCloseHandler();
    },
    [dialogState, onCloseHandler],
  );

  const Actions = useCallback(
    () => (
      <>
        <Button
          variant="contained"
          color="primary"
          sx={{ pl: 0.5, pr: 1.5 }}
          onClick={() => setDialogState('create')}
        >
          <Add />
          Create
        </Button>
      </>
    ),
    [],
  );

  const columns: Column[] = [
    {
      id: 'id',
      label: 'Id',
      minWidth: 80,
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'active',
      label: 'Active',
      minWidth: 80,
      component: (props: any) => (
        <Box sx={{ mx: 'auto' }}>
          <SwitchCell
            value={props.active}
            onChange={(active) => updateRewardSchema({ ...props, active })}
          />
        </Box>
      ),
    },
    {
      id: 'edit',
      label: '',
      minWidth: 40,
      component: (data) => (
        <IconButton
          sx={{ mx: 'auto' }}
          onClick={() => {
            setDialogData(data);
            setDialogState('edit');
          }}
        >
          <Create />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        columns={columns}
        data={rewardStore.rewardSchemas}
        totalCount={rewardStore.rewardSchemas.length}
        pageAction={Actions}
        isSortEnabled={false}
        requestAction={loadRewardSchemas}
        isPaginationShow={false}
      />
      <RewardSchemaDialog
        state={dialogState}
        data={dialogData || new OfferWallSchemaEntity().toObject()}
        onSubmit={onSubmitHandler}
        onClose={onCloseHandler}
      />
    </>
  );
});
