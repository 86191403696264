import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import { Box, Button, Checkbox, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDeleteDialog, CustomDialog } from '../../components/dialogs';
import { getCustomTableStyleV2 } from './mc-users-stats.styles';
import { RewardStagePatternUpdateRequest, StageOrder } from '../../proto/reward_pb';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';
import { McV2Edit } from '../../components/dialogs/mcv2-stage-edit';
import { McUserStat } from '@boints/grpc/lib/proto-interfaces/reward';
import { Launch } from '@mui/icons-material';
import { useEffect } from 'react';
import { ExportMcV2UsersProgress } from '../../components/modals';
import { McSingleUsersStats } from './mc-single-users-stats.page';

export const McUsersStats = observer(() => {
  const { motivatianlCurrencyV2Store, settingsStore } = useStores();
  const classes = getCustomTableStyleV2();

  const fetchData = (data) => {
    motivatianlCurrencyV2Store.getUsersStats(data as any);
  }

  const fetchEach = (order: number, version: number) => {
    motivatianlCurrencyV2Store.getOneByOrderAndVersion(order, version);
  }

  const columns: Column[] = [
    {
      id: 'userId',
      label: 'User id',
    },
    {
      id: 'stageOrder',
      label: 'Current Iteration ID',
      format: (item) => "#" + (item + 1),
    },
    {
      id: 'stageVersion',
      label: 'Current Iteration Version',
      component: (item) => (
        <Box>
          <McV2Edit
            updatableObject={motivatianlCurrencyV2Store.stageOrderToView}
            btnText={`v${item.stageVersion}`}
            fetchEach={() => fetchEach(item.stageOrder, item.stageVersion)}
            />
      </Box>
      ),
    },
    {
      id: 'currStage',
      label: 'Stage',
      format: (item) => (item + 1),
    },
    {
      id: 'currGroup',
      label: 'Group',
      format: (item) => (item + 1),
    },
    {
      id: 'currStep',
      label: 'Current Step Progress',
      format: (item) => (item + 1),
    },
    {
      id: 'currReward',
      label: 'Current Step Progress',
    },
    {
      id: 'totalEarned',
      label: 'Iteration Earned',
      format: (item) => (item / 100) + " $",
    },
    {
      id: 'action',
      label: '',
      disableSort: true,
      component: (item) => <McSingleUsersStats userId={item.userId}/>,
    },
  ];

  useEffect(() => {}, [motivatianlCurrencyV2Store.stageOrderToView]);

  const Actions = () => {
    return (
        <ExportMcV2UsersProgress />
    );
  };

  return (
    <>
      <CustomTable
        data={motivatianlCurrencyV2Store.mcUsersStats.mcUsers || []}
        totalCount={motivatianlCurrencyV2Store.mcUsersStats.totalCount || 0}
        isDataSliced
        requestAction={fetchData}
        columns={columns}
        searchInputText='Search by User id'
        isSearchShow={true}
        isPaginationShow={true}
        pageAction={Actions}
      />
    </>
  );
});
