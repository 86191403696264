import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import {Description} from '@mui/icons-material';
import moment from 'moment';
import {getExportMcV2UsersProgressStyle} from './export-mcv2-user-progress.styles';
import {generateCSV} from '../../../core';
import {RANGE_FOR_EXPORT} from '../../../core/constants';
import {useStores} from '../../../stores/app';
import { RewardMCStageOrderGetMcUsersStatsRoute } from '@boints/grpc';

const errorText = 'Date out of range, please enter valid range.';
const TWO_WEEK_IN_S = 1209600;

export const ExportMcV2UsersProgress = observer(() => {
  const {motivatianlCurrencyV2Store} = useStores();
  const [open, setOpen] = useState(false);
  const [rangeError, setRangeError] = useState(false);
  const [state, setState] = useState<any>({
    timefrom: Math.floor(
      new Date(Date.now() - RANGE_FOR_EXPORT).getTime() / 1000
    ),
    timeto: Math.floor(new Date().getTime() / 1000),
  });

  useEffect(() => {
    if (motivatianlCurrencyV2Store.mcUsersStatsForExport.mcUsers.length) {
      const headers: any[] = [];
      Object.keys(motivatianlCurrencyV2Store.mcUsersStatsForExport.mcUsers[0]).forEach((key: any) => {
        headers.push({
          key,
        });
      });

      if (open) {
        generateCSV(
          headers,
          motivatianlCurrencyV2Store.mcUsersStatsForExport.mcUsers,
          'users-' + moment().format('YYYY-MM-DD')
        );
      }

      handleClose();
    }
  }, [motivatianlCurrencyV2Store.mcUsersStatsForExport]);

  useEffect(() => {
    if (state.timeto - state.timefrom <= TWO_WEEK_IN_S) {
      setRangeError(false);
    } else {
      setRangeError(true);
    }
  }, [state]);

  const classes = getExportMcV2UsersProgressStyle();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: Math.floor(new Date(e.target.value).getTime() / 1000),
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
    setState({
      ...state,
      timefrom: Math.floor(
        new Date(Date.now() - RANGE_FOR_EXPORT).getTime() / 1000
      ),
      timeto: Math.floor(new Date().getTime() / 1000),
    });
  };

  const handleClose = () => {
    setOpen(false);
    setState({
      ...state,
      timefrom: Math.floor(
        new Date(Date.now() - RANGE_FOR_EXPORT).getTime() / 1000
      ),
      timeto: Math.floor(new Date().getTime() / 1000),
    });
  };

  const onSubmit = (values: RewardMCStageOrderGetMcUsersStatsRoute.RequestType) => {
    motivatianlCurrencyV2Store.getUsersStatsForCsv(values);
    setState({
      ...state,
      timefrom: Math.floor(
        new Date(Date.now() - RANGE_FOR_EXPORT).getTime() / 1000
      ),
      timeto: Math.floor(new Date().getTime() / 1000),
    });
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        <Description /> Export to CSV
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.root,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>
                Set range for export (less than 2 week)
              </Typography>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  type="datetime-local"
                  onChange={handleChange}
                  defaultValue={moment(
                    new Date(Date.now() - RANGE_FOR_EXPORT)
                  ).format('YYYY-MM-DD[T]HH:mm')}
                  name="timefrom"
                  label={'Start date'}
                  error={rangeError}
                  helperText={rangeError && errorText}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  type="datetime-local"
                  onChange={handleChange}
                  defaultValue={moment().format('YYYY-MM-DD[T]HH:mm')}
                  name="timeto"
                  label={'End date'}
                  error={rangeError}
                  helperText={rangeError && errorText}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit(state)} color="primary" autoFocus>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
