import React from 'react';
import { observer } from 'mobx-react';
import { Routes, Route, Navigate } from 'react-router';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { AuthLayout, DashboardLayout } from './components/layout';
import {
  Users,
  BannedUsers,
  Season,
  SeasonInfo,
  TourInfo,
  Settings,
  DoubleBoints,
  CashoutVersion,
  WithdrawalTickets,
  Transactions,
  Blacklist,
  Whitelist,
  DailyReport,
  DailyReportDetails,
  Rewards,
  Login,
  UserQuality,
  Notifications,
  FrontendSettings,
  DailyCoinsGoal,
} from './pages';
import { Notifier } from './components/notifier';
import { useStores } from './stores/app';
import { CssBaseline } from '@mui/material';
import { MotivationalCurrency } from './pages/motivation-currency';
import { Hrs } from './pages/hrs';
import { Tests } from './pages/tests';
import { FeatureToggle } from './pages/feature-toggle';
import { DailyCashGoal } from './pages/daily-cash-goal';
import { HrsStats } from './pages/hrs-stats';
import { MotivationalCurrencyV2 } from './pages/motivation-currency-v2';
import { McUsersStats } from './pages/mc-users-stats';
import { Hrsv2 } from './pages/hrsv2';

export const App = observer(() => {
  const { themeStore } = useStores();

  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <ThemeProvider theme={themeStore.theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="" element={<Navigate to="login" />} />
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="" element={<Navigate to="users" />} />
            <Route path="users" element={<Users />} />
            <Route path="banned-users" element={<BannedUsers />} />
            <Route path="season" element={<Season />} />
            <Route path="season-info/:id" element={<SeasonInfo />} />
            <Route path="tour-info/:tourId/:seasonId" element={<TourInfo />} />
            <Route path="double-boints" element={<DoubleBoints />} />
            <Route path="settings" element={<Settings />} />
            <Route path="cash-out-version" element={<CashoutVersion />} />
            <Route path="withdrawal-tickets" element={<WithdrawalTickets />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="blacklist" element={<Blacklist />} />
            <Route path="whitelist" element={<Whitelist />} />
            <Route path="daily-report" element={<DailyReport />} />
            <Route path="tests" element={<Tests />} />
            <Route
              path="daily-report-details/:id"
              element={<DailyReportDetails />}
            />
            <Route path="rewards" element={<Rewards />} />
            <Route path="motivational-currency" element={<MotivationalCurrency/>}/>
            <Route path="motivational-currency-v2" element={<MotivationalCurrencyV2/>}/>
            <Route path="hrs" element={<Hrsv2 />} />
            <Route path="user-quality" element={<UserQuality />} />
            <Route path="feature-toggle" element={<FeatureToggle />} />
            <Route path="daily-goal" element={<DailyCashGoal />} />
            <Route path="hrs-stats" element={<HrsStats />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="mc-users-stats" element={<McUsersStats />} />
            <Route path="frontend-settings" element={<FrontendSettings />} />
            <Route path="daily-coins-goal" element={<DailyCoinsGoal />} />
          </Route>
        </Routes>
        <Notifier />
      </ThemeProvider>
    </SnackbarProvider>
  );
});
