import { ResponseStatus } from "./transport.interfaces";

export interface UnknownObject {
  [k: string]: unknown | UnknownObject;
}

export enum ContractType {
  asyncRequestReply = 'asyncRequestReply',
  request = 'request',
  notification = 'notification'
}

export interface BaseMicroserviceContract {
  id: string;
  metadata: {
    [k: string]: unknown | UnknownObject;
  };
  type: ContractType;
}

export interface AsyncRequestReplyContract<REQUEST = UnknownObject, RESPONSE = UnknownObject> extends BaseMicroserviceContract {
  type: ContractType.asyncRequestReply;
  responseTopic: string;
  request: {
    params: REQUEST;
  };
  response?: ResponseContract<RESPONSE>;
}

export interface RequestContract<REQUEST = UnknownObject, RESPONSE = UnknownObject> extends BaseMicroserviceContract {
  type: ContractType.request;
  microserviceName: string;
  serviceName: string;
  method: string;
  metadata: {
    authInfo: {
      userId?: number;
      ip?: string;
      permissionLevel?: number;
    },
    [k: string]: unknown | UnknownObject;
  };
  request: {
    params: REQUEST;
  };
  response?: ResponseContract<RESPONSE>;
}

export interface ResponseContract<REQUEST = UnknownObject, RESPONSE = UnknownObject> extends BaseMicroserviceContract {
  type: ContractType.request;
  microserviceName: string;
  serviceName: string;
  method: string;
  metadata: {
    authInfo: {
      userId?: number;
      ip?: string;
      permissionLevel?: number;
    },
    [k: string]: unknown | UnknownObject;
  };
  request: {
    params: REQUEST;
  };
  response: ResponseData<RESPONSE>;
}

export type ResponseData<RESPONSE = UnknownObject> = {
  code: ResponseStatus.OK;
  data: RESPONSE;
} | {
  code: Exclude<ResponseStatus, ResponseStatus.OK>;
  message: string;
};

export enum NotificationMethod {
  balanceChanged = 'balanceChanged'
}

export interface NotificationsContract<DATA = UnknownObject> extends BaseMicroserviceContract {
  type: ContractType.notification;
  method: NotificationMethod;
  data: DATA;
}
