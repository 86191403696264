import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import { TransactionFindAll, TransactionFindAllResponse } from '../../proto/balance_pb';
import { BalanceTransactionFindAllRoute } from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

const mappingTransactionKeys = {
  "date": "balance.createDate",
}

export class TransactionStore {
  public error: RpcError | null = null;

  transactions: TransactionFindAllResponse.AsObject = {
    totalcount: 0,
    pagenumber: 1,
    amountperpage: 10,
    dataList: [],
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getTransactions(dto: TransactionFindAll.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    dto.order = mappingTransactionKeys[dto.order] || "id";

    try {
      const { pagenumber, amountperpage, currency, order, orderby, type, userid } = dto;
      const { amountPerPage, data, pageNumber, totalCount }: BalanceTransactionFindAllRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceTransactionFindAllRoute.ResponseType, BalanceTransactionFindAllRoute.RequestType>({
          method: 'balance_transactionFindAll',
          data: {
            type,
            order,
            currency,
            userId: userid,
            orderBy: orderby,
            pageNumber: pagenumber,
            amountPerPage: amountperpage,
          },
        });
      
      this.transactions.amountperpage = amountPerPage;
      this.transactions.pagenumber = pageNumber;
      this.transactions.totalcount = totalCount;
      this.transactions.dataList = data.map((v) => {
        const { id, createDate, credit, currency, debit, resultBalanceAmount, txId, type, updateDate, userId, additionalParams } = v;
        return {
          id,
          type,
          debit,
          credit,
          currency,
          txid: txId,
          userid: userId,
          createdate: createDate,
          updatedate: updateDate,
          resultbalanceamount: resultBalanceAmount,
          additionalparams: additionalParams as Record<string, string>,
        };
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
