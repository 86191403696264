import React, {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import moment from 'moment';
import {EditableHOC} from '../editable-hoc';

interface Props {
  value: number;
  onChange: (value: number) => void;
}

function format(datetime: number) {
  return moment(datetime).format('YYYY-MM-DD[T]HH:mm');
}

export const DatetimeCell: React.FC<Props> = ({value, onChange}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <EditableHOC
      onSave={() => {
        if (new Date(currentValue).getTime() > new Date('2100-01-01T08:30').getTime()) {
          setCurrentValue(value)
          return;
        }
        if (new Date(currentValue).getTime() < new Date('2000-01-01T08:30').getTime()) {
          setCurrentValue(value)
          return;
        }
        onChange(currentValue);
      }}
      onCancel={() => {
        setCurrentValue(value);
      }}
    >
      {(editable) => (
        <TextField
          type="datetime-local"
          value={format(currentValue)}
          inputProps={{
            min: "2000-01-01T08:30",
            max: "2100-01-01T08:30"
          }}
          disabled={!editable}
          onChange={(e) => {
            if (!e.target.value) return;
            setCurrentValue(moment(e.target.value).valueOf())
          }
        }
        />
      )}
    </EditableHOC>
  );
};
