export const bff = {
  login: {
    microserviceName: 'bff',
    serviceName: 'AuthService',
    method: 'login',
  },
  loginByToken: {
    microserviceName: 'bff',
    serviceName: 'AuthService',
    method: 'loginByToken',
  },
};