export const tests = {
  getTestsList: {
    microserviceName: 'integrationTests',
    serviceName: 'TestsService',
    method: 'getList',
  },
  getTestsWithPagination: {
    microserviceName: 'integrationTests',
    serviceName: 'TestsService',
    method: 'getTestsWithPagination',
  },
  runTests: {
    microserviceName: 'integrationTests',
    serviceName: 'TestsService',
    method: 'runMultiple',
  },
  runPerformanceTest: {
    microserviceName: 'integrationTests',
    serviceName: 'TestsService',
    method: 'runPerformanceTest',
  },
};