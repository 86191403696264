import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getResetUserModalStyle = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
    },
    autocomplete: {
      width: '100%',
      marginRight: theme.spacing(2),
    },
    checkbox: {
      alignItems: 'center !important',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(1),
    },
    test: {
      fontSize: 12,
    },
  })
);
