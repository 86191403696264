import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { OfferwallAdminUpdateGamesOrderRoute } from '@boints/grpc';

const MAX_BUNDLE_ID_LENGTH = 30;

const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: ${(props: any) => (props.isDragging ? '0px 4px 6px rgba(0, 0, 0, 0.05)' : 'none')};
`;

export interface BundleOrderInfo {
  bundleIdPrimary: number;
  bundleId: string;
  zIndex: number;
}

interface OwnProps {
  bundleOrderInfo: BundleOrderInfo[];
  updateBundleOrderInfo: (params: OfferwallAdminUpdateGamesOrderRoute.RequestType['games']) => any;
}

type Props = OwnProps;

export const BundleOrderDialog: React.FC<Props> = ({
  bundleOrderInfo,
  updateBundleOrderInfo,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [bundleOrder, setBundleOrder] = useState<BundleOrderInfo[]>(bundleOrderInfo);

  useEffect(() => {
    setBundleOrder(bundleOrderInfo);
  }, [bundleOrderInfo]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedBundleOrderInfo = [...bundleOrder];
    const [reorderedItem] = updatedBundleOrderInfo.splice(result.source.index, 1);
    updatedBundleOrderInfo.splice(result.destination.index, 0, reorderedItem);

    setBundleOrder(updatedBundleOrderInfo);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };
  
  const handleAction = () => {
    setOpenDialog(false);
    // back-end stores z-index in reverse order from highest to lowest
    const updatedBundleOrderInfo = setOppositeZIndex();
    const mappedBundleOrderInfo = updatedBundleOrderInfo.map(({ zIndex, bundleIdPrimary }) => {
      return {
        id: bundleIdPrimary,
        zIndex,
      };
    });
    updateBundleOrderInfo(mappedBundleOrderInfo);
  };

  const setOppositeZIndex = (): BundleOrderInfo[] => {
    const length = bundleOrder.length;

    const updatedBundleOrderInfo = bundleOrder.map((item, index) => {
      return {
        ...item,
        zIndex: (length - 1) - index,
      };
    });

    return updatedBundleOrderInfo;
  };

  const trimAndAppendEllipsis = (sentence, maxLength = MAX_BUNDLE_ID_LENGTH): string => {
    if (sentence.length <= maxLength) {
      return sentence;
    }
  
    const trimmedSentence = sentence.substring(0, maxLength - 3); // Subtract 3 for the ellipsis
    return trimmedSentence + "...";
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseModal}
        aria-labelledby="test-dialog-title"
        aria-describedby="test-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="test-dialog-description">
            Edit bundles (games) ordering
          </DialogContentText>
        </DialogContent>
        <DialogContent style={{ overflow: 'auto', maxHeight: '300px' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {bundleOrder.map(({ bundleIdPrimary, bundleId }, index) => (
                    <Draggable key={bundleIdPrimary} draggableId={bundleIdPrimary.toString()} index={index}>
                      {(provided, snapshot) => (
                        <StyledListItem
                          disablePadding
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >
                          <ListItemText primary={`${index + 1}:`} style={{ marginRight: '10px' }} />
                          <Tooltip title={bundleId}>
                            <ListItemText primary={trimAndAppendEllipsis(bundleId)}/>
                          </Tooltip>
                        </StyledListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen} variant='outlined' color='success'>Bundles ordering</Button>
    </>
  );
};
