import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import moment from 'moment';
import { AppStore } from '../app';
import {
  RequestGetUserDto,
} from '../../proto/auth_pb';
import {
  RequestSetBalanceDto,
} from '../../proto/balance_pb';
import {
  AuthUserGetPlayersRoute,
  AuthUserGetBannedPlayersRoute,
  AuthUserBanRoute,
  AuthUserGetUsersInfoRoute,
  AuthUserGetPlayersIdNamesRoute,
  AuthUserResetRoute,
  BalanceBalanceSetRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services';

const UserStatus: Record<number, string> = {
  1: 'Active',
  2: 'Banned',
  3: 'Merged',
};

const PermissionLevels: Record<number, string> = {
  1: 'Player',
  2: 'Player',
  3: 'Modarator',
  4: 'Admin',
  5: 'Service',
};

class OSDictionary {
  static ANDROID = 1;
  static IOS = 2;
}

export const getUserStatus = (status: number): string => {
  return UserStatus[status];
};

export const getRoleByPermission = (role: number): string => {
  return PermissionLevels[role];
};

export const VersionInfoAndroid = (props): string => {
  if (props?.length) {
    const version = props.filter(
      (item) => item.os === OSDictionary.ANDROID,
    )[0];
    return version ? version.currentVersion : '-';
  } else {
    return '-';
  }
};

export class UserStore {
  public error: RpcError | null = null;

  usersInfo: AuthUserGetUsersInfoRoute.ResponseType = {
    info: [],
  };

  public users: AuthUserGetPlayersRoute.ResponseType = {
    data: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  public bannedUsers: AuthUserGetBannedPlayersRoute.ResponseType = {
    data: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  public usersCSV: AuthUserGetPlayersRoute.ResponseType = {
    data: [],
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
  };

  public searchResult: AuthUserGetPlayersIdNamesRoute.ResponseType = {
    totalCount: 0,
    pageNumber: 1,
    amountPerPage: 10,
    players: [],
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getPlayers(dto: RequestGetUserDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountperpage, order, orderby, pagenumber, search, timefrom, timeto } = dto;
      const response: AuthUserGetPlayersRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthUserGetPlayersRoute.ResponseType, AuthUserGetPlayersRoute.RequestType>({
          method: 'auth_getPlayers',
          data: {
            order,
            search,
            timeTo: timeto,
            orderBy: orderby,
            timeFrom: timefrom,
            pageNumber: pagenumber,
            amountPerPage: amountperpage,
          },
        });

      this.users.data = [];

      if (response.data) {
        this.users = response;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getPlayersCSV(dto: RequestGetUserDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { timefrom, timeto } = dto;
      const response: AuthUserGetPlayersRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthUserGetPlayersRoute.ResponseType>({
          method: 'auth_getPlayers',
          data: {
            timeTo: timeto,
            timeFrom: timefrom,
          },
        });

      this.usersCSV.data = response.data.map((item) => {
        return {
          ...item,
          appsflyerId: item.appsflyerId || '',
          status: getUserStatus(+item.status),
          permissionLevel: getRoleByPermission(+item.permissionLevel),
          lastActiveDate:  moment(+item.createDate).format(
            'MM/DD/YYYY, HH:mm:ss',
          ),
          createDate: moment(+item.createDate).format(
            'MM/DD/YYYY, HH:mm:ss',
          ),
          isAdDisabled: (item as any).isAdDisabled  ? 'Enabled' : 'Disabled',
          versions: VersionInfoAndroid(item.versions),
        } as any;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getBannedPlayers(dto: RequestGetUserDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: AuthUserGetBannedPlayersRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthUserGetBannedPlayersRoute.ResponseType, AuthUserGetBannedPlayersRoute.RequestType>({
          method: 'auth_getBannedPlayers',
          data: {
            order: dto.order,
            timeTo: dto.timeto,
            search: dto.search,
            orderBy: dto.orderby,
            timeFrom: dto.timefrom,
            pageNumber: dto.pagenumber,
            amountPerPage: dto.amountperpage,
          },
        });

      this.bannedUsers.data = [];

      if (response.data) {
        this.bannedUsers = response;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updatePlayerStatus(dto: Omit<AuthUserBanRoute.RequestType, 'accessToken'>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<AuthUserBanRoute.ResponseType, AuthUserBanRoute.RequestType>({
          method: 'auth_userBan',
          data: { ...dto },
        });

      if (dto.userStatus === 1) {
        this.bannedUsers.data = this.bannedUsers.data.filter(
          ({ id }) => id !== dto.userId,
        );
      }

      if (dto.userStatus === 2) {
        this.users.data = this.users.data.filter(
          ({ id }) => id !== dto.userId,
        );
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getPlayersInfoList(dto: Partial<AuthUserGetUsersInfoRoute.RequestType>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: AuthUserGetUsersInfoRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthUserGetUsersInfoRoute.ResponseType, AuthUserGetUsersInfoRoute.RequestType>({
          method: 'auth_getUsersInfo',
          data: { userIds: dto.userIds || [] },
        });

      this.usersInfo.info = [];

      if (response.info) {
        this.usersInfo = response;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *searchPlayers(dto: Partial<AuthUserGetPlayersIdNamesRoute.RequestType>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: AuthUserGetPlayersIdNamesRoute.ResponseType = yield WebSocketService
        .sendRequest<AuthUserGetPlayersIdNamesRoute.ResponseType, Partial<AuthUserGetPlayersIdNamesRoute.RequestType>>({
          method: 'auth_getPlayersIdNames',
          data: { ...dto },
        });

      this.searchResult.players = [];

      if (response.players) {
        this.searchResult = response;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *resetPlayer(dto: Omit<AuthUserResetRoute.RequestType, 'accessToken'>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<AuthUserResetRoute.ResponseType, AuthUserResetRoute.RequestType>({
          method: 'auth_userReset',
          data: { ...dto },
        });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateUserBalance(dto: RequestSetBalanceDto.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService.sendRequest<BalanceBalanceSetRoute.ResponseType>({
        method: 'balance_balanceSet',
        data: {
          userId: dto.userid,
          amount: dto.amount,
          currency: dto.currency,
        },
      });

      this.users.data = this.users.data.map((item) => {
        if (item.id === dto.userid) {
          return {
            ...item,
            balanceboints: dto.amount,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
