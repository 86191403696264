export const notifications = {
    getTemplates: {
      microserviceName: 'notifications',
      serviceName: 'AdminService',
      method: 'getNotificationsTempaltes',
    },
    updateTemplate: {
      microserviceName: 'notifications',
      serviceName: 'AdminService',
      method: 'updateNotificationTemplate',
    },
    toggle: {
      microserviceName: 'notifications',
      serviceName: 'AdminService',
      method: 'toggleNotification',
    },
  }