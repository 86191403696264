import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable } from '../../components/table';
import {
  Profile,
  RequestGetUserDto,
} from '../../proto/auth_pb';
import { CustomDialog } from '../../components/dialogs';
import {
  dateFormat,
  onlyDateFormat,
  onlyTimeFormat,
  VersionInfoAndroid,
  VersionInfoIos,
} from '../../core/utils';
import { getRoleByPermission } from '../../stores';
import { ResetUserButton } from '../../components/misc';
import { ExportUsersModal } from '../../components/modals';
import { RequestSetBalanceDto } from '../../proto/balance_pb';
import { Box, Button, Tooltip } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { getCustomTableStyle } from './user.styles';
import { AuthUserBanRoute, AuthUserResetRoute } from '@boints/grpc';

const UserStatus: Record<number, string> = {
  1: 'Active',
  2: 'Banned',
  3: 'Merged',
  4: 'Reset',
};

const getUserStatus = (status: number): string => {
  return UserStatus[status];
};

const userDeviceId = (user: any): React.ReactNode => {
  return (
    <Tooltip title={user?.deviceId?.length > 0 ? user.deviceId : "-"}>
      <div>{user?.deviceId?.length > 0 ? user.deviceId : "-"}</div>
    </Tooltip>
  );
};

export const Users = observer(() => {
  const { userStore } = useStores();
  const [selectedUser, selectUser] = useState<Profile.AsObject | null>(null);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const loadPlayers = (dto: RequestGetUserDto.AsObject) =>
    userStore.getPlayers(dto);

  const userUpdateStatus = (dto: Omit<AuthUserBanRoute.RequestType, 'accessToken'>) =>
    userStore.updatePlayerStatus(dto);

  const userReset = (dto: Omit<AuthUserResetRoute.RequestType, 'accessToken'>) =>
    userStore.resetPlayer(dto);

  const updateUserBalance = (dto: RequestSetBalanceDto.AsObject) =>
    userStore.updateUserBalance(dto);

  const classes = getCustomTableStyle();

  const columns: Column[] = [
    {
      id: 'id',
      label: 'ID',
      sortKey: 'user.id',
      minWidth: 70,
      component: (user) => (
        <Box className={classes.userIdContainer}>
          <Box>
            <Button
              onClick={() => {
                selectUser(user);
                setOpen(true);
              }}
              className={classes.optionBtn}
              variant='text'
              color='inherit'
            >
              {user.id}
              <Launch style={{ fontSize: '0.9rem' }} />
            </Button>
          </Box>
        </Box>
      ),
    },
    {
      id: 'permissionLevel',
      label: 'Permission Level',
      sortKey: 'user.permissionLevel',
      minWidth: 100,
      format: getRoleByPermission,
    },
    { id: 'name', label: 'Login', sortKey: 'user.name', minWidth: 150 },
    {
      id: 'email',
      label: 'Email',
      sortKey: 'user.email',
      minWidth: 180,
      format: (item: string) => (item?.length > 0 ? item : '-'),
    },
    {
      id: 'facebookId',
      label: 'Facebook ID',
      sortKey: 'user.facebookId',
      minWidth: 170,
      format: (item: string) => (item?.length > 0 ? item : '-'),
    },
    {
      id: 'deviceId',
      label: 'Device Id',
      sortKey: 'user.deviceId',
      minWidth: 170,
      maxWidth: 300,
      component: userDeviceId,
    },
    {
      id: 'balanceBoints',
      label: 'Boints',
      disableSort: true,
      minWidth: 90,
    },
    {
      id: 'status',
      label: 'Status',
      sortKey: 'user.status',
      minWidth: 85,
      format: getUserStatus,
    },
    {
      id: 'lastActiveDate',
      label: 'Last Active Date Time',
      sortKey: 'user.lastActiveDate',
      minWidth: 140,
      format: dateFormat,
    },
    {
      id: 'lastActiveDate',
      label: 'Last Active Date',
      sortKey: 'user.lastActiveDate',
      minWidth: 130,
      format: onlyDateFormat,
    },
    {
      id: 'lastActiveDate',
      label: 'Last Active Time',
      sortKey: 'user.lastActiveDate',
      minWidth: 130,
      format: onlyTimeFormat,
    },
    {
      id: 'createDate',
      label: 'Install Date Time',
      sortKey: 'user.createDate',
      minWidth: 130,
      format: dateFormat,
    },
    {
      id: 'createDate',
      label: 'Install Date',
      sortKey: 'user.createDate',
      minWidth: 100,
      format: onlyDateFormat,
    },
    {
      id: 'createDate',
      label: 'Install Time',
      sortKey: 'user.createDate',
      minWidth: 100,
      format: onlyTimeFormat,
    },
    {
      id: 'isAdDisabled',
      label: 'Ad disabled',
      sortKey: 'user.isAdDisabled',
      minWidth: 100,
      format: (item: boolean) => (item ? 'Enabled' : 'Disabled'),
    },
    {
      id: 'versions',
      label: 'Version Android',
      sortKey: 'versions.currentVersion',
      minWidth: 100,
      format: VersionInfoAndroid,
    },
    {
      id: 'versions',
      label: 'Version iOS',
      sortKey: 'versions.currentVersion',
      minWidth: 100,
      format: VersionInfoIos,
    },
  ];

  const Actions = () => {
    return (
      <>
        <ResetUserButton
          userResetAction={userReset}
          refreshAction={setRefreshPage}
        />

        <ExportUsersModal />
      </>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomTable
        data={userStore.users.data}
        totalCount={userStore.users.totalCount}
        requestAction={loadPlayers}
        columns={columns}
        defaultSortKey="user.createDate"
        isSearchShow
        isDataSliced
        searchInputText="Type ID, Login, FacebookId or DeviceId here"
        pageAction={Actions}
        isRefreshPage={refreshPage}
        refreshResetFn={setRefreshPage}
      />
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        user={selectedUser}
        userReset={userReset}
        updateStatusAction={userUpdateStatus}
        updateBalance={updateUserBalance}
        refreshResetFn={setRefreshPage}
      />
    </>
  );
});
