// source: src/proto/custom/stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.stats.BalanceChanged', null, global);
goog.exportSymbol('proto.stats.BalanceChangedAdditionalParams', null, global);
goog.exportSymbol('proto.stats.Earned', null, global);
goog.exportSymbol('proto.stats.OfferwallData', null, global);
goog.exportSymbol('proto.stats.OfferwallPerProviderData', null, global);
goog.exportSymbol('proto.stats.StatsAdded', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.BalanceChangedAdditionalParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.BalanceChangedAdditionalParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.BalanceChangedAdditionalParams.displayName = 'proto.stats.BalanceChangedAdditionalParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.BalanceChanged = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.BalanceChanged, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.BalanceChanged.displayName = 'proto.stats.BalanceChanged';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.Earned = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.Earned, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.Earned.displayName = 'proto.stats.Earned';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.OfferwallData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.OfferwallData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.OfferwallData.displayName = 'proto.stats.OfferwallData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.OfferwallPerProviderData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.OfferwallPerProviderData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.OfferwallPerProviderData.displayName = 'proto.stats.OfferwallPerProviderData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stats.StatsAdded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stats.StatsAdded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stats.StatsAdded.displayName = 'proto.stats.StatsAdded';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.BalanceChangedAdditionalParams.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.BalanceChangedAdditionalParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.BalanceChangedAdditionalParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.BalanceChangedAdditionalParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    appname: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.BalanceChangedAdditionalParams}
 */
proto.stats.BalanceChangedAdditionalParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.BalanceChangedAdditionalParams;
  return proto.stats.BalanceChangedAdditionalParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.BalanceChangedAdditionalParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.BalanceChangedAdditionalParams}
 */
proto.stats.BalanceChangedAdditionalParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.BalanceChangedAdditionalParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.BalanceChangedAdditionalParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.BalanceChangedAdditionalParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.BalanceChangedAdditionalParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string appName = 1;
 * @return {string}
 */
proto.stats.BalanceChangedAdditionalParams.prototype.getAppname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stats.BalanceChangedAdditionalParams} returns this
 */
proto.stats.BalanceChangedAdditionalParams.prototype.setAppname = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.stats.BalanceChangedAdditionalParams} returns this
 */
proto.stats.BalanceChangedAdditionalParams.prototype.clearAppname = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.BalanceChangedAdditionalParams.prototype.hasAppname = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.BalanceChanged.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.BalanceChanged.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.BalanceChanged} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.BalanceChanged.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    txid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    debit: jspb.Message.getFieldWithDefault(msg, 4, 0),
    credit: jspb.Message.getFieldWithDefault(msg, 5, 0),
    balance: jspb.Message.getFieldWithDefault(msg, 6, 0),
    type: jspb.Message.getFieldWithDefault(msg, 7, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createdat: jspb.Message.getFieldWithDefault(msg, 9, 0),
    additionalparams: (f = msg.getAdditionalparams()) && proto.stats.BalanceChangedAdditionalParams.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.BalanceChanged}
 */
proto.stats.BalanceChanged.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.BalanceChanged;
  return proto.stats.BalanceChanged.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.BalanceChanged} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.BalanceChanged}
 */
proto.stats.BalanceChanged.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTransactionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTxid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDebit(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCredit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBalance(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCurrency(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 10:
      var value = new proto.stats.BalanceChangedAdditionalParams;
      reader.readMessage(value,proto.stats.BalanceChangedAdditionalParams.deserializeBinaryFromReader);
      msg.setAdditionalparams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.BalanceChanged.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.BalanceChanged.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.BalanceChanged} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.BalanceChanged.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTxid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDebit();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCredit();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getAdditionalparams();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.stats.BalanceChangedAdditionalParams.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 transactionId = 1;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getTransactionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setTransactionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string txId = 2;
 * @return {string}
 */
proto.stats.BalanceChanged.prototype.getTxid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setTxid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 userId = 3;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 debit = 4;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getDebit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setDebit = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 credit = 5;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getCredit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setCredit = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 balance = 6;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 type = 7;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 currency = 8;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getCurrency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 createdAt = 9;
 * @return {number}
 */
proto.stats.BalanceChanged.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional BalanceChangedAdditionalParams additionalParams = 10;
 * @return {?proto.stats.BalanceChangedAdditionalParams}
 */
proto.stats.BalanceChanged.prototype.getAdditionalparams = function() {
  return /** @type{?proto.stats.BalanceChangedAdditionalParams} */ (
    jspb.Message.getWrapperField(this, proto.stats.BalanceChangedAdditionalParams, 10));
};


/**
 * @param {?proto.stats.BalanceChangedAdditionalParams|undefined} value
 * @return {!proto.stats.BalanceChanged} returns this
*/
proto.stats.BalanceChanged.prototype.setAdditionalparams = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.BalanceChanged} returns this
 */
proto.stats.BalanceChanged.prototype.clearAdditionalparams = function() {
  return this.setAdditionalparams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.BalanceChanged.prototype.hasAdditionalparams = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.Earned.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.Earned.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.Earned} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.Earned.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.Earned}
 */
proto.stats.Earned.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.Earned;
  return proto.stats.Earned.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.Earned} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.Earned}
 */
proto.stats.Earned.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDay(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.Earned.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.Earned.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.Earned} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.Earned.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 day = 1;
 * @return {number}
 */
proto.stats.Earned.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.Earned} returns this
 */
proto.stats.Earned.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 month = 2;
 * @return {number}
 */
proto.stats.Earned.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.Earned} returns this
 */
proto.stats.Earned.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total = 3;
 * @return {number}
 */
proto.stats.Earned.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.Earned} returns this
 */
proto.stats.Earned.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.OfferwallData.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.OfferwallData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.OfferwallData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.OfferwallData.toObject = function(includeInstance, msg) {
  var f, obj = {
    postbackscount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offerwallcount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.OfferwallData}
 */
proto.stats.OfferwallData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.OfferwallData;
  return proto.stats.OfferwallData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.OfferwallData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.OfferwallData}
 */
proto.stats.OfferwallData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPostbackscount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOfferwallcount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.OfferwallData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.OfferwallData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.OfferwallData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.OfferwallData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPostbackscount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOfferwallcount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 postbacksCount = 1;
 * @return {number}
 */
proto.stats.OfferwallData.prototype.getPostbackscount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.OfferwallData} returns this
 */
proto.stats.OfferwallData.prototype.setPostbackscount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 offerWallCount = 2;
 * @return {number}
 */
proto.stats.OfferwallData.prototype.getOfferwallcount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.stats.OfferwallData} returns this
 */
proto.stats.OfferwallData.prototype.setOfferwallcount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string amount = 3;
 * @return {string}
 */
proto.stats.OfferwallData.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stats.OfferwallData} returns this
 */
proto.stats.OfferwallData.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.OfferwallPerProviderData.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.OfferwallPerProviderData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.OfferwallPerProviderData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.OfferwallPerProviderData.toObject = function(includeInstance, msg) {
  var f, obj = {
    dataperproviderMap: (f = msg.getDataperproviderMap()) ? f.toObject(includeInstance, proto.stats.OfferwallData.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.OfferwallPerProviderData}
 */
proto.stats.OfferwallPerProviderData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.OfferwallPerProviderData;
  return proto.stats.OfferwallPerProviderData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.OfferwallPerProviderData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.OfferwallPerProviderData}
 */
proto.stats.OfferwallPerProviderData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getDataperproviderMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.stats.OfferwallData.deserializeBinaryFromReader, "", new proto.stats.OfferwallData());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.OfferwallPerProviderData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.OfferwallPerProviderData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.OfferwallPerProviderData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.OfferwallPerProviderData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDataperproviderMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.stats.OfferwallData.serializeBinaryToWriter);
  }
};


/**
 * map<string, OfferwallData> dataPerProvider = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.stats.OfferwallData>}
 */
proto.stats.OfferwallPerProviderData.prototype.getDataperproviderMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.stats.OfferwallData>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.stats.OfferwallData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.stats.OfferwallPerProviderData} returns this
 */
proto.stats.OfferwallPerProviderData.prototype.clearDataperproviderMap = function() {
  this.getDataperproviderMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stats.StatsAdded.prototype.toObject = function(opt_includeInstance) {
  return proto.stats.StatsAdded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stats.StatsAdded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatsAdded.toObject = function(includeInstance, msg) {
  var f, obj = {
    balancechanged: (f = msg.getBalancechanged()) && proto.stats.BalanceChanged.toObject(includeInstance, f),
    earned: (f = msg.getEarned()) && proto.stats.Earned.toObject(includeInstance, f),
    aggregateddata: (f = msg.getAggregateddata()) && proto.stats.OfferwallData.toObject(includeInstance, f),
    perprovider: (f = msg.getPerprovider()) && proto.stats.OfferwallPerProviderData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stats.StatsAdded}
 */
proto.stats.StatsAdded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stats.StatsAdded;
  return proto.stats.StatsAdded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stats.StatsAdded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stats.StatsAdded}
 */
proto.stats.StatsAdded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stats.BalanceChanged;
      reader.readMessage(value,proto.stats.BalanceChanged.deserializeBinaryFromReader);
      msg.setBalancechanged(value);
      break;
    case 2:
      var value = new proto.stats.Earned;
      reader.readMessage(value,proto.stats.Earned.deserializeBinaryFromReader);
      msg.setEarned(value);
      break;
    case 3:
      var value = new proto.stats.OfferwallData;
      reader.readMessage(value,proto.stats.OfferwallData.deserializeBinaryFromReader);
      msg.setAggregateddata(value);
      break;
    case 4:
      var value = new proto.stats.OfferwallPerProviderData;
      reader.readMessage(value,proto.stats.OfferwallPerProviderData.deserializeBinaryFromReader);
      msg.setPerprovider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stats.StatsAdded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stats.StatsAdded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stats.StatsAdded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stats.StatsAdded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalancechanged();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stats.BalanceChanged.serializeBinaryToWriter
    );
  }
  f = message.getEarned();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.stats.Earned.serializeBinaryToWriter
    );
  }
  f = message.getAggregateddata();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.stats.OfferwallData.serializeBinaryToWriter
    );
  }
  f = message.getPerprovider();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.stats.OfferwallPerProviderData.serializeBinaryToWriter
    );
  }
};


/**
 * optional BalanceChanged balanceChanged = 1;
 * @return {?proto.stats.BalanceChanged}
 */
proto.stats.StatsAdded.prototype.getBalancechanged = function() {
  return /** @type{?proto.stats.BalanceChanged} */ (
    jspb.Message.getWrapperField(this, proto.stats.BalanceChanged, 1));
};


/**
 * @param {?proto.stats.BalanceChanged|undefined} value
 * @return {!proto.stats.StatsAdded} returns this
*/
proto.stats.StatsAdded.prototype.setBalancechanged = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatsAdded} returns this
 */
proto.stats.StatsAdded.prototype.clearBalancechanged = function() {
  return this.setBalancechanged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatsAdded.prototype.hasBalancechanged = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Earned earned = 2;
 * @return {?proto.stats.Earned}
 */
proto.stats.StatsAdded.prototype.getEarned = function() {
  return /** @type{?proto.stats.Earned} */ (
    jspb.Message.getWrapperField(this, proto.stats.Earned, 2));
};


/**
 * @param {?proto.stats.Earned|undefined} value
 * @return {!proto.stats.StatsAdded} returns this
*/
proto.stats.StatsAdded.prototype.setEarned = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatsAdded} returns this
 */
proto.stats.StatsAdded.prototype.clearEarned = function() {
  return this.setEarned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatsAdded.prototype.hasEarned = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OfferwallData aggregatedData = 3;
 * @return {?proto.stats.OfferwallData}
 */
proto.stats.StatsAdded.prototype.getAggregateddata = function() {
  return /** @type{?proto.stats.OfferwallData} */ (
    jspb.Message.getWrapperField(this, proto.stats.OfferwallData, 3));
};


/**
 * @param {?proto.stats.OfferwallData|undefined} value
 * @return {!proto.stats.StatsAdded} returns this
*/
proto.stats.StatsAdded.prototype.setAggregateddata = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatsAdded} returns this
 */
proto.stats.StatsAdded.prototype.clearAggregateddata = function() {
  return this.setAggregateddata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatsAdded.prototype.hasAggregateddata = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OfferwallPerProviderData perProvider = 4;
 * @return {?proto.stats.OfferwallPerProviderData}
 */
proto.stats.StatsAdded.prototype.getPerprovider = function() {
  return /** @type{?proto.stats.OfferwallPerProviderData} */ (
    jspb.Message.getWrapperField(this, proto.stats.OfferwallPerProviderData, 4));
};


/**
 * @param {?proto.stats.OfferwallPerProviderData|undefined} value
 * @return {!proto.stats.StatsAdded} returns this
*/
proto.stats.StatsAdded.prototype.setPerprovider = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stats.StatsAdded} returns this
 */
proto.stats.StatsAdded.prototype.clearPerprovider = function() {
  return this.setPerprovider(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stats.StatsAdded.prototype.hasPerprovider = function() {
  return jspb.Message.getField(this, 4) != null;
};


goog.object.extend(exports, proto.stats);
