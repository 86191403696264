import React, {useEffect, useState} from 'react';
import {Autocomplete, Chip, TextField} from '@mui/material';
import {EditableHOC} from '../editable-hoc';

interface Props {
  value: any[];
  onChange: (value: any[]) => void;
}

export const ArrayCell: React.FC<Props> = ({value, onChange}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <EditableHOC
      onSave={() => {
        onChange(currentValue);
      }}
      onCancel={() => {
        setCurrentValue(value);
      }}
    >
      {(editable) => (
        <Autocomplete
          multiple
          options={[]}
          value={currentValue}
          freeSolo
          disabled={!editable}
          onChange={(e, value) => setCurrentValue(value)}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({index})}
                key={index}
              />
            ))
          }
          renderInput={(params) => <TextField {...params} variant="filled" />}
        />
        // <TextField
        //   value={currentValue}
        //   disabled={!editable}
        //   onChange={(e) => setCurrentValue(e.target.value)}
        // />
      )}
    </EditableHOC>
  );
};
