import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import {Description} from '@mui/icons-material';
import moment from 'moment';
import {getExportTicketsStyle} from './export-tickets.styles';
import {generateCSV} from '../../../core/utils';
import {RANGE_FOR_EXPORT} from '../../../core/constants';
import {useStores} from '../../../stores/app';
import { RequestGetTicketCsvDto } from '../../../stores';

export const ExportTickets = observer(() => {
  const {ticketStore} = useStores();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(``);
  const [state, setState] = useState<any>({
    startdate: new Date(Date.now() - RANGE_FOR_EXPORT).getTime(),
    enddate: new Date().getTime()
  });

  useEffect(() => {
    if (ticketStore.ticketsCSV.dataList.length) {
      const headers: any[] = [];
      Object.keys(ticketStore.ticketsCSV.dataList[0]).forEach((key: any) => {
        headers.push({
          key,
        });
      });
      open &&
        generateCSV(
          headers,
          ticketStore.ticketsCSV.dataList,
          'tickets-' + moment().format('YYYY-MM-DD')
        );
      handleClose();
    }
  }, [ticketStore.ticketsCSV.dataList]);

  const classes = getExportTicketsStyle();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (new Date(e.target.value).getTime() > new Date().getTime()) {
      setErrorMessage(`${e.target.name} can't be bigger than now.`);
    } else {
      setErrorMessage(``);
      setState({ ...state, [e.target.name]: new Date(e.target.value).getTime() })
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setState({
      ...state,
      startdate: new Date(Date.now() - RANGE_FOR_EXPORT).getTime(),
      enddate: new Date().getTime(),
    });
  };

  const handleClose = () => {
    setOpen(false);
    setState({
      ...state,
      startdate: new Date(Date.now() - RANGE_FOR_EXPORT).getTime(),
      enddate: new Date().getTime(),
    });
  };

  const onSubmit = (values: RequestGetTicketCsvDto) => {
    ticketStore.getTicketsCSV(values);
    setState({
      ...state,
      startdate: new Date(Date.now() - RANGE_FOR_EXPORT).getTime(),
      enddate: new Date().getTime(),
    });
  };

  return (
    <Box>
      <Button onClick={handleClickOpen} variant="contained" color="primary">
        <Description /> Export to CSV
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.root,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>
                Set range for export
              </Typography>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  type="datetime-local"
                  onChange={handleChange}
                  defaultValue={moment(new Date(Date.now() - RANGE_FOR_EXPORT)).format('YYYY-MM-DD[T]HH:mm')}
                  name="startdate"
                  label={'Start date'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Box className={classes.textField}>
                <TextField
                  className={classes.input}
                  type="datetime-local"
                  onChange={handleChange}
                  defaultValue={moment().format('YYYY-MM-DD[T]HH:mm')}
                  name="enddate"
                  label={'End date'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Typography className={classes.errorTitle}>
                {errorMessage}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit({startDate: state.startdate, endDate: state.enddate, accesstoken: ''})} color="primary" autoFocus disabled={!!errorMessage}>
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
});