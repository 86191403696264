import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import { LevelsOrder } from '../../pages/hrs/levels-order-dialog';
import {
  OfferwallAdminAddGameRoute,
  OfferwallAdminAddOfferwallRoute,
  OfferwallAdminAddRewardRoute,
  OfferwallAdminGetDailyRewardsRoute,
  OfferwallAdminGetOfferwallsRoute,
  OfferwallAdminGetRewardsRoute,
  OfferwallAdminImportCsvRoute,
  OfferwallAdminUpdateDailyRewardsRoute,
  OfferwallAdminUpdateGameRoute,
  OfferwallAdminUpdateRewardRoute,
  OfferwallAdminDeleteGameRoute,
  OfferwallAdminUpdateGamesOrderRoute,
  OfferwallAdminGetGeneralOffersChangeStatusRoute,
  OfferwallAdminGetOffersChangeStatusRoute,
  OfferwallAdminGetChangeStatusListByCampaignIdRoute,
  OfferwallAdminToggleFavoriteGameRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';
import { GetOffersChangeStatusResponse, 
  GetOffersChangeStatusResponse_OffersChangeStatusPerOfferwall } from '@boints/grpc/lib/proto-interfaces/offerwall';

export class HrsStore {
  public error: RpcError | null = null;

  rewardList: OfferwallAdminGetRewardsRoute.ResponseType['rewards'] = [];
  levelsOrder: LevelsOrder[] = [];

  dailyRewards: OfferwallAdminGetDailyRewardsRoute.ResponseType | null = null;

  allBundles: OfferwallAdminGetOfferwallsRoute.ResponseType['offerwalls'] = [];

  offerwallStatuses: GetOffersChangeStatusResponse_OffersChangeStatusPerOfferwall[] = [];

  hrsAllStatus: string = 'admitted';

  rewardErrors: {[key: string]: {[column: string]: string}} = {};
  rewardWarnings: string[] = [];


  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getChangeStatusListByCampaignId(campaignId: string) {

    try {
      const response: OfferwallAdminGetChangeStatusListByCampaignIdRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminGetChangeStatusListByCampaignIdRoute.ResponseType, OfferwallAdminGetChangeStatusListByCampaignIdRoute.RequestType>({
          method: 'offerwall_getChangeStatusListByCampaignId',
          data: {
            campaignId,
          },
        });


        this.rewardWarnings = [];
        response.minor.map(item => {
          this.rewardWarnings.push(item.status);
        });
    
        this.rewardErrors = {};
        response.major.map(item => {
          if (item.eventKey) this.rewardErrors[item.eventKey] = {...this.rewardErrors[item.eventKey], [item.status]: item.prevValue || 'null'};
        });
        this.rewardErrors = {...this.rewardErrors, helloWorld: 'hello'} as any;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }
  }

  *getHrsStatus() {
    try {
      const response: OfferwallAdminGetGeneralOffersChangeStatusRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminGetGeneralOffersChangeStatusRoute.ResponseType, OfferwallAdminGetGeneralOffersChangeStatusRoute.RequestType>({
          method: 'offerwall_getHrsStatus',
          data: {},
        });

        this.hrsAllStatus = response.status;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }
  }

  *getOfferwallsStatus() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: OfferwallAdminGetOffersChangeStatusRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminGetOffersChangeStatusRoute.ResponseType, OfferwallAdminGetOffersChangeStatusRoute.RequestType>({
          method: 'offerwall_getOffersChangeStatus',
          data: {},
        });

        this.offerwallStatuses = response.offersChangeStatusPerOfferwall;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getBundles() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { offerwalls }: OfferwallAdminGetOfferwallsRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminGetOfferwallsRoute.ResponseType, OfferwallAdminGetOfferwallsRoute.RequestType>({
          method: 'offerwall_getOfferwalls',
          data: {},
        });

      this.allBundles = [];
      if (offerwalls) {
        this.allBundles = offerwalls;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getRewards(dto: OfferwallAdminGetRewardsRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;
    this.rewardList = [];

    try {
      const response: OfferwallAdminGetRewardsRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminGetRewardsRoute.ResponseType, OfferwallAdminGetRewardsRoute.RequestType>({
          method: 'offerwall_getRewards',
          data: { ...dto },
        });

      if (!response.rewards) {
        this.rewardList = [];
      } else {
        this.rewardList = response.rewards;
      }

      this.levelsOrder = this.allBundles
        .find((v) => v.offerwallId === dto.offerwallProviderId)?.games
        .find((v) => v.campaignId === dto.campaignId)?.levelsOrder
        .map((key) => ({ key, alias: this.rewardList.find((v) => v.key === key && v.type === 'level')?.alias || key })) || [];
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *addReward(dto: OfferwallAdminAddRewardRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: OfferwallAdminAddRewardRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminAddRewardRoute.ResponseType, OfferwallAdminAddRewardRoute.RequestType>({
          method: 'offerwall_addReward',
          data: { ...dto, rewardAmount: +dto.rewardAmount },
        });

      this.rewardList.push({ ...dto, ...{ id: response.id } });

      if (dto.type === 'level') {
        this.allBundles
          .find((v) => v.offerwallId === dto.offerwallProviderId)?.games
          .find((v) => v.campaignId === dto.campaignId)?.levelsOrder
          .push(dto.key);

        this.levelsOrder = this.allBundles
          .find((v) => v.offerwallId === dto.offerwallProviderId)?.games
          .find((v) => v.campaignId === dto.campaignId)?.levelsOrder
          .map((key) => ({ key, alias: this.rewardList.find((v) => v.key === key && v.type === 'level')?.alias || key })) || [];
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateReward(dto: OfferwallAdminUpdateRewardRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<OfferwallAdminUpdateRewardRoute.ResponseType, OfferwallAdminUpdateRewardRoute.RequestType>({
          method: 'offerwall_updateReward',
          data: { ...dto, rewardAmount: +dto.rewardAmount },
        });

      this.rewardList = this.rewardList.map(item => {
        if (item.id === dto.id) {
          this.levelsOrder.forEach((v) => {
            if (v.key === item.key) {
              v.key = dto.key;
              v.alias = dto.alias;
            }
          });

          return dto;
        } else return item;
      });

      yield this.getBundles();
      
      this.levelsOrder = this.allBundles
        .find((v) => v.offerwallId === dto.offerwallProviderId)?.games
        .find((v) => v.campaignId === dto.campaignId)?.levelsOrder
        .map((key) => ({ key, alias: this.rewardList.find((v) => v.key === key && v.type === 'level')?.alias || key })) || [];
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *addOfferwall(dto: OfferwallAdminAddOfferwallRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { id: newItemId, secret }: OfferwallAdminAddOfferwallRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminAddOfferwallRoute.ResponseType, OfferwallAdminAddOfferwallRoute.RequestType>({
          method: 'offerwall_addOfferwall',
          data: { ...dto },
        });

      this.allBundles.push({ id: newItemId, offerwallId: dto.offerwallId, secret, games: [] });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *addBundle(dto: OfferwallAdminAddGameRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    dto.campaignId = crypto.randomUUID();

    const { bundleId, deepLinkTemplate, name, offerwallId, description, campaignId } = dto;

    try {
      const { id, picture }: OfferwallAdminAddGameRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminAddGameRoute.ResponseType, OfferwallAdminAddGameRoute.RequestType>({
          method: 'offerwall_addGame',
          data: { ...dto },
        });

      this.allBundles.find(offer => offer.id === offerwallId)?.games
        .push({
          id,
          name,
          picture,
          bundleId,
          description,
          campaignId,
          levelsOrder: [],
          deepLinkTemplate,
          enabledForApp: true,
          enabledForServer: true,
          changeStatus: 'admitted',
          zIndex: 0,
          favorite: false,
          isNewPlayTimeRewards: false,
        });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateBundle(dto: OfferwallAdminUpdateGameRoute.RequestType, offerwallId: string) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { picture }: OfferwallAdminUpdateGameRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminUpdateGameRoute.ResponseType, Partial<OfferwallAdminUpdateGameRoute.RequestType>>({
          method: 'offerwall_updateGame',
          data: { ...dto },
        });

      this.allBundles.map(offer => {
        if (offer.offerwallId === offerwallId) {
          offer.games = offer.games.map(game => game.id === dto.id ? { ...game, ...{ picture }, ...dto } : game);
        }
        return offer;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getDailyRewards(dto: OfferwallAdminGetDailyRewardsRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: OfferwallAdminGetDailyRewardsRoute.ResponseType = yield WebSocketService
        .sendRequest<OfferwallAdminGetDailyRewardsRoute.ResponseType, OfferwallAdminGetDailyRewardsRoute.RequestType>({
          method: 'offerwall_getDailyRewards',
          data: { ...dto },
        });

      if (!response.rewards) {
        this.dailyRewards = null;
      } else {
        this.dailyRewards = {
          repeatCycle: response.repeatCycle,
          rewards: response.rewards,
        };
      }

    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateDailyRewards(dto: OfferwallAdminUpdateDailyRewardsRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<OfferwallAdminUpdateDailyRewardsRoute.ResponseType, OfferwallAdminUpdateDailyRewardsRoute.RequestType>({
          method: 'offerwall_updateDailyRewards',
          data: { ...dto },
        });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *importCSV(dto: OfferwallAdminImportCsvRoute.RequestType, campaignId: string, offerwall: string) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const rewards: OfferwallAdminImportCsvRoute.RequestType['rewards'] = dto.rewards.map((reward) => {
        return { ...reward, id: +reward.id, rewardAmount: +reward.rewardAmount };
      });

      yield WebSocketService
        .sendRequest<OfferwallAdminImportCsvRoute.ResponseType, OfferwallAdminImportCsvRoute.RequestType>({
          method: 'offerwall_importCsv',
          data: {
            rewards,
            campaignId,
            offerwallProviderId: offerwall,
          },
        });

      this.getRewards({
        campaignId,
        offerwallProviderId: offerwall,
        accessToken: '',
      });

      this.rootStore.notificationStore.enqueueSnackBar({
        message: "File successfully uploaded.",
        variant: 'success',
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *deleteGame(dto: OfferwallAdminDeleteGameRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<OfferwallAdminDeleteGameRoute.ResponseType, OfferwallAdminDeleteGameRoute.RequestType>({
          method: 'offerwall_deleteGame',
          data: {
            id: dto.id,
          },
        });
      
      this.allBundles.map(offerwall => {
        offerwall.games = offerwall.games.filter(game => game.id !== dto.id);
        return offerwall;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateGamesOrder(dto: OfferwallAdminUpdateGamesOrderRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<OfferwallAdminUpdateGamesOrderRoute.ResponseType, OfferwallAdminUpdateGamesOrderRoute.RequestType>({
          method: 'offerwall_updateGamesOrder',
          data: { ...dto },
        });

      yield this.getBundles();
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *toggleFavoriteGame(dto: OfferwallAdminToggleFavoriteGameRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<OfferwallAdminToggleFavoriteGameRoute.ResponseType, OfferwallAdminToggleFavoriteGameRoute.RequestType>({
          method: 'offerwall_toggleFavoriteGame',
          data: { ...dto },
        });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
