import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getCreateEditModalStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            margin: '10px',
        },
        input: {
            marginTop: '10px !important',
        }
    })
);
