import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Column,
  CustomTable,
  SwitchCell,
  TimeCell,
  leftPad,
} from '../../components/table';
import { useStores } from '../../stores/app';
import { DoubleBoints as DoubleBointsType, UpdateDoubleBoints } from '../../proto/doubleBoints_pb';
import { Box } from '@mui/material';

export const DayMapper: {[key: number]: string} = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

const day = (val: number): string => {
  return DayMapper[val] || 'unknown';
};

export const DoubleBoints = observer(() => {
  const { doubleBointsStore } = useStores();
  const [data, setData] = React.useState<DoubleBointsType.AsObject[]>([]);

  const loadDoubleBointsSchedule = () => doubleBointsStore.getDoubleBoints();

  const updateDoubleBoints = (dto: UpdateDoubleBoints.AsObject) =>
    doubleBointsStore.updateDoubleBoints(dto);

  const columns: Column[] = [
    { id: 'day', label: 'Day', minWidth: 100, format: day },
    {
      id: 'enabled',
      label: 'Double on/off',
      minWidth: 170,
      component: (props: any) => (
        <Box sx={{ mx: 'auto' }}>
          <SwitchCell
            value={props.enabled}
            onChange={(enabled) => updateDoubleBoints({ ...props, enabled })}
          />
        </Box>
      ),
    },
    {
      id: 'hourbegin',
      label: 'Start time',
      minWidth: 170,
      component: (props: any) => (
        <TimeCell
          value={`${leftPad(props.hourbegin)}:${leftPad(props.minutesbegin)}`}
          onChange={(time) => {
            const [hourbegin, minutesbegin] = time.split(':').map(Number);

            updateDoubleBoints({ ...props, hourbegin, minutesbegin });
          }}
        />
      ),
    },
    {
      id: 'hourend',
      label: 'End time',
      minWidth: 170,
      component: (props: any) => (
        <TimeCell
          value={`${leftPad(props.hourend)}:${leftPad(props.minutesend)}`}
          onChange={(time) => {
            const [hourend, minutesend] = time.split(':').map(Number);

            updateDoubleBoints({ ...props, hourend, minutesend });
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    setData(doubleBointsStore.doubleBoints.entitiesList.sort((a, b) => a.day - b.day));
  }, [doubleBointsStore.doubleBoints.entitiesList]);

  return (
    <CustomTable
      columns={columns}
      data={data}
      totalCount={doubleBointsStore.doubleBoints.totalcount}
      isSortEnabled={false}
      requestAction={loadDoubleBointsSchedule}
      isPaginationShow={false}
    />
  );
});
