import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { CustomTable } from '../../components/table';
import { Box, Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDeleteDialog } from '../../components/dialogs';
import { getCustomTableStyle } from './motivation-currency.styles';
import { McEditStage } from '../../components/dialogs/mc-stage-edit';
import { RewardStagePattern, RewardStagePatternUpdateRequest } from '../../proto/reward_pb';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';

export const MotivationalCurrency = observer(() => {
  const { motivatianlCurrencyStore, settingsStore } = useStores();
  const classes = getCustomTableStyle();

  const enableSettingsName = "MOTIVATIONAL_CURRENCY_ENABLE";

  const patternRender = (item) => {
    return (
      <div>
        {item.patternList.map(eachItem => (
          <p className={classes.patternItem}>
            {`${eachItem.value ? eachItem.value : ''} ${eachItem.trigger} = ${eachItem.reward / 100}$`}
          </p>
        ))}
      </div>
    );
  };
  
  const fetchData = () => {
    settingsStore.getSettingsByMicroservices({ accesstoken: '', microservicesList: ["orchestrator", "balance"] });
    motivatianlCurrencyStore.getAll();
  };

  const deleteItem = (idToDelete: number) => {
    motivatianlCurrencyStore.delete({ id: idToDelete, accesstoken: '' });
  };

  const updateItem = (item: RewardStagePatternUpdateRequest.AsObject) => {
    motivatianlCurrencyStore.update(item);
  };

  const createItem = (item: RewardStagePatternUpdateRequest.AsObject) => {
    motivatianlCurrencyStore.create(item);
  };

  const mcActionButtons = (props) => {
    return (
      <>
        <McEditStage
          updatableObject={props}
          updateItemAction={updateItem}/>
        <CustomDeleteDialog
          labelForDelete={`Are you sure you want delete Motivational Currency Stage `}
          objectId={props.id}
          deleteItemAction={deleteItem}
          disabled
        />
      </>
    );
  };

  const columns = [
    {
      id: 'index',
      label: 'Index',
    },
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'patternList',
      label: 'Pattern',
      minWidth: 100,
      component: patternRender,
    },
    {
      id: 'offerwall',
      label: 'Offerwall',
    },
    {
      id: 'nextstageconditions',
      label: 'Next Stage',
    },
    {
      id: 'randomizereward',
      label: 'Random Reward',
      component: (props) => props.randomizereward ? <CheckIcon/> : <CloseIcon/>,
    },
    {
      id: 'actionButton',
      label: '',
      component: mcActionButtons,
      labelComponent: (data) => <McEditStage updatableObject={new RewardStagePattern().toObject()} updateItemAction={createItem} isCreate disabled/>,
    },
  ];

  // const tableActions = () => ()

  return (
    <>
      <Box style={{ margin: '5px' }}>
        MC Enable:
        <Checkbox style={{ margin: '0px' }}
          checked={settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName)?.value === 'true'}
          color="primary"
          onChange={(val) => {
            const sett = settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName);
            console.log(settingsStore.featureToggleSettings.settingsList);
            if (sett) settingsStore.updateSettingsByMicroservice({ id: sett.id, value: String(!(sett.value === 'true')), key: sett.key } as RequestUpdateSettingsDto.AsObject);
          }}
        />
      </Box>
      <CustomTable
        data={motivatianlCurrencyStore.stagePatterns.itemsList}
        requestAction={fetchData}
        columns={columns}
        isPaginationShow={false}
        isSortEnabled={false}
      />
    </>

  );
});
