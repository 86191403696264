export interface FormsValidatorFields {
  error: string,
  isError: boolean,
  validate: (value: string) => string,
}

export class FormsValidator {
  fields: { [key: string]: FormsValidatorFields };

  constructor(data: {[fieldName:string]: (fieldValue: string) => string}) {
    this.fields = {};
    Object.keys(data).forEach(item => {
      this.fields[item] = {
        error: '',
        isError: false,
        validate: data[item],
      };
    })
  }

  public validateField(fieldName, fieldValue) {
    if (!this.fields[fieldName]) return;
    const error = this.fields[fieldName].validate(fieldValue); 
    this.fields[fieldName].isError = !!error;
    this.fields[fieldName].error = error;
  }

  public getError(fieldName: string): string {
    if (!this.fields[fieldName]) return "";
    return this.fields[fieldName].error;
  }

  public isFormHaveError(): boolean {
    let isError = false;
    Object.keys(this.fields).forEach(item => {
      if (this.fields[item].isError) {
        isError = true;
      }
    })
    return isError;
  }
}
