import { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable, TextCell, ArrayCell } from '../../components/table';
import { MenuItem, Select, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { getSettingsStyle } from './settings.styles';
import {
  RequestUpdateSettingsDto,
} from '../../proto/leaderboard_pb';
import { GetSettingsWithPaginationRequest } from '../../proto/settings_pb';

export enum SettingsDictionary {
  ALL_SERVICES = 'all',
  AUTH = 'auth',
  BALANCE = 'balance',
  // LEADERBOARD = 'leaderboard',
  SCHEDULE = 'schedule',
  OFFERWALL = 'offerwall',
}

export const settingsDictionaryKeys: Record<string, string> = {
  all: 'All Services',
  auth: 'Auth',
  balance: 'Balance',
  // leaderboard: 'Leaderboard',
  schedule:'Schedule',
  offerwall: 'Offerwall',
};

export const Settings = observer(() => {
  const { settingsStore } = useStores();
  const classes = getSettingsStyle();
  const [microservice, setMicroservice] = useState(SettingsDictionary.ALL_SERVICES);

  const loadSettings = (dto: GetSettingsWithPaginationRequest.AsObject) => {
    if (dto.microservice === SettingsDictionary.ALL_SERVICES) dto.microservice = '';
    settingsStore.getSettings(dto);
  };

  const updateSettings = (dto: RequestUpdateSettingsDto.AsObject) =>
    settingsStore.updateSettings(dto);

  const settingsFilter = (resetPagination) => {
    return (
      <Select
        className={classes.selectInput}
        id="select"
        onChange={(e: any) => {
          setMicroservice(e.target.value);
          resetPagination();
        }}
        value={microservice}
        name={`currency`}
      >
        {Object.keys(settingsDictionaryKeys).map((el: any) => (
          <MenuItem value={el} key={Math.random()}>
            {settingsDictionaryKeys[el]}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const infoTip = (props: any) => {
    return (
      <>
        {props.description}
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
          }}
          title={props.key}
        >
          <Info className={classes.help} />
        </Tooltip>
      </>
    );
  };

  const columns: Column[] = [
    {
      id: 'description',
      label: 'Description',
      alignContent: 'start',
      minWidth: 50,
      component: infoTip,
    },
    {
      id: 'value',
      label: 'Value',
      minWidth: 500,
      component: (props: any) => {
        if (props.key === 'MAX_REWARD_AMOUNTS') {
          return (
            <ArrayCell
              value={JSON.parse(props.value)}
              onChange={(value) =>
                updateSettings({
                  ...props,
                  value: JSON.stringify(value.map(Number)),
                })
              }
            />
          );
        } else {
          return (
            <TextCell
              value={props.value}
              onChange={(value) => updateSettings({ ...props, value })}
            />
          );
        }
      },
    },
    {
      id: 'microservice',
      label: 'Service',
      minWidth: 50,
      format: (value) => settingsDictionaryKeys[value],
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={settingsStore.settings.settingsList}
      isDataSliced={true}
      totalCount={settingsStore.settings.totalcount}
      isSortEnabled={false}
      requestAction={loadSettings}
      pageAction={settingsFilter}
      paramsForAction={{ microservice }}
      paramsForActionName="microservice"
    />
  );
});
