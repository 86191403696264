import { makeAutoObservable } from 'mobx';
import {
  SnackbarKey,
  SnackbarMessage,
  TransitionCloseHandler,
  TransitionHandler,
  VariantType,
} from 'notistack';
import { AppStore } from '../app';

export type Notification = {
  key: SnackbarKey;
  message: SnackbarMessage;
  options?: {
    key: SnackbarKey;
    variant: VariantType;
    onClose?: TransitionCloseHandler;
    onExited?: TransitionHandler;
  };
  dismissed?: boolean;
};

type createNotification = {
  key?: number | string;
  message: string;
  variant: VariantType;
};

export class NotificationStore {
  public displayed: SnackbarKey[] = [];
  public notifications: Notification[] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  storeDisplayed(key: SnackbarKey) {
    this.displayed.push(key);
  }

  removeDisplayed(key: SnackbarKey) {
    this.displayed.filter((id) => id !== key);
  }

  enqueueSnackBar(notification: createNotification) {
    const key = notification.key || Date.now();

    this.notifications.push({
      key,
      message: notification.message,
      options: {
        key,
        variant: notification.variant,
      },
    });
  }

  closeSnackBar(key?: SnackbarKey) {
    this.notifications = this.notifications.map((notification) => {
      if (!key || notification.key === key) {
        notification.dismissed = true;
      }

      return notification;
    });
  }

  removeSnackBar(key: SnackbarKey) {
    this.notifications = this.notifications.filter(
      (notification) => notification.key !== key,
    );
  }
}
