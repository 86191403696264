import { RpcError } from "grpc-web";
import { makeAutoObservable } from "mobx";
import {

} from "../../proto/reward_pb";
import { AppStore } from "../app";
import {
  RewardMCStageOrderFindAllRoute,
  RewardMCStageOrderUpdateRoute,
  RewardMCStageOrderDeleteStageorderRoute,
  RewardMCStageOrderGetMcUsersStatsRoute,
  RewardMCStageOrderGetMcUserStatsRoute,
  RewardMCStageOrderFindOneByOrderAndVersionRoute,
} from "@boints/grpc";
import { WebSocketService } from "../../services/transport";
import {
  StageOrderFindAllResponse,
  StageOrder,
  McUserStat,
} from '@boints/grpc/lib/proto-interfaces/reward';

export class MotivatianlCurrencyV2Store {
  public error: RpcError | null = null;

  public stagePatterns: StageOrderFindAllResponse = {
    stageOrders: [],
  };

  public mcUsersStats: RewardMCStageOrderGetMcUsersStatsRoute.ResponseType = {
    totalCount: 0,
    pageNumber: 0,
    amountPerPage: 0,
    mcUsers: [],
  };

  public mcUsersStatsForExport: RewardMCStageOrderGetMcUsersStatsRoute.ResponseType = {
    totalCount: 0,
    pageNumber: 0,
    amountPerPage: 0,
    mcUsers: [],
  };

  public userStats: McUserStat[] = [];

  public stageOrderToView: StageOrder = null as any;

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getUserStats(userId: number) {
    this.rootStore.isLoading = true;
    this.error = null;
    this.userStats = [];

    try {
      const response: RewardMCStageOrderGetMcUserStatsRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderGetMcUserStatsRoute.ResponseType, RewardMCStageOrderGetMcUserStatsRoute.RequestType>({
          method: 'reward_getUserStats',
          data: {
            userId,
          },
        });

      if (response.data) {
        this.userStats = response.data;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getAll() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: RewardMCStageOrderFindAllRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderFindAllRoute.ResponseType, RewardMCStageOrderFindAllRoute.RequestType>({
          method: 'reward_stageOrderFindAll',
          data: {},
        });

      this.stagePatterns.stageOrders = response.stageOrders.map(item => {
        const {id, stageOrder, stageVersion, startingBonus, stages, bointsCashoutAllowed} = item;
        return {
          id,
          active: true,
          stageOrder: stageOrder,
          stageVersion: stageVersion,
          startingBonus: startingBonus,
          bointsCashoutAllowed,
          stages: stages.map(stage => {
            const { offerwall, groups, valueType} = stage;
            return {
              id: Math.random(),
              offerwall,
              valueType,
              groups: groups.map(group => {
                const {description, steps } = group;
                return {
                  id: Math.random(),
                  description,
                  steps: steps.map(step => {
                    const { reward, value, rewardType } = step;
                    return {
                      id: Math.random(),
                      reward,
                      value,
                      rewardType: rewardType,
                    }
                  })
                };
              })
            };
          }),
        };
      }) as any;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *update(data: StageOrder) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: RewardMCStageOrderUpdateRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderUpdateRoute.ResponseType, RewardMCStageOrderUpdateRoute.RequestType>({
          method: 'reward_stageOrderUpdate',
          data: {
            stageOrder: data.stageOrder,
            startingBonus: data.startingBonus,
            bointsCashoutAllowed: data.bointsCashoutAllowed,
            items: data.stages.map(stage => {
              return {
                offerwall: stage.offerwall,
                valueType: stage.valueType,
                groups: stage.groups.map(group => {
                  return {
                    description: group.description,
                    steps: group.steps.map(step => {
                      return {
                        reward: step.reward,
                        value: step.value,
                        rewardType: step.rewardType,
                      }
                    })
                  };
                })
              };
            }) as any,
          },
        });

        const {id, stageOrder, stageVersion, startingBonus, stages} = response;
        const newItem = {
          id,
          active: true,
          stageOrder: stageOrder,
          stageVersion: stageVersion,
          startingBonus: startingBonus,
          stages: stages.map(stage => {
            const { offerwall, groups, valueType} = stage;
            return {
              id: Math.random(),
              offerwall,
              valueType,
              groups: groups.map(group => {
                const {description, steps } = group;
                return {
                  id: Math.random(),
                  description,
                  steps: steps.map(step => {
                    const { reward, value, rewardType } = step;
                    return {
                      id: Math.random(),
                      reward,
                      value,
                      rewardType: rewardType,
                    }
                  })
                };
              })
            };
          })
        };

        let isExistInArray = false;
        this.stagePatterns.stageOrders = this.stagePatterns.stageOrders.map(item => {
          if (item.stageOrder === data.stageOrder) {
            isExistInArray = true;
            return newItem;
          }
          else return item;
        }) as any;

        if (!isExistInArray) {
          this.stagePatterns.stageOrders.push(newItem as any);
        }

    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *delete(stageOrderId: number) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: RewardMCStageOrderDeleteStageorderRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderDeleteStageorderRoute.ResponseType, RewardMCStageOrderDeleteStageorderRoute.RequestType>({
          method: 'reward_stageOrderRemove',
          data: {
            id: stageOrderId,
          },
        });

      if (response) {
        this.stagePatterns.stageOrders = this.stagePatterns.stageOrders.filter(item => item.id !== stageOrderId);
      }

    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }


    this.rootStore.isLoading = false;
  }

  *getUsersStats(dto: RewardMCStageOrderGetMcUsersStatsRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountperpage, order, orderby, pagenumber, search, timefrom, timeto } = dto as any;
      const response: RewardMCStageOrderGetMcUsersStatsRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderGetMcUsersStatsRoute.ResponseType, RewardMCStageOrderGetMcUsersStatsRoute.RequestType>({
          method: 'reward_getUsersStats',
          data: {
            order,
            search,
            timeTo: timeto,
            orderBy: orderby,
            timeFrom: timefrom,
            pageNumber: pagenumber,
            amountPerPage: amountperpage,
          },
        });

      if (response.mcUsers) {
        this.mcUsersStats = response;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getUsersStatsForCsv(dto: RewardMCStageOrderGetMcUsersStatsRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountperpage, order, orderby, pagenumber, search, timefrom, timeto } = dto as any;
      const response: RewardMCStageOrderGetMcUsersStatsRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderGetMcUsersStatsRoute.ResponseType, RewardMCStageOrderGetMcUsersStatsRoute.RequestType>({
          method: 'reward_getUsersStats',
          data: {
            order,
            search,
            timeTo: timeto,
            orderBy: orderby,
            timeFrom: timefrom,
            pageNumber: pagenumber,
            amountPerPage: amountperpage,
          },
        });

      if (response.mcUsers) {
        this.mcUsersStatsForExport = response;
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getOneByOrderAndVersion(order: number, version: number) {
    this.rootStore.isLoading = true;
    this.error = null;
    this.stageOrderToView = null as any;

    try {
      const response: RewardMCStageOrderFindOneByOrderAndVersionRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardMCStageOrderFindOneByOrderAndVersionRoute.ResponseType, RewardMCStageOrderFindOneByOrderAndVersionRoute.RequestType>({
          method: 'reward_findOneByOrderAndVersion',
          data: {stageOrder: order, stageVersion: version},
        });

        const {id, stageOrder, stageVersion, startingBonus, stages, bointsCashoutAllowed} = response;
        this.stageOrderToView = {
            id,
            active: true,
            stageOrder: stageOrder,
            stageVersion: stageVersion,
            startingBonus: startingBonus,
            bointsCashoutAllowed: bointsCashoutAllowed,
            stages: stages.map(stage => {
              const { offerwall, groups, valueType} = stage;
              return {
                id: Math.random(),
                offerwall,
                valueType,
                groups: groups.map(group => {
                  const {description, steps } = group;
                  return {
                    id: Math.random(),
                    description,
                    steps: steps.map(step => {
                      const { reward, value, rewardType } = step;
                      return {
                        id: Math.random(),
                        reward,
                        value,
                        rewardType: rewardType,
                      }
                    })
                  };
                })
              };
            }) as any,
        };
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
