import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  OfferWallSchemaFindAllRequest,
  OfferWallSchemaUpdate,
  OfferWallSchemaCreateRequest,
  OfferWallRewardFindAllRequest,
  OfferWallSchemaEntity,
  OfferWallRewardEntity,
} from '../../proto/reward_pb';
import {
  RewardOfferWallSchemasFindAllRoute,
  RewardOfferWallSchemasCreateRoute,
  RewardOfferWallSchemasUpdateRoute,
  RewardSendRewardsWorkerIsWorkerEnabledRoute,
  RewardSendRewardsWorkerEnableWorkerRoute,
  RewardSendRewardsWorkerDisableWorkerRoute,
  RewardOfferWallRewardsFindAllRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services';

export class RewardStore {
  public error: RpcError | null = null;

  public sendRewardWorkerEnbled = true;

  public rewardSchemas: OfferWallSchemaEntity.AsObject[] = [];

  public rewards: OfferWallRewardEntity.AsObject[]  = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getRewardSchemes(dto: Partial<OfferWallSchemaFindAllRequest.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { items }: RewardOfferWallSchemasFindAllRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardOfferWallSchemasFindAllRoute.ResponseType, RewardOfferWallSchemasFindAllRoute.RequestType>({
          method: 'reward_offerwallSchemasFindAll',
          data: {
            active: dto.active,
          },
        });

      this.rewardSchemas = [];

      if (items) {
        this.rewardSchemas = items.map((item) => {
          return {
            active: item.active,
            id: item.id,
            identifier: item.identifier,
            name: item.name,
            schema: {
              containsmorethancondition: item.schema?.containsMoreThanCondition || false,
              rewardtypesList: !item.schema?.rewardTypes ? [] : item.schema?.rewardTypes.map((type) => {
                return {
                  conditionsList: !type.conditions ? [] : type.conditions.map((condition) => {
                    return {
                      conditiontype: condition.conditionType,
                      conditionvalue: condition.conditionValue,
                      conditionfieldpath: condition.conditionFieldPath,
                    };
                  }),
                  rewardpayouttype: type.rewardPayoutType,
                  rewardpayoutvalue: type.rewardPayoutValue,
                  type: type.type,
                  rewardpayoutfieldpath: type.rewardPayoutFieldPath || '',
                };
              }),
            },
          };
        });
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *createRewardScheme(dto: Partial<OfferWallSchemaCreateRequest.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { item }: RewardOfferWallSchemasCreateRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardOfferWallSchemasCreateRoute.ResponseType>({
          method: 'reward_offerwallSchemasCreate',
          data: {
            name: dto.name || '',
            active: !!dto.active,
            data: dto.data && {
              rewardTypes: dto.data?.rewardtypesList.map((type) => {
                return {
                  conditions: type.conditionsList.map(({ conditionfieldpath, conditiontype, conditionvalue }) => {
                    return {
                      conditionType: conditiontype,
                      conditionValue: conditionvalue,
                      conditionFieldPath: conditionfieldpath,
                    };
                  }),
                  rewardPayoutType: type.rewardpayouttype,
                  rewardPayoutValue: type.rewardpayoutvalue,
                  type: type.type,
                  rewardPayoutFieldPath: type.rewardpayoutfieldpath || '',
                };
              }),
            },
          },
        });

      if (item) {
        this.rewardSchemas.push({
          active: item.active,
          id: item.id,
          identifier: item.identifier,
          name: item.name,
          schema: {
            containsmorethancondition: item.schema?.containsMoreThanCondition || false,
            rewardtypesList: !item.schema?.rewardTypes ? [] : item.schema?.rewardTypes.map((type) => {
              return {
                conditionsList: !type.conditions ? [] : type.conditions.map((condition) => {
                  return {
                    conditiontype: condition.conditionType,
                    conditionvalue: condition.conditionValue,
                    conditionfieldpath: condition.conditionFieldPath,
                  };
                }),
                rewardpayouttype: type.rewardPayoutType,
                rewardpayoutvalue: type.rewardPayoutValue,
                type: type.type,
                rewardpayoutfieldpath: type.rewardPayoutFieldPath || '',
              };
            }),
          },
        });
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateRewardScheme(dto: Partial<OfferWallSchemaUpdate.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const mappedData = {
        id: dto.id,
        name: dto.name || '',
        active: !!dto.active,
        data: dto.data && {
          rewardTypes: dto.data?.rewardtypesList.map((type) => {
            return {
              conditions: type.conditionsList.map(({ conditionfieldpath, conditiontype, conditionvalue }) => {
                return {
                  conditionType: conditiontype,
                  conditionValue: conditionvalue,
                  conditionFieldPath: conditionfieldpath,
                };
              }),
              rewardPayoutType: type.rewardpayouttype,
              rewardPayoutValue: type.rewardpayoutvalue,
              type: type.type,
              rewardPayoutFieldPath: type.rewardpayoutfieldpath || '',
            };
          }),
        },
      };

      yield WebSocketService
        .sendRequest<RewardOfferWallSchemasUpdateRoute.ResponseType>({
          method: 'reward_offerwallSchemasUpdate',
          data: mappedData,
        });

      this.rewardSchemas = this.rewardSchemas.map((item) => {
        if (item.id === dto.id) {
          const { data, ...rest } = dto;

          return {
            ...item,
            ...rest,
            schema: data,
          };
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *isSendRewardWorkerEnabled() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { isEnabled }: RewardSendRewardsWorkerIsWorkerEnabledRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardSendRewardsWorkerIsWorkerEnabledRoute.ResponseType, RewardSendRewardsWorkerIsWorkerEnabledRoute.RequestType>({
          method: 'reward_isSendRewardWorkerEnabled',
          data: {},
        });

      this.sendRewardWorkerEnbled = isEnabled;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *enableSendRewardWorker() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardSendRewardsWorkerEnableWorkerRoute.ResponseType, RewardSendRewardsWorkerEnableWorkerRoute.RequestType>({
          method: 'reward_sendRewardsWorkerEnable',
          data: {},
        });

      const { isEnabled }: RewardSendRewardsWorkerIsWorkerEnabledRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardSendRewardsWorkerIsWorkerEnabledRoute.ResponseType, RewardSendRewardsWorkerIsWorkerEnabledRoute.RequestType>({
          method: 'reward_isSendRewardWorkerEnabled',
          data: {},
        });

      this.sendRewardWorkerEnbled = isEnabled;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *disableSendRewardWorker() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardSendRewardsWorkerDisableWorkerRoute.ResponseType, RewardSendRewardsWorkerDisableWorkerRoute.RequestType>({
          method: 'reward_sendRewardsWorkerDisable',
          data: {},
        });

      const { isEnabled }: RewardSendRewardsWorkerIsWorkerEnabledRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardSendRewardsWorkerIsWorkerEnabledRoute.ResponseType, RewardSendRewardsWorkerIsWorkerEnabledRoute.RequestType>({
          method: 'reward_isSendRewardWorkerEnabled',
          data: {},
        });

      this.sendRewardWorkerEnbled = isEnabled;
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getRewards(dto: Partial<OfferWallRewardFindAllRequest.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { items }: RewardOfferWallRewardsFindAllRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardOfferWallRewardsFindAllRoute.ResponseType, RewardOfferWallRewardsFindAllRoute.RequestType>({
          method: 'reward_offerwallRewardsFindAll',
          data: {
            isSent: !!dto.issent,
          },
        });

      this.rewards = [];
      
      if (items) {
        this.rewards = items.map((item) => {
          return {
            createdat: item.createdAt,
            id: item.id,
            issenttobalances: item.isSentToBalances,
            offerwallschemaidentifier: item.offerWallSchemaIdentifier,
            payoutsList: item.payouts,
            requestid: item.requestId,
            userid: item.userId,
          };
        });
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
