import React, {useState} from 'react';
import {Button} from '@mui/material';
import {ResetUserModal} from '../../modals';
import {RotateLeft} from '@mui/icons-material';
import { IShortUser } from '../../../core';

interface Props {
  userResetAction: any;
  refreshAction: any;
  initialSelectedUser?: IShortUser;
}

interface IReset {
  accessToken: string;
  userIds: number[];
  withFullStory: boolean;
}

export const ResetUserButton: React.FC<Props> = ({
  userResetAction,
  refreshAction,
  initialSelectedUser,
}) => {
  const [resetModalOpen, setResetModalOpen] = useState<boolean>(false);

  const handleReset = () => {
    setResetModalOpen(true);
  };

  const handleResetUser = (data: IReset) => {
    userResetAction(data);
    setResetModalOpen(false);
    refreshAction(true);
  };

  return (
    <>
      <ResetUserModal
        open={resetModalOpen}
        handleClose={() => setResetModalOpen(false)}
        handleAgree={handleResetUser}
        initialSelectedUser={initialSelectedUser}
      />
      <Button
        variant="contained"
        color="primary"
        style={{marginRight: '12px'}}
        onClick={handleReset}
      >
        <RotateLeft /> Reset
      </Button>
    </>
  );
};
