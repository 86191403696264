import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  JwtAuth,
  StepBonus,
  DeletStepBonusConfigRequest,
  CreateStepBonusConfigRequest,
  EditStepBonusConfigRequest,
} from '../../proto/reward_pb';
import {
  RewardBonusGetStepBonusConfigRoute,
  RewardBonusCreateStepBonusConfigRoute,
  RewardBonusEditStepBonusConfigRoute,
  RewardBonusDeletStepBonusConfigRoute,
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export class BonusStore {
  public error: RpcError | null = null;

  public bonusStepConfig: StepBonus.AsObject[] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getStepBonusConfig(dto: Partial<JwtAuth.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { bonusStep }: RewardBonusGetStepBonusConfigRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardBonusGetStepBonusConfigRoute.ResponseType, RewardBonusGetStepBonusConfigRoute.RequestType>({
          method: 'reward_getStepBonusConfig',
          data: {},
        });

      this.bonusStepConfig = bonusStep.map(({ id, rewardAfter, rewardAmount }) => {
        return {
          id,
          rewardafter: rewardAfter,
          rewardamount: rewardAmount,
        };
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  * createStepBonusConfig(dto: Partial<CreateStepBonusConfigRequest.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { step }: RewardBonusCreateStepBonusConfigRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardBonusCreateStepBonusConfigRoute.ResponseType>({
          method: 'reward_createStepBonusConfig',
          data: {
            step: {
              rewardAfter: dto.step?.rewardafter || 0,
              rewardAmount: dto.step?.rewardamount || 0,
            },
          },
        });

      if (step) {
        const mappedStep = {
          id: step.id,
          rewardafter: step.rewardAfter,
          rewardamount: step.rewardAmount,
        };

        this.bonusStepConfig = [...this.bonusStepConfig, mappedStep];
      }
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateStepBonusConfig(dto: Partial<EditStepBonusConfigRequest.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardBonusEditStepBonusConfigRoute.ResponseType, RewardBonusEditStepBonusConfigRoute.RequestType>({
          method: 'reward_editStepBonusConfig',
          data: {
            step: {
              id: dto.step?.id || 0,
              rewardAfter: dto.step?.rewardafter || 0,
              rewardAmount: dto.step?.rewardamount || 0,
            },
          },
        });

      this.bonusStepConfig = this.bonusStepConfig.map((item) => {
        if (item.id === dto.step?.id) {

          return {
            ...item,
            ...dto.step,
          } as StepBonus.AsObject;
        }

        return item;
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  * deleteStepBonusConfig(dto: Partial<DeletStepBonusConfigRequest.AsObject>) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardBonusDeletStepBonusConfigRoute.ResponseType, RewardBonusDeletStepBonusConfigRoute.RequestType>({
          method: 'reward_deleteStepBonusConfig',
          data: {
            id: dto.id || 0,
          },
        });

      this.bonusStepConfig = this.bonusStepConfig.filter(item => item.id !== dto.id);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
