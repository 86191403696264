export interface DeepObject {
  [k: string]: unknown | DeepObject;
}

export const getObjectProperty = (object: DeepObject | unknown, path: string): unknown => {
  const parts = path.split('.');
  for (let i = 0; i < parts.length; i+=1) {
    if (!object) {
      return undefined;
    }

    const key = parts[i];
    // eslint-disable-next-line no-param-reassign
    object = (object as DeepObject)[key];
  }
  return object;
};

export const updateObjectProperty = (object: DeepObject, path: string, value: unknown): DeepObject => {
  let newObject: DeepObject | unknown = object;

  const parts = path.split('.');
  for (let i = 0; i < parts.length; i+=1) {
    const key = parts[i];
    if (i === parts.length - 1) {
      // @ts-ignore
      newObject[key] = value;
    } else {
      newObject = (object as DeepObject)[key];
    }
  }

  return object;
};