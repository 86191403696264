import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  styled,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const StyledListItem = styled(ListItem)`
  border-bottom: 1px solid #e0e0e0;
  height: 32px;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: ${(props: any) => (props.isDragging ? '0px 4px 6px rgba(0, 0, 0, 0.05)' : 'none')};
`;

export interface LevelsOrder {
  key: string;
  alias: string;
}

interface OwnProps {
  bundleId: string;
  offerwallId: string;
  bundleIdPrimary: number;
  levelsOrder: LevelsOrder[];
  updateLevelsOrderAction: (params: { levelsOrder: string[]; bundleId: string; offerwallId: string; bundleIdPrimary: number }) => any;
}

type Props = OwnProps;

export const LevelsOrderDialog: React.FC<Props> = ({
  levelsOrder: levelsOrderProp,
  updateLevelsOrderAction: updateDailyRewardsAciton,
  bundleId,
  offerwallId,
  bundleIdPrimary,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [levelsOrder, setLevelsOrder] = useState<LevelsOrder[]>(levelsOrderProp);

  useEffect(() => {
    setLevelsOrder(levelsOrderProp);
  }, [levelsOrderProp]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = [...levelsOrder];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setLevelsOrder(newItems);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };
  
  const handleAction = () => {
    setOpenDialog(false);
    updateDailyRewardsAciton({ levelsOrder: levelsOrder.map((v) => v.key), bundleId, offerwallId, bundleIdPrimary });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseModal}
        aria-labelledby="test-dialog-title"
        aria-describedby="test-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="test-dialog-description">
            Edit levels ordering
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef}>
                  {levelsOrder.map(({ key, alias }, index) => (
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided, snapshot) => (
                        <StyledListItem
                          disablePadding
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >
                          <ListItemText primary={alias} />
                        </StyledListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen} variant='outlined' color='success'>Level ordering</Button>
    </>
  );
};
