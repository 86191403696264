import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getSettingsStyle = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: 10,
    },
    help: {
      fontSize: 16,
      marginLeft: 8,
    },
    selectInput: {
      width: '100%',
      marginRight: theme.spacing(1),
    },
  }),
);
