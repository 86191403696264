import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getRewardSchemaStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      width: '550px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    input: {
      width: '100%',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    select: {
      width: '100%',
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    buttons: {
      padding: theme.spacing(1),
    },
  })
);
