import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getHrsV1Style = makeStyles((theme: Theme) =>
  createStyles({
    gameImg: {
      display: 'flex',
      margin: theme.spacing(1),
      "& img": {
        borderRadius: '10px',
        minWidth: '150px',
        minHeight: '150px',
        width: '150px',
        height: '150px',
        marginRight: theme.spacing(1)
      },
      "& h4, p": {
        margin: 0
      }
    },
    offerwallName: {
      borderRadius: '5px 0px 0px 5px',
      border: `1px solid ${theme.palette.action.focus}`,
      padding: '2px 5px',
      backgroundColor: theme.palette.action.hover,
      fontSize: '1.2em'
    },
    gameName: {
      borderRadius: '0px 5px 5px 0px',
      border: `1px solid ${theme.palette.action.focus}`,
      padding: '2px 5px',
      fontSize: '1.2em',
      borderLeft: 'none'
    },
    test: {
      "box-shadow": `0 0 0 0 ${theme.palette.error.main}`,
      animation: `$pulseError 2s infinite`,
    },
    "@keyframes pulseError": {
      "0%": {
        // transform: "scale(0.95)",
        "box-shadow": `0 0 0 0 ${theme.palette.error.main}`,
      },
      "70%": {
        // transform: "scale(1)",
        "box-shadow": "0 0 0 10px rgba(0, 100, 0, 0)",
      },
      "100%": {
        // transform: "scale(0.95)",
        "box-shadow": "0 0 0 0 rgba(0, 100, 0, 0)",
      },
    },
  }),
);
