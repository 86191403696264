import { Description } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Column, CustomTable } from '../../components/table';
import { generateCSV } from '../../core/utils/csv-gen';
import { useStores } from '../../stores/app';
import { useSearchParams } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const HrsStats = observer(() => {
    const { hrsStatsStore } = useStores();
    const [searchParams, setSearchParams] = useSearchParams();
    const [firstSearch, setFirstSearch] = useState(searchParams.get("search"));

    const [lastReqData, setLastReqData] = useState({
        postback: {
            timefrom: Math.floor((new Date().getTime() - (24 * 60 * 60 * 1000)) / 1000),
            timeto: Math.floor(new Date().getTime() / 1000),
        },
        blocker: {
            timefrom: Math.floor((new Date().getTime() - (24 * 60 * 60 * 1000)) / 1000),
            timeto: Math.floor(new Date().getTime() / 1000),
        }
    });

    // const [datePickers, setDatePickers] = useState({
    //     postback: {
    //         timefrom: Math.floor((new Date().getTime() - (24 * 60 * 60 * 1000)) / 1000),
    //         timeto: Math.floor(new Date().getTime() / 1000),
    //     },
    //     blocker: {
    //         timefrom: Math.floor((new Date().getTime() - (24 * 60 * 60 * 1000)) / 1000),
    //         timeto: Math.floor(new Date().getTime() / 1000),
    //     }
    // });

    const hrsPostbackStatsColumns: Column[] = [
        {
            id: 'offerwallid',
            label: 'Offerwall ID',
            sortKey: 'offerwall_provider_id',
            minWidth: 50,
        },
        {
            id: 'bunldeid',
            label: 'Bundle ID',
            sortKey: 'bundle_id',
            minWidth: 50,
        },
        {
            id: 'type',
            label: 'Type',
            sortKey: 'type',
            minWidth: 50,
        },
        {
            id: 'key',
            label: 'Key',
            sortKey: 'key',
            minWidth: 50,
        },
        {
            id: 'sum',
            label: 'Sum',
            sortKey: 'sum',
            minWidth: 50,
        },
        {
            id: 'succeesspostbackcount',
            label: 'Success Postback',
            minWidth: 50,
            sortKey: "succeessPostbackCount",
        },
        {
            id: 'blockerspostbackcount',
            label: 'Blocked Postback',
            sortKey: "blockersPostbackCount",
            minWidth: 50,
        },
        {
            id: 'successpercent',
            label: 'Success Percent',
            sortKey: "successPercent",
            format: (val) => val + "%",
            minWidth: 50,

        },
    ]

    const postbackRequestAction = (data) => {
        lastReqData.postback = { ...lastReqData.postback, ...data };
        setLastReqData({ ...lastReqData, postback: { ...lastReqData.postback, ...data } });
        hrsStatsStore.getPostbackStats(lastReqData.postback as any);
    }

    const timerChange = (tableName, e) => {
        lastReqData.postback = { ...lastReqData.postback, [e.target.name]: Math.floor(new Date(e.target.value).getTime() / 1000) };
        setLastReqData({ ...lastReqData, postback: { ...lastReqData.postback, [e.target.name]: Math.floor(new Date(e.target.value).getTime() / 1000) } });
        hrsStatsStore.getPostbackStats(lastReqData.postback as any);
    }

    const genCsv = () => {
        // const headers: any[] = [];
        // Object.keys(hrsStatsStore.hrsPostbackStats.dataList[0]).forEach((key: any) => {
        //     headers.push({
        //         key,
        //     });
        // });

        // generateCSV(
        //     headers,
        //     hrsStatsStore.hrsPostbackStats.dataList,
        //     'hrsStats-' + moment().format('YYYY-MM-DD')
        // );

        hrsStatsStore.getPostbackStatsCsv(lastReqData.postback as any);
    }

    const timers = (tableName: string) => <>
        <TextField
            type="datetime-local"
            onChange={e => timerChange(tableName, e)}
            defaultValue={moment(new Date().getTime() - (24 * 60 * 60 * 1000)).format('YYYY-MM-DD[T]HH:mm')}
            name="timefrom"
            size='small'
            label={'Start date'}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ marginRight: '10px' }}
        />
        <TextField
            type="datetime-local"
            onChange={e => timerChange(tableName, e)}
            defaultValue={moment().format('YYYY-MM-DD[T]HH:mm')}
            name="timeto"
            size='small'
            label={'End date'}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <Button
            onClick={genCsv}
            variant="contained"
            color="primary"
            style={{ marginLeft: '10px' }}
            disabled={hrsStatsStore.hrsPostbackStats?.totalcount === 0}
        >
            <Description /> Export
        </Button>
    </>

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <CustomTable
                    defaultSearchValue={firstSearch || ''}
                    pageAction={() => timers('postback')}
                    data={hrsStatsStore.hrsPostbackStats.dataList}
                    totalCount={hrsStatsStore.hrsPostbackStats.totalcount}
                    requestAction={postbackRequestAction}
                    columns={hrsPostbackStatsColumns}
                    defaultSortKey='offerwall_provider_id'
                    isSearchShow
                    isDataSliced
                    searchInputText="Type Offerwall Id, Bundle Id or type"
                    tableInnerProps={{ size: 'small' }}
                />
            </Box>
        </>
    );
});
