export const auth = {
  login: {
    microserviceName: 'auth',
    serviceName: 'AuthService',
    method: 'login',
  },
  loginByUsername: {
    microserviceName: 'auth',
    serviceName: 'AuthService',
    method: 'loginByUsername',
  },
  getBlackWhiteList: {
    microserviceName: 'auth',
    serviceName: 'BlackWhiteListService',
    method: 'get',
  },
  addBlackWhiteList: {
    microserviceName: 'auth',
    serviceName: 'BlackWhiteListService',
    method: 'add',
  },
  updateBlackWhiteList: {
    microserviceName: 'auth',
    serviceName: 'BlackWhiteListService',
    method: 'update',
  },
  deleteBlackWhiteList: {
    microserviceName: 'auth',
    serviceName: 'BlackWhiteListService',
    method: 'delete',
  },
  getPlayers: {
    microserviceName: 'auth',
    serviceName: 'UserService',
    method: 'getPlayers',
  },
  getBannedPlayers: {
    microserviceName: 'auth',
    serviceName: 'UserService',
    method: 'getBannedPlayers',
  },
  userBan: {
    microserviceName: 'auth',
    serviceName: 'UserService',
    method: 'ban',
  },
  getUsersInfo: {
    microserviceName: 'auth',
    serviceName: 'UserService',
    method: 'getUsersInfo',
  },
  getPlayersIdNames: {
    microserviceName: 'auth',
    serviceName: 'UserService',
    method: 'getPlayersIdNames',
  },
  userReset: {
    microserviceName: 'auth',
    serviceName: 'UserService',
    method: 'reset',
  },
};