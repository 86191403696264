import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getSwitchTableCellStyle = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: theme.spacing(1, 0),
      width: '100%',
      maxWidth: 200,
    },
  });
});
