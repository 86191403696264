/**
 * @fileoverview gRPC-Web generated client stub for notifications
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: src/proto/notifications.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as src_proto_notifications_pb from '../../src/proto/notifications_pb';


export class AdminServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetNotificationsTempaltes = new grpcWeb.MethodDescriptor(
    '/notifications.AdminService/getNotificationsTempaltes',
    grpcWeb.MethodType.UNARY,
    src_proto_notifications_pb.GetNotificationsTempaltesRequest,
    src_proto_notifications_pb.GetNotificationsTempaltesResponse,
    (request: src_proto_notifications_pb.GetNotificationsTempaltesRequest) => {
      return request.serializeBinary();
    },
    src_proto_notifications_pb.GetNotificationsTempaltesResponse.deserializeBinary
  );

  getNotificationsTempaltes(
    request: src_proto_notifications_pb.GetNotificationsTempaltesRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_proto_notifications_pb.GetNotificationsTempaltesResponse>;

  getNotificationsTempaltes(
    request: src_proto_notifications_pb.GetNotificationsTempaltesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_proto_notifications_pb.GetNotificationsTempaltesResponse) => void): grpcWeb.ClientReadableStream<src_proto_notifications_pb.GetNotificationsTempaltesResponse>;

  getNotificationsTempaltes(
    request: src_proto_notifications_pb.GetNotificationsTempaltesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_proto_notifications_pb.GetNotificationsTempaltesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.AdminService/getNotificationsTempaltes',
        request,
        metadata || {},
        this.methodDescriptorgetNotificationsTempaltes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.AdminService/getNotificationsTempaltes',
    request,
    metadata || {},
    this.methodDescriptorgetNotificationsTempaltes);
  }

  methodDescriptorupdateNotificationTemplate = new grpcWeb.MethodDescriptor(
    '/notifications.AdminService/updateNotificationTemplate',
    grpcWeb.MethodType.UNARY,
    src_proto_notifications_pb.UpdateNotificationTemplateRequest,
    src_proto_notifications_pb.UpdateNotificationTemplateResponse,
    (request: src_proto_notifications_pb.UpdateNotificationTemplateRequest) => {
      return request.serializeBinary();
    },
    src_proto_notifications_pb.UpdateNotificationTemplateResponse.deserializeBinary
  );

  updateNotificationTemplate(
    request: src_proto_notifications_pb.UpdateNotificationTemplateRequest,
    metadata: grpcWeb.Metadata | null): Promise<src_proto_notifications_pb.UpdateNotificationTemplateResponse>;

  updateNotificationTemplate(
    request: src_proto_notifications_pb.UpdateNotificationTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: src_proto_notifications_pb.UpdateNotificationTemplateResponse) => void): grpcWeb.ClientReadableStream<src_proto_notifications_pb.UpdateNotificationTemplateResponse>;

  updateNotificationTemplate(
    request: src_proto_notifications_pb.UpdateNotificationTemplateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: src_proto_notifications_pb.UpdateNotificationTemplateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/notifications.AdminService/updateNotificationTemplate',
        request,
        metadata || {},
        this.methodDescriptorupdateNotificationTemplate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/notifications.AdminService/updateNotificationTemplate',
    request,
    metadata || {},
    this.methodDescriptorupdateNotificationTemplate);
  }

}

