import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { SchamasTab } from './schemas.tab';
import { RewardsTab } from './rewards.tab';

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const Rewards = () => {
  const [tab, setTab] = useState(0);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={(_, idx) => setTab(idx)}
          aria-label="basic tabs example"
        >
          <Tab label="Schemas" {...a11yProps(0)} />
          <Tab label="Rewards" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <SchamasTab />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <RewardsTab />
      </TabPanel>
    </>
  );
};
