import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useStores } from '../../stores/app';
import { Column, CustomTable, LinkCell } from '../../components/table';
import { LeaderboardSeasonGetRoute } from '@boints/grpc';

enum EventStatus {
  NOT_STARTED = 'not_started',
  CURRENT = 'current',
  FINISHED = 'finished',
}

const columns: Column[] = [
  {
    id: 'name',
    label: 'Season',
    minWidth: 170,
    component: (props: any) => (
      <LinkCell value={props.name} url={`/dashboard/season-info/${props.id}`} />
    ),
  },
  {
    id: 'tournaments',
    label: 'Tournaments',
    minWidth: 170,
    format: (value: any[]) => String(value.length),
    component: (props: any) => {
      return props.tournaments?.length ? props.tournaments.length : 0;
    },
  },
  {
    id: 'tournaments',
    label: 'Participants',
    minWidth: 170,
    format: (value: any[]) => {
      let sum = 0;
      value?.forEach((item) => {
        sum += item.participants;
      });
      return String(sum);
    },
  },
  {
    id: 'startDate',
    label: 'Start Date',
    minWidth: 170,
    format: (value: number) =>
      value ? moment(+value).format('MM/DD/YYYY') : 'until now',
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 170,
    format: (value: number) =>
      value ? moment(+value).format('MM/DD/YYYY') : 'until now',
  },
  { id: 'status', label: 'Status', minWidth: 170 },
];

export const Season = observer(() => {
  const { seasonsStore } = useStores();

  const loadSeasons = (dto: LeaderboardSeasonGetRoute.RequestType) =>
    seasonsStore.getSeasons(dto);

  return (
    <CustomTable
      columns={columns}
      data={seasonsStore.seasons.entities}
      totalCount={seasonsStore.seasons.totalCount}
      requestAction={loadSeasons}
      isSortEnabled={false}
    />
  );
});
