import { Autocomplete, Box, Button, ButtonPropsColorOverrides, ButtonPropsVariantOverrides, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField, Tooltip } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { EditableFieldType } from "../../../core/enum/modals.enum";
import { EditableField } from "../../../core/interfaces/modal.interfaces";
import { ListboxComponent } from "../reset-user";
import { getCreateEditModalStyles } from "./create-edit.styles";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { getObjectProperty, updateObjectProperty } from "./get-update-object-property";
import { OverridableStringUnion } from '@mui/types';

interface OwnProps {
    btnLabel?: string,
    dialogName?: string,
    editableFields: EditableField[],
    currItem?: any,
    tooltipText?: string,
    disabled?: boolean,
    acceptAction?: (currItem: any) => void;
    addItem?: (currItem: any) => void;
    btnColor?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',ButtonPropsColorOverrides>;
    icon?: any;
    fullWidth?: boolean;
    style?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>;
    btnProps?: any;
    btnStyle?: CSSProperties;
}

type Props = OwnProps;

export const CreateEditModal: React.FC<Props> = ({ 
    currItem,
    dialogName = '',
    editableFields,
    acceptAction,
    btnLabel,
    tooltipText,
    disabled,
    btnColor,
    icon,
    fullWidth,
    btnProps,
    style,
    btnStyle,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const classes = getCreateEditModalStyles();
    const [currItemState, setCurrItemState] = useState(currItem ?? {});

    const generateContentByFields = () => {
        return editableFields.map(editedItem => {
            editedItem.validateError = editedItem && editedItem.validate ? editedItem?.validate(currItemState[editedItem.paramName], currItemState) : ''

            if (editedItem.customComponent) return editedItem.customComponent(getObjectProperty(currItemState, editedItem.paramName), (val) => {
                setCurrItemState({
                    ...updateObjectProperty(currItemState, editedItem.paramName, val),
                });
            }, currItem);

            if (editedItem.type === EditableFieldType.multipick && editedItem.enumVariants) {
                const reversedEnumVariants = Object.fromEntries(Object.entries(editedItem.enumVariants).map(([k, v]) => [v, k]));

                return (
                    <Box className={editedItem.className}>
                        <Autocomplete
                            key={editedItem.paramName}
                            size='small'
                            multiple
                            sx={editedItem.sx}
                            className={classes.input}
                            disabled={editedItem.disabled || (currItem && editedItem.isEditThenDisable)}
                            options={Object.values(editedItem.enumVariants)}
                            value={getObjectProperty(currItemState, editedItem.paramName) as string[]}
                            isOptionEqualToValue={(option, value) => {
                                return option === value;
                            }}
                            ListboxComponent={
                                ListboxComponent as React.ComponentType<
                                    React.HTMLAttributes<HTMLElement>
                                >
                            }
                            getOptionLabel={(option) => reversedEnumVariants[option]}
                            onChange={(e, values) => {
                                setCurrItemState({
                                    ...updateObjectProperty(currItemState, editedItem.paramName, values),
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={editedItem.label}
                                />
                            )}
                        />
                    </Box>
                );
            }

            if (editedItem.enumVariants) {
                const currItemValue = getObjectProperty(currItemState, editedItem.paramName);
                const value = Object.keys(editedItem.enumVariants).find(key => (editedItem.enumVariants as any)[key] === currItemValue);
                return (
                    <Autocomplete
                        key={editedItem.paramName}
                        size='small'
                        className={[classes.input, editedItem.className].join(' ')}
                        disabled={editedItem.disabled || (currItem && editedItem.isEditThenDisable)}
                        options={Object.keys(editedItem.enumVariants)}
                        value={value}
                        isOptionEqualToValue={(option, value) => {
                            return option === value;
                        }}
                        ListboxComponent={
                            ListboxComponent as React.ComponentType<
                                React.HTMLAttributes<HTMLElement>
                            >
                        }
                        getOptionLabel={(option) => option}
                        onChange={(e, key) => {
                            if (key && editedItem.enumVariants && editedItem?.enumVariants[key]) {
                                setCurrItemState({ ...updateObjectProperty(currItemState, editedItem.paramName, editedItem.enumVariants[key] )});
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={editedItem.label}
                            />
                        )}
                    />
                );
            }

            if (editedItem.type === EditableFieldType.checkbox) {
                return (
                    <Box key={editedItem.label}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!getObjectProperty(currItemState, editedItem.paramName)}
                                    onChange={(e) => {
                                        setCurrItemState({ ...updateObjectProperty(currItemState, editedItem.paramName, e.target.checked )});
                                    }}
                                />
                            } label={editedItem.label}
                        />
                    </Box>
                );
            }

            if (editedItem.type === EditableFieldType.file) {
              return (
                  <Box key={editedItem.label}>
                      <FormControlLabel
                          control={
                            <Button
                              style={{ margin: 10 }}
                              variant="contained"
                              component="label"
                            >
                              Upload Image
                              <input
                                type="file"
                                onChange={(e) => {
                                  if (e.target.files && e.target.files[0]) {
                                    const fileSize = e.target.files[0].size / 1024; // in KB

                                    if (fileSize > 500) { // 500 KB
                                      alert('File size should be less than 500 KB');
                                      e.target.value = '';
                                    } else {
                                      const reader = new FileReader();
                                      reader.onload = function(e) {
                                          const base64 = e.target?.result;
                                          setCurrItemState({
                                              ...updateObjectProperty(currItemState, editedItem.paramName, base64)
                                          });
                                      }
                                      reader.readAsDataURL(e.target.files[0]);
                                    }
                                  }
                                }}
                                hidden
                              />
                            </Button>
                          }
                          label=""
                      />
                  </Box>
              );
          }

            return (
                <Box key={editedItem.label} sx={editedItem.sx} className={editedItem.className}>
                    <TextField
                        size='small'
                        // defaultValue={getObjectProperty(currItemState, editedItem.paramName)}
                        label={editedItem.label}
                        disabled={editedItem.disabled}
                        error={!!editedItem.validateError}
                        helperText={editedItem.validateError}
                        fullWidth
                        onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && editedItem.type === EditableFieldType.number ? e.preventDefault() : ""}
                        className={classes.input}
                        type={EditableFieldType[editedItem.type] ?? 'string'}
                        value={getObjectProperty(currItemState, editedItem.paramName)}
                        onChange={(e: any) => {
                            setCurrItemState({ ...updateObjectProperty(currItemState, editedItem.paramName, e.target.value)});
                        }}
                    />
                </Box>
            );
        });
    }

    useEffect(() => {
        setCurrItemState(currItem ?? {});
    }, [currItem]);

    return (
        <>
            {currItem ?
                <Tooltip title={tooltipText ?? ""}>
                    <span>
                        <IconButton disabled={disabled} onClick={() => setIsOpen(true)} color='primary'>
                            <EditIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                :
                <Tooltip title={tooltipText ?? ""}>
                    <span>
                        <Button {...btnProps} disabled={disabled} onClick={() => setIsOpen(true)} variant={style ? style : 'outlined'} color={btnColor ? btnColor : 'success'} style={{ ...btnStyle, marginRight: "10px", width: fullWidth ? "100%" : 'auto' }}>
                            {icon ? icon : <AddIcon />} {btnLabel ? btnLabel : "Open modal"}
                        </Button>
                    </span>
                </Tooltip>
            }

            <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className={classes.main}
            >
                <DialogTitle>{currItem ? `Editing ${dialogName}` : `Creating new ${dialogName}`}</DialogTitle>
                <DialogContent style={{ minWidth: '500px' }}> {generateContentByFields()} </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => setIsOpen(false)}>Cancel</Button>
                    <Button disabled={!!editableFields.find(item => !!item.validateError)} onClick={() => {
                        if (acceptAction) {
                            acceptAction(currItemState as any);
                        }
                        setCurrItemState(currItem ?? {});
                        setIsOpen(false)
                    }}>Accept</Button>
                </DialogActions>
            </Dialog >
        </>

    )
}