import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

export const getDeleteDialogStyles = makeStyles((theme: Theme) => {
  return createStyles({
    deleteIcon: {
      color: red[600],
    },
  });
});
