import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const dailyReportDetailsStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    title: {
      textAlign: 'center',
    },
    wrapper: {
      maxWidth: 850,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '16px',
    },
    offerwalls: {
      borderBottom: '1px #000 solid',
      paddingTop: 22,
      paddingBottom: 22,
      marginBottom: 22,
    },
  }),
);
