export const leaderboard = {
  getSettings: {
    microserviceName: 'leaderboard',
    serviceName: 'SettingsService',
    method: 'get',
  },
  getVersionSettings: {
    microserviceName: 'leaderboard',
    serviceName: 'SettingsService',
    method: 'getVersionSettings',
  },
  deleteSettings: {
    microserviceName: 'leaderboard',
    serviceName: 'SettingsService',
    method: 'delete',
  },
  updateSettings: {
    microserviceName: 'leaderboard',
    serviceName: 'SettingsService',
    method: 'update',
  },
  createSettings: {
    microserviceName: 'leaderboard',
    serviceName: 'SettingsService',
    method: 'create',
  },
  seasonGet: {
    microserviceName: 'leaderboard',
    serviceName: 'SeasonService',
    method: 'get',
  },
  tournamentGet: {
    microserviceName: 'leaderboard',
    serviceName: 'TourService',
    method: 'get',
  },
  tournamentMake: {
    microserviceName: 'leaderboard',
    serviceName: 'TourService',
    method: 'make',
  },
  tournamentUpdate: {
    microserviceName: 'leaderboard',
    serviceName: 'TourService',
    method: 'update',
  },
  tournamentFinish: {
    microserviceName: 'leaderboard',
    serviceName: 'TourService',
    method: 'finish',
  },
  tournamentGetCurrent: {
    microserviceName: 'leaderboard',
    serviceName: 'TourService',
    method: 'getCurrent',
  },
  singleStatGet: {
    microserviceName: 'leaderboard',
    serviceName: 'SingleStatService',
    method: 'get',
  },
};