import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';
import { useStores } from '../../../stores/app';
import { GetListResponse_ParamsEntry, TestParamsType } from '../../../core';

type TestLocalKey = `test:${string}_params`;

interface OwnProps {
  tests: { testName: string, paramsSchema?: GetListResponse_ParamsEntry[] }[];
  runTestAction: (tests: { testName: string, params: { [k: string]: string } }[]) => any;
}

type Props = OwnProps;

const intRegex = /^[0-9\b]+\.?[0-9\b]*$/;

export const TestParamsDialog: React.FC<Props> = ({
  runTestAction,
  tests,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [testsParams, setTestsParams] = useState<{ [k: string]: { [k: string]: string } }>({});

  useEffect(() => {
    setTestsParams(
      Object.fromEntries(
        tests.map(({ testName, paramsSchema }) => {
          const localKey: TestLocalKey = `test:${testName}_params`;
          const localSchema = localStorage.getItem(localKey);

          const params = localSchema
            ? JSON.parse(localSchema)
            : paramsSchema ? Object.fromEntries(paramsSchema.map(({ key, default: d }) => [key, d || ''])) : {};
            
          return [
            testName,
            params,
          ];
        }),
      ),
    );
  }, [tests]);

  const { notificationStore } = useStores();

  const handleClickOpen = () => {
    if (!tests.length) {
      notificationStore.enqueueSnackBar({ variant: 'error', message: 'Select at least one test' });
      return;
    }

    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };
  
  const handleAction = () => {
    setOpenDialog(false);
    runTestAction(Object.entries(testsParams).map(([testName, params]) => ({ testName, params })));
  };

  const changeTestParams = (
    { field, testName, value }: { testName: string, field: string, value: string },
  ) => {
    setTestsParams({
      ...testsParams,
      [testName]: {
        ...testsParams[testName],
        [field]: value,
      },
    });

    const localKey: TestLocalKey = `test:${testName}_params`;
    const localValue = JSON.stringify({
      ...testsParams[testName],
      [field]: value,
    });

    localStorage.setItem(localKey, localValue);
  };

  const renderInputStrategies: { [k in TestParamsType]: (
    params: { testName: string, field: string, data: string },
  ) => JSX.Element } = {
    number: ({ testName, field }) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "" || intRegex.test(e.target.value)) {
          changeTestParams({ testName, field, value: e.target.value });
        }
      };

      return (
        <FormControl fullWidth>
          <TextField
            type='text'
            label={field}
            placeholder={field}
            onChange={handleChange}
            value={(testsParams[testName] || {})[field]}
          />
        </FormControl> 
      );
    },
    string: ({ testName, field }) => {
      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeTestParams({ testName, field, value: e.target.value });
      };

      return (
        <FormControl fullWidth>
          <TextField
            type='text'
            label={field}
            placeholder={field}
            onChange={handleChange}
            value={(testsParams[testName] || {})[field]}
          />
        </FormControl> 
      );
    },
    oneof: ({ data, field, testName }) => {
      const oneofArray = JSON.parse(data);
      if (!Array.isArray(oneofArray)) throw new Error('Params error: data is not array');

      const handleChange = (e: SelectChangeEvent) => {
        changeTestParams({ testName, field, value: e.target.value });
      };

      return (
        <FormControl fullWidth>
          <InputLabel id={`select-label-${testName}-${field}`}>{field}</InputLabel>
          <Select
            labelId={`select-label-${testName}-${field}`}
            id={`select-${testName}-${field}`}
            value={(testsParams[testName] || {})[field] as string}
            label={field}
            onChange={handleChange}
          >
            {oneofArray.map((text) => (<MenuItem key={text} value={text}>{text}</MenuItem>))}
          </Select>  
        </FormControl>
      );
    },
  };

  const testsJsx = () => tests
    .filter(({ paramsSchema }) => paramsSchema?.length)
    .map(({ testName, paramsSchema }) => (
      <DialogContent key={testName}>
        <DialogContentText className='mb-2'>{testName}</DialogContentText>
        <Box>
          <div className='row flex-wrap'>
            {paramsSchema?.map(({ key, type, data }) => (
              <div className='col-12 mb-3' key={key}>  
                {renderInputStrategies[type]({ field: key, testName, data })}
              </div>
            ))}
          </div>
        </Box>
      </DialogContent>
    ));

  return (
    <div className="col-auto">
      <Dialog
        open={openDialog}
        onClose={handleCloseModal}
        aria-labelledby="test-params-dialog-title"
        aria-describedby="test-params-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="test-dialog-description">
            You are trying to run tests {tests.map(({ testName }) => testName).join(', ')}. Please enter tests params
          </DialogContentText>
        </DialogContent>

        {testsJsx()}
        
        <DialogActions>
          <Button onClick={handleAction} color="primary" autoFocus>
            Start test
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen} variant='contained' color='info'>Run</Button>
    </div>
  );
};
