import { Box, Button, Dialog, DialogActions } from '@mui/material';
import { observer } from 'mobx-react';
import { getHrsStyle } from './hrs.styles';
import { useStores } from '../../stores/app';
import { useState } from 'react';

type Props = {
  children?: React.ReactNode
  onClose?: () => void,
};

export const EditHrs: React.FC<Props> = observer(({ children, onClose }) => {
  const { hrsStore } = useStores();
  const [open, setOpen] = useState(false);
  const classes = getHrsStyle();

  const closeWindow = () => {
    hrsStore.getHrsStatus();
    setOpen(false);
    if (onClose) onClose();
  }
  return (
    <Box style={{ width: '100%' }}>
      <Dialog open={open} onClose={() => closeWindow()} fullScreen>
        {children}
        <DialogActions className={classes.editHrsActions}>
          <Button onClick={() => {closeWindow()}} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant='outlined' fullWidth style={{ marginLeft: '5px' }} onClick={() => setOpen(true)}>Edit</Button>
    </Box>
  );
});
