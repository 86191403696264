import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getFeatureToggleStyle = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      margin: '10px',
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    description: {
      color: theme.palette.text.secondary,
    },
  }),
);
