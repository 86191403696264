export const userQuality = {
  getSettings: {
    microserviceName: 'userQuality',
    serviceName: 'QualityService',
    method: 'getSettings',
  },
  updateSettings: {
    microserviceName: 'userQuality',
    serviceName: 'QualityService',
    method: 'updateSettings',
  },
};