import React, {useState} from 'react';
import {Box, TextField, IconButton} from '@mui/material';
import {Edit, Done, Close} from '@mui/icons-material';
import {getEditableCellStyle} from './editable-cell.styles';
import {Fields} from '../custom-dialog';

type Props = {
  defaultValue: number;
  label: Fields;
  id: number;
  updateBalance: any;
};

export const EditableCell: React.FC<Props> = ({
  defaultValue,
  label,
  id,
  updateBalance,
}) => {
  const [value, setValue] = useState<string>();
  const [editable, setEditable] = useState(true);
  const classes = getEditableCellStyle();

  const handleChange = (inputValue: string) => {
    const NUMERIC_REGEXP = /^\d+(\.?\d+)?$/;
    if (!inputValue.length) {
      setValue('');
    }
    if (NUMERIC_REGEXP.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const handleEdit = (status: boolean) => {
    setEditable(status);
  };

  const handleCLose = () => {
    setEditable(true);
  };

  const handleSave = () => {
    const labels: Record<string, number> = {
      Boints: 1,
      Tokens: 2,
    };

    const currency = labels[label];
    if (currency) {
      updateBalance({
        userid: id,
        amount: Math.abs(Number(value)),
        currency,
      });
    }

    setEditable(true);
  };

  return (
    <Box className={classes.wrapper}>
      <TextField
        defaultValue={defaultValue}
        label={label}
        disabled={editable}
        className={classes.input}
        value={value}
        onChange={(e: any) => handleChange(e.target.value)}
      />
      {editable ? (
        <IconButton onClick={() => handleEdit(false)}>
          <Edit />
        </IconButton>
      ) : (
        <Box className={classes.iconWrapper}>
          <IconButton onClick={handleSave}>
            <Done />
          </IconButton>
          <IconButton onClick={handleCLose}>
            <Close />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
