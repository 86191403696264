import * as auth from './auth';
import * as tests from './tests';
import * as leaderboard from './leaderboard';
import * as offerwall from './offerwall';
import * as balance from './balance';
import * as reward from './reward';
import * as cashout from './cashout';
import * as settings from './settings';
import * as userQuality from './user-quality';
import * as bff from './bff';
import * as stats from './stats';
import * as notifications from './notifications';

type Requests = typeof allRequests;
type InnerKeys = {
  [K in keyof Requests]: keyof Requests[K];
}

type CombinedRequests = {
  [K in keyof Requests as `${K}_${InnerKeys[K]}`]: Requests[K][keyof Requests[K]];
}

const allRequests = {
  ...auth,
  ...tests,
  ...leaderboard,
  ...offerwall,
  ...balance,
  ...reward,
  ...cashout,
  ...settings,
  ...userQuality,
  ...bff,
  ...stats,
  ...notifications,
};

export const requests = flattenObject(allRequests) as CombinedRequests;

function flattenObject(obj) {
  let result = {};
  for (let key in obj) {
    for (let innerKey in obj[key]) {
      result[key + '_' + innerKey] = obj[key][innerKey];
    }
  }
  return result;
}
