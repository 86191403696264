import React from 'react';
import {Box, Switch} from '@mui/material';
import {getSwitchTableCellStyle} from './switch-cell.styles';

interface Props {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const SwitchCell: React.FC<Props> = ({value, onChange}) => {
  const classes = getSwitchTableCellStyle();

  return (
    <Box className={classes.wrapper}>
      <Switch
        color="primary"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
    </Box>
  );
};
