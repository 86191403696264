export interface FeatureItemInput {
    key: string,
    value?: string,
    type: FeatureItemInputType,
    dropDownListItems?: { [value: string]: string; },
    groupName?: string,
}

export enum FeatureItemInputType {
    INPUT_CHECKBOX,
    INPUT_DROPDOWN,
    INPUT_TEXT,
}