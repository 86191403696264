export const cashout = {
  ticketGet: {
    microserviceName: 'cashout',
    serviceName: 'TicketService',
    method: 'get',
  },
  ticketUpdate: {
    microserviceName: 'cashout',
    serviceName: 'TicketService',
    method: 'update',
  },
}