import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import {
  Column,
  CustomTable,
  SelectCell,
  TextCell,
} from '../../components/table';
import { CustomDeleteDialog } from '../../components/dialogs';
import { AddBlackWhiteListModal } from '../../components/modals';
import {
  AddBlackWhiteList,
  GetBlackWhiteList,
  UpdateBlackWhiteList,
} from '../../proto/auth_pb';

const WhiteListTypeOptions: {[value: string]: string} = {
  id: 'ID',
  ip: 'IP Address',
  email: 'Email',
};

export const Whitelist = observer(() => {
  const { whitelistStore } = useStores();

  const loadWhitelist = (dto: GetBlackWhiteList.AsObject) =>
    whitelistStore.getWhiteList(dto);

  const addWhiteList = (dto: AddBlackWhiteList.AsObject) =>
    whitelistStore.addWhiteList(dto);

  const updateWhiteList = (dto: UpdateBlackWhiteList.AsObject) =>
    whitelistStore.updateWhiteList(dto);

  const deleteWhiteList = (id: number) => whitelistStore.deleteWhiteList(id);

  const columns: Column[] = [
    { id: 'id', label: 'Id', minWidth: 10 },
    {
      id: 'value',
      label: 'Value',
      component: (props: any) => (
        <TextCell
          value={props.value}
          onChange={(value) => updateWhiteList({ ...props, value })}
        />
      ),
    },
    {
      id: 'type',
      label: 'Type',
      component: (props: any) => (
        <SelectCell
          options={WhiteListTypeOptions}
          value={props.type}
          onChange={(type) => updateWhiteList({ ...props, type })}
        />
      ),
    },
    {
      id: 'navButtons',
      label: '',
      minWidth: 100,
      component: (props: any) => (
        <CustomDeleteDialog
          labelForDelete={`Are you sure you want delete white list item `}
          objectId={props.id}
          deleteItemAction={deleteWhiteList}
        />
      ),
    },
  ];

  return (
    <CustomTable
      data={whitelistStore.whitelist.items}
      totalCount={whitelistStore.whitelist.totalCount}
      requestAction={loadWhitelist}
      isSortEnabled={false}
      columns={columns}
      defaultSortKey="id"
      isDataSliced
      pageAction={() => (
        <AddBlackWhiteListModal
          addBlackWhiteListRequest={addWhiteList}
          isWhiteList
        >
          Create new
        </AddBlackWhiteListModal>
      )}
    />
  );
});
