import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const getCustomTableStyle = makeStyles((theme: Theme) =>
  createStyles({
    patternItem: {
      margin: 0,
      padding: 0,
    },
  }),
);
