import React from 'react';
import {Launch} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';
import {Box, IconButton} from '@mui/material';

interface OwnProps {
  value: string;
  url: string;
}

export const LinkCell: React.FC<OwnProps> = ({value, url}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url);
  };

  return (
    <Box>
      {value}
      <IconButton size="small" onClick={handleClick}>
        <Launch style={{fontSize: '0.9rem'}} />
      </IconButton>
    </Box>
  );
};
