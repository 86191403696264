import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {OfferWallSchemaEntity} from '../../../proto/reward_pb';
import {getRewardSchemaStyles} from './reward-schema.styles';

export type DialogState = 'create' | 'edit' | 'closed';

const RewardTypes = {
  'postback-based': 'Postback based',
  'offer-based': 'Offer based',
};

const PayoutTypes = {
  'amount': 'Amount',
  'percent': 'Percent',
};

const PathTypes = {
  'dataPerProvider': 'Per provider',
  'aggregatedData': 'Aggregated',
};

const Fields = {
  'amount': 'Amount',
  'postbacksCount': 'Postback',
  'offerWallCount': 'Offerwall',
};

const ConditionType = {
  'moreThan': 'More than',
  'multiplicity': 'Multiplicity',
  'lessThan': 'Less than',
  'equals': 'Equals',
};

const PathField = ({value, onChange}) => {
  const [pathType, setPathType] = useState('dataPerProvider');
  const [pathField, setPathField] = useState('amount');

  useEffect(() => {
    if (value) {
      const data = value.split('.');
      if (data.length === 3) {
        setPathType(data[0]);
        setPathField(data[2]);
      }
      if (data.length === 2) {
        setPathType(data[0]);
        setPathField(data[1]);
      }
    }
  }, [value]);

  return (
    <Box sx={{flex: 1, display: 'flex'}}>
      <FormControl margin="normal" sx={{mr: 1, flex: 4}} fullWidth>
        <InputLabel>Statistic</InputLabel>
        <Select
          onChange={(e) => {
            setPathType(e.target.value);
            let val =
              e.target.value === 'aggregatedData'
                ? `${e.target.value}.${pathField}`
                : `${e.target.value}.$providerId.${pathField}`;
            onChange(val);
          }}
          value={pathType}
          label="Statistic"
        >
          {Object.entries(PathTypes).map(([value, title], idx) => (
            <MenuItem value={value} key={idx}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl margin="normal" sx={{flex: 3}} fullWidth>
        <InputLabel>Target</InputLabel>
        <Select
          onChange={(e) => {
            setPathField(e.target.value);
            onChange(
              pathType === 'aggregatedData'
                ? `${pathType}.${e.target.value}`
                : `${pathType}.$providerId.${e.target.value}`
            );
          }}
          value={pathField}
          label="Target"
        >
          {Object.entries(Fields).map(([value, title], idx) => (
            <MenuItem value={value} key={idx}>
              {title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

const Condition = ({idx, value, onChange, onDelete}) => {
  const [conditionType, setConditionType] = useState('moreThan');
  const [conditionValue, setConditionValue] = useState('');
  const [conditionPath, setConditionPath] = useState('');

  useEffect(() => {
    setConditionType(value.conditiontype);
    setConditionValue(value.conditionvalue);
    setConditionPath(value.conditionfieldpath);
  }, [value]);

  return (
    <Box>
      <Typography sx={{width: '100%'}}>Condition #{idx + 1}</Typography>
      <Box sx={{display: 'flex'}}>
        <FormControl margin="normal" sx={{mr: 1}}>
          <InputLabel>Type</InputLabel>
          <Select
            sx={{width: '125px'}}
            onChange={(e) =>
              onChange(
                Object.assign({}, value, {conditiontype: e.target.value})
              )
            }
            value={conditionType}
            label="Type"
          >
            {Object.entries(ConditionType).map(([value, title], idx) => (
              <MenuItem value={value} key={idx}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl margin="normal" sx={{mr: 1}}>
          <TextField
            sx={{width: '100px'}}
            type="number"
            onChange={(e) =>
              onChange(
                Object.assign({}, value, {
                  conditionvalue: Number(e.target.value),
                })
              )
            }
            value={conditionValue}
            placeholder="0"
            label="Value"
            InputLabelProps={{shrink: true}}
          />
        </FormControl>
        <PathField
          value={conditionPath}
          onChange={(conditionfieldpath) =>
            onChange(Object.assign({}, value, {conditionfieldpath}))
          }
        />
        <IconButton onClick={onDelete}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};

interface IRewardDialogProps {
  state: DialogState;
  data: OfferWallSchemaEntity.AsObject;
  onSubmit: (data: OfferWallSchemaEntity.AsObject) => void;
  onClose: () => void;
}

const getTitle = (state: DialogState) => {
  if (state === 'create') {
    return 'Create new reward schema';
  }

  if (state === 'edit') {
    return 'Edit reward schema';
  }
};

const getSubmit = (state: DialogState) => {
  if (state === 'create') {
    return 'Create';
  }

  if (state === 'edit') {
    return 'Update';
  }
};

export const RewardSchemaDialog = ({
  data,
  state,
  onClose,
  onSubmit,
}: IRewardDialogProps) => {
  const classes = getRewardSchemaStyles();

  const [name, setName] = useState('');
  const [rewardType, setRewardType] = useState(
    data.schema?.rewardtypesList[0].type || 'postback-based'
  );
  const [rewardPayoutType, setRewardPayoutType] = useState(
    data.schema?.rewardtypesList[0].rewardpayouttype || 'amount'
  );
  const [rewardPayoutValue, setRewardPayoutValue] = useState(
    data.schema?.rewardtypesList[0].rewardpayoutvalue || ''
  );
  const [rewardPayoutPath, setRewardPayoutPath] = useState(
    'dataPerProvider.$providerId.amount'
  );

  const [conditions, setConditions] = useState<any>([
    {
      conditiontype: 'moreThan',
      conditionvalue: '',
      conditionfieldpath: 'dataPerProvider.$providerId.amount',
    },
  ]);

  useEffect(() => setName(data.name), [data.name]);
  useEffect(
    () =>
      setRewardType(data.schema?.rewardtypesList[0].type || 'postback-based'),
    [data.schema?.rewardtypesList[0].type]
  );
  useEffect(
    () =>
      setRewardPayoutValue(
        data.schema?.rewardtypesList[0].rewardpayoutvalue || ''
      ),
    [data.schema?.rewardtypesList[0].rewardpayoutvalue]
  );
  useEffect(
    () =>
      setRewardPayoutType(
        data.schema?.rewardtypesList[0].rewardpayouttype || 'amount'
      ),
    [data.schema?.rewardtypesList[0].rewardpayouttype]
  );
  useEffect(() => {
    setRewardPayoutPath(
      data.schema?.rewardtypesList[0].rewardpayoutfieldpath ||
        'dataPerProvider.$providerId.amount'
    );
  }, [data.schema?.rewardtypesList[0].rewardpayoutfieldpath]);
  useEffect(
    () =>
      setConditions(
        data.schema?.rewardtypesList[0].conditionsList || [
          {
            conditiontype: 'moreThan',
            conditionvalue: '',
            conditionfieldpath: 'dataPerProvider.$providerId.amount',
          },
        ]
      ),
    [data.schema?.rewardtypesList[0].conditionsList]
  );

  const submitHandler = () => {
    onSubmit({
      id: data.id,
      name,
      active: data.active,
      identifier: data.identifier,
      schema: {
        containsmorethancondition: data.schema?.containsmorethancondition || false,
        rewardtypesList: [
          {
            type: rewardType,
            rewardpayouttype: rewardPayoutType,
            rewardpayoutvalue: Number(rewardPayoutValue),
            rewardpayoutfieldpath: rewardPayoutPath,
            conditionsList: conditions,
          },
        ],
      },
    });
  };

  const addCondition = () => {
    setConditions([
      ...conditions,
      {
        conditiontype: 'moreThan',
        conditionvalue: '',
        conditionfieldpath: 'dataPerProvider.$providerId.amount',
      },
    ]);
  };

  return (
    <Dialog
      open={state !== 'closed'}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{getTitle(state)}</DialogTitle>
      <DialogContent>
        <Box className={classes.body}>
          <FormControl margin="normal" fullWidth>
            <TextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              label="Name"
              InputLabelProps={{shrink: true}}
              placeholder="Reward schema name"
            />
          </FormControl>
          <FormControl margin="normal" sx={{mr: 1}} fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              onChange={(e) => setRewardType(e.target.value)}
              value={rewardType}
              label="Type"
            >
              {Object.entries(RewardTypes).map(([value, title], idx) => (
                <MenuItem value={value} key={idx}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{display: 'flex', justifyContent: 'start'}}>
            <FormControl margin="normal" sx={{mr: 1}}>
              <InputLabel>Payout type</InputLabel>
              <Select
                sx={{width: '105px'}}
                onChange={(e) => setRewardPayoutType(e.target.value)}
                value={rewardPayoutType}
                label="Payout type"
              >
                {Object.entries(PayoutTypes).map(([value, title], idx) => (
                  <MenuItem value={value} key={idx}>
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="normal" sx={{mr: 1}}>
              <TextField
                sx={{width: '120px'}}
                onChange={(e) => setRewardPayoutValue(e.target.value)}
                type="number"
                value={rewardPayoutValue}
                label={`Payout ${rewardPayoutType}`}
                InputLabelProps={{shrink: true}}
                placeholder="0"
              />
            </FormControl>
            {rewardPayoutType === 'percent' && (
              <PathField
                value={rewardPayoutPath}
                onChange={(value) => setRewardPayoutPath(value)}
              />
            )}
          </Box>
          <Box sx={{pt: 1}}>
            {conditions.map((value, idx) => (
              <Condition
                value={value}
                idx={idx}
                key={idx}
                onDelete={() => {
                  const result = conditions.concat();
                  result.splice(idx, 1);
                  setConditions([...result]);
                }}
                onChange={(value) => {
                  conditions[idx] = value;
                  setConditions([...conditions]);
                }}
              />
            ))}
          </Box>
          <Button
            size="small"
            variant="outlined"
            sx={{mt: 1}}
            onClick={addCondition}
          >
            Add condition
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.buttons}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={submitHandler} autoFocus>
            {getSubmit(state)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
