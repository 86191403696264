import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import {
  Column,
  CustomTable,
  SelectCell,
  TextCell,
} from '../../components/table';
import { CustomDeleteDialog } from '../../components/dialogs';
import {
  CreateVersion,
  FindVersion,
  UpdateVersion,
} from '../../proto/balance_pb';
import { CreateSettingModal } from './modal';

const OSList = {
  1: 'ANDROID',
  2: 'IOS',
};

const StatusList = {
  available: 'AVAILABLE',
  forbidden: 'FORBIDDEN',
};

export const CashoutVersion = observer(() => {
  const { cashoutVersionStore } = useStores();

  const loadCashOutVersion = (dto: FindVersion.AsObject) =>
    cashoutVersionStore.getCashoutVersions(dto);

  const createCashOutVersion = (dto: CreateVersion.AsObject) =>
    cashoutVersionStore.createCashoutVersion(dto);

  const updateCashOutVersion = (dto: UpdateVersion.AsObject) =>
    cashoutVersionStore.updateCashoutVersion(dto);

  const deleteCashOutVersion = (id: number) =>
    cashoutVersionStore.deleteCashOutVersion(id);

  const columns: Column[] = [
    {
      id: 'id',
      label: 'ID',
      minWidth: 50,
    },
    {
      id: 'os',
      label: 'OS Name',
      minWidth: 100,
      component: (props: any) => (
        <SelectCell
          value={props.os}
          options={OSList}
          onChange={(os) => updateCashOutVersion({ ...props, os: Number(os) })}
        />
      ),
    },
    {
      id: 'value',
      label: 'Version',
      minWidth: 100,
      component: (props: any) => (
        <TextCell
          value={props.value}
          onChange={(value) => updateCashOutVersion({ ...props, value })}
        />
      ),
    },
    {
      id: 'inappstatus',
      label: 'Status',
      minWidth: 100,
      component: (props: any) => (
        <SelectCell
          value={props.inappstatus}
          options={StatusList}
          onChange={(inappstatus) =>
            updateCashOutVersion({ ...props, inappstatus })
          }
        />
      ),
    },
    {
      id: 'navButtons',
      label: '',
      minWidth: 100,
      component: (props: any) => (
        <CustomDeleteDialog
          labelForDelete={`Are you sure you want delete setting`}
          objectId={props.id}
          deleteItemAction={deleteCashOutVersion}
        />
      ),
    },
  ];

  return (
    <CustomTable
      columns={columns}
      data={cashoutVersionStore.versions.entities}
      totalCount={cashoutVersionStore.versions.totalCount}
      isSortEnabled={false}
      requestAction={loadCashOutVersion}
      pageAction={() => (
        <CreateSettingModal createAction={createCashOutVersion} />
      )}
    />
  );
});
