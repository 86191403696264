export interface LogType {
  type: 'error' | 'warning' | 'trace' | 'success';
  msg: string;
}

export enum TestStatus {
  running,
  passed,
  failed,
}

export interface UpdateTestStatusBroadcast {
  id: number;
  status: TestStatus;
  logs: LogType[];
  method: string;
  createdAt: number;
}


export interface GetListRequest {
  accessToken: string;
}

export interface GetListResponse {
  tests: GetListResponse_Test[];
}

export type TestParamsType = 'number' | 'string' | 'oneof';

export interface GetListResponse_ParamsEntry {
  key: string;
  type: TestParamsType;
  data: string;
  default?: string;
}

export interface GetListResponse_Test {
  name: string;
  params: GetListResponse_ParamsEntry[];
}

export interface GetTestsWithPaginationRequest {
  page: number;
  perPage: number;
}

export interface GetTestsWithPaginationResponse {
  tests: GetTestsWithPaginationResponse_Test[];
  count: number;
}

export interface GetTestsWithPaginationResponse_Log {
  type: string;
  msg: string;
}

export interface GetTestsWithPaginationResponse_Test {
  id: number;
  status: TestStatus;
  method: string;
  logs: GetTestsWithPaginationResponse_Log[];
  createdAt: number;
}

export interface RunRequest {
  testName: string;
  params: RunRequest_ParamsEntry[];
}

export interface RunRequest_ParamsEntry {
  key: string;
  type: string;
}

export interface RunResponse {
}

export interface RunPerformanceRequest {
  testName: string;
  params: RunPerformanceRequest_ParamsEntry[];
  testsCount: number;
  runInterval: number;
}

export interface RunPerformanceRequest_ParamsEntry {
  key: string;
  type: string;
}

export interface RunPerformanceResponse {
}

export interface RunMultipleRequest {
  tests: RunMultipleReqest_Test[];
}

export interface RunMultipleReqest_ParamsEntry {
  key: string;
  type: string;
}

export interface RunMultipleReqest_Test {
  testName: string;
  params: RunMultipleReqest_ParamsEntry[];
}

export interface RunMultipleResponse {
}
