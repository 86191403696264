export const settings = {
  getWithPagination: {
    microserviceName: 'settings',
    serviceName: 'SettingsService',
    method: 'getWithPagination',
  },
  getByMicroservices: {
    microserviceName: 'settings',
    serviceName: 'SettingsService',
    method: 'getByMicroservices',
  },
  update: {
    microserviceName: 'settings',
    serviceName: 'SettingsService',
    method: 'update',
  },
  getFrontendSettings: {
    microserviceName: 'settings',
    serviceName: 'FrontendSettingsService',
    method: 'getByCertainVersion',
  },
  updateFrontendSettings: {
    microserviceName: 'settings',
    serviceName: 'FrontendSettingsService',
    method: 'update',
  },
  getAllFrontendSettingsVersions: {
    microserviceName: 'settings',
    serviceName: 'FrontendSettingsService',
    method: 'getAllVersions',
  },
  deleteFrontendSettings: {
    microserviceName: 'settings',
    serviceName: 'FrontendSettingsService',
    method: 'deleteByVersion',
  },
}