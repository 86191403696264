import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import { RewardDailyCashGoalDeleteIterationRoute, RewardDailyCashGoalFindAllRoute, RewardDailyCashGoalUpdateRoute } from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export class DailyCoinsGoalStore {
  public error: RpcError | null = null;

  public templates: RewardDailyCashGoalFindAllRoute.ResponseType['templates'] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getTemplates() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const response: RewardDailyCashGoalFindAllRoute.ResponseType = yield WebSocketService
        .sendRequest<RewardDailyCashGoalFindAllRoute.ResponseType, RewardDailyCashGoalFindAllRoute.RequestType>({
          method: 'reward_dailyCoinsGoalFindAll',
          data: {},
        });

      this.templates = response.templates.map(
        (template) => ({ ...template, levels: template.levels.map(
          (level) => ({ ...level, id: Math.random(), groups: level.groups.map(
            (group) => ({ ...group, id: Math.random(), tasks: group.tasks.map(
              (task) => ({ ...task, id: Math.random() }),
            )}),
          )}),
        )}),
      );
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *updateTemplate(dto: RewardDailyCashGoalUpdateRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardDailyCashGoalUpdateRoute.ResponseType, RewardDailyCashGoalUpdateRoute.RequestType>({
          method: 'reward_dailyCoinsGoalUpdate',
          data: dto,
        });

      this.getTemplates();
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *deleteIteration(dto: RewardDailyCashGoalDeleteIterationRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<RewardDailyCashGoalDeleteIterationRoute.ResponseType, RewardDailyCashGoalDeleteIterationRoute.RequestType>({
          method: 'reward_dailyCoinsGoalDeleteIteration',
          data: dto,
        });

      this.getTemplates();
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
