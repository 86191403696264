import React, {useState} from 'react';
import { Box, collapseClasses, IconButton } from '@mui/material';
import {Close, Done, Edit} from '@mui/icons-material';
import {getEditableHocStyle} from './editable.styles';

interface Props {
  children: (editable: boolean) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const EditableHOC: React.FC<Props> = ({children, onSave, onCancel}) => {
  const classes = getEditableHocStyle();
  const [editable, setEditable] = useState(false);

  return (
    <Box className={classes.wrapper}>
      <Box><>{children(editable)}</></Box>
      <Box className={classes.btnContainers}>
        {editable ? (
          <Box className={classes.iconWrapper}>
            <IconButton
              onClick={() => {
                setEditable(false);
                onSave();
              }}
            >
              <Done className={classes.doneIcon} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditable(false);
                onCancel();
              }}
            >
              <Close className={classes.closeIcon} />
            </IconButton>
          </Box>
        ) : (
            <Box className={classes.iconWrapper}>
              <IconButton onClick={() => setEditable(true)}>
                <Edit className={classes.editIcon} />
              </IconButton>
            </Box>
        )}
      </Box>
    </Box>
  );
};
