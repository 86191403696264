import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  TextField,
  Box,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { getCreateSettingStyle } from './create-setting.styles';
import { CreateVersion } from '../../../proto/balance_pb';

const osValue: Record<string, any> = {
  ANDROID: 1,
  IOS: 2,
};

const statusValue: Record<string, any> = {
  AVAILABLE: 'available',
  FORBIDDEN: 'forbidden',
};

interface OwnProps {
  createAction: any;
}

type Props = OwnProps;

export const CreateSettingModal: React.FC<Props> = ({ createAction }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = useState<CreateVersion.AsObject>({
    accesstoken: '',
    value: '',
    os: 2,
    inappstatus: 'available',
  });
  const classes = getCreateSettingStyle();
  const [openSelectOS, setOpenSelectOS] = React.useState<null | string>(null);
  const [openSelectStatus, setOpenSelectStatus] = React.useState<null | string>(
    null,
  );

  const handleOpenModalCreate = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = (data: CreateVersion.AsObject) => {
    createAction(data);
    setOpen(false);
  };

  const handleOpenSelectOS = (id: string) => {
    setOpenSelectOS(id);
  };

  const handleOpenSelectStatus = (id: string) => {
    setOpenSelectStatus(id);
  };

  const handleChangeOS = (e: any) => {
    const inputValue = e.target.value;

    setState({ ...state, os: inputValue });
  };

  const handleChangeStatus = (e: any) => {
    const inputValue = e.target.value;

    setState({ ...state, inappstatus: inputValue });
  };

  const handleCloseSelectOS = () => {
    setOpenSelectOS(null);
  };

  const handleCloseSelectStatus = () => {
    setOpenSelectStatus(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    return () => {
      setState({ ...state, os: 2, inappstatus: 'available' });
    };
  }, [open]);

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleOpenModalCreate}
      >
        <Add /> Create
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.root,
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box className={classes.wrapper}>
              <Typography className={classes.title}>
                Create new configuration setting
              </Typography>
              <Box className={classes.textField} key={'value'}>
                <TextField
                  className={classes.input}
                  onChange={handleChange}
                  value={state.value}
                  label={'Version'}
                  name={'value'}
                />
              </Box>
              <Select
                className={classes.input}
                id="select"
                open={openSelectOS === `select`}
                onClose={handleCloseSelectOS}
                onOpen={() => handleOpenSelectOS('select')}
                onChange={handleChangeOS}
                value={state.os}
                label="OS"
                name={`os`}
              >
                {Object.keys(osValue).map((el: any, idx: number) => (
                  <MenuItem value={osValue[el]} key={idx}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
              <Select
                className={classes.input}
                id="select"
                open={openSelectStatus === `select`}
                onClose={handleCloseSelectStatus}
                onOpen={() => handleOpenSelectStatus('select')}
                onChange={handleChangeStatus}
                value={state.inappstatus}
                label="Currency"
                name={`currency`}
              >
                {Object.keys(statusValue).map((el: any, idx: number) => (
                  <MenuItem value={statusValue[el]} key={idx}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleAgree(state)}
            color="primary"
            autoFocus
            disabled={!state.value}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
