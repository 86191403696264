import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import {
  DailyReport,
  DailyReportMany,
  FindDailyReports,
} from '../../proto/balance_pb';
import { AppStore } from '../app';
import { BalanceDailyReportGetOneRoute, BalanceDailyReportGetRoute } from '@boints/grpc';
import { WebSocketService } from '../../services/transport';

export class DailyReportStore {
  public error: RpcError | null = null;

  public report: DailyReport.AsObject = { id: 0, reportMap: [], timestamp: 0 };

  public reports: DailyReportMany.AsObject = {
    entitiesList: [],
    totalcount: 0,
    pagenumber: 1,
    amountperpage: 10,
  };

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getDailyReports(dto: FindDailyReports.AsObject) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { amountPerPage, entities, pageNumber, totalCount }: BalanceDailyReportGetRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceDailyReportGetRoute.ResponseType, BalanceDailyReportGetRoute.RequestType>({
          method: 'balance_dailyReportGet',
          data: {
            amountPerPage: dto.amountperpage || 10,
            pageNumber: dto.pagenumber || 1,
          },
        });

      this.reports.amountperpage = amountPerPage;
      this.reports.pagenumber = pageNumber;
      this.reports.totalcount = totalCount;

      this.reports.entitiesList = entities.map(({ id, report, timestamp }) => {
        return {
          id,
          timestamp,
          reportMap: Object.entries(report),
        };
      });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *getOneDailyReport(id: number) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { id: reportId, report, timestamp }: BalanceDailyReportGetOneRoute.ResponseType = yield WebSocketService
        .sendRequest<BalanceDailyReportGetOneRoute.ResponseType, BalanceDailyReportGetOneRoute.RequestType>({
          method: 'balance_dailyReportGetOne',
          data: {
            id,
          },
        });

      this.report.id = reportId;
      this.report.timestamp = timestamp;
      this.report.reportMap = Object.entries(report);
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
