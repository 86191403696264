import { observer } from 'mobx-react';
import { useStores } from '../../stores/app';
import { Column, CustomTable, SelectCell } from '../../components/table';
import { Box, Checkbox } from '@mui/material';
import { CustomDeleteDialog } from '../../components/dialogs';
import { getCustomTableStyleV2 } from './daily-coins-goal.styles';
import { RewardDailyCashGoalFindAllRoute, RewardDailyCashGoalUpdateRoute } from '@boints/grpc';
import { EditDailyCoinsGoal } from './edit';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';

const enableSettingsName = "DAILY_GOAL_TYPE";

const dropDownListItems = {
  "single": 'Daily goal',
  "step": 'Daily cash goal',
  "daily_coins_goal": 'Daily Coins Goal',
  'off': 'Off',
};

export const DailyCoinsGoal = observer(() => {
  const { settingsStore, dailyCoinsGoalStore } = useStores();
  const classes = getCustomTableStyleV2();

  const patternRender = (template: RewardDailyCashGoalFindAllRoute.ResponseType['templates'][number]) => {
    return (
      <div key={template.id}>
        {template.levels.map((level) => (
          <div key={(level as any).id} className={classes.patternItem}>
            <b style={{ }}>
              {`Offerwall: ${level.offerwall} - Total reward: ${level.groups.reduce((totalSum, group) => totalSum + (group.tasks.reduce((sum, task) => sum + task.rewardAmount, 0)), 0)} Coins`}
            </b>
            {level.groups.map(group => (
            <>{`${group.description} - ${group.tasks.reduce((sum, task) => sum + task.rewardAmount, 0)} Coins`}<br/></>
            ))}
          </div>
        ))}
      </div>
    );
  };
  
  const fetchData = () => {
    settingsStore.getSettingsByMicroservices({ accesstoken: '', microservicesList: ["orchestrator", "balance"] });
    dailyCoinsGoalStore.getTemplates();
  };

  const deleteItem = (iterationIndex: number) => {
    dailyCoinsGoalStore.deleteIteration({ iterationIndex: iterationIndex });
  };

  const updateItem = (item: RewardDailyCashGoalUpdateRoute.RequestType) => {
    if (item.iterationIndex === -1) {
      item.iterationIndex = dailyCoinsGoalStore.templates.length;
    }
    dailyCoinsGoalStore.updateTemplate(item);
  };


  const actionButtons = (props) => {
    console.log(props);
    return (
      <Box style={{ display: 'flex' }}>
        <EditDailyCoinsGoal
          updatableObject={props}
          updateItemAction={updateItem}/>
          <CustomDeleteDialog
            labelForDelete={`Are you sure you want delete Daily Coins Goal Level `}
            objectId={props.id}
            deleteItemAction={deleteItem}
          />
      </Box>
    );
  };

  const columns: Column[] = [
    {
      id: 'iterationIndex',
      label: 'Iteration ID',
      format: (item) => "#" + (item + 1),
    },
    {
      id: 'version',
      label: 'Iteration Version',
      format: (item) => "v" + item,
    },
    {
      id: 'levels',
      label: 'Iteration Preview',
      component: patternRender,
    },
    {
      id: 'actionButton',
      label: '',
      labelComponent: () => (<EditDailyCoinsGoal
        isCreate
        updatableObject={{
          id: 0,
          iterationIndex: -1,
          version: 0,
          levels: [],
        }}
        updateItemAction={updateItem}/>),
      component: actionButtons,
    }
  ];

  return (
    <>
      <div className={classes.selectContainer}>
        <SelectCell
          options={dropDownListItems}
          value={settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName)?.value || 'off'}
          onChange={(val) => {
            const sett = settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName);
            if (sett) settingsStore.updateSettingsByMicroservice({ id: sett.id, value: val, key: sett.key } as RequestUpdateSettingsDto.AsObject);
          }}
        />
      </div>
      <CustomTable
        data={dailyCoinsGoalStore.templates}
        requestAction={fetchData}
        columns={columns}
        isPaginationShow={false}
        isSortEnabled={false}
      />
    </>

  );
});
