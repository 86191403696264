import React from 'react';
import {CircularProgress} from '@mui/material';
import {getLoadingStyles} from './loading.styles';

interface Props {
  isLoading: boolean;
}

export const Loading: React.FC<Props> = ({isLoading}) => {
  const classes = getLoadingStyles();

  if (!isLoading) {
    return null;
  }

  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};
