import { Box, Card, Checkbox, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Column, CustomTable, SelectCell, TextCell } from '../../components/table';
import { useStores } from '../../stores/app';
import { CreateEditModal } from '../../components/modals/create-edit';
import { EditableField } from '../../core/interfaces/modal.interfaces';
import { EditableFieldType } from '../../core/enum/modals.enum';
import { CustomDeleteDialog } from '../../components/dialogs';
import { RequestUpdateSettingsDto } from '../../proto/leaderboard_pb';
import { Setting } from '../../proto/settings_pb';

export const DailyCashGoal = observer(() => {
  const { bonusStore, settingsStore } = useStores();

  const [currSettings, setCurrentSettings] = useState({});
  const [singleAmount, setSingleAmount] = useState({});
  const [singleAfter, setSingleAfter] = useState({});

  const [strikeEnable, setStrikeEnable] = useState({});
  const [strikeDays, setStrikeDays] = useState({});
  const [strikeAmount, setStrikeAmount] = useState({});

  const enableSettingsName = "DAILY_GOAL_TYPE";
  const singleDailySettingsAmount = "USER_EARNING_BONUS_AMOUNT";
  const singleDailySettingsAfter = "USER_EARNING_BONUS_REWARD_AFTER";

  const enableStrikeSettingsName = "IS_DAILY_STRIKE_ENABLE";
  const daysStrikeSettingsName = "BONUS_STRIKE_DAYS_NEED";
  const amountStrikeSettingsName = "BONUS_STRIKE_BOINTS_REWARD";

  const dropDownListItems = {
    "single": 'Daily goal',
    "step": 'Daily cash goal',
    "daily_coins_goal": 'Daily Coins Goal',
    'off': 'Off',
  };

  const getReward = (item: any) => {
    bonusStore.getStepBonusConfig({
      accesstoken: '',
    });
  };

  const addNewReward = (item: any) => {
    bonusStore.createStepBonusConfig({
      accesstoken: '',
      step: {
        id: 0,
        rewardafter: item.rewardafter,
        rewardamount: item.rewardamount,
      },
    });
  };

  const updateReward = (item: any) => {
    bonusStore.updateStepBonusConfig({
      accesstoken: '',
      step: {
        id: item.id,
        rewardafter: item.rewardafter,
        rewardamount: item.rewardamount,
      },
    });
  };

  const deleteReward = (item: any) => {
    bonusStore.deleteStepBonusConfig({
      accesstoken: '',
      id: item,
    });
  };

  const columns: Column[] = [
    {
      id: "id",
      label: 'id',
    },
    {
      id: "rewardafter",
      label: 'rewardAfter',
      minWidth: 500,
    },
    {
      id: "rewardamount",
      label: 'rewardAmount',
      minWidth: 500,
    },
    {
      id: "",
      label: '',
      component: (props) => (<>
        <CreateEditModal
          tooltipText="Edit daily streak"
          btnLabel=''
          dialogName="Daily streak"
          editableFields={editabledRewardItems}
          acceptAction={updateReward}
          currItem={props}
        />
        <CustomDeleteDialog
          labelForDelete={`Are you sure you want delete daily goal item #`}
          objectId={props.id}
          deleteItemAction={deleteReward}
        />
      </>),
    },
  ];

  const editabledRewardItems: EditableField[] = [
    {
      label: "Reward After",
      type: EditableFieldType.number,
      paramName: "rewardafter",
    },
    {
      label: "Reward Amount",
      type: EditableFieldType.number,
      paramName: "rewardamount",
    },
  ];

  useEffect(() => {
    settingsStore.getSettingsByMicroservices({ accesstoken: '', microservicesList: ["orchestrator", "balance"] });
  }, []);

  useEffect(() => {

    const sett = settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName);
    const amount = settingsStore.featureToggleSettings.settingsList.find(item => item.key === singleDailySettingsAmount);
    const after = settingsStore.featureToggleSettings.settingsList.find(item => item.key === singleDailySettingsAfter);

    if (sett) {
      setCurrentSettings(sett);
    }
    if (amount) {
      setSingleAmount(amount);
    }
    if (after) {
      setSingleAfter(after);
    }

    setStrikeEnable(settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableStrikeSettingsName) ?? {});
    setStrikeDays(settingsStore.featureToggleSettings.settingsList.find(item => item.key === daysStrikeSettingsName) ?? {});
    setStrikeAmount(settingsStore.featureToggleSettings.settingsList.find(item => item.key === amountStrikeSettingsName) ?? {});
  }, [settingsStore.featureToggleSettings.settingsList]);

  const updateSettings = (id, value, key) => {
    settingsStore.updateSettingsByMicroservice({ id, value, key } as RequestUpdateSettingsDto.AsObject);
  };

  return (
    <Box>
      <Card style={{ margin: '10px' }} elevation={4}>
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '10px' }}>Daily Goal</Box>
        <Box style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
          <Box style={{ margin: '5px', display: 'flex', alignItems: 'center' }}>
            <Typography style={{ marginRight: '10px' }}>Daily Goal Type:</Typography>

            <SelectCell
              options={dropDownListItems}
              value={settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName)?.value || 'off'}
              onChange={(val) => {
                const sett = settingsStore.featureToggleSettings.settingsList.find(item => item.key === enableSettingsName);
                if (sett) settingsStore.updateSettingsByMicroservice({ id: sett.id, value: val, key: sett.key } as RequestUpdateSettingsDto.AsObject);
              }}
            />
          </Box>
          {/* <Box style={{ margin: '5px', display: 'flex', alignItems: 'center' }}>
					<Typography style={{ marginRight: '10px' }}>Daily streak enabled?:</Typography>

					<Checkbox
						checked={item.value === 'true'}
						color="primary"
						onChange={(val) => { updateData(item.id, String(!(item.value === 'true')), item.key) }}
					/>
				</Box> */}

          {currSettings && (currSettings as Setting.AsObject).value === 'step' ? 
            <Box style={{ display: 'flex' }}>
              <CreateEditModal
                tooltipText="Update daily streak"
                btnLabel='Create new'
                dialogName="Daily streak"
                editableFields={editabledRewardItems}
                acceptAction={addNewReward}
              />
            </Box> : <></>}

        </Box>

        {currSettings && (currSettings as Setting.AsObject).value === 'step' ?
          <CustomTable
            data={bonusStore.bonusStepConfig}
            requestAction={getReward}
            // requestAction={() => { }}
            columns={columns}
            isPaginationShow={false}
            tableInnerProps={{ "size": "small" }}
            isSortEnabled={false}
            isDataSliced={true}
          /> : <></>}

        {currSettings && (currSettings as Setting.AsObject).value === 'single' ?
          <Box style={{ width: '100%' }}>
            <Card elevation={1} style={{ display: 'flex', padding: '10px', margin: '10px', justifyContent: 'space-between' }}>
              <Box style={{ width: '600px' }}>
                <Typography>{(singleAmount as Setting.AsObject).description}</Typography>
                <Typography color='text.secondary'>{(singleAmount as Setting.AsObject).key}</Typography>
              </Box>
              <Box style={{ width: '300px' }}>
                <TextCell
                  value={(singleAmount as Setting.AsObject).value}
                  onChange={(value) => updateSettings((singleAmount as Setting.AsObject).id, value, (singleAmount as Setting.AsObject).key)}
                />
              </Box>
            </Card>

            <Card elevation={1} style={{ display: 'flex', padding: '10px', margin: '10px', justifyContent: 'space-between' }}>
              <Box style={{ width: '600px' }}>
                <Typography>{(singleAfter as Setting.AsObject).description}</Typography>
                <Typography color='text.secondary'>{(singleAfter as Setting.AsObject).key}</Typography>
              </Box>
              <Box style={{ width: '300px' }}>
                <TextCell
                  value={(singleAfter as Setting.AsObject).value}
                  onChange={(value) => updateSettings((singleAfter as Setting.AsObject).id, value, (singleAfter as Setting.AsObject).key)}
                />
              </Box>
            </Card>
          </Box> : <></>}
      </Card>
      <Card style={{ margin: '10px' }} elevation={4}>
        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '20px' }}>Daily Streak</Box>

        <Box style={{ width: '100%' }}>
          <Card elevation={1} style={{ display: 'flex', padding: '10px', margin: '10px', justifyContent: 'space-between' }}>
            <Box style={{ width: '600px' }}>
              <Typography>{(strikeEnable as Setting.AsObject).description}</Typography>
              <Typography color='text.secondary'>{(strikeEnable as Setting.AsObject).key}</Typography>
            </Box>
            <Box style={{ width: '300px' }}>
              <Checkbox
                checked={(strikeEnable as Setting.AsObject).value === 'true'}
                color="primary"
                onChange={(value) => updateSettings((strikeEnable as Setting.AsObject).id, String(!((strikeEnable as Setting.AsObject).value === 'true')), (strikeEnable as Setting.AsObject).key)}
              />
            </Box>
          </Card>

          <Card elevation={1} style={{ display: 'flex', padding: '10px', margin: '10px', justifyContent: 'space-between' }}>
            <Box style={{ width: '600px' }}>
              <Typography>{(strikeDays as Setting.AsObject).description}</Typography>
              <Typography color='text.secondary'>{(strikeDays as Setting.AsObject).key}</Typography>
            </Box>
            <Box style={{ width: '300px' }}>
              <TextCell
                value={(strikeDays as Setting.AsObject).value}
                onChange={(value) => updateSettings((strikeDays as Setting.AsObject).id, value, (strikeDays as Setting.AsObject).key)}
              />
            </Box>
          </Card>

          <Card elevation={1} style={{ display: 'flex', padding: '10px', margin: '10px', justifyContent: 'space-between' }}>
            <Box style={{ width: '600px' }}>
              <Typography>{(strikeAmount as Setting.AsObject).description}</Typography>
              <Typography color='text.secondary'>{(strikeAmount as Setting.AsObject).key}</Typography>
            </Box>
            <Box style={{ width: '300px' }}>
              <TextCell
                value={(strikeAmount as Setting.AsObject).value}
                onChange={(value) => updateSettings((strikeAmount as Setting.AsObject).id, value, (strikeAmount as Setting.AsObject).key)}
              />
            </Box>
          </Card>
        </Box>
      </Card>
    </Box>
  );
});
