import moment from 'moment';
import {BalanceMapper} from '../enum';

interface IVersion {
  os: number;
  startversion: string;
  currentversion: string;
}

class OSDictionary {
  static ANDROID = 1;
  static IOS = 2;
}

export enum EventStatus {
  NOT_STARTED = 'not_started',
  CURRENT = 'current',
  FINISHED = 'finished',
}

export const VersionInfoAndroid = (props): string => {
  if (props?.length) {
    const version = props.filter(
      (item) => item.os === OSDictionary.ANDROID
    )[0];
    return version ? version.currentVersion : '-';
  } else {
    return '-';
  }
};

export const VersionInfoIos = (props): string => {
  if (props?.length) {
    const version = props.filter(
      (item) => item.os === OSDictionary.IOS
    )[0];
    return version ? version.currentVersion : '-';
  } else {
    return '-';
  }
};

const payment: Record<number, string> = {
  1: 'PayPal',
  2: 'Amazon',
};

export const convertWithdrawalMethod = (a: number) => {
  return payment[a];
};

export const dateFormat = (value: any): string => {
  if (value && Number(value) !== 0) {
    return moment(+value).format('MM/DD/YYYY, HH:mm:ss');
  } else {
    return '-';
  }
};

export const onlyDateFormat = (value: any): string => {
  if (value && Number(value) !== 0) {
    return moment(+value).format('MM/DD/YYYY');
  } else {
    return '-';
  }
};

export const onlyTimeFormat = (value: any): string => {
  if (value && Number(value) !== 0) {
    return moment(+value).format('HH:mm:ss');
  } else {
    return '-';
  }
};

export const fraudStatusKeys: Record<string, string> = {
  1: 'Pass it',
  2: 'Need to check',
  3: 'Suspicious ticket',
};

export enum SettingsDictionary {
  AUTH = 1,
  BALANCE = 2,
  LEADERBOARD = 3,
  SCHEDULE = 4,
  OFFERWALL = 5,
  ALL = 10,
}

export const settingsDictionaryKeys: Record<string, string> = {
  1: 'Auth',
  2: 'Balance',
  3: 'Leaderboard',
  // 4:'Schedule',
  5: 'Offerwall',
  10: 'All services',
};

export const transactionType = (val: number): string => {
  return BalanceMapper[val] || 'unknown';
};
