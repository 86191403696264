import React, { useState } from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  TextField,
} from '@mui/material';
import { useStores } from '../../../stores/app';

interface OwnProps {
  testName?: string;
  runTestAction: (params: { testsCount: number, runInterval: number }) => any;
}

type Props = OwnProps;

export const PerformanceTestDialog: React.FC<Props> = ({
  runTestAction,
  testName,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [testsCount, setTestsCount] = useState<string>('');
  const [runInterval, setRunInterval] = useState<string>('');

  const { notificationStore } = useStores();

  const handleClickOpen = () => {
    if (!testName) {
      notificationStore.enqueueSnackBar({ variant: 'error', message: 'Select the test' });
      return;
    }

    setOpenDialog(true);
  };

  const handleCloseModal = () => {
    setOpenDialog(false);
  };
  
  const handleAction = () => {
    if (!+testsCount || !+runInterval) {
      notificationStore.enqueueSnackBar({
        variant: 'error', message: 'Enter not 0 params',
      });
      return;
    }

    setOpenDialog(false);
    runTestAction({ testsCount: +testsCount, runInterval: +runInterval });
  };

  const handleChangeTestsCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setTestsCount(e.target.value);
    }
  };

  const handleChangeRunInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setRunInterval(e.target.value);
    }
  };

  return (
    <div className="col-auto">
      <Dialog
        open={openDialog}
        onClose={handleCloseModal}
        aria-labelledby="test-dialog-title"
        aria-describedby="test-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="test-dialog-description">
            Write performance test {testName} params
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <Box>
            <div className="row">
              <div className="col-6">
                <TextField
                  type='text'
                  label='Tests count'
                  placeholder='Tests count'
                  onChange={handleChangeTestsCount}
                  value={testsCount}
                />
              </div>
              <div className="col-6">
                <TextField
                  type='text'
                  label='Run interval'
                  placeholder='Run interval'
                  onChange={handleChangeRunInterval}
                  value={runInterval}
                />
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAction} color="primary" autoFocus>
            Start test
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={handleClickOpen} variant='contained' color='info'>Run performance test</Button>
    </div>
  );
};
