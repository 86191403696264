import {createStyles, makeStyles} from '@mui/styles';
import {Theme, alpha} from '@mui/material/styles';

export const getLoadingStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      background: alpha('#000', 0.3),
      zIndex: 2000,
    },
  });
});
