import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    banResetContainer: {
      display: 'flex',
    },
    banReserBtn: {
      margin: '0px 10px 0px 0px !important',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px !important',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '24px',
    },
    cancelContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      // marginRight: '24px !important'
    },
  })
);
