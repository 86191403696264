interface Queue {
  resolve: () => void;
  timer: ReturnType<typeof setTimeout>;
}


export class Semaphore {
  private counter: number;
  private timeout: number;
  private size: number;
  private queue: Queue[];

  constructor() {
    this.counter = 1;
    this.timeout = 25000;
    this.size = 10000;
    this.queue = [];
  }

  public enter(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.counter > 0) {
        this.counter--;
        resolve();
        return;
      }
      if (this.queue.length >= this.size) {
        reject(new Error('Semaphore queue is full'));
        return;
      }
      const timer = setTimeout(() => {
        this.queue.shift();
        reject(new Error('Semaphore timeout'));
      }, this.timeout);
      this.queue.push({ resolve, timer });
    });
  }

  public leave(): void {
    if (this.queue.length === 0) {
      this.counter++;
      return;
    }
    const item = this.queue.shift();
    if (item) {
      const { resolve, timer } = item;
      clearTimeout(timer);
      setTimeout(() => {
        resolve();
      }, 0);
    }
  }

  public sizeQueue(): number {
    return this.queue.length;
  }
}
