import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';
import {red, green} from '@mui/material/colors';

export const getEditableHocStyle = makeStyles((theme: Theme) => {
  return createStyles({
    input: {
      width: '100%',
      marginRight: theme.spacing(1),
    },
    autocompleteInput: {
      minWidth: 550,
    },
    wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1, 0),
      maxWidth: 200,
    },
    iconWrapper: {
      display: 'flex',
      position: 'absolute',
      top: '-20px',
    },
    doneIcon: {
      color: green[600],
    },
    closeIcon: {
      color: red[600],
    },
    editIcon: {
      color: theme.palette.primary.main,
    },
    btnContainers: {
      position: 'relative',
    }
  });
});
