// Headers type [{key}]
const arrayToCsv = (headers: any, data: any) => {
  const csvRows: any[] = [];
  const headerValues = headers.map((header: any) => header.key);
  csvRows.push(headerValues.join(','));

  for (const row of data) {
    const rowValues = headers.map((header: any) => {
      const escaped = ('' + row[header.key]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(rowValues.join(','));
  }
  return csvRows.join('\n');
};

const download = (data: any, fileName: string) => {
  const blob = new Blob([data], {type: 'text/csv'});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName + '.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSV = (header: any, data: any, filename: string) => {
  const csvData = arrayToCsv(header, data);
  download(csvData, filename);
};
