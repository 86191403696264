import React, {useState} from 'react';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
} from '@mui/material';
import {getUpdateTicketStatusDialogStyles} from './update-ticket-status.styles';

interface Props {
  row: any;
  changeStatusAction?: any;
}

export const UpdateTicketStatusDialog: React.FC<Props> = ({
  row,
  changeStatusAction,
}) => {
  const classes = getUpdateTicketStatusDialogStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [info, setInfo] = useState<{
    status: 'decline' | 'accept' | null;
    id: number | null;
  }>({status: null, id: null});

  const handleClickOpen = (status: 'decline' | 'accept', id: number) => {
    setInfo({status, id});
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const acceptRequest = (id: number) => {
    changeStatusAction({
      ticketid: id,
      status: 2,
    });
  };

  const declineRequest = (id: number) => {
    changeStatusAction({
      ticketid: id,
      status: 3,
    });
  };

  const handleAgree = () => {
    if (info.id && info.status === 'decline') {
      declineRequest(info.id);
      handleClose();
    }
    if (info.id && info.status === 'accept') {
      acceptRequest(info.id);
      handleClose();
    }
  };

  return (
    <Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => handleClickOpen('accept', row.id)}
        >
          Accept
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => handleClickOpen('decline', row.id)}
        >
          Decline
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want {info.status} ticket {info.id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
