import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import moment from 'moment';
import { useStores } from '../../stores/app';
import {
  Column,
  CustomTable,
  LinkCell,
  DatetimeCell,
} from '../../components/table';
import { Typography } from '@mui/material';
import {
  FinishEvent,
  ReqTourStatGet,
  TourMake,
  TourUpdate,
} from '../../proto/leaderboard_pb';
import { FinishTournament } from '../../components/misc/finish-tournament';
import { CreateTournamentModal } from '../../components/modals';

enum EventStatus {
  NOT_STARTED = 'not_started',
  CURRENT = 'current',
  FINISHED = 'finished',
}

export const SeasonInfo = observer(() => {
  const { seasonsStore, tournamentsStore } = useStores();
  const { id } = useParams();

  const seasonItem = seasonsStore.seasons.entities.filter(
    (item) => item.id === Number(id),
  )[0];

  const loadTournaments = (dto: ReqTourStatGet.AsObject) =>
    tournamentsStore.getTournament(dto);

  const createTournament = (dto: TourMake.AsObject) =>
    tournamentsStore.createTournament(dto);

  const updateTournament = (dto: TourUpdate.AsObject) => 
    tournamentsStore.updateTournament(dto);

  const finishTournament = (dto: FinishEvent.AsObject) =>
    tournamentsStore.finishTournament(dto);

  const columns: Column[] = [
    {
      id: 'id',
      label: 'ID',
      minWidth: 50,
      component: (props: any) => (
        <LinkCell
          value={props.id}
          url={`/dashboard/tour-info/${props.id}/${id}`}
        />
      ),
    },
    {
      id: 'startDate',
      label: 'Start Date',
      minWidth: 300,
      format: (value: number) =>
        value ? moment(+value).format('YYYY-MM-DD, HH:mm') : 'until now',
      component: (props: any) => {
        if (props.status === 'current' || props.status === 'not_started') {
          return (
            <DatetimeCell
              value={+props.startDate}
              onChange={(startDate) => {
                updateTournament({ ...props, startdate: startDate });}
              }
            />
          );
        } else {
          return <>{moment(+props.startDate).format('YYYY-MM-DD, HH:mm')}</>;
        }
      },
    },
    {
      id: 'endDate',
      label: 'End Date',
      minWidth: 300,
      format: (value: number) =>
        value ? moment(+value).format('MM/DD/YYYY') : 'until now',
      component: (props: any) => {
        if (props.status === 'current' || props.status === 'not_started') {
          return (
            <DatetimeCell
              value={+props.endDate}
              onChange={(endDate) => updateTournament({ ...props, enddate: endDate })}
            />
          );
        } else {
          return <>{moment(+props.endDate).format('YYYY-MM-DD, HH:mm')}</>;
        }
      },
    },
    { id: 'participants', label: 'Participants', minWidth: 70 },
    {
      id: 'status',
      label: 'Status',
      minWidth: 70,
      component: (props: any) => (
        <FinishTournament
          id={props.id}
          value={props.status}
          finishTour={finishTournament}
        />
      ),
    },
    { id: 'bointsPool', label: 'Individual prize', minWidth: 70 },
  ];

  const seasonInfoBlock = (item: typeof seasonsStore.seasons.entities[0]) => {
    return (
      <>
        <Typography>{item.name}</Typography>
        <Typography>
          {moment(+item.startDate).format('MM/DD/YYYY') +
            ' - ' +
            (item.status === EventStatus.CURRENT
              ? 'until now'
              : moment(+item.endDate).format('MM/DD/YYYY'))}
        </Typography>
      </>
    );
  };

  const isTournamentFinished: boolean = tournamentsStore.current ? false : true;

  return (
    <CustomTable
      columns={columns}
      data={tournamentsStore.tournaments.entities}
      isDataSliced={true}
      totalCount={tournamentsStore.tournaments.totalCount}
      isGoBackShow={true}
      isSortEnabled={false}
      requestAction={loadTournaments}
      paramsForAction={{ seasonid: id }}
      infoBlock={seasonItem && seasonInfoBlock(seasonItem)}
      pageAction={() => (
        <CreateTournamentModal
          seasonid={Number(id)}
          createTournament={createTournament}
          isTournamentFinished={isTournamentFinished}
        />
      )}
    />
  );
});
