import {createStyles, makeStyles} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export const getEditableCellStyle = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      marginRight: theme.spacing(1),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(1, 0),
    },
    iconWrapper: {
      display: 'flex',
    },
  })
);
